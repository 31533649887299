import React, { useEffect } from 'react';
import {useLocation, useParams} from "react-router-dom";
import queryString from 'query-string';
//import axios from "axios";
//import Grid from '@mui/material/Grid';
//import CircularProgress from '@mui/material/CircularProgress';

//let endpoint = "http://localhost:8080/api";
var hostbase = process.env.REACT_APP_ANGULAR_LEGACY_BASE;

//MainPage receives props
const Items = props => {
	document.title="Items - Legacy System";
	var searchparams = window.location.search;
	var searchvalues = queryString.parse(searchparams);
	searchvalues.hasOwnProperty = Object.hasOwnProperty;
	var appendage="";
	if (searchvalues.hasOwnProperty('serial')){
		appendage="&serial="+searchvalues.serial;
	}
	//Hide Scroll Bars for injected content.
	return (
		<div style={{ textAlign: 'center', overflow: "hidden", paddingTop:"5px" }}>
			<div style={{ height: (window.innerHeight - 35) + "px" }}>
				<iframe src={hostbase+"/legacy/items?react=true"+appendage} style={{ width: "100%", border:"none" }} height={window.innerHeight - 35}></iframe>
			</div>
		</div>
	)
}


export default Items;
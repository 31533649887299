//Dead Project

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";
import { styled } from '@mui/material/styles';

import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';


//ErrorContext
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//Material UI Components
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Collapse from '@mui/material/Collapse';

import Fade from '@mui/material/Fade';

//import { useHistory } from "react-router-dom";
//import { v4 as uuidv4 } from 'uuid';
//import fetch from 'cross-fetch';

//Search Tools
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/lab/Autocomplete';
// import CircularProgress from '@mui/material/CircularProgress';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';



//Tables
// import TablePagination from '@mui/material/TablePagination';
// import TableSortLabel from '@mui/material/TableSortLabel';
// import PropTypes from 'prop-types';
// import Checkbox from '@mui/material/Checkbox';


//App Components
// import Button from '@mui/material/Button';
// import Grid from '@mui/material/Grid';

//Icons
// import IconButton from '@mui/material/IconButton';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import AddIcon from '@mui/icons-material/Add';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';


//Inventory Manager
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import MenuIcon from '@mui/icons-material/Menu';
// import SearchIcon from '@mui/icons-material/Search';
// import MoreIcon from '@mui/icons-material/MoreVert';



//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;
let logouturl = process.env.REACT_APP_LOGOUTURL;


/*	Row Monitor takes a row input, outputs list of Products (ProductID)

Count?

//Need to store in PHP SESSION rows?

*/

const RowMonitor = (props) => {
	/* ##########################  AppState UseState Variables  ########################## */	  
	let localappstate = Object.assign({}, props.appstate);




	/* ##########################  UseState Variables  ########################## */	  
	const classes = useClasses(flexstyles);
	const [state, setState] = useState({
		/*dbreload:false, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		griditems:[],		//Defaults
		totalitems:0,
		page:0, //Assume page 0, or else pagination throws and error.
		order:'asc',
		orderby:'Name',
		selectedcount:0,
		rowsperpage:10,
		selectedindexes:[],
		pendingsaves:false, //Used for parent view - Warnings about unsaved items!
		searchoptions:{
			//New! Key-Value pair array. Easier to itterate in API.
			searchpairs:{
				searchpair1:{type:"", "value": "", "mode":"like"},
				searchpair2:{"type":"", "value": "", "mode":"strict"},
				searchpair3:{"type":"", "value": "", "mode":"left"},
				searchpair4:{"type":"Name", "value": "", "mode":"right"}
			}
		}
		*/

		//On init, fetch columnrows
		fetchcolumnrows:true,
		columnrow1:"",
		columnset1:false,
		columndata1:{
			rows:[],
			expandedsearch:{
				show:false,
				productid:false,
				cpus:[],
				resolutions:[],
				grades:[]
			}
		},

		columnrow2:"",
		columnset2:false,
		columndata2:{
			rows:[],
			expandedsearch:{
				show:false,
				productid:false,
				cpus:[],
				resolutions:[],
				grades:[]
			}
		},

		cpus:[],
		resolutions:[],
		grades:[],
		bodygrades:[],
		lcdgrades:[],
		selectedcpu:"",
		selectedres:"",
		selectedlcd:"",
		selectedgrade:"",

		dbreload:false
	});


	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);
	
	function UpdateState(stateobject) {
		setState(stateobject);
	}

	//Error Context
	const errors = useContext(ErrorContext);

	useEffect(() => {
		document.title="Row Monitor";
		if (state.fetchcolumnrows){
			localstate.fetchcolumnrows = false;
			GetSessionColumnRows("rowmonitor");
		}
		if (state.dbreload){
			//Avoid duplicate loades.
			localstate.dbreload = false;
			LoadRows();
			console.log("DB Reload");
			//LoadItems();
		} 

	},);

	const SetColumn = (columnnumber, event) => {
		if (event.which === 13){
			localstate["columnrow"+columnnumber] = event.target.value;
			localstate["columnset"+columnnumber] = true;
			localstate.dbreload = true;
			SetSessionColumn(localstate);
			UpdateState(localstate);
		}
	}

	const GetSessionColumnRows = (variabletype) => {
		const postdata = {
			variabletype: variabletype
		};
		var postoptions = {
			withCredentials: true,
			withXSRFToken: true,
			crossDomain: true,
			mode: "no-cors",
			timeout: 11800,
		};
		axios.post(dbendpoint + "/sessionvariables/get", postdata, postoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					//window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					//console.log(res);
					//According to the internet, this is the fastest way to see if array key exists
					if (res.data["rowmonitor"]!==undefined){
						if (res.data["rowmonitor"]["columnrow1"]!==undefined){
							localstate.columnrow1 = res.data["rowmonitor"]["columnrow1"];
							localstate.columnset1 = true;
						}
					}
					if (res.data["rowmonitor"]!==undefined){
						if (res.data["rowmonitor"]["columnrow2"]!==undefined){
							localstate.columnrow2 = res.data["rowmonitor"]["columnrow2"];
							localstate.columnset2 = true;
						}
					}
					localstate.dbreload = true;
					UpdateState(localstate);
				}
				if (res.data.Status === "Failure") {
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
		});
	}

	const SetSessionColumn = (localstate) => {
		const postdata = {
			variabletype:"rowmonitor",
			columnrow1: localstate.columnrow1,
			columnrow2: localstate.columnrow2
		};
		var postoptions = {
			withCredentials:true,
			withXSRFToken: true,
			crossDomain:true,
			mode:"no-cors",
			timeout:11800,
		};
		axios.post(dbendpoint+"/sessionvariables/set", postdata, postoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==="login"){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status==="Success"){
					console.log(res);
				}
				if (res.data.Status==="Failure"){
					//Failure error
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}

	const EditColumnValue = (columnnumber) => {
		localstate["columnrow"+columnnumber] = "";
		localstate["columnset"+columnnumber] = false;
		UpdateState(localstate);
	}

	//Load Rows
	//Just Products!
	function LoadRows(){  
		const postdata = {
			searchoptions:{
				limit:500,
				columnrows:[localstate.columnrow1,localstate.columnrow2]
			}
		};
		var postoptions = {
			withCredentials:true,
			withXSRFToken: true,
			crossDomain:true,
			mode:'no-cors',
			timeout:11800,
		};
		
		axios.post(dbendpoint+"/items/getrowitems", postdata, postoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==='login'){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status==='Success'){
					//We should now have a non-0 result from the API
					//Add variables for use with table
					var i=0;
					//for (i=0; i<res.data.rows.length )

					localstate.columndata1.rows=res.data.columnrows[localstate.columnrow1];
					localstate.columndata2.rows=res.data.columnrows[localstate.columnrow2];
					console.log(localstate.columndata1);
					UpdateState(localstate);
				}
				if (res.data.Status==='Failure'){
					//Failure error
					//localstate.griditems=[];
					UpdateState(localstate);
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:'neutral'})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:'warning'})
			}
		});
	}

	const ExpandProductSearch = (columnnumber, row, productid, assettype) => {
		if (localstate["columndata"+columnnumber].expandedsearch.productid===productid){
			//Unset current 
			localstate["columndata"+columnnumber].expandedsearch.productid="";
			localstate["columndata"+columnnumber].expandedsearch.show=false;
			//Unset selections
			localstate.selectedcpu = "";
			localstate.selectedres = "";
			localstate.selectedlcd = "";
			localstate.selectedgrade = "";
			UpdateState(localstate);
		} else {
			localstate["columndata"+columnnumber].expandedsearch.show=true;
			localstate["columndata"+columnnumber].expandedsearch.productid=productid;
			//Unset selections
			localstate.selectedcpu = "";
			localstate.selectedres = "";
			localstate.selectedlcd = "";
			localstate.selectedgrade = "";
			const postdata = {
				searchoptions:{
					row:row,
					productid: productid,
					assettype:assettype
				}
			};
			var postoptions = {
				withCredentials:true,
				withXSRFToken: true,
				crossDomain:true,
				mode:'no-cors',
				timeout:11800,
			};
			
			axios.post(dbendpoint+"/items/getitemcomponents", postdata, postoptions).then(res => {
				//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
				if (res.status===200){
					//If ValidateUser() fails to verify user, it sends back 'login' error. 
					if (res.data.Status==='login'){
						//Not logged in. Reload page causes redirect to /login
						window.location.reload(false);
					}
					//All new API calls should return a status.
					if (res.data.Status==='Success'){
						//We should now have a non-0 result from the API
						//Add variables for use with table
						localstate.cpus = res.data.cpus;
						localstate.resolutions = res.data.resolutions;
						localstate.grades = res.data.grades;
						localstate.bodygrades = res.data.bodygrades;
						localstate.lcdgrades = res.data.lcdgrades;
						UpdateState(localstate);
					}
					if (res.data.Status==='Failure'){
						//Failure error
						//localstate.griditems=[];
						UpdateState(localstate);
						errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:'neutral'})
					}
				} else {
					//Non-200 message from server.
					errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:'warning'})
				}
			});
		}
		
	}

	const SelectComponent = (componenttype, componentstring) =>{
		switch (componenttype) {
			case "cpu":
				if (localstate.selectedcpu===componentstring){
					localstate.selectedcpu = "";
				} else {
					localstate.selectedcpu = componentstring;
				}
				break;
			case "res":
				if (localstate.selectedres===componentstring){
					localstate.selectedres = "";
				} else {
					localstate.selectedres = componentstring;
				}
				break;
			case "lcd":
				if (localstate.selectedlcd===componentstring){
					localstate.selectedlcd = "";
				} else {
					localstate.selectedlcd = componentstring;
				}
				break;
			case "grade":
				if (localstate.selectedgrade===componentstring){
					localstate.selectedgrade = "";
				} else {
					localstate.selectedgrade = componentstring;
				}
				break;
			default:
				break
		}

		UpdateState(localstate);
	}

	const SearchInventory = (productname) => {
		//Match format of other controller
		var searchpairs = {
			searchpair1: {type:"Model", value:productname, mode:"strict", uuid:uuidv4()},
			searchpair2:{type:"CPU", value: localstate.selectedcpu, mode:"like", uuid:uuidv4()},
		 	searchpair3:{type:"Resolution", value: localstate.selectedres, mode:"like", uuid:uuidv4()},
		 	searchpair4:{type:"LCDGrade", value: localstate.selectedlcd, mode:"strict", uuid:uuidv4()},
		 	searchpair5:{type:"Grade", value: localstate.selectedgrade, mode:"strict", uuid:uuidv4()},
		 	searchpair6:{type:"Status", value: "Checked In", mode:"strict", uuid:uuidv4()}
		}
		//Pass searchpairs to AppState
		localappstate.itemsearch.searchpairs = searchpairs;
		localappstate.itemsearch.usevars = true;
		localappstate.navigate=true;
		localappstate.navigateto="/inventorymanager";
		props.updateState(localappstate);
	}





	/* ##########################  Render Function  ########################## */
	return (
        <div style={{minWidth:"1060px", height:"100%", backgroundColor:"#DDD", padding:"0px"}}>

			{(errors.currenterror.errshow) &&
				<div style={{ position: "relative", float: "right", bottom: "26px", height: "25px", fontSize: "12px" }}>
					<ErrorMessage />
				</div>
			}

			{(!state.columnset1 || !state.columnset2) &&
				<div style={{ height: "55px" }}>
					<div style={{ textAlign: 'center' }}>
						<h2>Assign Rows:</h2>
					</div>
				</div>
			}

			{/* Container that holds 2 columns */}
			<Grid container spacing={3} direction="row"   display="flex"   justifyContent="flex-start">

				{/* Column 1 */}
				<Grid item xs={6}>
					<div style={{ textAlign: "center", fontSize:"50px" }}>
						<div className={classes.rowlabel} onClick={()=>{EditColumnValue(1)}}>Row: {state.columnrow1}</div> 
						{(!state.columnset1) &&
							<TextField id="row1" variant="outlined"
								onKeyUp={(event) => SetColumn(1, event)} onBlur={(event) =>SetColumn(1, event)} InputProps={{
									classes: {
										input: classes.biginput,
									}
								}}
							/>
						}
					</div>
				
					{/* Map on Row Data */}
					{state.columndata1.rows.map((product, index) => {
						return (
							<div style={{ margin: "auto", maxWidth: "800px" }}>
								<Paper className={classes.paper} style={{ padding: "10px", margin: "10px 0px", fontSize: "30px", maxWidth: "750px"}}>

									<div style={{ width: "80px", display: "inline-block", verticalAlign:"top" }}>
										({product.count})
									</div>
									<div style={{ maxWidth:"640px", display: "inline-block" }} className={classes.hoverunit} onClick={() => ExpandProductSearch(1, state.columnrow1, product.ProductID, product.product.ProductType)}>
										{product.product.Name}
									</div>

									{(localstate.columndata1.expandedsearch.show && (localstate.columndata1.expandedsearch.productid === product.ProductID)) &&
										<div style={{ fontSize: "24px" }}>


											{/* CPUs */}
											<div style={{ margin: "10px 0px" }}>
												<div style={{ width: "80px", display: "inline-block", textAlign: "right", verticalAlign:"top" }}>
													CPU:
												</div>
												<div style={{ maxWidth:"640px", display: "inline-block" }}>
													{localstate.cpus.map((cpu, index) => {
														if (cpu.CPU) {
															return (
																<Button variant="outlined" style={{ marginLeft: "10px" }}
																	className={state.selectedcpu === cpu.CPU ? classes.gradea : ""}
																	onClick={() => SelectComponent("cpu", cpu.CPU)}>
																	{cpu.CPU}
																</Button>
															)
														}
													})}
												</div>
											</div>

											{/* Resolution */}
											<div style={{ margin: "10px 0px" }}>
												<div style={{ width: "80px", display: "inline-block", textAlign: "right", verticalAlign:"top" }}>
													Res:
											</div>
												<div style={{ maxWidth:"640px", display: "inline-block" }}>
													{localstate.resolutions.map((res, index) => {
														if (res.Resolution) {
															return (
																<Button variant="outlined" style={{ marginLeft: "10px" }}
																	className={state.selectedres === res.Resolution ? classes.gradea : ""}
																	onClick={() => SelectComponent("res", res.Resolution)}>
																	{res.Resolution}
																</Button>
															)
														}
													})}
												</div>
											</div>

											{/* LCD Grade */}
											<div style={{ margin: "10px 0px" }}>
												<div style={{ width: "80px", display: "inline-block", textAlign: "right", verticalAlign:"top" }}>
													LCD:
											</div>
												<div style={{ maxWidth:"640px", display: "inline-block" }}>
													{localstate.lcdgrades.map((lcd, index) => {
														if (lcd.LCDGrade) {
															return (
																<Button variant="outlined" style={{ marginLeft: "10px" }}
																	className={state.selectedlcd === lcd.LCDGrade ? classes.gradea : ""}
																	onClick={() => SelectComponent("lcd", lcd.LCDGrade)}>
																	{lcd.LCDGrade}
																</Button>
															)
														}
													})}
												</div>
											</div>

											{/* Grade */}
											<div style={{ margin: "10px 0px" }}>
												<div style={{ width: "80px", display: "inline-block", textAlign: "right", verticalAlign:"top" }}>
													Grade:
											</div>
												<div style={{ maxWidth:"490px",display: "inline-block" }}>
													{localstate.grades.map((grade, index) => {
														if (grade.Grade) {
															return (
																<Button variant="outlined" style={{ marginLeft: "10px" }}
																	className={state.selectedgrade === grade.Grade ? classes.gradea : ""}
																	onClick={() => SelectComponent("grade", grade.Grade)}>
																	{grade.Grade}
																</Button>
															)
														}
													})}
												</div>
												<div style={{ maxWidth:"200px", float: "right" }}>
													<Button variant="contained" color="primary" style={{ marginRight: "10px" }}														
														onClick={()=>SearchInventory(product.product.Name)}>
														Search
													</Button>
												</div>
											</div>
										</div>
									}

								</Paper>
							</div>
							
						)
					})}
				</Grid>
				{/* End of Column 1 */}

				{/* Column 2 */}
				<Grid item xs={6}>
					<div style={{ textAlign: "center", fontSize:"50px" }}>
						<div className={classes.rowlabel} onClick={()=>{EditColumnValue(2)}}>Row: {state.columnrow2}</div> 
						{(!state.columnset2) &&
							<TextField id="row2" variant="outlined"
								onKeyUp={(event) => SetColumn(2, event)} onBlur={(event) =>SetColumn(2, event)} InputProps={{
									classes: {
										input: classes.biginput,
									}
								}}
							/>
						}
					</div>
				
					{/* Map on Row Data */}
					{state.columndata2.rows.map((product, index) => {
						return (
							<div style={{ margin: "auto", maxWidth: "800px" }}>
								<Paper className={classes.paper} style={{ padding: "10px", margin: "10px 0px", fontSize: "30px", maxWidth: "750px"}}>

									<div style={{ width: "80px", display: "inline-block", verticalAlign:"top" }}>
										({product.count})
									</div>
									<div style={{ maxWidth:"640px", display: "inline-block" }} className={classes.hoverunit} onClick={() => ExpandProductSearch(2, state.columnrow2, product.ProductID, product.product.ProductType)}>
										{product.product.Name}
									</div>

									{(localstate.columndata2.expandedsearch.show && (localstate.columndata2.expandedsearch.productid === product.ProductID)) &&
										<div style={{ fontSize: "24px" }}>


											{/* CPUs */}
											<div style={{ margin: "10px 0px" }}>
												<div style={{ width: "80px", display: "inline-block", textAlign: "right", verticalAlign:"top" }}>
													CPU:
												</div>
												<div style={{ maxWidth:"640px", display: "inline-block" }}>
													{localstate.cpus.map((cpu, index) => {
														if (cpu.CPU) {
															return (
																<Button variant="outlined" style={{ marginLeft: "10px" }}
																	className={state.selectedcpu === cpu.CPU ? classes.gradea : ""}
																	onClick={() => SelectComponent("cpu", cpu.CPU)}>
																	{cpu.CPU}
																</Button>
															)
														}
													})}
												</div>
											</div>

											{/* Resolution */}
											<div style={{ margin: "10px 0px" }}>
												<div style={{ width: "80px", display: "inline-block", textAlign: "right", verticalAlign:"top" }}>
													Res:
											</div>
												<div style={{ maxWidth:"640px", display: "inline-block" }}>
													{localstate.resolutions.map((res, index) => {
														if (res.Resolution) {
															return (
																<Button variant="outlined" style={{ marginLeft: "10px" }}
																	className={state.selectedres === res.Resolution ? classes.gradea : ""}
																	onClick={() => SelectComponent("res", res.Resolution)}>
																	{res.Resolution}
																</Button>
															)
														}
													})}
												</div>
											</div>

											{/* LCD Grade */}
											<div style={{ margin: "10px 0px" }}>
												<div style={{ width: "80px", display: "inline-block", textAlign: "right", verticalAlign:"top" }}>
													LCD:
											</div>
												<div style={{ maxWidth:"640px", display: "inline-block" }}>
													{localstate.lcdgrades.map((lcd, index) => {
														if (lcd.LCDGrade) {
															return (
																<Button variant="outlined" style={{ marginLeft: "10px" }}
																	className={state.selectedlcd === lcd.LCDGrade ? classes.gradea : ""}
																	onClick={() => SelectComponent("lcd", lcd.LCDGrade)}>
																	{lcd.LCDGrade}
																</Button>
															)
														}
													})}
												</div>
											</div>

											{/* Grade */}
											<div style={{ margin: "10px 0px" }}>
												<div style={{ width: "80px", display: "inline-block", textAlign: "right", verticalAlign:"top" }}>
													Grade:
											</div>
												<div style={{ maxWidth:"490px",display: "inline-block" }}>
													{localstate.grades.map((grade, index) => {
														if (grade.Grade) {
															return (
																<Button variant="outlined" style={{ marginLeft: "10px" }}
																	className={state.selectedgrade === grade.Grade ? classes.gradea : ""}
																	onClick={() => SelectComponent("grade", grade.Grade)}>
																	{grade.Grade}
																</Button>
															)
														}
													})}
												</div>
												<div style={{ maxWidth:"200px", float: "right" }}>
													<Button variant="contained" color="primary" style={{ marginRight: "10px" }}														
														>
														Search
													</Button>
												</div>
											</div>
										</div>
									}

								</Paper>
							</div>
							
						)
					})}
				</Grid>
				{/* End of Column 2 */}

			</Grid>

		</div>
    );
}

export default RowMonitor;

//Listings Table

/* ##########################  Configuration Sections  ########################## */
//## UseState Variables
//## Column States
//##Column Configuration
//##Column Toggles
//##Row Design
//##Search Inputs
//##Button Functions

//React & Friends
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";


//Redux Features
import { useSelector, useDispatch } from 'react-redux';
//ProgressBar
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import { ProgressBar } from '../../features/progressbar/ProgressBar';
import {
	newProgress,
	incrementPass,
	incrementFail,
	setProgressTimeout
} from '../../features/progressbar/progressbarSlice';
//Main Menu
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//Contexts
//AuthContext
import { AppContext } from "../Auth/contexts/AppContext"
//Error Context - warning, danger, ok, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';


//MaterialUI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';

//Tables
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';

//Icons
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//Export
import ExportCSV from '../common/ExportCSV';

import theme from '../../ui/theme';


/* ##########################  Configuration  ########################## */

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 300000,
};

//Helper Functions
//Have not used sleep just yet - is currently on auto-complete sample
function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}

//Find Duplicate Example:
//This will short-circuit once some() finds a truthy value.
var values = [
	{ name: 'someName1' },
	{ name: 'someName2' },
	{ name: 'someName4' },
	{ name: 'someName1' }
];

var valueArr = values.map(function (item) { return item.name });
var isDuplicate = valueArr.some(function (item, idx) {
	return valueArr.indexOf(item) !== idx
});
//console.log(isDuplicate);

//Simple Find Duplicates (simple array of values).
const input = [1, 1, 2, 3, 3];
const GetDupeArray = (inputarray) => {
	var results = inputarray.reduce(function (acc, el, i, arr) {
		if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
	}, []);
	return results;
}
const dupearray = GetDupeArray(input);
//console.log("Duplicates2: "+dupearray); // = 1,3 (actual array == [1, 3])

//Find and return all unique values:
const GetUniqueArray = (inputarray) => {
	return inputarray.filter((x, i, a) => a.indexOf(x) === i);
}


//Remove all instances of string from string:
String.prototype.replaceAll = function (find, replace) {
	var str = this;
	return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
};



const ListingsTable = (props) => {
	document.title = "Deluxe Listings";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Inventory"));
	dispatch(setCurrentMenuItem("/listings"));


	//Init with IDs
	const params = new URLSearchParams(useLocation().search);
	var ids = params.get('ids');
	//console.log(ids);
	//Explode to array:
	var idstring = "";
	if (ids) {
		var idarray = ids.split("-");
		for (var i = 0; i < idarray.length; i++) {
			idstring += idarray[i];
			if ((i + 1) < idarray.length) {
				//Add new line
				idstring += "\r\n";
			}
		}
	}

	//Set Item List later on - For now we need this in search options....


	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);
	const isPrintView = useMediaQuery("print");

	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;

	const rowRefs = useRef([]);

	/* ##########################  UseState Variables  ########################## */
	const [state, setState] = useState({
		dbreload: true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		clearselection: true,//Default clear selection everytime we reload DB. Continuous bulk edits may set this to false between updates.
		griditems: [],		//Defaults
		defaultvalues: [],   //Default values: Useful for initial render.
		totalitems: 0,
		page: 0, //Assume page 0, or else pagination throws an error.
		order: "asc",
		orderby: "ListingType", //
		selectedcount: 0,
		rowsperpage: 100,
		selectedindexes: [],
		pendingsaves: false, //Used for parent view - Warnings about unsaved items!
		searchoptions: {
			//New! Key-Value pair array. Easier to itterate in API.
			searchpairs: {
				//Reserved for basic searchpairs. Inject search parameter 'nameparameter' here.
				searchpair1: { type: "Model", value: "", mode: "like", uuid: uuidv4() },
				searchpair2: { type: "", value: "", mode: "like", uuid: uuidv4() },
				searchpair3: { type: "", value: "", mode: "left", uuid: uuidv4() },
				searchpair4: { type: "", value: "", mode: "right", uuid: uuidv4() },
				searchpair5: { type: "", value: "", mode: "like", uuid: uuidv4() },
				searchpair6: { type: "", value: "", mode: "like", uuid: uuidv4() },
				//Reserved for AutoComplete searchpairs.
				searchpair7: { type: "", value: "", mode: "strict", uuid: uuidv4() },
				searchpair8: { type: "", value: "", mode: "strict", uuid: uuidv4() },
				searchpair9: { type: "", value: "", mode: "strict", uuid: uuidv4() },
				searchpair10: { type: "", value: "", mode: "strict", uuid: uuidv4() },
			},
			nestedrelationships: {

			}
		},
		currentitem: false, //Used to load item view and init grades
		currentindex: false
	});

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}



	/* ##########################  Top Button Refs and Functions  ########################## */	 
	//Buttons must exist in order to disable or enable them. Hide buttons with hidden class and ternary.
	const btnSave = useRef();
	const btnPendingSave = useRef();
	const btnAddRow = useRef();
	const btnDeleteSelected = useRef();
	const btnExpandAll = useRef();
	const btnResetSearches = useRef();
	const btnEditSelected = useRef();
	const btnItemList = useRef();
	const btnExport = useRef();
	const btnEditMode = useRef();
	

	//Disable Buttons
	const DisableButtons = () => {
		btnSave.current.setAttribute("disabled", true);
		btnPendingSave.current.setAttribute("disabled", true);
		btnAddRow.current.setAttribute("disabled", true);
		btnDeleteSelected.current.setAttribute("disabled", true);
		btnExpandAll.current.setAttribute("disabled", true);
		btnResetSearches.current.setAttribute("disabled", true);
		btnEditSelected.current.setAttribute("disabled", true);
		btnExport.current.setAttribute("disabled", true);
		btnItemList.current.setAttribute("disabled", true);
		btnEditMode.current.setAttribute("disabled", true);
	}

	//Enable Buttons
	const EnableButtons = () => {
		btnSave.current.removeAttribute("disabled");
		btnPendingSave.current.removeAttribute("disabled");
		btnAddRow.current.removeAttribute("disabled");
		btnDeleteSelected.current.removeAttribute("disabled");
		btnExpandAll.current.removeAttribute("disabled");
		btnResetSearches.current.removeAttribute("disabled");
		btnEditSelected.current.removeAttribute("disabled");
		btnExport.current.removeAttribute("disabled");
		btnItemList.current.removeAttribute("disabled");
		btnEditMode.current.removeAttribute("disabled");
	}





	//Reusable test to be used with useState bool variables
	//Invalid for none selected or pending changes
	//Bulk Edit Selected
	//Export Selected
	//Print Selected
	const RejectIfInvalidSelected = (value, fnCallback) => {
		if (localstate.selectedindexes.length === 0) {
			//Lock in potential localstate.pendingsaves
			if (localstate.pendingsaves) {
				UpdateState(localstate);
			}
			errors.NewError({ errmsg: "No items selected.", errshow: true, errtimeout: 5, errtype: 'neutral' });
		} else if (localstate.pendingsaves) {
			errors.NewError({ errmsg: "One or more items have a pending save.", errshow: true, errtimeout: 5, errtype: 'warning' });
		} else {
			//If all tests pass, use callback
			fnCallback(value);
		}
	}

	//Edit Mode
	const [editmode, setEditMode] = useState(false);
	const EditMode = () => {
		setEditMode(!editmode);
	}


	/* ##########################  Column States (Colstates!)  ########################## */
	//Used for hiding/showing columns. Can access using bracket notation later on! colstate[headCell.id]
	const [colstate, setColState] = useState({
		SerialNumber: true,
		Qty: true,
		AssetType: true,
		Model: true,
		Grade: true,
		BodyGrade: true,
		LCDGrade: true,
		CPU: true,
		RAM: true,
		HDD1Size: true,
		HDD2Size: false,
		Resolution: true,
		Webcam: true,
		Batt1: true,
		Batt2: false,
		Video1Desc: true,
		Video2Desc: false,
		Touchscreen: true,
		Bluetooth: true,
		Network1: false,
		Network2: false,
		Network3: false,
		Network4: false,
		Network5: false,
		Network6: false,
		ACAdapter: true,
		created_at: true,
		ListingType: true,
		AskPrice: true,
		Status: true,
		Published: true //Set bool for customer viewable
	});

	const UpdateColState = (colstate) => {
		setColState(colstate);
	}


	/* ##########################  Menus  ########################## */

	/* Column Menu */
	const [showcolumnmenu, setColumnMenu] = useState(null);
	const ShowColumnMenu = (event) => {
		setColumnMenu(event.currentTarget);
	}
	const CloseColumnMenu = () => {
		setColumnMenu(null);
	}
	const ToggleColumn = (key) => {
		let newcolstate = colstate;
		newcolstate[key] = !newcolstate[key];
		UpdateColState(newcolstate);
	}
	const FlexColumnOption = (props) => {
		let columnvalue = props.value;
		return (
			<React.Fragment>
				{(colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox defaultChecked onClick={() => { ToggleColumn(columnvalue) }} />} label={props.label} className={classes.columnselecthover} />
				}
				{(!colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox onClick={() => { ToggleColumn(columnvalue) }} />} label={props.label} className={classes.columnselecthover} />
				}
			</React.Fragment>
		)
	}
	const SetCustomView1 = () => {
		//Applies a few as set to true.
		let newcolstate = colstate;
		newcolstate['Name'] = true;
		newcolstate['Cost'] = true;
		newcolstate['Margin'] = true;
		UpdateColState(newcolstate);
		CloseColumnMenu();
	}

	/* Export Menu */
	const [showexportmenu, setExportMenu] = useState(null);
	const ShowExportMenu = (event) => { setExportMenu(event.currentTarget); }
	const CloseExportMenu = () => { setExportMenu(null); }


	/* ##########################  Selected Rows  ########################## */
	const SelectRow = (index) => {
		//alert(index);
		if (localstate.selectedindexes.indexOf(index) === -1) {
			localstate.selectedindexes.push(index);
			//Check for condition that would check Select All Checkbox - Requires Rerender
			if (localstate.griditems.length === localstate.selectedindexes.length) {
				UpdateState(localstate);
			}
		} else {
			var spliceindex = localstate.selectedindexes.indexOf(index);
			localstate.selectedindexes.splice(spliceindex, 1);
			//Check for condition that would un-check Select All Checkbox, just 1 less will do - Rerender
			if (localstate.griditems.length === (localstate.selectedindexes.length + 1)) {
				UpdateState(localstate);
			}
		}

		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length === 0) {
			setShowExportConfirmation(false);
		}
	}

	const handleSelectAllClick = (event) => {
		//Material UI Checkbox Component won't rerender unless we force it. Set a changed GridKey so that shallow comparison fails.
		var i = 0;
		if (event.target.checked) {
			localstate.selectedindexes = [];
			for (i = 0; i < localstate.griditems.length; i++) {
				localstate.griditems[i].isSelected = true;
				localstate.selectedindexes.push(i);
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		} else {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
			for (i = 0; i < localstate.griditems.length; i++) {
				localstate.griditems[i].isSelected = false;
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		}
		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length === 0) {
			setShowExportConfirmation(false);
		}
	};



	/* ##########################  Search Options  ########################## */
	// Returns a function, that, as long as it continues to be invoked, will not
	// be triggered. The function will be called after it stops being called for
	// N milliseconds. If `immediate` is passed, trigger the function on the
	// leading edge, instead of the trailing.
	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};



	//API Build-out: 
	//	Old: Types [searchtype, sub1type, sub2type] , Search [search, sub1search, sub2search]
	//	New: Types [searchtype1, searchtype2, searchtype3], Search [search1, search2, search3]
	//Set Search Key:
	var mode = "";
	const onChangeSearchType = (searchtype, searchnumber) => {
		//Search Mode: Each search type may have a different search mode
		//left, right, like, strict, not
		//Default Mode: LIKE

		mode = "like";
		//Provision for automatically switching search mode
		if (searchtype === "Location") {
			mode = "strict";
		}

		//Not Conditionals: Convert NotName to Name in BasicTableController
		if (searchtype === "NotName") {
			mode = "not";
		}

		//Date Searches
		if (searchtype === "DateAfter") {
			mode = "dateafter";
		}
		if (searchtype === "DateBefore") {
			mode = "datebefore";
		}

		if (searchtype === "Cost >") {
			mode = "greaterthan";
		}
		if (searchtype === "Cost <") {
			mode = "lessthan";
		}


		localstate.searchoptions.searchpairs["searchpair" + searchnumber].type = searchtype;
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].mode = mode;
		//Provision to add columns if selected for search.
		if (searchtype === "Model") {
			colstate["Model"] = true;
			setColumnMenu(null);
		}
		UpdateState(localstate);
	}

	//Set Search Value:
	const onChangeSearchValue = debounce(function (searchvalue, searchnumber) {
		//Clears out changes because table is about to be reloaded:
		ResetPendingSaves();
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].value = searchvalue;

		//Provision to warn user about a bad datetime format:
		//On blank datetime, proceed to load data
		if ((localstate.searchoptions.searchpairs["searchpair" + searchnumber].type === "DateAfter" || localstate.searchoptions.searchpairs["searchpair" + searchnumber].type === "DateBefore") && searchvalue !== "") {
			var count = (searchvalue.match(/-/g) || []).length;
			if (searchvalue.length === 10 && count === 2) {
				let isValidDate = Date.parse(searchvalue);
				if (isNaN(isValidDate)) {
					errors.NewError({ errmsg: "Invalid date.", errshow: true, errtimeout: 5, errtype: "warning" });
				} else {
					localstate.page = 0;
					localstate.dbreload = true;
					UpdateState(localstate);
				}
			} else {
				errors.NewError({ errmsg: "Use YYYY-MM-DD format for dates.", errshow: true, errtimeout: 5, errtype: "ok" });
			}
		} else {
			localstate.page = 0;
			localstate.dbreload = true;
			UpdateState(localstate);
		}
	}, 600);

	//Key-Value Inputs
	const [searchinputs, setSearchInputs] = useState({
		show1: true,
		show2: true,
		show3: true,
		show4: true,
		show5: false,
		show6: false,
		lastsearch: 4
	});


	const AddSearch = () => {
		let newsearchinputs = Object.assign({}, searchinputs);
		newsearchinputs["show" + (newsearchinputs.lastsearch + 1)] = true;
		newsearchinputs.lastsearch++;
		setSearchInputs(newsearchinputs);
	}

	const RemoveSearch = () => {
		let newsearchinputs = Object.assign({}, searchinputs);
		newsearchinputs["show" + (newsearchinputs.lastsearch)] = false;
		newsearchinputs.lastsearch--;
		setSearchInputs(newsearchinputs);
	}



	/* ##########################  Loading and Page Changes  ########################## */
	const handleRequestSort = (event, property) => {
		const isAsc = localstate.orderby === property && localstate.order === "asc";
		localstate.order = (isAsc ? "desc" : "asc");
		localstate.orderby = property;
		localstate.dbreload = true;
		localstate.pendingsaves = false;
		UpdateState(localstate);
	};

	const handleChangePage = (event, newPage) => {
		localstate.pendingsaves = false;
		localstate.dbreload = true;
		localstate.page = newPage;
		UpdateState(localstate);
	};

	const handleChangeRowsPerPage = (event) => {
		localstate.pendingsaves = false;
		localstate.dbreload = true;
		localstate.rowsperpage = parseInt(event.target.value, 10);
		localstate.page = 0;
		UpdateState(localstate);
	};

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Items
	function LoadItems() {
		if (localstate.clearselection) {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
		} else {
			//Reset to clear selections on subsequent requests
			localstate.clearselection = true;
		}
		const postdata = {
			searchoptions: {
				limit: localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				searchpairs: localstate.searchoptions.searchpairs,
				nestedrelationships: localstate.searchoptions.nestedrelationships
			}
		};

		//Provision to send along item id list
		if (idarray) {
			if (idarray.length > 0) {
				postdata.searchoptions.itemlist = idarray;
			}
		}

		if (itemlistsearch.length > 0) {
			//Serials exist in serial list.
			postdata.searchoptions.itemlist = itemlistsearch;
		}

		axios.post(dbendpoint + "/listings/getitems?page=" + (localstate.page + 1), postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					//We should now have a non-0 result from the API
					//Add variables for use with table
					var resultdata = res.data.pagedata.data;
					for (var i = 0; i < resultdata.length; i++) {
						//Try: GridKey - Apply GridKey to components: key={row.GridKey}
						//Try: Increment GridKey between rerenders; ie: UpdateState(localstate);
						resultdata[i].GridKey = i;
						resultdata[i].unsaved = false;
						resultdata[i].ExpandRow = false;
						if (localstate.selectedindexes.includes(i)) {
							resultdata[i].isSelected = true;
						} else {
							resultdata[i].isSelected = false;
						}

						//Set Defaults for selectables: //Maybe got rid of these by using defaults /*
						/*
						resultdata[i].GradeDefault = resultdata[i].Grade;
						resultdata[i].BodyGradeDefault = resultdata[i].BodyGrade;
						resultdata[i].LCDGradeDefault = resultdata[i].LCDGrade;
						*/
					}

					//Hold items for default values
					localstate.defaultvalues = Object.assign({}, resultdata);

					localstate.griditems = resultdata;
					localstate.totalitems = res.data.pagedata.total;
					//Data freshly loaded, head off any new requests with this state change. Handle in useEffect?
					//localstate.dbreload = false;
					UpdateState(localstate);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					localstate.griditems = [];
					UpdateState(localstate);
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
		});
	}

	useEffect(() => {
		document.title = "Deluxe Deals Inventory";
		if (state.dbreload) {
			//Avoid duplicate loads.
			localstate.dbreload = false;
			LoadItems();
		} else {
			//Data Loaded:
			//Sync Grade btns
			if (localstate.currentitem && editmode) {
				//alert();
				console.log(localstate.currentitem);
				SetGrade(localstate.currentitem.Grade, localstate.currentindex, true);
				if (localstate.currentitem.AssetType === "Laptop" || localstate.currentitem.AssetType === "AIO") {
					SetBodyGrade(localstate.currentitem.BodyGrade, localstate.currentindex, true);
					SetLCDGrade(localstate.currentitem.LCDGrade, localstate.currentindex, true);
				}
			}
		}
	},);



	/* ##########################  CRUD  ########################## */

	//New Row adds property 'PendingItem' for use in the API to add such rows.
	const AddRow = () => {
		localstate.griditems.unshift({
			PendingItem: true,
			ID: uuidv4(),
			ListingType: "bulk", //Default for now, unmutable. "item" listings come from regular inventory system.
			SerialNumber: "",
			Qty: 1,
			AssetType: "",
			Model: "",
			Grade: "",
			BodyGrade: "",
			LCDGrade: "",
			CPU: "",
			RAM: "",
			HDD1Size: "",
			HDD2Size: "",
			Resolution: "",
			Webcam: false,
			Battery: false,
			Batt1: "",
			Batt2: "",
			Video1Desc: "",
			Video2Desc: "",
			Touchscreen: false,
			Bluetooth: false,
			Network1: "",
			Network2: "",
			Network3: "",
			Network4: "",
			Network5: "",
			Network6: "",
			ACAdapter: false,
			created_at: "",
			ListingType: "item", //or bulk
			AskPrice: 0,
			Status: "Unavailable",
			Published: false
		});
		//All selected indexes move up by 1.
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			localstate.selectedindexes[i] += 1;
		}
		UpdateState(localstate);
	}

	//A Ref stays consistent throughout async function
	const requestcount = useRef(0);

	const SaveChanges = () => {
		//Clean up current errors:
		errors.HideError(errors);
		
		//Configuration
		requestcount.current = 0;
		var timeout = 300; //Seconds before closing final ProgressBar results - To Do: Add option to NOT timeout at all
		var requestinterval = 100; //Milliseconds

		var updatearray = [];
		for (var i = 0; i < localstate.griditems.length; i++) {
			if (localstate.griditems[i].unsaved) {
				updatearray.push(localstate.griditems[i]);
			}
		}
		console.log(updatearray.length);

		if (updatearray.length > 0) {
			dispatch(newProgress({
				msg: 'Saving listings...',
				show: true,
				settimeout: true, //This option allows for a timeout after the final item is pass/failed
				timeout: timeout, //Initiated on final item 
				type: 'items', //Labels your save types such as items, products, skus, parts
				finished: 0,
				percent: "0%",
				total: updatearray.length,
				pass: 0,
				fail: 0,
				faillist: [], //Can take simple values,
				faillinks: [], //Can take links to things like items or products.
				timeoutid: false,
				errors:[] //Can take strings
			}));
			i = 0;
			var limit = updatearray.length - 1;
			/* INTERVAL */
			var updateitems = setInterval(function () {
				var item = updatearray[i];
				//Do work here, API call.
				const postdata = {
					item: item
				};
				axios.post(dbendpoint + "/listings/update", postdata, defaultpostoptions).then(res => {
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status === 200) {
						//If ValidateUser() fails to verify user, it sends back 'login' error. 
						if (res.data.Status === "login") {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status === "Success") {
							//Success response also includes the item!
							//If we sent new rows, we'll need to reference the old ID.
							var itemindex = 0;
							if (res.data.OldID) {
								itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems[itemindex].unsaved = false;
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
								//Set New ID
								localstate.griditems[itemindex].ID = res.data.item.ID;
							} else {
								itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.item.ID);
								localstate.griditems[itemindex].unsaved = false;
								//Refs allow us to update the grid live!
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
							}
							dispatch(incrementPass());
						}
						if (res.data.Status === "Failure") {
							//Failure error - To Do: Dispatch failure messages to the ProgressBar
							dispatch(incrementFail());
						}
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);
					} else {
						//Non-200 message from server.
						console.log("Non-200 Error.");
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);
						dispatch(incrementFail());
					}
				}).catch(err => {
					//Non-200, 500 Error, timeout?
					console.log("Axios Error, possible timeout." + err);
					requestcount.current++;
					FinalizeRequest(requestcount.current, limit, timeout);
					dispatch(incrementFail());
				});
				///If we have looped through all items, clear this interval.
				if (i === limit) {
					clearInterval(updateitems);
				}
				i++;
			}, requestinterval);
		} else {
			errors.NewError({ errmsg: "Nothing to save.", errshow: true, errtimeout: 5, errtype: "neutral" })
			ResetPendingSaves();
		}
	}


	//Clean up View
	const FinalizeRequest = (i, limit, timeout) => {
		//The itterator after responses are incremented to n+1. Reversed below:
		if ((i - 1) === limit) {
			EnableButtons();
			dispatch(setProgressTimeout(timeout));
			MarkErrorItems();
			btnSave.current.style.display = "";
			btnPendingSave.current.style.display = "none";
		}
	}


	//Mark Remaining Items as Warning Items
	const MarkErrorItems = () => {
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				rowRefs.current[i+'SaveStatus'].classList.remove(classes.unsavedhighlight);
				rowRefs.current[i+'SaveStatus'].classList.add(classes.errorhighlight);
			} else {
				//Resolve previously unresolved
				rowRefs.current[i+'SaveStatus'].classList.remove(classes.errorhighlight);
			}
		}
	}


	const ResetPendingSaves = () => {
		for (var i = 0; i < localstate.griditems.length; i++) {
			if (localstate.griditems[i].unsaved) {
				localstate.griditems[i].unsaved = false;
			}
		}
	}


	{/* BULK EDIT DRAWER STATE AND FUNCTIONS */ }
	const [showbulkeditdrawer, setShowBulkEditDrawer] = useState(false);
	const btnApplyBulkEdit = useRef();

	const CloseBulkEditDrawer = () => {
		setShowBulkEditDrawer(false);
		//Reload DB
		localstate.dbreload = true;
		//Update State
		UpdateState(localstate);
	}

	//Bulk edits may incur many different types of changes.
	//Using state variables, we can allow the view to change between them.
	const [changetype, setChangeType] = React.useState('');



	//Try to replace all this?
	//In render: {(changetype==='text')}, etc. NO. We need changetype for when we go back to the DB for the update.


	//// CHANGE TYPES - Controls change type and input viewables
	const [changeistext, setChangeIsText] = React.useState(false);
	const [changeisfloat, setChangeIsFloat] = React.useState(false);
	const [changeissomeselectable, setChangeIsSomeSelectable] = React.useState(false);
	const [changeisdbitem, setChangeIsDBItem] = React.useState(false);
	const [changeisdate, setChangeIsDate] = React.useState(false);
	const [changeisbool, setChangeIsBool] = React.useState(false);


	const [changeisint, setChangeIsInt] = React.useState(false);
	const [changeisstatus, setChangeIsStatus] = React.useState(false);




	//// VALUES - Controls input values and value validation
	const changevalue = React.createRef();
	//These 2 refs are used to update a number input (RestrictNumber() will limit what can be put into input box)
	const changevaluefloat = React.createRef();
	const changevalueint = React.createRef();
	const changevalueselectable = React.createRef();
	const [dbitemselection, setDBItemSelection] = React.useState();


	const handleChangeEditType = (event) => {
		//Reset change value
		changevalue.current = "";
		//Reset Inputs:
		setChangeIsText(false);
		setChangeIsFloat(false);
		setChangeIsSomeSelectable(false);
		setChangeIsDBItem(false);
		setChangeIsBool(false);

		//Examples
		setChangeIsInt(false);
		setChangeIsStatus(false);
		setChangeIsDate(false);

		//Reset Btn
		btnApplyBulkEdit.current.classList.remove(["MuiButton-containedPrimary"]);
		btnApplyBulkEdit.current.classList.add(["Mui-disabled"]);

		setChangeType(event.target.value);

		if (event.target.value === "Model" ||
			event.target.value === "AssetType" ||
			event.target.value === "Grade" ||
			event.target.value === "BodyGrade" ||
			event.target.value === "LCDGrade" ||
			event.target.value === "CPU" ||
			event.target.value === "RAM" ||
			event.target.value === "HDD1Size" ||
			event.target.value === "HDD2Size" ||
			event.target.value === "Resolution" ||
			event.target.value === "Webcam" ||
			event.target.value === "Batt1" ||
			event.target.value === "Batt2" ||
			event.target.value === "Video1Desc" ||
			event.target.value === "Video2Desc" ||
			event.target.value === "Network1" ||
			event.target.value === "Network2" ||
			event.target.value === "Network3" ||
			event.target.value === "Network4" ||
			event.target.value === "Network5" ||
			event.target.value === "Network6"

		) {
			setChangeIsText(true);
		}

		if (event.target.value === "Cost" || event.target.value === "Price" || event.target.value === "AskPrice") {
			setChangeIsFloat(true);
		}

		if (event.target.value === "SomeSelectable") {
			setChangeIsSomeSelectable(true);
		}

		if (event.target.value === "Webcam" ||
			event.target.value === "Touchscreen" ||
			event.target.value === "Bluetooth" ||
			event.target.value === "ACAdapter" ||
			event.target.value === "Published"
		) {
			setChangeIsBool(true);
		}

		if (event.target.value === "DBItem") {
			setChangeIsDBItem(true);
		}

		if (event.target.value === "Date") {
			setChangeIsDate(true);
		}

		//Unavailable, Available, Reserved, Sold
		if (event.target.value === "Status") {
			setChangeIsStatus(true);
		}

		//Unused Examples:
		if (event.target.value === "LotID" || event.target.value === "SkidNumber") {
			setChangeIsInt(true);
		}




		if (event.target.value === "LotID" || event.target.value === "SkidNumber") {
			setChangeIsInt(true);
		}

	};

	const handleChangeBulkValue = (event) => {
		//Currency
		if (changetype === "Cost" || changetype === "Price" || changetype === "AskPrice") {
			changevalue.current = RestrictNumber(event.target.value, changevalue.current);
			//Updates view:
			changevaluefloat.current.value = changevalue.current;
			//Integers	
		} else if (changetype === "LotID" || changetype === "SkidNumber" || changetype === "Qty") {
			changevalue.current = RestrictNumberInteger(event.target.value, changevalue.current);
			//Updates view:
			changevalueint.current.value = changevalue.current;
			//Raw Values
		} else {
			changevalue.current = event.target.value;
		}


		//Verify changes can be saved in current form.
		//Avoid certain items being saved as a blank string:
		if (changetype === "Location" && event.target.value === "") {
			btnApplyBulkEdit.current.classList.remove(["MuiButton-containedPrimary"]);
			btnApplyBulkEdit.current.classList.add(["Mui-disabled"]);
			errors.NewError({ errmsg: "Value cannot be blank.", errshow: true, errtimeout: 3, errtype: "neutral" });

			//Allow bulk change to be saved
		} else {
			btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
			btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
		}
	}

	const handleChangeDBItem = (event, item) => {
		setDBItemSelection(item);
		//Allow bulk change to be saved
		btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
		btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
	}

	const handleChangeSomeSelectable = (event) => {
		changevalue.current = event.target.value;
		btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
		btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
	}


	//Search for DB Item (autocomplete):
	const [dbitemkey, DBItemKey] = useState("Model");
	const [opendbitemoptions, openDBItemOptions] = React.useState(false);
	const [dbitemoptions, setDBItemOptions] = React.useState([]);
	const [loadingdbitemoptions, setLoadingDBItemOptions] = useState(false);
	const InitDBItemOptions = () => {
		if (dbitemoptions.length === 0) {
			//Load (up to limit) options
			DBItemSearch("");
		}
		openDBItemOptions(true);
	}

	const DBItemSearch = debounce(function (searchvalue) {
		setLoadingDBItemOptions(true);
		const postdata = {
			key: dbitemkey,
			search: searchvalue,
			limit: 20
		};
		//Error such as "TypeError: Cannot read property 'filter' of undefined" means we aren't handling the response correctly or the response is malformed.
		axios.post(dbendpoint + "/listings/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status === 200) {
				if (res.data.Status === "login") {
					window.location.reload(false);
				} else {
					setDBItemOptions(res.data.items);
					console.log(res.data);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
			setLoadingDBItemOptions(false);
		});
	}, 600);


	const ApplyBulkEdit = () => {
		var items = [];
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			items.push(localstate.griditems[localstate.selectedindexes[i]]);
		}

		//Simple key-value pairs:
		if (changetype === "Model" ||
			changetype === "AssetType" ||
			changetype === "Grade" ||
			changetype === "BodyGrade" ||
			changetype === "LCDGrade" ||
			changetype === "CPU" ||
			changetype === "RAM" ||
			changetype === "HDD1Size" ||
			changetype === "HDD2Size" ||
			changetype === "Resolution" ||
			changetype === "Webcam" ||
			changetype === "Batt1" ||
			changetype === "Batt2" ||
			changetype === "Video1Desc" ||
			changetype === "Video2Desc" ||
			changetype === "Touchscreen" ||
			changetype === "Bluetooth" ||
			changetype === "Network1" ||
			changetype === "Network2" ||
			changetype === "Network3" ||
			changetype === "Network4" ||
			changetype === "Network5" ||
			changetype === "Network6" ||
			changetype === "ACAdapter" ||
			changetype === "Published" ||
			changetype === "Status"
		) {
			for (i = 0; i < items.length; i++) {
				items[i][changetype] = changevalue.current;
			}
		}

		//Database Item Selection (potential multiple values to change)
		if (changetype === "DBItem") {
			//Place your specific column values here:
			for (i = 0; i < items.length; i++) {
				items[i]["Name"] = dbitemselection.Name;
				//Example: items[i]["ProductID"] = dbitemselection.ProductID;
			}
		}

		//Reassign other item values if needed before pushing them to postdata.items.
		//Example provision to change Margin if price or cost change
		if (changetype === "Price") {
			for (i = 0; i < items.length; i++) {
				//items[i].Price = changevalue.current;
				items[i].Margin = items[i].Price - items[i].Cost;
			}
		}

		if (changetype === "Cost") {
			for (i = 0; i < items.length; i++) {
				//items[i].Cost = changevalue.current;
				items[i].Margin = items[i].Price - items[i].Cost;
			}
		}

		var postdata = {
			items: items
		}



		axios.post(dbendpoint + "/listings/bulkedititems", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === 'login') {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				if (res.data.Status === 'Success') {
					//console.log(res.data);
					if (res.data['SuccessCount'] > 0) {
						errors.NewError({
							errmsg: "Items Changed: " + res.data['SuccessCount'] + ", Failures: " + res.data['FailCount'],
							errshow: true,
							errtimeout: 5,
							errtype: 'ok'
						})
					}
				}

				if (res.data.Status === 'Failure') {
					//Failure error
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: 'neutral' })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: 'warning' })
			}
		});
	}



	//Delete Confirmation and Deletion
	const [showdeleteconfirmation, setShowDeleteConfirmation] = useState(false);
	const [deleteitems, setDeleteItems] = useState([]);
	const DeleteSelectedInit = () => {
		var deleteitemsarray = [];
		//Reflect items to user for confimation.
		for (var i = 0; i < state.selectedindexes.length; i++) {
			deleteitemsarray.push(localstate.griditems[state.selectedindexes[i]]);
		}
		setDeleteItems(deleteitemsarray);
		setShowDeleteConfirmation(true);
	}

	const DeleteSelected = () => {
		var finishedrequests = 0;
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			if (localstate.griditems[localstate.selectedindexes[i]].hasOwnProperty("PendingItem")) {
				//Pending items are simply removed from the view and forgotten.
				localstate.griditems.splice(localstate.selectedindexes[i], 1);
				//Count as finished request
				finishedrequests++;
			} else {
				//Make Delete request to DB
				const postdata = {
					item: localstate.griditems[localstate.selectedindexes[i]]
				};
				axios.post(dbendpoint + "/listings/delete", postdata, defaultpostoptions).then(res => {
					//No matter the response, we consider the result as a 'finished request'. We can then properly do clean-up.
					finishedrequests++;
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status === 200) {
						//If ValidateUser() fails to verify user, it sends back "login" error. 
						if (res.data.Status === "login") {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status === "Success") {
							//Success response also includes the item!
							//If we're pulling the item out of grid items, we'll use the ID of the item for reference.
							if (res.data.OldID) {
								//Since griditems state can be reloaded anytime, we look for the indexOf the ID
								var itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems.splice(itemindex, 1);
							} else {
								errors.NewError({ errmsg: "Could not delete one or more items.", errshow: true, errtimeout: 8, errtype: "warning" })
							}
						}
						if (res.data.Status === "Failure") {
							//Failure error
							errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
						}
					} else {
						//Non-200 message from server.
						errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
					}
					//After result from last request, do cleanup.
					if (finishedrequests === localstate.selectedindexes.length) {
						//Clear out all selections! Since checkboxes are controlled only by grid items index, we don't have good
						//tracking on which items are which.
						localstate.selectedindexes = [];
						localstate.selectedcount = 0;
						UpdateState(localstate);
						setShowDeleteConfirmation(false);
					}
				});
			}
		}
	}
	const CancelDelete = () => {
		setShowDeleteConfirmation(false);
	}


	/* ##########################  Cell Interaction  ########################## */


	//Tab Order: Used for horizontal tabbing below.
	//We need an order for horizontal tabbing - attempting to tab to an unavailable column (like a disabled column via colstate) will result in an error

	const taborder = [
		"Model",
		"AssetType",
		"CPU",
		"RAM",
		"HDD1Size",
		"HDD2Size",
		"Resolution",
		"Batt1",
		"Batt2",
		"Video1Desc",
		"Video2Desc",
		"Network1",
		"Network2",
		"Network3",
		"Network4",
		"Network5",
		"Network6",
		"AskPrice"
	];


	//Excel-like functionality for grid
	const HandleKeyDown = (event, index, column) => {
		//Handle Tabs!
		if (event.key === "Tab") {
			event.preventDefault();
			//Vertical VS Horizontal Tabbing

			//Vertical Tabbing - Checkboxes, Gross Income, Rates
			//Vertical Tabbing is never subject to the next column not being shown (we always to back to record #1 instead!)
			if (column === "Checkbox") {  //Insert each type of column you want vertically tabbed here: if (column==="Checkbox" || column==="Margin"){ etc
				//If the next row ref exists....
				if (rowRefs.current[(index + 1) + column]) {
					rowRefs.current[(index + 1) + column].focus();
				} else {
					//Go to first element
					rowRefs.current[("0" + column)].focus();
				}
			} else {
				//Horizontal Tabbing - Row Data
				//Horizontal Tabbing is subject to certain columns not being available for selection. (colstate)

				//Get index within tab order:
				var tabindex = taborder.indexOf(column);
				//Increase index until we find the next tab order column
				for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
					//If we're at the last column element, go to the next row's first available column element
					if (i === taborder.length) {
						//Start at beginning of row tab order and reitterate
						i = -1;
						//If next row exists:
						if (rowRefs.current[(index + 1) + column]) {
							index = index + 1;
						} else {
							//Go to first row.
							index = 0;
						}
					} else {
						//If there is another elemet in the taborder.... Continue. Else, go to first column.
						if (taborder[i]) {
							//If that next element is available
							if (colstate[taborder[i]]) {
								rowRefs.current[index + taborder[i]].select();
								break;
							}
						} else {
							//Start at beginning of row tab order and reitterate
							i = -1;
						}
					}
				}
			}
		}
		//Handle Down Arrow
		if (event.key === "ArrowDown") {
			event.preventDefault();
			if (rowRefs.current[(index + 1) + column]) {
				rowRefs.current[(index + 1) + column].focus();
			} else {
				//Go to first element
				rowRefs.current[("0" + column)].focus();
			}
		}
		//Handle Up Arrow
		if (event.key === "ArrowUp") {
			event.preventDefault();
			if (rowRefs.current[(index - 1) + column]) {
				rowRefs.current[(index - 1) + column].focus();
			} else {
				//Go to last element
				var lastelement = localstate.griditems.length - 1;
				rowRefs.current[(lastelement + column)].focus();
			}
		}
	}

	//Restrict Number (too many places past the decimal)
	const RestrictNumber = (newvalue, oldvalue) => {
		var len = newvalue.length;
		var index = newvalue.indexOf('.');
		if (index > 0) {
			var decimalchars = (len - 1) - index;
			if (decimalchars > 2) {
				return oldvalue;
			}
		}
		return newvalue;
	}

	//Restrict Number to Integer - Don't accept any decimal.
	const RestrictNumberInteger = (newvalue, oldvalue) => {
		console.log(newvalue);
		if (newvalue < 0) {
			return 0;
		} else {
			return parseInt(newvalue);
		}
	}

	const DetectBlankNumber = (event, index, column) => {
		if (event.target.value === "") {
			rowRefs.current[index + column].value = "0.00";
			localstate.griditems[index][column] = "0.00";
		}
	}

	//Catch-All Method.
	const onChangeValue = (event, index, column) => {
		var oldvalue = localstate.griditems[index][column];
		var newvalue = event.target.value;
		if (event.key !== "Tab" &&
			event.key !== "ArrowDown" &&
			event.key !== "ArrowUp" &&
			event.key !== "ArrowLeft" &&
			event.key !== "ArrowRight" &&
			event.key !== "ShiftLeft" &&
			event.key !== "ShiftRight"
		) {
			//Conditionals for Types:
			if (column === "Cost") {
				newvalue = RestrictNumber(newvalue, oldvalue);
				//If Cost changes, so does Margin
				rowRefs.current[index + "Margin"].textContent = (rowRefs.current[index + "Price"].value - newvalue).toFixed(2);
				localstate.griditems[index].Margin = rowRefs.current[index + "Margin"].textContent;
			}
			if (column === "Qty") {
				newvalue = RestrictNumberInteger(newvalue, oldvalue);
			}
			if (column === "AskPrice") {
				if (!oldvalue) {
					oldvalue = "0";
				}
				newvalue = RestrictNumber(newvalue, oldvalue);
				//Values pass to localstate later in logic.
				//Check:
				console.log("Event Value:" + event.target.value + "   Old Value:" + oldvalue + "   New Value:" + newvalue);

				//Backspace on a decimal should yield same value for old and new
				if (oldvalue.indexOf(".") > -1 && newvalue.indexOf(".") === -1) {
					//No need to change front end, simply update griditem
					localstate.griditems[index][column] = newvalue;
				} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)) {
					rowRefs.current[index + column].value = newvalue;
					localstate.griditems[index][column] = newvalue;
				}
			}

			if (column === "Price") {
				newvalue = RestrictNumber(newvalue, oldvalue);
				//If Price changes, so does Margin
				rowRefs.current[index + "Margin"].textContent = (newvalue - rowRefs.current[index + "Cost"].value).toFixed(2);
				localstate.griditems[index].Margin = rowRefs.current[index + "Margin"].textContent;
			}
			//Provision for Booleans that require a re-render. Expensive!
			if (column === "SomeBoolean") {
				if (event.target.checked) {
					console.log("Checked = true");
					localstate.griditems[index][column] = 1;
					UpdateState(localstate);
				} else {
					console.log("Checked = false");
					localstate.griditems[index][column] = 0;
					UpdateState(localstate);
				}

			} else {
				//Selects already render the correct and unsaved result of selecting. (but right now they cause rerender via state.... possible change here.)
				if (column === "Webcam" || column === "Touchscreen" || column === "Bluetooth" || column === "ACAdapter" || column === "Published") { //MaterialUI Checkbox
					if (event.target.checked) {
						localstate.griditems[index][column] = 1;
					} else {
						localstate.griditems[index][column] = 0;
					}
				} else if (column === "Status" || column === "Grade" || column === "BodyGrade" || column === "LCDGrade" || column === "AskPrice") {
					//Input Component takes care of view while localstate has yet to update the DB
					localstate.griditems[index][column] = newvalue;
				} else {
					//Update Refs like usual.
					rowRefs.current[index + column].value = newvalue;
					localstate.griditems[index][column] = newvalue;
				}
			}
			rowRefs.current[index + "SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[index].unsaved = true;
			localstate.pendingsaves = true;

			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
		}
	}


	//Changes to item - in detailed expanded column view.
	const onChangeItemValue = (event, index, itemkey) => {
		var oldvalue = localstate.griditems[index][itemkey];
		var newvalue = event.target.value;

		if (event.key !== "Tab" &&
			event.key !== "ArrowDown" &&
			event.key !== "ArrowUp" &&
			event.key !== "ShiftLeft" &&
			event.key !== "ShiftRight"
		) {
			//Restrictions for Numbers
			if (itemkey === "Cost") {
				newvalue = RestrictNumber(newvalue, oldvalue);
				//itemRefs.current.CostEl.value = newvalue;
			}
			localstate.griditems[index][itemkey] = newvalue;

			rowRefs.current[index + "SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[index].unsaved = true;
			localstate.pendingsaves = true;
			btnSave.current.style.display = "";
			btnPendingSave.current.style.display = "none";
			UpdateState(localstate);
		}

	}


	/* ##########################  Button Functions  ########################## */

	const ExpandRowToggle = (index) => {
		//Reset All
		for (var i = 0; i < localstate.griditems.length; i++) {
			if (i !== index) {
				localstate.griditems[i].ExpandRow = false;
			}
		}

		if (index === localstate.currentindex) {
			localstate.currentindex = false;
			localstate.currentitem = false;
		} else {
			localstate.currentindex = index;
			localstate.currentitem = localstate.griditems[index];
		}


		localstate.griditems[index].ExpandRow = !localstate.griditems[index].ExpandRow;
		UpdateState(localstate);
	}

	const ExpandAll = () => {
		for (var i = 0; i < localstate.griditems.length; i++) {
			localstate.griditems[i].ExpandRow = true;
		}
		UpdateState(localstate);
	}

	const ResetSearches = () => {
		//Reset all:
		for (var i = 1; i < 7; i++) {
			localstate.searchoptions.searchpairs["searchpair" + i].type = "";
			localstate.searchoptions.searchpairs["searchpair" + i].value = "";
			localstate.searchoptions.searchpairs["searchpair" + i].mode = "like";
			localstate.searchoptions.searchpairs["searchpair" + i].uuid = uuidv4();
		}
		//Set Defaults:
		setItemListSearch('');
		localstate.searchoptions.searchpairs.searchpair1.type = "Name";
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	function getRandomInt(max) {
		return Math.floor(Math.random() * Math.floor(max));
	}

	//Export Confirmation and Exports
	const [showexportconfirmation, setShowExportConfirmation] = useState(false);
	//Chooses between "selected" or "searchresults"
	const [exporttype, setExportType] = useState("");
	//Export Message
	const [exportmessage, setExportMessage] = useState("");
	var exportmode = "";
	var exportlimit = 2000;
	var exportfilename = "ListingsCSV";

	const InitExport = (exporttype) => {
		if (exporttype === "selected" && localstate.selectedindexes.length === 0) {
			//Do nothing
		} else {
			//Show export type selection
			setShowExportConfirmation(true);
			//Set export by "selected" or "searchresults"
			setExportType(exporttype);
			if (exporttype === "selected") {
				setExportMessage("");
			}
			if (exporttype === "searchresults") {
				setExportMessage("Exports of search results will be limited to " + exportlimit + " rows.");
			}
		}
	}

	const PrepareExport = (mode) => {
		//Set global
		exportmode = mode;
		var exportdata = [];
		var clonedata = [];
		if (exporttype === "selected") {
			//Need to DEEP clone:
			var clonedata = JSON.parse(JSON.stringify(localstate.griditems));
			for (var i = 0; i < localstate.selectedindexes.length; i++) {
				exportdata.push(clonedata[localstate.selectedindexes[i]]);
			}
			Export(exportdata);
		}
		if (exporttype === "searchresults") {
			const postdata = {
				searchoptions: {
					//Set Max Limit Here
					limit: exportlimit,
					currentsort: localstate.orderby,
					currentsortdir: localstate.order,
					searchpairs: localstate.searchoptions.searchpairs
				}
			};
			axios.post(dbendpoint + "/listings?page=" + (localstate.page + 1), postdata, defaultpostoptions).then(res => {
				if (res.status === 200) {
					if (res.data.Status === "login") {
						window.location.reload(false);
					}
					if (res.data.Status === "Success") {
						exportdata = res.data.pagedata.data;
						Export(exportdata);
					}
					if (res.data.Status === "Failure") {
						errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
					}
				} else {
					errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
				}
			});
		}
	}

	const Export = (exportdata) => {
		//Remove Metas
		for (var i = 0; i < exportdata.length; i++) {
			removeProp(exportdata[i], "isSelected");
			removeProp(exportdata[i], "unsaved");
			removeProp(exportdata[i], "ExpandRow");
			removeProp(exportdata[i], "GridKey");
		}

		//Use Export Mode to pare data
		if (exportmode === "simple") {
			for (var i = 0; i < exportdata.length; i++) {
				removeProp(exportdata[i], "Date");
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode === "expanded") {
			for (var i = 0; i < exportdata.length; i++) {
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode === "exhaustive") {
			//Remove nothing - or possibly break down nested relationships
		}
		ExportCSV(exportdata, exportfilename);
		setShowExportConfirmation(false);
	}

	//Chips
	const ReturnGradeClass = (grade) => {
		if (grade === 'A') {
			return classes.gradea;
		}
		if (grade === 'B') {
			return classes.gradeb;
		}
		if (grade === 'C') {
			return classes.gradec;
		}
		if (grade === 'Repair') {
			return classes.graderepair;
		}
		if (grade === 'Scrap') {
			return classes.gradescrap;
		}
		if (grade === 'Bad') {
			return classes.gradebad;
		}
		return classes.gradenone;
	}


	/* GRADES */
	const btnGradeA = useRef();
	const btnGradeB = useRef();
	const btnGradeC = useRef();
	const btnGradeRepair = useRef();
	const btnGradeScrap = useRef();

	const SetGrade = (grade, index, init) => {
		if (!init) {
			localstate.griditems[index].Grade = grade;
		}
		if (grade === "A") {
			btnGradeA.current.classList.add(classes.flexactivebtn);
			btnGradeB.current.classList.remove(classes.flexactivebtn);
			btnGradeC.current.classList.remove(classes.flexactivebtn);
			btnGradeRepair.current.classList.remove(classes.flexactivebtn);
			btnGradeScrap.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "B") {
			btnGradeA.current.classList.remove(classes.flexactivebtn);
			btnGradeB.current.classList.add(classes.flexactivebtn);
			btnGradeC.current.classList.remove(classes.flexactivebtn);
			btnGradeRepair.current.classList.remove(classes.flexactivebtn);
			btnGradeScrap.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "C") {
			btnGradeA.current.classList.remove(classes.flexactivebtn);
			btnGradeB.current.classList.remove(classes.flexactivebtn);
			btnGradeC.current.classList.add(classes.flexactivebtn);
			btnGradeRepair.current.classList.remove(classes.flexactivebtn);
			btnGradeScrap.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "Repair") {
			btnGradeA.current.classList.remove(classes.flexactivebtn);
			btnGradeB.current.classList.remove(classes.flexactivebtn);
			btnGradeC.current.classList.remove(classes.flexactivebtn);
			btnGradeRepair.current.classList.add(classes.flexactivebtn);
			btnGradeScrap.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "Scrap") {
			btnGradeA.current.classList.remove(classes.flexactivebtn);
			btnGradeB.current.classList.remove(classes.flexactivebtn);
			btnGradeC.current.classList.remove(classes.flexactivebtn);
			btnGradeRepair.current.classList.remove(classes.flexactivebtn);
			btnGradeScrap.current.classList.add(classes.flexactivebtn);
		}

		if (!init) {
			//btnSave.current.disabled = false;
			rowRefs.current[index + "SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[index].unsaved = true;
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
			localstate.pendingsaves = true;
			UpdateState(localstate);
		}
	}

	/* Body Grades */
	const btnBodyGradeA = useRef();
	const btnBodyGradeB = useRef();
	const btnBodyGradeC = useRef();
	const btnBodyGradeBad = useRef();

	const SetBodyGrade = (grade, index, init) => {
		if (!init) {
			localstate.griditems[index].BodyGrade = grade;
		}
		if (grade === "A") {
			btnBodyGradeA.current.classList.add(classes.flexactivebtn);
			btnBodyGradeB.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeC.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeBad.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "B") {
			btnBodyGradeA.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeB.current.classList.add(classes.flexactivebtn);
			btnBodyGradeC.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeBad.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "C") {
			btnBodyGradeA.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeB.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeC.current.classList.add(classes.flexactivebtn);
			btnBodyGradeBad.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "Bad") {
			btnBodyGradeA.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeB.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeC.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeBad.current.classList.add(classes.flexactivebtn);
		}

		if (!init) {
			//btnSave.current.disabled = false;
			rowRefs.current[index + "SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[index].unsaved = true;
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
			localstate.pendingsaves = true;
			UpdateState(localstate);
		}
	}

	/* LCD Grades */
	const btnLCDGradeA = useRef();
	const btnLCDGradeB = useRef();
	const btnLCDGradeC = useRef();
	const btnLCDGradeBad = useRef();

	const SetLCDGrade = (grade, index, init) => {
		if (!init) {
			localstate.griditems[index].LCDGrade = grade;
		}
		if (grade === "A") {
			btnLCDGradeA.current.classList.add(classes.flexactivebtn);
			btnLCDGradeB.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeC.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeBad.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "B") {
			btnLCDGradeA.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeB.current.classList.add(classes.flexactivebtn);
			btnLCDGradeC.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeBad.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "C") {
			btnLCDGradeA.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeB.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeC.current.classList.add(classes.flexactivebtn);
			btnLCDGradeBad.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "Bad") {
			btnLCDGradeA.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeB.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeC.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeBad.current.classList.add(classes.flexactivebtn);
		}

		if (!init) {
			//localstate.pendingsaves = true;
			//btnSave.current.disabled = false;
			rowRefs.current[index + "SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[index].unsaved = true;
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
			localstate.pendingsaves = true;
			UpdateState(localstate);
		}
	}


	/* ##########################  TABLE HEAD  ########################## */
	/* ##########################  Column Configuration  ########################## */
	const headCells = [
		//Be sure to adjust widths for cells as well.
		{ id: "SerialNumber", numeric: false, label: "Serial", align: "left", allowsort: true, style: {} },
		{ id: "Qty", numeric: true, label: "Qty", align: "right", allowsort: true, style: {} },
		{ id: "Model", numeric: false, label: "Model", align: "left", allowsort: true, style: { paddingLeft: "40px" } },
		{ id: "AssetType", numeric: false, label: "Asset Type", align: "center", allowsort: true, style: { width: "70px" } },
		{ id: "Grade", numeric: false, label: "Grade", align: "center", allowsort: true, style: { width: "60px" } },
		{ id: "BodyGrade", numeric: false, label: "Body Grade", align: "center", allowsort: true, style: { width: "60px" } },
		{ id: "LCDGrade", numeric: false, label: "LCD Grade", align: "center", allowsort: true, style: { width: "60px" } },
		{ id: "CPU", numeric: false, label: "CPU", align: "left", allowsort: true, style: {} },
		{ id: "RAM", numeric: false, label: "RAM", align: "left", allowsort: true, style: { width: "70px" } },
		{ id: "HDD1Size", numeric: false, label: "HDD1Size", align: "left", allowsort: true, style: {} },
		{ id: "HDD2Size", numeric: false, label: "HDD2Size", align: "left", allowsort: true, style: {} },
		{ id: "Resolution", numeric: false, label: "Resolution", align: "left", allowsort: true, style: {} },
		{ id: "Webcam", numeric: false, label: "Webcam", align: "center", allowsort: true, style: { width: "50px" } },
		{ id: "Batt1", numeric: false, label: "Batt1", align: "left", allowsort: true, style: {} },
		{ id: "Batt2", numeric: false, label: "Batt2", align: "left", allowsort: true, style: {} },
		{ id: "Video1Desc", numeric: false, label: "Video1Desc", align: "left", allowsort: true, style: {} },
		{ id: "Video2Desc", numeric: false, label: "Video2Desc", align: "left", allowsort: true, style: {} },
		{ id: "Touchscreen", numeric: false, label: "Touch", align: "center", allowsort: true, style: { width: "50px" } },
		{ id: "Bluetooth", numeric: false, label: "BT", align: "center", allowsort: true, style: { width: "50px" } },
		{ id: "Network1", numeric: false, label: "Network1", align: "left", allowsort: true, style: {} },
		{ id: "Network2", numeric: false, label: "Network2", align: "left", allowsort: true, style: {} },
		{ id: "Network3", numeric: false, label: "Network3", align: "left", allowsort: true, style: {} },
		{ id: "Network4", numeric: false, label: "Network4", align: "left", allowsort: true, style: {} },
		{ id: "Network5", numeric: false, label: "Network5", align: "left", allowsort: true, style: {} },
		{ id: "Network6", numeric: false, label: "Network6", align: "left", allowsort: true, style: {} },
		{ id: "ACAdapter", numeric: false, label: "AC", align: "center", allowsort: true, style: { width: "50px" } },
		{ id: "created_at", numeric: false, label: "Date", align: "left", allowsort: true, style: {} },
		{ id: "ListingType", numeric: false, label: "Type", align: "left", allowsort: true, style: {} },
		{ id: "AskPrice", numeric: true, label: "Ask Price", align: "right", allowsort: true, style: {} },
		{ id: "Status", numeric: false, label: "Status", align: "left", allowsort: true, style: {} },
		{ id: "Published", numeric: false, label: "Published", align: "left", allowsort: true, style: {} },
	];

	function EnhancedTableHead(props) {
		const { classes, onSelectAllClick, order, onRequestSort } = props;
		const createSortHandler = (property) => (event) => {
			onRequestSort(event, property);
		};

		return (
			<thead style={{ display: "table-header-group" }}>
				<tr style={{
					border: "1px solid #CCC",
					backgroundColor: "#DDD"
				}}>
					<td style={{ width: "14px", padding: "none", display: "table-cell", padding: "2px 4px 2px 5px" }}>

						<Checkbox
							className={classes.gridcheckbox}
							disableRipple
							color="default"
							defaultChecked={localstate.griditems.length === localstate.selectedindexes.length}
							checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
							icon={<span className={classes.icon} />}
							onChange={onSelectAllClick}
						/>

					</td>
					{/* Map remaining table headers */}
					{headCells.map((headCell) =>
						colstate[headCell.id] &&
						(
							<td
								key={headCell.id}
								align={headCell.align}
								style={headCell.style}
							>
								{(headCell.allowsort) &&
									<TableSortLabel
										active={localstate.orderby === headCell.id}
										direction={localstate.orderby === headCell.id ? order : "asc"}
										onClick={createSortHandler(headCell.id, headCell.allowsort)}
										hideSortIcon
									>
										{/* This is a conditional for headCell where the id is "Name". This allows us to put a spacer in here for the expand icon button */}
										{/* The expand button is optional, this can be removed if needed! */}
										{(headCell.id === "Name") &&
											<div style={{ width: "30px", display: "inline-block" }}></div>
										}
										{/* If current sort, show bold label */}
										{(localstate.orderby === headCell.id)
											? <span style={{ fontWeight: "bold" }}>{headCell.label}</span>
											: <span>{headCell.label}</span>
										}
									</TableSortLabel>
								}
								{(!headCell.allowsort) &&
									<span>{headCell.label}</span>
								}
							</td>
						))}
				</tr>
			</thead>
		);
	}

	EnhancedTableHead.propTypes = {
		classes: PropTypes.object.isRequired,
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(["asc", "desc"]).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};

	{/* Utility Drawer state and functions */ }
	const [showutilitydrawer, setShowUtilityDrawer] = useState(false);

	const CloseUtilityDrawer = () => {
		setShowUtilityDrawer(false);
		//Reload DB?
		//localstate.dbreload = true;
		//Update State?
		//UpdateState(localstate);
	}

	{/* Item List - General purpose Listings */ }
	const [showitemlist, setShowItemList] = useState(false);
	const [itemlist, setItemList] = useState(idstring);
	//We keep our original item list intact, use itemlistsearch for new DB reload
	const [itemlistsearch, setItemListSearch] = useState("");
	const [dupeitems, setDupeItems] = useState('');
	const CloseItemList = () => {
		//Get unique items
		const result = itemlist.split(/\r?\n/).filter(element => element);
		const newitemlist = GetUniqueArray(result);
		setItemListSearch(newitemlist);
		setShowItemList(false);

		//Reload DB?
		localstate.dbreload = true;
		//Update State?
		UpdateState(localstate);
	}
	const onChangeItemList = (event) => {
		//Optional check for dupes:
		//Bust apart by line breaks!
		//Let's start sending arrays to PHP Laravel instead where possible since we're already
		//going to do work here before the API call.

		//If there is a line break at the end of the list (blank new line), you may end up with a falsy array element at the end.
		//Filter creates a new array based on passing a test function (true or false).
		//By passing a falsy array element (undefined), it won't return that element in the result array.
		//Split by Line Breaks, then filter.
		const result = event.target.value.split(/\r?\n/).filter(element => element);
		//Find Dupes, let the user know there are dupes.
		const dupes = GetDupeArray(result);
		if (dupes.length > 0) {
			setDupeItems(dupes);
			console.log(dupes);
		} else {
			setDupeItems('');
		}
		setItemList(event.target.value);
	}

	{/* Print Labels */ }
	const PrintLabels = () => {
		var itemids = [];
		//Grab all IDs
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			itemids.push(localstate.griditems[localstate.selectedindexes[i]]['ID']);
		}

		var params = {
			itemids: JSON.stringify(itemids),
			labeltype: 'basiclabel',
			autoclose: false,
			seriallist: '',
			orderby: localstate.orderby,
			order: localstate.order
		};

		// Creating a form
		var form = document.createElement("form");
		form.setAttribute("method", "post");
		form.setAttribute("action", "../v3/labels");
		form.setAttribute("target", "NewLabel");
		for (var i in params) {
			if (params.hasOwnProperty(i)) {
				// Creating the input
				var input = document.createElement('input');
				input.type = 'hidden';
				input.name = i;
				input.value = params[i];

				// Attach input to form
				form.appendChild(input);
			}
		}
		document.body.appendChild(form);
		form.submit();
	}



	/* MASS UPDATE UTILITIES */
	const updatemanifests = (itteration) => {
		const postdata = {
			itteration: itteration,
			limit: 100
		};
		axios.post(dbendpoint + "/utility/updatemanifests", postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Continue") {
					console.log(res);
					updatemanifests(res.data.Itteration + 1);
				}
				if (res.data.Status === "Finished") {

				}
				if (res.data.Status === "Failure") {
					//Failure error
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
		});
	}

	const updateserialnumbers = (itteration) => {
		const postdata = {
			itteration: itteration,
			limit: 100
		};
		axios.post(dbendpoint + "/utility/updateserialnumbers", postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Continue") {
					console.log(res);
					updateserialnumbers(res.data.Itteration + 1);
				}
				if (res.data.Status === "Finished") {

				}
				if (res.data.Status === "Failure") {
					//Failure error
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
		});
	}


	//Some requests might take longer
	const longpostoptions = {
		withCredentials: true,
		withXSRFToken: true,
		crossDomain: true,
		mode: "no-cors",
		timeout: 20000,
	};

	const setuserforcondition = (itteration) => {
		const postdata = {
			itteration: itteration,
			limit: 50
		};
		axios.post(dbendpoint + "/utility/setuserforcondition", postdata, longpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Continue") {
					console.log(res);
					setuserforcondition(res.data.Itteration + 1);
				}
				if (res.data.Status === "Finished") {

				}
				if (res.data.Status === "Failure") {
					//Failure error
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
		});
	}


	/* 
																							 
		 _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
		_/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
	   _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
	  _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
	 _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
																						 
																						 
	  */

	/* ##########################  Render Function  ########################## */
	return (
		<div style={{ padding: "8px", minWidth: "600px" }}>

			{/* Utility Drawer, good for search options */}
			<Drawer open={showutilitydrawer} style={{ width: "600px" }}>
				<div style={{ height: "47px" }}></div>
				<Typography variant="h4" gutterBottom align="center">
					Utility Drawer
				</Typography>
				<div style={{ width: "400px", padding: "10px" }}>
					<Typography variant="subtitle1" gutterBottom>
						You may want to add some instructional text here.<br></br>
					</Typography>

					{/* You may want access to errors here */}
					{(errors.currenterror.errshow) &&
						<div style={{ textAlign: "center", height: "25px", fontSize: "12px" }}>
							<ErrorMessage />
						</div>
					}

					<Button
						className={classes.bluebtn}
						color="primary" variant="contained"
						onClick={() => CloseUtilityDrawer()}>
						Close
					</Button>

				</div>
			</Drawer>


			{/* Item List - used to load only items placed in text area. */}
			<Drawer open={showitemlist} style={{ width: "600px" }}>
				<div style={{ height: "47px" }}></div>
				<Typography variant="h4" gutterBottom align="center">
					Listing IDs
				</Typography>
				<div style={{ width: "400px", padding: "10px" }}>
					<Typography variant="subtitle1" gutterBottom>
						Add IDs here:<br></br>
					</Typography>

					{/* You may want access to errors here */}
					{(errors.currenterror.errshow) &&
						<div style={{ textAlign: "center", height: "25px", fontSize: "12px" }}>
							<ErrorMessage />
						</div>
					}

					<TextareaAutosize style={{ width: "100%" }} minRows={10} placeholder="Paste IDs from spreadsheet here."
						onChange={(event) => onChangeItemList(event)}
						value={itemlist}
					/>

					<Button
						className={classes.bluebtn}
						color="primary" variant="contained"
						onClick={() => CloseItemList()}>
						Close
					</Button>

					{(dupeitems.length > 0) &&
						<div>
							Duplicates found:
							{dupeitems.map(function (dupe, i) {
								console.log('test');
								return <li key={i}>{dupe}</li>
							})}
						</div>
					}

				</div>
			</Drawer>



			{/* Bulk Edit Drawer */}
			<Drawer open={showbulkeditdrawer} style={{ width: "600px" }}>
				<div style={{ height: "47px" }}></div>
				<Typography variant="h4" gutterBottom align="center">
					Bulk Edit Selected Items
				</Typography>
				<div style={{ width: "400px", padding: "10px" }}>
					<Typography variant="subtitle1" gutterBottom>
						Changes apply to all checkbox selected items in the list view. You may perform multiple changes before returning to the list. Select change type to get started.<br></br>
					</Typography>

					{/* You may want access to errors here - create parent container to hold position for you. */}
					<div style={{ height: "25px" }}>
						{(errors.currenterror.errshow) &&
							<div style={{ textAlign: "center", height: "25px", fontSize: "12px" }}>
								<ErrorMessage />
							</div>
						}
					</div>


					<FormControl className={classes.formControl} style={{ width: "300px" }} variant="standard">
						<InputLabel id="Change type">Change Type</InputLabel>
						<Select
							labelId="change-type"
							id="change-type"
							value={changetype}
							onChange={handleChangeEditType}
						>
							<MenuItem value={'Model'}>Model</MenuItem>
							<MenuItem value={'AssetType'}>AssetType</MenuItem>
							<MenuItem value={'Grade'}>Grade</MenuItem>
							<MenuItem value={'BodyGrade'}>BodyGrade</MenuItem>
							<MenuItem value={'LCDGrade'}>LCDGrade</MenuItem>
							<MenuItem value={'CPU'}>CPU</MenuItem>
							<MenuItem value={'RAM'}>RAM</MenuItem>
							<MenuItem value={'HDD1Size'}>HDD1Size</MenuItem>
							<MenuItem value={'HDD2Size'}>HDD2Size</MenuItem>
							<MenuItem value={'Resolution'}>Resolution</MenuItem>
							<MenuItem value={'Webcam'}>Webcam</MenuItem>
							<MenuItem value={'Batt1'}>Batt1</MenuItem>
							<MenuItem value={'Batt2'}>Batt2</MenuItem>
							<MenuItem value={'Video1Desc'}>Video1Desc</MenuItem>
							<MenuItem value={'Video2Desc'}>Video2Desc</MenuItem>
							<MenuItem value={'Touchscreen'}>Touchscreen</MenuItem>
							<MenuItem value={'Bluetooth'}>Bluetooth</MenuItem>
							<MenuItem value={'Network1'}>Network1</MenuItem>
							<MenuItem value={'Network2'}>Network2</MenuItem>
							<MenuItem value={'Network3'}>Network3</MenuItem>
							<MenuItem value={'Network4'}>Network4</MenuItem>
							<MenuItem value={'Network5'}>Network5</MenuItem>
							<MenuItem value={'Network6'}>Network6</MenuItem>
							<MenuItem value={'ACAdapter'}>AC Adapter</MenuItem>
							<MenuItem value={'Status'}>Status</MenuItem>
							<MenuItem value={'Published'}>Published</MenuItem>
							<MenuItem value={'AskPrice'}>Ask Price</MenuItem>

						</Select>
					</FormControl>

					{/* To do: Set change values. Most are just text, so we'll start with that: */}
					{(changeistext) &&
						<div>
							<TextField variant="standard" style={{ width: "300px" }} required id="standard-required" defaultValue="" onChange={handleChangeBulkValue} />
						</div>
					}

					{(changeissomeselectable) &&
						<FormControl className={classes.formControl} style={{ width: "300px" }} variant="standard">
							<InputLabel id="demo-simple-select-label">Select Type</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								onChange={handleChangeBulkValue}
							>
								<MenuItem value={"Checked In"}>Checked In</MenuItem>
								<MenuItem value={"Sold"}>Sold</MenuItem>
								<MenuItem value={"Scrap"}>Scrap</MenuItem>
								<MenuItem value={"Returned to Vendor"}>Returned to Vendor</MenuItem>
								<MenuItem value={"Undetermined or Missing"}>Undetermined or Missing</MenuItem>
							</Select>
						</FormControl>
					}

					{/* Float: Cost */}
					{(changeisfloat) &&
						<div>
							<TextField variant="standard" type="number" step="0.01" inputRef={el => changevaluefloat.current = el} style={{ width: "300px" }} required id="standard-required" defaultValue="Value" onChange={handleChangeBulkValue} />
						</div>
					}

					{/* EXAMPLE: Integer: LotID or SkidNumber */}
					{(changeisint) &&
						<div>
							<TextField variant="standard" type="number" step="1"
								inputRef={el => changevalue.current = el}
								style={{ width: "300px" }}
								required
								id="standard-required"
								defaultValue=""
								onChange={handleChangeBulkValue} />
						</div>
					}


					{/* Change is Some Selectable SomeSelectable */}
					{(changeissomeselectable) &&
						<div>
							<FormControl variant="standard" className={classes.formControl}>
								{/* <InputLabel id="demo-controlled-open-select-label">Optional Label</InputLabel> */}
								{/* Need: Set default value if row.SomeSelectable is null. */}
								{/* Why: Some of the values may be momentarily null for some reason - DB fetch, rerender? */}

								{/* Override icon prop class to our custom 'nodisplay' to remove arrowdown icon for this select */}
								{/* Override select prop class with new padding specs */}

								<Select
									defaultValue={""}
									onChange={(event) => handleChangeSomeSelectable(event)}
									classes={{
										//icon: classes.nodisplay,
										//select: classes.selectpadding
									}}
									style={{ width: "300px" }}
								>
									<MenuItem value="">
										<em>None (blank string)</em>
									</MenuItem>
									<MenuItem value={10}>Ten (integer)</MenuItem>
									<MenuItem value={"A"}>
										<Chip size="small" label="A" clickable className={classes.gradea} />
									</MenuItem>
									<MenuItem value={"B"}>
										<Chip size="small" label="B" clickable className={classes.gradeb} />
									</MenuItem>
									<MenuItem value={"C"}>
										<Chip size="small" label="C" clickable className={classes.gradec} />
									</MenuItem>
									<MenuItem value={"Bad"}>
										<Chip size="small" label="Bad" clickable className={classes.gradebad} />
									</MenuItem>
									<MenuItem value={"Repair"}>
										<Chip size="small" label="Repair" clickable className={classes.graderepair} />
									</MenuItem>
									<MenuItem value={"Scrap"}>
										<Chip size="small" label="Scrap" clickable className={classes.gradescrap} />
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					}


					{/* DB Item (Autocomplete) */}
					{(changeisdbitem) &&
						<div>
							<Autocomplete freeSolo forcePopupIcon={false} disableClearable style={{ width: "300px", padding: "0px" }}
								open={opendbitemoptions} onOpen={() => { InitDBItemOptions(); }} onClose={() => { openDBItemOptions(false); }}
								//Not sure how to avoid passing event, then newvalue... seems to break when removing event.
								//* onChange={(event, newValue) => onChangeOption1(event, newValue)} */}
								onChange={(event, newValue) => handleChangeDBItem(event, newValue)}
								onInputChange={(event) => DBItemSearch(event.target.value)}
								isOptionEqualToValue={(option, value) => option[dbitemkey] === value}
								getOptionLabel={(option) => option[dbitemkey]}
								options={dbitemoptions}
								loading={loadingdbitemoptions}
								renderInput={(params) => (
									<TextField variant="standard"
										className={classes.autocompleteinput}
										style={{ padding: "0px" }}
										{...params}
										InputProps={{
											disableUnderline: false,
											...params.InputProps,
											autoComplete: "new-password",
											endAdornment: (
												<React.Fragment>
													{loadingdbitemoptions ? <CircularProgress color="inherit" size={20} /> : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</div>
					}

					{/* Date */}
					{(changeisdate) &&
						<FormControl className={classes.formControl} style={{ width: "300px" }}>
							<TextField variant="standard"
								id="datetime-local"
								type="datetime-local"
								defaultValue=""
								onChange={handleChangeBulkValue}
								sx={{ width: 300 }}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</FormControl>
					}

					{/* Change Is Bool */}
					{(changeisbool && (changetype === "Webcam" || changetype === "Bluetooth" || changetype === "ACAdapter" || changetype === "Touchscreen")) &&
						<div style={{ marginTop: "20px" }}>
							<FormControl>
								<FormLabel id="demo-radio-buttons-group-label">Set {changetype} status:</FormLabel>
								<RadioGroup
									aria-labelledby="demo-radio-buttons-group-label"
									name="radio-buttons-group"
									onChange={handleChangeBulkValue}
								>
									<FormControlLabel value="0" control={<Radio />} label="Not Included" />
									<FormControlLabel value="1" control={<Radio />} label="Included" />
								</RadioGroup>
							</FormControl>
						</div>
					}

					{(changeisbool && changetype !== "Webcam" && changetype !== "Bluetooth" && changetype !== "ACAdapter" && changetype !== "Touchscreen") &&
						<div style={{ marginTop: "20px" }}>
							<FormControl>
								<FormLabel id="demo-radio-buttons-group-label">Set {changetype} status:</FormLabel>
								<RadioGroup
									aria-labelledby="demo-radio-buttons-group-label"
									name="radio-buttons-group"
									onChange={handleChangeBulkValue}
								>
									<FormControlLabel value="1" control={<Radio />} label="Yes" />
									<FormControlLabel value="0" control={<Radio />} label="No" />
								</RadioGroup>
							</FormControl>
						</div>
					}


					{/* Change Is Status */}
					{(changeisstatus) &&
						<div style={{ marginTop: "20px" }}>
							<FormControl>
								<FormLabel id="demo-radio-buttons-group-label">Set {changetype} status:</FormLabel>
								<RadioGroup
									aria-labelledby="demo-radio-buttons-group-label"
									name="radio-buttons-group"
									onChange={handleChangeBulkValue}
								>
									<FormControlLabel value="Unavailable" control={<Radio />} label="Unavailable" />
									<FormControlLabel value="Available" control={<Radio />} label="Available" />
									<FormControlLabel value="Reserved" control={<Radio />} label="Reserved" />
									<FormControlLabel value="Sold" control={<Radio />} label="Sold" />
								</RadioGroup>
							</FormControl>
						</div>
					}

					<Button
						className={classes.bluebtn}
						color="primary" variant="contained"
						onClick={() => ApplyBulkEdit()}
						ref={el => btnApplyBulkEdit.current = el}>
						Apply Change
					</Button>

					<Button
						className={classes.bluebtn}
						color="primary" variant="contained"
						onClick={() => CloseBulkEditDrawer()}>
						Close
					</Button>

				</div>
			</Drawer>




			{/* Standard Page Header with right floated error message space */}
			<div style={{ height: "50px", paddingTop: "5px" }}>
				<div style={{ textAlign: "center" }}>
					<h2>Deluxe Deals Inventory</h2>
				</div>
				{(errors.currenterror.errshow) &&
					<div style={{ position: "relative", float: "right", bottom: "26px", height: "0px", fontSize: "12px" }}>
						{/* <ErrorMessage /> */}
					</div>
				}

				<div style={{ position: "relative", float: "right", bottom: "30px", height: "0px" }}>
					<ProgressBar />
				</div>
			</div>

			{/* /* ##########################  Search Inputs  ########################## */}
			{/* Search Tools should: Fall in-line, stack, have padding-right of 15px, 300px wide.*/}
			{/* CHOOSE between AutoCompletes OR Key-Value searches. Helps keep interface looking CLEAN. */}

			{(!isPrintView) &&
				<div>

					{/* Key-Value Searches */}

					{/* Search Pair 1 */}
					{(searchinputs.show1) &&
						<div className={classes.searchinputs} style={{ width: "300px" }}>
							<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
								{/* Value must match one of the MenuItem values, SerialNumber != Serial Number */}
								<Select
									key={localstate.searchoptions.searchpairs.searchpair1.uuid}
									value={localstate.searchoptions.searchpairs.searchpair1.type} disableUnderline
									onChange={(event) => onChangeSearchType(event.target.value, 1)}
									MenuProps={{
										style: { zIndex: 2001 }
									}}
								>
									<MenuItem value={'SerialNumber'}>Serial Number</MenuItem>
									<MenuItem value={"AssetType"}>Asset Type</MenuItem>
									<MenuItem value={"Model"}>Model</MenuItem>
									<MenuItem value={"Grade"}>Grade</MenuItem>
									<MenuItem value={"BodyGrade"}>BodyGrade</MenuItem>
									<MenuItem value={"LCDGrade"}>LCDGrade</MenuItem>
									<MenuItem value={"CPU"}>CPU</MenuItem>
									<MenuItem value={"RAM"}>RAM</MenuItem>
									<MenuItem value={"HDD1Size"}>HDD1Size</MenuItem>
									<MenuItem value={"HDD2Size"}>HDD2Size</MenuItem>
									<MenuItem value={"Resolution"}>Resolution</MenuItem>


								</Select>
							</FormControl>
							<TextField id="search1" variant="standard"
								key={localstate.searchoptions.searchpairs.searchpair1.uuid + 1}
								defaultValue={localstate.searchoptions.searchpairs.searchpair1.value}
								className={classes.searchinput} InputProps={{ disableUnderline: true }}
								onChange={(event) => onChangeSearchValue(event.target.value, 1)} />
							{/* The 30px wide spacer for a close button can be optionally removed. The space might better be used for view vs the need for close buttons */}
							<div style={{ width: "30px" }}>&nbsp;</div>
						</div>
					}

					{/* Search Pair 2 */}
					{(searchinputs.show2) &&
						<div className={classes.searchinputs} style={{ width: "300px" }}>
							<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
								<Select
									key={localstate.searchoptions.searchpairs.searchpair2.uuid}
									value={localstate.searchoptions.searchpairs.searchpair2.type} disableUnderline
									onChange={(event) => onChangeSearchType(event.target.value, 2)}
									MenuProps={{
										style: { zIndex: 2001 }
									}}
								>
									<MenuItem value={'SerialNumber'}>Serial Number</MenuItem>
									<MenuItem value={"AssetType"}>Asset Type</MenuItem>
									<MenuItem value={"Model"}>Model</MenuItem>
									<MenuItem value={"Grade"}>Grade</MenuItem>
									<MenuItem value={"BodyGrade"}>BodyGrade</MenuItem>
									<MenuItem value={"LCDGrade"}>LCDGrade</MenuItem>
									<MenuItem value={"CPU"}>CPU</MenuItem>
									<MenuItem value={"RAM"}>RAM</MenuItem>
									<MenuItem value={"HDD1Size"}>HDD1Size</MenuItem>
									<MenuItem value={"HDD2Size"}>HDD2Size</MenuItem>
									<MenuItem value={"Resolution"}>Resolution</MenuItem>
								</Select>
							</FormControl>
							<TextField id="search2" variant="standard"
								key={localstate.searchoptions.searchpairs.searchpair2.uuid + 1}
								defaultValue={localstate.searchoptions.searchpairs.searchpair2.value}
								className={classes.searchinput} InputProps={{ disableUnderline: true }}
								onChange={(event) => onChangeSearchValue(event.target.value, 2)} />
							<div style={{ width: "30px", float: "right" }}>
								{(searchinputs.show2 && !searchinputs.show3) &&
									<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
										<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
									</IconButton>
								}
							</div>
						</div>
					}

					{/* Search Pair 3 */}
					{(searchinputs.show3) &&
						<div className={classes.searchinputs} style={{ width: "300px" }}>
							<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
								<Select
									key={localstate.searchoptions.searchpairs.searchpair3.uuid}
									value={localstate.searchoptions.searchpairs.searchpair3.type} disableUnderline
									onChange={(event) => onChangeSearchType(event.target.value, 3)}
									MenuProps={{
										style: { zIndex: 2001 }
									}}
								>
									<MenuItem value={'SerialNumber'}>Serial Number</MenuItem>
									<MenuItem value={"AssetType"}>Asset Type</MenuItem>
									<MenuItem value={"Model"}>Model</MenuItem>
									<MenuItem value={"Grade"}>Grade</MenuItem>
									<MenuItem value={"BodyGrade"}>BodyGrade</MenuItem>
									<MenuItem value={"LCDGrade"}>LCDGrade</MenuItem>
									<MenuItem value={"CPU"}>CPU</MenuItem>
									<MenuItem value={"RAM"}>RAM</MenuItem>
									<MenuItem value={"HDD1Size"}>HDD1Size</MenuItem>
									<MenuItem value={"HDD2Size"}>HDD2Size</MenuItem>
									<MenuItem value={"Resolution"}>Resolution</MenuItem>
								</Select>
							</FormControl>
							<TextField id="search3" variant="standard"
								key={localstate.searchoptions.searchpairs.searchpair3.uuid + 1}
								defaultValue={localstate.searchoptions.searchpairs.searchpair3.value}
								className={classes.searchinput} InputProps={{ disableUnderline: true }}
								onChange={(event) => onChangeSearchValue(event.target.value, 3)} />
							<div style={{ width: "30px" }}>
								{(searchinputs.show3 && !searchinputs.show4) &&
									<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
										<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
									</IconButton>
								}
							</div>
						</div>
					}

					{/* Search Pair 4 */}
					{(searchinputs.show4) &&
						<div className={classes.searchinputs} style={{ width: "300px" }}>
							<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
								<Select
									key={localstate.searchoptions.searchpairs.searchpair4.uuid}
									value={localstate.searchoptions.searchpairs.searchpair4.type} disableUnderline
									onChange={(event) => onChangeSearchType(event.target.value, 4)}
									MenuProps={{
										style: { zIndex: 2001 }
									}}
								>
									<MenuItem value={'SerialNumber'}>Serial Number</MenuItem>
									<MenuItem value={"AssetType"}>Asset Type</MenuItem>
									<MenuItem value={"Model"}>Model</MenuItem>
									<MenuItem value={"Grade"}>Grade</MenuItem>
									<MenuItem value={"BodyGrade"}>BodyGrade</MenuItem>
									<MenuItem value={"LCDGrade"}>LCDGrade</MenuItem>
									<MenuItem value={"CPU"}>CPU</MenuItem>
									<MenuItem value={"RAM"}>RAM</MenuItem>
									<MenuItem value={"HDD1Size"}>HDD1Size</MenuItem>
									<MenuItem value={"HDD2Size"}>HDD2Size</MenuItem>
									<MenuItem value={"Resolution"}>Resolution</MenuItem>
								</Select>
							</FormControl>
							<TextField id="search4" variant="standard"
								key={localstate.searchoptions.searchpairs.searchpair4.uuid + 1}
								defaultValue={localstate.searchoptions.searchpairs.searchpair4.value}
								className={classes.searchinput} InputProps={{ disableUnderline: true }}
								onChange={(event) => onChangeSearchValue(event.target.value, 4)} />
							<div style={{ width: "30px" }}>
								{(searchinputs.show4 && !searchinputs.show5) &&
									<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
										<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
									</IconButton>
								}
							</div>
						</div>
					}


					{/* Search Pair 5 */}
					{(searchinputs.show5) &&
						<div className={classes.searchinputs} style={{ width: "300px" }}>
							<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
								<Select
									key={localstate.searchoptions.searchpairs.searchpair5.uuid}
									value={localstate.searchoptions.searchpairs.searchpair5.type} disableUnderline
									onChange={(event) => onChangeSearchType(event.target.value, 5)}
									MenuProps={{
										style: { zIndex: 2001 }
									}}
								>
									<MenuItem value={'SerialNumber'}>Serial Number</MenuItem>
									<MenuItem value={"AssetType"}>Asset Type</MenuItem>
									<MenuItem value={"Model"}>Model</MenuItem>
									<MenuItem value={"Grade"}>Grade</MenuItem>
									<MenuItem value={"BodyGrade"}>BodyGrade</MenuItem>
									<MenuItem value={"LCDGrade"}>LCDGrade</MenuItem>
									<MenuItem value={"CPU"}>CPU</MenuItem>
									<MenuItem value={"RAM"}>RAM</MenuItem>
									<MenuItem value={"HDD1Size"}>HDD1Size</MenuItem>
									<MenuItem value={"HDD2Size"}>HDD2Size</MenuItem>
									<MenuItem value={"Resolution"}>Resolution</MenuItem>
								</Select>
							</FormControl>
							<TextField id="search5" variant="standard"
								key={localstate.searchoptions.searchpairs.searchpair5.uuid + 1}
								defaultValue={localstate.searchoptions.searchpairs.searchpair5.value}
								className={classes.searchinput} InputProps={{ disableUnderline: true }}
								onChange={(event) => onChangeSearchValue(event.target.value, 5)} />
							<div style={{ width: "30px" }}>
								{(searchinputs.show5 && !searchinputs.show6) &&
									<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
										<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
									</IconButton>
								}
							</div>
						</div>
					}

					{/* Search Pair 6 */}
					{(searchinputs.show6) &&
						<div className={classes.searchinputs} style={{ width: "300px" }}>
							<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
								<Select
									key={localstate.searchoptions.searchpairs.searchpair6.uuid}
									value={localstate.searchoptions.searchpairs.searchpair6.type} disableUnderline
									onChange={(event) => onChangeSearchType(event.target.value, 6)}
									MenuProps={{
										style: { zIndex: 2001 }
									}}
								>
									<MenuItem value={'SerialNumber'}>Serial Number</MenuItem>
									<MenuItem value={"AssetType"}>Asset Type</MenuItem>
									<MenuItem value={"Model"}>Model</MenuItem>
									<MenuItem value={"Grade"}>Grade</MenuItem>
									<MenuItem value={"BodyGrade"}>BodyGrade</MenuItem>
									<MenuItem value={"LCDGrade"}>LCDGrade</MenuItem>
									<MenuItem value={"CPU"}>CPU</MenuItem>
									<MenuItem value={"RAM"}>RAM</MenuItem>
									<MenuItem value={"HDD1Size"}>HDD1Size</MenuItem>
									<MenuItem value={"HDD2Size"}>HDD2Size</MenuItem>
									<MenuItem value={"Resolution"}>Resolution</MenuItem>
								</Select>
							</FormControl>
							<TextField id="search6" variant="standard"
								key={localstate.searchoptions.searchpairs.searchpair6.uuid + 1}
								defaultValue={localstate.searchoptions.searchpairs.searchpair6.value}
								className={classes.searchinput} InputProps={{ disableUnderline: true }}
								onChange={(event) => onChangeSearchValue(event.target.value, 6)} />
							<div style={{ width: "30px" }}>
								{(searchinputs.show6) &&
									<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
										<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
									</IconButton>
								}
							</div>
						</div>
					}


					{/* On last search input, don't show! */}
					{(!searchinputs.show6) &&
						<div style={{ display: "inline-block", paddingLeft: "15px" }}>
							<IconButton size="small" aria-label="Add Search" onClick={() => AddSearch()}>
								<AddIcon color="primary" fontSize="large" style={{ padding: "5px" }}></AddIcon>
							</IconButton>
						</div>
					}

				</div>
			}

			{/* End of Search Inputs */}


			{/* Top Buttons & Pagination */}
			{(!isPrintView) &&
				<React.Fragment>
					<div style={{ height: "5px" }}>&nbsp;</div>
					<div>
						{(!showdeleteconfirmation && !showexportconfirmation) &&
							<React.Fragment>

									<Button
										className={(userPerms.updateListing === 1) ? classes.bluebtn : classes.hidden }
										color="primary" variant="contained"
										onClick={() => SaveChanges()}
										ref={el => btnSave.current = el}>
										<SaveIcon sx={{ color: "lightgray" }}></SaveIcon>&nbsp;Save Changes
									</Button>
								
									<Button
										className={(userPerms.updateListing === 1) ? classes.bluebtn : classes.hidden }
										color="primary" variant="contained"
										style={{ display: "none" }}
										onClick={() => SaveChanges()}
										ref={el => btnPendingSave.current = el}>
										<PendingIcon sx={{ color: "orange" }}></PendingIcon>&nbsp;Save Changes
									</Button>
								

								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => AddRow()}
									ref={el => btnAddRow.current = el}>
									Add Row
								</Button>

							
								<Button
									className={(userPerms.deleteListing === 1) ? classes.bluebtn : classes.hidden }
									color="primary" variant="contained"
									onClick={() => RejectIfInvalidSelected("", DeleteSelectedInit)}
									ref={el => btnDeleteSelected.current = el}>
									Delete Selected
								</Button>
								


								{/* ##########################  Column Toggles  ########################## */}
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									aria-haspopup="true"
									onClick={ShowColumnMenu}>
									Columns
								</Button>

								<Menu
									className={classes.bluebtn}
									color="primary"
									id="simple-menu"
									anchorEl={showcolumnmenu}
									keepMounted
									open={Boolean(showcolumnmenu)}
									onClose={CloseColumnMenu}
								>
									<MenuItem disableRipple className={classes.columnmenu}>
										<div style={{ verticalAlign: "top" }}> {/* Optional container for 2 column menu! */}
											<div style={{ display: "inline-block", maxHeight: "600px", overflow: "auto", verticalAlign: "top" }}>
												<FormGroup>
													<FlexColumnOption value="SerialNumber" label="Serial Number" />
													<FlexColumnOption value="AssetType" label="Asset Type" />
													<FlexColumnOption value="Model" label="Model" />
													<FlexColumnOption value="Grade" label="Grade" />
													<FlexColumnOption value="BodyGrade" label="Body Grade" />
													<FlexColumnOption value="LCDGrade" label="LCD Grade" />
													<FlexColumnOption value="CPU" label="CPU" />
													<FlexColumnOption value="RAM" label="RAM" />
													<FlexColumnOption value="HDD1Size" label="HDD 1 Size" />
													<FlexColumnOption value="HDD2Size" label="HDD 2 Size" />
													<FlexColumnOption value="Resolution" label="Resolution" />
													<FlexColumnOption value="Webcam" label="Webcam" />
													<FlexColumnOption value="Batt1" label="Batt 1" />
													<FlexColumnOption value="Batt2" label="Batt 2" />
													<FlexColumnOption value="Video1Desc" label="Video 1" />
													<FlexColumnOption value="Video2Desc" label="Video 2" />
													<FlexColumnOption value="Touchscreen" label="Touchscreen" />
													<FlexColumnOption value="Bluetooth" label="Bluetooth" />
													<FlexColumnOption value="Network1" label="Network 1" />
													<FlexColumnOption value="Network2" label="Network 2" />
													<FlexColumnOption value="Network3" label="Network 3" />
													<FlexColumnOption value="Network4" label="Network 4" />
													<FlexColumnOption value="Network5" label="Network 5" />
													<FlexColumnOption value="Network6" label="Network 6" />
													<FlexColumnOption value="ACAdapter" label="AC Adapter" />
													<FlexColumnOption value="created_at" label="Date" />
													<FlexColumnOption value="ListingType" label="Type" />
													<FlexColumnOption value="AskPrice" label="Ask Price" />
													<FlexColumnOption value="Status" label="Status" />
													<FlexColumnOption value="Published" label="Published" />
												</FormGroup>
											</div>
										</div>
									</MenuItem>
								</Menu>

								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => ExpandAll()}
									ref={el => btnExpandAll.current = el}>
									Expand All
								</Button>

								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => ResetSearches()}
									ref={el => btnResetSearches.current = el}>
									Reset Searches
								</Button>

								{(userPerms.updateListing === 1) &&
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => RejectIfInvalidSelected(true, setShowBulkEditDrawer)}
										ref={el => btnEditSelected.current = el}>
										Edit Selected
									</Button>
								}

								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => setShowItemList(true)}
									{...(itemlist !== '' ? {
										style: {
											textDecoration: "underline"
										}
									} :
										{
											style: {
												textDecoration: "none"
											}
										})}
										ref={el => btnItemList.current = el}>
									Item List
								</Button>


								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									aria-haspopup="true"
									onClick={ShowExportMenu}
									ref={el => btnExport.current = el}>
									Export
								</Button>
								<Menu id="simple-menu"
									anchorEl={showexportmenu}
									keepMounted
									open={Boolean(showexportmenu)}
									onClose={CloseExportMenu}>
									<MenuItem onClick={() => RejectIfInvalidSelected("selected", InitExport)}>Export Selected</MenuItem>
									<MenuItem onClick={() => InitExport("searchresults")}>Export All (limit 2000)</MenuItem>
								</Menu>

								
								<Button
									className={(userPerms.updateListing === 1) ? classes.bluebtn : classes.hidden }
									color="primary" variant="contained"
									{...(editmode ? {
										style: {
											textDecoration: "underline"
										}
									} :
										{
											style: {
												textDecoration: "none"
											},
											disabled: (localstate.rowsperpage > 100 ? true : false)
										})}
									onClick={() => EditMode()}
									ref={el => btnEditMode.current = el}>
									Edit Mode
								</Button>
								

							</React.Fragment>
						}

						{/* Delete Items Confirmation */}
						{(showdeleteconfirmation) &&
							<div>
								<b>Are you sure you want to delete these items?</b>
								<div style={{ padding: "10px 0px" }}>
									{deleteitems.map((row, index) => {
										if (deleteitems.length === index + 1) {
											return (<span key={index}>{row.ID}</span>)
										} else {
											return (<span key={index}>{row.ID}, </span>)
										}
									})}
								</div>
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained" onClick={() => DeleteSelected()}>Yes, Delete Listing(s)</Button>&nbsp;&nbsp;
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained" onClick={() => CancelDelete()}>Cancel</Button>
							</div>
						}

						{/* Export Mode Confirmation */}
						{(showexportconfirmation) &&
							<div>
								<b>Export Mode</b><br></br>
								In most cases a simple export is appropriate as to avoid revealing information to potential buyers. {exportmessage}
								<div style={{ paddingTop: "10px" }}>
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => PrepareExport("simple")}>
										Simple
									</Button>
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => PrepareExport("expanded")}>
										Expanded
									</Button>
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => PrepareExport("exhaustive")}>
										Exhaustive
									</Button>
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => setShowExportConfirmation(false)}>
										Cancel
									</Button>
								</div>
							</div>
						}



						{(localstate.totalitems > 0) &&
							<TablePagination className={classes.paginationalign}
								style={{ display: "inline-flex", float: "right" }}
								component="div"
								count={localstate.totalitems}
								page={localstate.page}
								onPageChange={handleChangePage}
								rowsPerPage={localstate.rowsperpage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								rowsPerPageOptions={[100, 250, 500]}
							/>
						}
					</div>
				</React.Fragment>
			}
			{/* End of Top Buttons & Pagination */}


			{/* Add container for overflow scroll bars - Allows us to set a min width for the page (better looking in many cases), and freeze controls at the top and bottom for ease of use. */}
			<div className={classes.flexgridcontainerA}>
				{/* ##########################  Start of Table  ########################## */}
				<table id="resizeMe" aria-label="caption table" size="small" className={classes.flexgrid} style={{ minWidth: "100%", borderCollapse: "collapse", borderColor: "grey" }}>
					<EnhancedTableHead
						numSelected={localstate.selectedcount}
						classes={classes}
						order={localstate.order}
						orderBy={localstate.orderby}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
						rowCount={state.griditems.length}
					/>
					{/* /* ##########################  Row Design  ########################## */}
					{/* If DB reload, don't allow view of table. */}
					{(!localstate.dbreload) &&
						<tbody style={{ display: "table-row-group" }}>
							{(localstate.griditems.length > 0) &&
								localstate.griditems.map((row, index) => {
									//Create all-new refs on each render. Helps avoid issues with grid states.
									rowRefs.current[index + "Checkbox"] = React.createRef();
									rowRefs.current[index + "SaveStatus"] = React.createRef();
									rowRefs.current[index + "Qty"] = React.createRef();
									rowRefs.current[index + "AssetType"] = React.createRef();
									rowRefs.current[index + "Model"] = React.createRef();
									rowRefs.current[index + "Grade"] = React.createRef();
									rowRefs.current[index + "BodyGrade"] = React.createRef();
									rowRefs.current[index + "LCDGrade"] = React.createRef();
									rowRefs.current[index + "CPU"] = React.createRef();
									rowRefs.current[index + "RAM"] = React.createRef();
									rowRefs.current[index + "HDD1Size"] = React.createRef();
									rowRefs.current[index + "HDD2Size"] = React.createRef();
									rowRefs.current[index + "Resolution"] = React.createRef();
									rowRefs.current[index + "Webcam"] = React.createRef();
									rowRefs.current[index + "Batt1"] = React.createRef();
									rowRefs.current[index + "Video1Desc"] = React.createRef();
									rowRefs.current[index + "Video2Desc"] = React.createRef();
									rowRefs.current[index + "Touchscreen"] = React.createRef();
									rowRefs.current[index + "Bluetooth"] = React.createRef();
									rowRefs.current[index + "Network1"] = React.createRef();
									rowRefs.current[index + "Network2"] = React.createRef();
									rowRefs.current[index + "Network3"] = React.createRef();
									rowRefs.current[index + "Network4"] = React.createRef();
									rowRefs.current[index + "Network5"] = React.createRef();
									rowRefs.current[index + "Network6"] = React.createRef();
									rowRefs.current[index + "ACAdapter"] = React.createRef();
									rowRefs.current[index + "ListingType"] = React.createRef();
									rowRefs.current[index + "AskPrice"] = React.createRef();
									rowRefs.current[index + "Status"] = React.createRef();
									rowRefs.current[index + "Published"] = React.createRef();
									return (
										<React.Fragment key={row.ID}>
											{/* STATIC MODE */}
											{(!editmode) &&
												<tr className={row.ExpandRow ? classes.flexgridrowselected : classes.flexgridrow}>
													{/* Checkbox - Requires inner div to change background color with SaveStatus */}
													<td style={{ verticalAlign: "top" }} ref={el => rowRefs.current[index + "SaveStatus"] = el}>
														<div style={{ padding: "3px 4px 1px 4px" }}>
															{/*	MaterialUI Checkbox will pass a shallow comparison between UpdateState(s). Be sure GridKey changes if it needs to be rerendered such as selectall.	*/}
															{/* Don't show checkbox if this is a PendingItem */}
														{(!row.PendingItem) &&
																<Checkbox
																	key={row.GridKey}
																	inputRef={el=>rowRefs.current[index+"Checkbox"]=el} 
																	className={classes.gridcheckbox}
																	color="default"
																	defaultChecked={localstate.griditems[index].isSelected ? true : false}
																	checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onKeyDown={(event) => HandleKeyDown(event, index, "Checkbox")}
																	onChange={() => SelectRow(index)}
																/>
															}
														</div>
													</td>

													{(colstate.SerialNumber) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.SerialNumber}
														</td>
													}

													{(colstate.Qty) &&
														<td className={classes.flexgridstaticcontainer} style={{ textAlign: "right" }}>
															{row.Qty}
														</td>
													}


													{/* Model - Optional Expand toggle! Change flexgridinput-30 to flexgridinput to remove icon spacer. */}
													{(colstate.Model) &&
														<React.Fragment>
															{(colstate.Model) &&
																<td className={classes.flexgridstaticcontainer + " " + classes.hoverunit} onClick={() => ExpandRowToggle(index)}>
																	{(!row.ExpandRow) &&
																		<div className={classes.flexgridexpand}>
																			<ExpandMoreIcon className={classes.transparenticon} color="primary" fontSize="inherit"></ExpandMoreIcon>
																		</div>
																	}
																	{(row.ExpandRow) &&
																		<div className={classes.flexgridexpand}>
																			<ExpandLessIcon className={classes.transparenticon} color="primary" fontSize="inherit"></ExpandLessIcon>
																		</div>
																	}
																	{row.Model}
																</td>
															}
														</React.Fragment>
													}


													{(colstate.AssetType) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.AssetType}
														</td>
													}


													{/* Non-Editable: */}
													{(colstate.Grade) &&
														<td style={{ textAlign: "center" }}>
															<Chip size="small" label={row.Grade} className={ReturnGradeClass(row.Grade)} />
														</td>
													}

													{/* Body Grade - With Conditional for Desktops, Workstations*/}
													{(colstate.BodyGrade) &&
														<td style={{ textAlign: "center" }}>
															<Chip size="small" label={row.BodyGrade} className={ReturnGradeClass(row.BodyGrade)} />
														</td>
													}

													{/* LCD Grade */}
													{(colstate.LCDGrade) &&
														<td style={{ textAlign: "center" }}>
															<Chip size="small" label={row.LCDGrade} className={ReturnGradeClass(row.LCDGrade)} />
														</td>
													}


													{(colstate.CPU) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.CPU}
														</td>
													}


													{/* RAM */}
													{(colstate.RAM) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.RAM}
														</td>
													}


													{/* HDD1Size */}
													{(colstate.HDD1Size) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.HDD1Size}
														</td>
													}


													{/* HDD2Size */}
													{(colstate.HDD2Size) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.HDD2Size}
														</td>
													}


													{/* Resolution */}
													{(colstate.AssetType) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Resolution}
														</td>
													}


													{/* Webcam */}
													{(colstate.Webcam) &&
														<td style={{ textAlign: "center" }}>
															<CheckCircleIcon fontSize="small" color="success" className={(row.Webcam === 0) ? classes.hidden : classes.gridstaticcheck} />
														</td>
													}


													{/* Batt1 */}
													{(colstate.Batt1) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Batt1}
														</td>
													}


													{/* Batt2 */}
													{(colstate.Batt2) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Batt2}
														</td>
													}


													{/* Video1Desc */}
													{(colstate.Video1Desc) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Video1Desc}
														</td>
													}


													{/* Video2Desc */}
													{(colstate.Video2Desc) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Video2Desc}
														</td>
													}


													{/* Touchscreen */}
													{(colstate.Touchscreen) &&
														<td style={{ textAlign: "center" }}>
															<CheckCircleIcon fontSize="small" color="success" className={(row.Touchscreen === 0) ? classes.hidden : classes.gridstaticcheck} />
														</td>
													}


													{/* Bluetooth */}
													{(colstate.Bluetooth) &&
														<td style={{ textAlign: "center" }}>
															<CheckCircleIcon fontSize="small" color="success" className={(row.Bluetooth === 0) ? classes.hidden : classes.gridstaticcheck} />
														</td>
													}


													{/* Network1 */}
													{(colstate.Network1) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Network1}
														</td>
													}


													{/* Network2 */}
													{(colstate.Network2) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Network2}
														</td>
													}


													{/* Network3 */}
													{(colstate.Network3) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Network3}
														</td>
													}


													{/* Network4 */}
													{(colstate.Network4) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Network4}
														</td>
													}


													{/* Network5 */}
													{(colstate.Network5) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Network5}
														</td>
													}


													{/* Network6 */}
													{(colstate.Network6) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Network6}
														</td>
													}


													{/* ACAdapter */}
													{(colstate.ACAdapter) &&
														<td style={{ textAlign: "center" }}>
															<CheckCircleIcon fontSize="small" color="success" className={(row.ACAdapter === 0) ? classes.hidden : classes.gridstaticcheck} />
														</td>
													}


													{/* created_at */}
													{(colstate.created_at) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.created_at}
														</td>
													}

													{/* ListingType */}
													{(colstate.ListingType) &&
														<td className={classes.flexgridstaticcontainer} style={{ textAlign: "right" }}>
															{row.ListingType}
														</td>
													}

													{/* AskPrice */}
													{(colstate.AskPrice) &&
														<td className={classes.flexgridstaticcontainer} style={{ textAlign: "right" }}>
															{row.AskPrice}
														</td>
													}

													{/* Status */}
													{(colstate.Status) &&
														<td className={classes.flexgridstaticcontainer} style={{ textAlign: "right" }}>
															{row.Status}
														</td>
													}

													{/* Published */}
													{(colstate.Published) &&
														<td style={{ textAlign: "center" }}>
															<CheckCircleIcon fontSize="small" color="success" className={(row.Published === 0) ? classes.hidden : classes.gridstaticcheck} />
														</td>
													}

												</tr>
											}

											{/* EDIT MODE ROW ##############################################  */}
											{(editmode) &&
												<tr className={row.ExpandRow ? classes.flexgridrowselected : classes.flexgridrow}>
													{/* Checkbox - Requires inner div to change background color with SaveStatus */}
													<td style={{ verticalAlign: "top" }} ref={el => rowRefs.current[index + "SaveStatus"] = el}>
														<div style={{ padding: "3px 4px 1px 4px" }}>
															{/*	MaterialUI Checkbox will pass a shallow comparison between UpdateState(s). Be sure GridKey changes if it needs to be rerendered such as selectall.	*/}
															<Checkbox
																key={row.GridKey}
																inputRef={el => rowRefs.current[index + "Checkbox"] = el}
																className={classes.gridcheckbox}
																color="default"
																defaultChecked={localstate.griditems[index].isSelected ? true : false}
																checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
																icon={<span className={classes.icon} />}
																onKeyDown={(event) => HandleKeyDown(event, index, "Checkbox")}
																onChange={() => SelectRow(index)}
															/>
														</div>
													</td>

													{(colstate.SerialNumber) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.SerialNumber}
														</td>
													}

													{/* Qty */}
													{(colstate.Qty) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																type="number" step="0.01"
																ref={el => rowRefs.current[index + 'Qty'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "right" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Qty')}
																onKeyUp={(event) => onChangeValue(event, index, 'Qty')}
																onBlur={(event) => DetectBlankNumber(event, index, "Qty")}
																defaultValue={localstate.defaultvalues[index].Qty} />
														</td>
													}


													{/* Model - Optional Expand toggle! Change flexgridinput-30 to flexgridinput to remove icon spacer.  #############################*/}
													{(colstate.Model) &&
														<React.Fragment>

															{(colstate.Model) &&
																<td className={classes.flexgridinputcontainer}>
																	{(!row.ExpandRow) &&
																		<div className={classes.flexgridexpand}>
																			<ExpandMoreIcon className={classes.transparenticon} color="primary" fontSize="inherit" onClick={() => ExpandRowToggle(index)}></ExpandMoreIcon>
																		</div>
																	}
																	{(row.ExpandRow) &&
																		<div className={classes.flexgridexpand}>
																			<ExpandLessIcon className={classes.transparenticon} color="primary" fontSize="inherit" onClick={() => ExpandRowToggle(index)}></ExpandLessIcon>
																		</div>
																	}
																	<input
																		ref={el => rowRefs.current[index + 'Model'] = el}
																		className={classes.flexgridinput30}
																		style={{ minWidth: '50px', textAlign: "left" }}
																		onKeyDown={(event) => HandleKeyDown(event, index, 'Model')}
																		onKeyUp={(event) => onChangeValue(event, index, 'Model')}
																		defaultValue={localstate.defaultvalues[index].Model} />
																</td>
															}
														</React.Fragment>
													}


													{/* AssetType */}
													{(colstate.AssetType) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'AssetType'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'AssetType')}
																onKeyUp={(event) => onChangeValue(event, index, 'AssetType')}
																defaultValue={localstate.defaultvalues[index].AssetType} />
														</td>
													}


													{/* Grades */}
													{(colstate.Grade) &&
														<td style={{ textAlign: "center" }}>
															<FormControl className={classes.formControl}>
																<Select variant="standard"
																	defaultValue={localstate.defaultvalues[index].Grade ? localstate.defaultvalues[index].Grade : "-"}
																	disableUnderline
																	onChange={(event) => onChangeValue(event, index, 'Grade')}
																	classes={{
																		icon: classes.nodisplay,
																		select: classes.selectpadding
																	}}
																>
																	<MenuItem value={"-"}>
																		<Chip size="small" label="N" variant="outlined" clickable className={classes.gradenone} />
																	</MenuItem>
																	<MenuItem value={"A"}>
																		<Chip size="small" label="A" clickable className={classes.gradea} />
																	</MenuItem>
																	<MenuItem value={"B"}>
																		<Chip size="small" label="B" clickable className={classes.gradeb} />
																	</MenuItem>
																	<MenuItem value={"C"}>
																		<Chip size="small" label="C" clickable className={classes.gradec} />
																	</MenuItem>
																	<MenuItem value={"Repair"}>
																		<Chip size="small" label="Repair" clickable className={classes.graderepair} />
																	</MenuItem>
																	<MenuItem value={"Scrap"}>
																		<Chip size="small" label="Scrap" clickable className={classes.gradescrap} />
																	</MenuItem>
																</Select>
															</FormControl>
														</td>
													}

													{/* Body Grade - With Conditional for Desktops, Workstations*/}
													{(colstate.BodyGrade) &&
														<td style={{ textAlign: "center" }}>
															{(row.AssetType !== "Desktop") &&
																<FormControl className={classes.formControl}>
																	<Select variant="standard"
																		defaultValue={localstate.defaultvalues[index].BodyGrade ? localstate.defaultvalues[index].BodyGrade : "-"}
																		disableUnderline
																		onChange={(event) => onChangeValue(event, index, 'BodyGrade')}
																		classes={{
																			icon: classes.nodisplay,
																			select: classes.selectpadding
																		}}
																	>
																		<MenuItem value={"-"}>
																			<Chip size="small" label="N" variant="outlined" clickable className={classes.gradenone} />
																		</MenuItem>
																		<MenuItem value={"A"}>
																			<Chip size="small" label="A" clickable className={classes.gradea} />
																		</MenuItem>
																		<MenuItem value={"B"}>
																			<Chip size="small" label="B" clickable className={classes.gradeb} />
																		</MenuItem>
																		<MenuItem value={"C"}>
																			<Chip size="small" label="C" clickable className={classes.gradec} />
																		</MenuItem>
																		<MenuItem value={"Bad"}>
																			<Chip size="small" label="Bad" clickable className={classes.gradebad} />
																		</MenuItem>
																	</Select>
																</FormControl>
															}
														</td>
													}

													{/* LCD Grade */}
													{(colstate.LCDGrade) &&
														<td style={{ textAlign: "center" }}>
															{(row.AssetType !== "Desktop") &&
																<FormControl className={classes.formControl}>
																	<Select variant="standard"
																		defaultValue={localstate.defaultvalues[index].LCDGrade ? localstate.defaultvalues[index].LCDGrade : "-"}
																		disableUnderline
																		onChange={(event) => onChangeValue(event, index, 'LCDGrade')}
																		classes={{
																			icon: classes.nodisplay,
																			select: classes.selectpadding
																		}}
																	>
																		<MenuItem value={"-"}>
																			<Chip size="small" label="N" variant="outlined" clickable className={classes.gradenone} />
																		</MenuItem>
																		<MenuItem value={"A"}>
																			<Chip size="small" label="A" clickable className={classes.gradea} />
																		</MenuItem>
																		<MenuItem value={"B"}>
																			<Chip size="small" label="B" clickable className={classes.gradeb} />
																		</MenuItem>
																		<MenuItem value={"C"}>
																			<Chip size="small" label="C" clickable className={classes.gradec} />
																		</MenuItem>
																		<MenuItem value={"Bad"}>
																			<Chip size="small" label="Bad" clickable className={classes.gradebad} />
																		</MenuItem>
																	</Select>
																</FormControl>
															}
														</td>
													}



													{/* CPU */}
													{(colstate.CPU) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'CPU'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'CPU')}
																onKeyUp={(event) => onChangeValue(event, index, 'CPU')}
																defaultValue={localstate.defaultvalues[index].CPU} />
														</td>
													}


													{/* RAM */}
													{(colstate.RAM) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'RAM'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'RAM')}
																onKeyUp={(event) => onChangeValue(event, index, 'RAM')}
																defaultValue={localstate.defaultvalues[index].RAM} />
														</td>
													}

													{/* HDD1Size */}
													{(colstate.HDD1Size) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'HDD1Size'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'HDD1Size')}
																onKeyUp={(event) => onChangeValue(event, index, 'HDD1Size')}
																defaultValue={localstate.defaultvalues[index].HDD1Size} />
														</td>
													}


													{/* HDD2Size */}
													{(colstate.HDD2Size) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'HDD2Size'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'HDD2Size')}
																onKeyUp={(event) => onChangeValue(event, index, 'HDD2Size')}
																defaultValue={localstate.defaultvalues[index].HDD2Size} />
														</td>
													}


													{/* Resolution */}
													{(colstate.Resolution) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Resolution'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Resolution')}
																onKeyUp={(event) => onChangeValue(event, index, 'Resolution')}
																defaultValue={localstate.defaultvalues[index].Resolution} />
														</td>
													}


													{/* Webcam */}
													{(colstate.Webcam) &&
														<td>{/* defaultChecked={row.Webcam === 1 ? true : false} */}
															<div style={{ padding: "3px 4px 1px 4px", textAlign: "center" }}>
																<Checkbox
																	className={classes.gridcheckbox}
																	disableRipple
																	color="default"
																	defaultChecked={(!row.Webcam) ? false : ((row.Webcam === 1) ? true : false)}
																	checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onChange={(event) => onChangeValue(event, index, 'Webcam')}
																/>
															</div>
														</td>
													}


													{/* Batt1 */}
													{(colstate.Batt1) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Batt1'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Batt1')}
																onKeyUp={(event) => onChangeValue(event, index, 'Batt1')}
																defaultValue={localstate.defaultvalues[index].Batt1} />
														</td>
													}


													{/* Batt2 */}
													{(colstate.Batt2) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Batt2'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Batt2')}
																onKeyUp={(event) => onChangeValue(event, index, 'Batt2')}
																defaultValue={localstate.defaultvalues[index].Batt2} />
														</td>
													}


													{/* Video1Desc */}
													{(colstate.Video1Desc) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Video1Desc'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Video1Desc')}
																onKeyUp={(event) => onChangeValue(event, index, 'Video1Desc')}
																defaultValue={localstate.defaultvalues[index].Video1Desc} />
														</td>
													}


													{/* Video2Desc */}
													{(colstate.Video2Desc) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Video2Desc'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Video2Desc')}
																onKeyUp={(event) => onChangeValue(event, index, 'Video2Desc')}
																defaultValue={localstate.defaultvalues[index].Video2Desc} />
														</td>
													}


													{/* Touchscreen */}
													{(colstate.Touchscreen) &&
														<td>
															<div style={{ padding: "3px 4px 1px 4px", textAlign: "center" }}>
																<Checkbox
																	className={classes.gridcheckbox}
																	disableRipple
																	color="default"
																	defaultChecked={row.Touchscreen === 1 ? true : false}
																	checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onChange={(event) => onChangeValue(event, index, 'Touchscreen')}
																/>
															</div>
														</td>
													}


													{/* Bluetooth */}
													{(colstate.Bluetooth) &&
														<td>
															<div style={{ padding: "3px 4px 1px 4px", textAlign: "center" }}>
																<Checkbox
																	className={classes.gridcheckbox}
																	disableRipple
																	color="default"
																	defaultChecked={row.Bluetooth === 1 ? true : false}
																	checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onChange={(event) => onChangeValue(event, index, 'Bluetooth')}
																/>
															</div>
														</td>
													}


													{/* Network1 */}
													{(colstate.Network1) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Network1'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Network1')}
																onKeyUp={(event) => onChangeValue(event, index, 'Network1')}
																defaultValue={localstate.defaultvalues[index].Network1} />
														</td>
													}


													{/* Network2 */}
													{(colstate.Network2) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Network2'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Network2')}
																onKeyUp={(event) => onChangeValue(event, index, 'Network2')}
																defaultValue={localstate.defaultvalues[index].Network2} />
														</td>
													}


													{/* Network3 */}
													{(colstate.Network3) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Network3'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Network3')}
																onKeyUp={(event) => onChangeValue(event, index, 'Network3')}
																defaultValue={localstate.defaultvalues[index].Network3} />
														</td>
													}

													{/* Network4 */}
													{(colstate.Network4) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Network4'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Network4')}
																onKeyUp={(event) => onChangeValue(event, index, 'Network4')}
																defaultValue={localstate.defaultvalues[index].Network4} />
														</td>
													}


													{/* Network5 */}
													{(colstate.Network5) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Network5'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Network5')}
																onKeyUp={(event) => onChangeValue(event, index, 'Network5')}
																defaultValue={localstate.defaultvalues[index].Network5} />
														</td>
													}


													{/* Network6 */}
													{(colstate.Network6) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Network6'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Network6')}
																onKeyUp={(event) => onChangeValue(event, index, 'Network6')}
																defaultValue={localstate.defaultvalues[index].Network6} />
														</td>
													}


													{/* ACAdapter */}
													{(colstate.ACAdapter) &&
														<td>
															<div style={{ padding: "3px 4px 1px 4px", textAlign: "center" }}>
																<Checkbox
																	className={classes.gridcheckbox}
																	disableRipple
																	color="default"
																	defaultChecked={row.ACAdapter === 1 ? true : false}
																	checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onChange={(event) => onChangeValue(event, index, 'ACAdapter')}
																/>
															</div>
														</td>
													}

													{/* created_at */}
													{(colstate.created_at) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.created_at}
														</td>
													}

													{/* ListingType */}
													{(colstate.ListingType) &&
														<td className={classes.flexgridstaticcontainer} style={{ textAlign: "right" }}>
															{row.ListingType}
														</td>
													}


													{/* AskPrice */}
													{(colstate.AskPrice) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																type="number" step="0.01"
																ref={el => rowRefs.current[index + 'AskPrice'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "right" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'AskPrice')}
																onKeyUp={(event) => onChangeValue(event, index, 'AskPrice')}
																onBlur={(event) => DetectBlankNumber(event, index, "AskPrice")}
																defaultValue={localstate.defaultvalues[index].AskPrice} />
														</td>
													}

													{/* Status */}
													{(colstate.Status) &&
														<td>
															<FormControl className={classes.formControl}>
																<Select variant="standard"
																	defaultValue={localstate.defaultvalues[index].Status ? localstate.defaultvalues[index].Status : ""}
																	disableUnderline
																	onChange={(event) => onChangeValue(event, index, 'Status')}
																	classes={{
																		icon: classes.nodisplay,
																		select: classes.selectpadding
																	}}
																>
																	<MenuItem value={"Unavailable"}>Unavailable</MenuItem>
																	<MenuItem value={"Available"}>Available</MenuItem>
																	<MenuItem value={"Reserved"}>Reserved</MenuItem>
																	<MenuItem value={"Sold"}>Sold</MenuItem>

																</Select>
															</FormControl>
														</td>
													}

													{/* Published */}
													{(colstate.Published) &&
														<td>
															<div style={{ padding: "3px 4px 1px 4px", textAlign: "center" }}>
																<Checkbox
																	className={classes.gridcheckbox}
																	disableRipple
																	color="default"
																	defaultChecked={row.Published === 1 ? true : false}
																	checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onChange={(event) => onChangeValue(event, index, 'Published')}
																/>
															</div>
														</td>
													}

												</tr>
											}







											{/* Expanded Row Edit Mode Version */}
											{(row.ExpandRow === true && editmode) &&
												<tr>
													<td colSpan="100%" style={{ padding: "0px", margin: "0px" }}>
														<div style={{ padding: "25px", border: "2px solid #CCC" }}>
															<Typography variant="h6">
																<div style={{ maxWidth: "900px" }}>
																	<Grid container spacing={0}>
																		<Grid item sm={12} md={6}>
																			<div className={classes.flextabledebug}>
																				<table className={classes.itemtable}>
																					<tbody>
																						<tr>
																							<td style={{ width: "140px" }}>
																								Mfg:
																							</td>
																							<td>
																								<input className={classes.flexiteminput}
																									type="text"
																									defaultValue={localstate.defaultvalues[index].Mfg}
																									onKeyUp={(event) => onChangeItemValue(event, index, "Mfg")}
																								/>
																							</td>
																						</tr>
																						<tr>
																							<td>
																								Model:
																							</td>
																							<td>
																								<input className={classes.flexiteminput}
																									type="text"
																									defaultValue={localstate.defaultvalues[index].Model}
																									onKeyUp={(event) => onChangeItemValue(event, index, "Model")}
																								/>
																							</td>
																						</tr>
																						<tr>
																							<td>
																								Asset Type:
																							</td>
																							<td>
																								<input className={classes.flexiteminput}
																									type="text"
																									defaultValue={localstate.defaultvalues[index].AssetType}
																									onKeyUp={(event) => onChangeItemValue(event, index, "AssetType")}
																								/>
																							</td>
																						</tr>
																						<tr>
																							<td>
																								CPU:
																							</td>
																							<td>

																								<input className={classes.flexiteminput}
																									type="text"
																									defaultValue={localstate.defaultvalues[index].CPU}
																									onKeyUp={(event) => onChangeItemValue(event, index, "CPU")}
																								/>
																							</td>
																						</tr>
																						<tr>
																							<td>
																								RAM:
																							</td>
																							<td>

																								<input className={classes.flexiteminput}
																									type="text"
																									defaultValue={localstate.defaultvalues[index].RAM}
																									onKeyUp={(event) => onChangeItemValue(event, index, "RAM")}
																								/>
																							</td>
																						</tr>
																						<tr>
																							<td>
																								HDD1 Size:
																							</td>
																							<td>

																								<input className={classes.flexiteminput}
																									type="text"
																									defaultValue={localstate.defaultvalues[index].HDD1Size}
																									onKeyUp={(event) => onChangeItemValue(event, index, "HDD1Size")}
																								/>
																							</td>
																						</tr>

																						<tr>
																							<td>
																								HDD2 Size:
																							</td>
																							<td>

																								<input className={classes.flexiteminput}
																									type="text"
																									defaultValue={localstate.defaultvalues[index].HDD2Size}
																									onKeyUp={(event) => onChangeItemValue(event, index, "HDD2Size")}
																								/>
																							</td>
																						</tr>

																						<tr>
																							<td>
																								Optical:
																							</td>
																							<td>

																								<input className={classes.flexiteminput}
																									type="text"
																									defaultValue={localstate.defaultvalues[index].Optical}
																									onKeyUp={(event) => onChangeItemValue(event, index, "Optical")}
																								/>
																							</td>
																						</tr>

																						<tr>
																							<td>
																								Resolution:
																							</td>
																							<td>

																								<input className={classes.flexiteminput}
																									type="text"
																									defaultValue={localstate.defaultvalues[index].Resolution}
																									onKeyUp={(event) => onChangeItemValue(event, index, "Resolution")}
																								/>
																							</td>
																						</tr>

																						<tr>
																							<td>
																								Video Card 1:
																							</td>
																							<td>

																								<input className={classes.flexiteminput}
																									type="text"
																									defaultValue={localstate.defaultvalues[index].Video1Desc}
																									onKeyUp={(event) => onChangeItemValue(event, index, "Video1Desc")}
																								/>
																							</td>
																						</tr>

																						<tr>
																							<td>
																								Video Card 2:
																							</td>
																							<td>

																								<input className={classes.flexiteminput}
																									type="text"
																									defaultValue={localstate.defaultvalues[index].Video2Desc}
																									onKeyUp={(event) => onChangeItemValue(event, index, "Video2Desc")}
																								/>
																							</td>
																						</tr>



																						<tr>
																							<td style={{ width: "140px" }}>
																								Webcam:
																							</td>
																							<td>
																								<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																									<Select
																										displayEmpty
																										onChange={(event) => onChangeItemValue(event, index, "Webcam")}
																										//Setting value means React will try to consider this a controlled component.
																										//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
																										//This will present an error whent he component tries to go back to controlled.
																										defaultValue={localstate.defaultvalues[index].Webcam}
																										renderValue={
																											(selected) => {
																												if (!selected) {
																													//Placeholder!
																													return <Typography variant="h6">
																														{(row.Webcam === 1) &&
																															<React.Fragment>Yes</React.Fragment>
																														}
																														{(row.Webcam === 0) &&
																															<React.Fragment>No</React.Fragment>
																														}
																													</Typography>;
																												} else {
																													return <Typography variant="h6">
																														{(row.Webcam === 1) &&
																															<React.Fragment>Yes</React.Fragment>
																														}
																														{(row.Webcam === 0) &&
																															<React.Fragment>No</React.Fragment>
																														}
																													</Typography>;
																												}
																											}
																										}
																									>
																										<MenuItem value={1}>Yes</MenuItem>
																										<MenuItem value={0}>No</MenuItem>
																									</Select>
																								</FormControl>
																							</td>
																						</tr>
																						<tr>
																							<td style={{ width: "140px" }}>
																								Battery:
																							</td>
																							<td>
																								<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																									<Select
																										displayEmpty
																										onChange={(event) => onChangeItemValue(event, index, "Battery")}
																										//Setting value means React will try to consider this a controlled component.
																										//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
																										//This will present an error whent he component tries to go back to controlled.
																										defaultValue={localstate.defaultvalues[index].Battery}
																										renderValue={
																											(selected) => {
																												if (!selected) {
																													//Placeholder!
																													return <Typography variant="h6">
																														{(row.Battery === 1) &&
																															<React.Fragment>Yes</React.Fragment>
																														}
																														{(row.Battery === 0) &&
																															<React.Fragment>No</React.Fragment>
																														}
																													</Typography>;
																												} else {
																													return <Typography variant="h6">
																														{(row.Battery === 1) &&
																															<React.Fragment>Yes</React.Fragment>
																														}
																														{(row.Battery === 0) &&
																															<React.Fragment>No</React.Fragment>
																														}
																													</Typography>;
																												}
																											}
																										}
																									>
																										<MenuItem value={1}>Yes</MenuItem>
																										<MenuItem value={0}>No</MenuItem>
																									</Select>
																								</FormControl>
																							</td>
																						</tr>

																						<tr>
																							<td>
																								Batt 1:
																							</td>
																							<td>

																								<input className={classes.flexiteminput}
																									type="text"
																									defaultValue={localstate.defaultvalues[index].Batt1}
																									onKeyUp={(event) => onChangeItemValue(event, index, "Batt1")}
																								/>
																							</td>
																						</tr>

																						<tr>
																							<td>
																								Batt 2:
																							</td>
																							<td>

																								<input className={classes.flexiteminput}
																									type="text"
																									defaultValue={localstate.defaultvalues[index].Batt2}
																									onKeyUp={(event) => onChangeItemValue(event, index, "Batt2")}
																								/>
																							</td>
																						</tr>

																						<tr>
																							<td style={{ width: "140px" }}>
																								Touchscreen:
																							</td>
																							<td>
																								<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																									<Select
																										displayEmpty
																										onChange={(event) => onChangeItemValue(event, index, "Touchscreen")}
																										//Setting value means React will try to consider this a controlled component.
																										//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
																										//This will present an error whent he component tries to go back to controlled.
																										defaultValue={localstate.defaultvalues[index].Touchscreen}
																										renderValue={
																											(selected) => {
																												if (!selected) {
																													//Placeholder!
																													return <Typography variant="h6">
																														{(row.Touchscreen === 1) &&
																															<React.Fragment>Yes</React.Fragment>
																														}
																														{(row.Touchscreen === 0) &&
																															<React.Fragment>No</React.Fragment>
																														}
																													</Typography>;
																												} else {
																													return <Typography variant="h6">
																														{(row.Touchscreen === 1) &&
																															<React.Fragment>Yes</React.Fragment>
																														}
																														{(row.Touchscreen === 0) &&
																															<React.Fragment>No</React.Fragment>
																														}
																													</Typography>;
																												}
																											}
																										}
																									>
																										<MenuItem value={1}>Yes</MenuItem>
																										<MenuItem value={0}>No</MenuItem>
																									</Select>
																								</FormControl>
																							</td>
																						</tr>
																						<tr>
																							<td style={{ width: "140px" }}>
																								Bluetooth:
																							</td>
																							<td>
																								<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																									<Select
																										displayEmpty
																										onChange={(event) => onChangeItemValue(event, index, "Bluetooth")}
																										//Setting value means React will try to consider this a controlled component.
																										//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
																										//This will present an error whent he component tries to go back to controlled.
																										defaultValue={localstate.defaultvalues[index].Bluetooth}
																										renderValue={
																											(selected) => {
																												if (!selected) {
																													//Placeholder!
																													return <Typography variant="h6">
																														{(row.Bluetooth === 1) &&
																															<React.Fragment>Yes</React.Fragment>
																														}
																														{(row.Bluetooth === 0) &&
																															<React.Fragment>No</React.Fragment>
																														}
																													</Typography>;
																												} else {
																													return <Typography variant="h6">
																														{(row.Bluetooth === 1) &&
																															<React.Fragment>Yes</React.Fragment>
																														}
																														{(row.Bluetooth === 0) &&
																															<React.Fragment>No</React.Fragment>
																														}
																													</Typography>;
																												}
																											}
																										}
																									>
																										<MenuItem value={1}>Yes</MenuItem>
																										<MenuItem value={0}>No</MenuItem>
																									</Select>
																								</FormControl>
																							</td>
																						</tr>
																						<tr>
																							<td style={{ width: "140px" }}>
																								AC Adapter:
																							</td>
																							<td>
																								<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																									<Select
																										displayEmpty
																										onChange={(event) => onChangeItemValue(event, index, "ACAdapter")}
																										//Setting value means React will try to consider this a controlled component.
																										//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
																										//This will present an error whent he component tries to go back to controlled.
																										defaultValue={localstate.defaultvalues[index].ACAdapter}
																										renderValue={
																											(selected) => {
																												if (!selected) {
																													//Placeholder!
																													return <Typography variant="h6">
																														{(row.ACAdapter === 1) &&
																															<React.Fragment>Yes</React.Fragment>
																														}
																														{(row.ACAdapter === 0) &&
																															<React.Fragment>No</React.Fragment>
																														}
																													</Typography>;
																												} else {
																													return <Typography variant="h6">
																														{(row.ACAdapter === 1) &&
																															<React.Fragment>Yes</React.Fragment>
																														}
																														{(row.ACAdapter === 0) &&
																															<React.Fragment>No</React.Fragment>
																														}
																													</Typography>;
																												}
																											}
																										}
																									>
																										<MenuItem value={1}>Yes</MenuItem>
																										<MenuItem value={0}>No</MenuItem>
																									</Select>
																								</FormControl>
																							</td>
																						</tr>

																						<tr>
																							<td>
																								Network 1:
																							</td>
																							<td>

																								<input className={classes.flexiteminput}
																									type="text"
																									defaultValue={localstate.defaultvalues[index].Network1}
																									onKeyUp={(event) => onChangeItemValue(event, index, "Network1")}
																								/>
																							</td>
																						</tr>

																						<tr>
																							<td>
																								Network 2:
																							</td>
																							<td>

																								<input className={classes.flexiteminput}
																									type="text"
																									defaultValue={localstate.defaultvalues[index].Network2}
																									onKeyUp={(event) => onChangeItemValue(event, index, "Network2")}
																								/>
																							</td>
																						</tr>

																						<tr>
																							<td>
																								Network 3:
																							</td>
																							<td>

																								<input className={classes.flexiteminput}
																									type="text"
																									defaultValue={localstate.defaultvalues[index].Network3}
																									onKeyUp={(event) => onChangeItemValue(event, index, "Network3")}
																								/>
																							</td>
																						</tr>

																						<tr>
																							<td>
																								Network 4:
																							</td>
																							<td>

																								<input className={classes.flexiteminput}
																									type="text"
																									defaultValue={localstate.defaultvalues[index].Network4}
																									onKeyUp={(event) => onChangeItemValue(event, index, "Network4")}
																								/>
																							</td>
																						</tr>

																						<tr>
																							<td>
																								Network 5:
																							</td>
																							<td>

																								<input className={classes.flexiteminput}
																									type="text"
																									defaultValue={localstate.defaultvalues[index].Network5}
																									onKeyUp={(event) => onChangeItemValue(event, index, "Network5")}
																								/>
																							</td>
																						</tr>

																						<tr>
																							<td>
																								Network 6:
																							</td>
																							<td>

																								<input className={classes.flexiteminput}
																									type="text"
																									defaultValue={localstate.defaultvalues[index].Network6}
																									onKeyUp={(event) => onChangeItemValue(event, index, "Network6")}
																								/>
																							</td>
																						</tr>



																					</tbody>
																				</table>
																			</div>
																		</Grid>

																		<Grid item sm={12} md={6}>
																			<div className={classes.flextabledebug}>
																				<table className={classes.itemtable}>
																					<tbody>
																						<tr>
																							<td style={{ width: "140px" }}>
																								Status:
																							</td>
																							<td>
																								<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
																									<Select
																										displayEmpty
																										onChange={(event) => onChangeValue(event, index, "Status")}
																										//Setting value means React will try to consider this a controlled component.
																										//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
																										//This will present an error whent he component tries to go back to controlled.
																										value={row.Status}
																										disabled={row.Status === "Sold"}
																										renderValue={
																											(selected) => {
																												return <Typography variant="h6">{row.Status}</Typography>;
																											}
																										}
																									>
																										<MenuItem value={"Checked In"}>Checked In</MenuItem>
																										<MenuItem value={"Sold"}>Sold</MenuItem>
																										<MenuItem value={"Scrap"}>Scrap</MenuItem>
																										<MenuItem value={"Returned to Vendor"}>Returned to Vendor</MenuItem>
																									</Select>
																								</FormControl>
																							</td>
																						</tr>
																						{/* Grades */}
																						<tr>
																							<td style={{ width: "140px" }}>
																								Overall:
																							</td>
																							<td>
																								<div>
																									<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																										<Button ref={el => btnGradeA.current = el} onClick={() => SetGrade('A', index)} key="gradeabtn">A</Button>
																										<Button ref={el => btnGradeB.current = el} onClick={() => SetGrade('B', index)} key="gradebbtn">B</Button>
																										<Button ref={el => btnGradeC.current = el} onClick={() => SetGrade('C', index)} key="gradecbtn">C</Button>
																										<Button ref={el => btnGradeRepair.current = el} onClick={() => SetGrade('Repair', index)} key="graderepairbtn">Repair</Button>
																										<Button ref={el => btnGradeScrap.current = el} onClick={() => SetGrade('Scrap', index)} key="gradescrapbtn">Scrap</Button>
																									</ButtonGroup>
																								</div>

																							</td>
																						</tr>
																						{(row.AssetType === "Laptop" || row.AssetType === "AIO") &&
																							<React.Fragment>
																								<tr>
																									<td>
																										Body Grade:
																									</td>
																									<td>
																										<div>
																											<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																												<Button ref={el => btnBodyGradeA.current = el} onClick={() => SetBodyGrade('A', index)} key="bodygradeabtn">A</Button>
																												<Button ref={el => btnBodyGradeB.current = el} onClick={() => SetBodyGrade('B', index)} key="bodygradebbtn">B</Button>
																												<Button ref={el => btnBodyGradeC.current = el} onClick={() => SetBodyGrade('C', index)} key="bodygradecbtn">C</Button>
																												<Button ref={el => btnBodyGradeBad.current = el} onClick={() => SetBodyGrade('Bad', index)} key="bodygradebadbtn">Bad</Button>
																											</ButtonGroup>
																										</div>

																									</td>
																								</tr>
																								<tr>
																									<td>
																										LCD Grade:
																									</td>
																									<td>
																										<div>
																											<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																												<Button ref={el => btnLCDGradeA.current = el} onClick={() => SetLCDGrade('A', index)} key="lcdgradeabtn">A</Button>
																												<Button ref={el => btnLCDGradeB.current = el} onClick={() => SetLCDGrade('B', index)} key="lcdgradebbtn">B</Button>
																												<Button ref={el => btnLCDGradeC.current = el} onClick={() => SetLCDGrade('C', index)} key="lcdgradecbtn">C</Button>
																												<Button ref={el => btnLCDGradeBad.current = el} onClick={() => SetLCDGrade('Bad', index)} key="lcdgradebadbtn">Bad</Button>
																											</ButtonGroup>
																										</div>

																									</td>
																								</tr>
																							</React.Fragment>


																						}

																						{/* Notes */}
																						<tr>
																							<td>
																								Notes:
																							</td>
																							<td>
																								<TextareaAutosize
																									style={{ minHeight: "50px", backgroundColor: "#EEE" }}
																									className={classes.flexiteminput}
																									defaultValue={localstate.defaultvalues[index].Notes}
																									onKeyUp={(event) => onChangeItemValue(event, index, "Notes")}
																								/>
																							</td>
																						</tr>

																					</tbody>
																				</table>
																			</div>
																		</Grid>
																	</Grid>
																</div>
															</Typography>






























														</div>
													</td>
												</tr>
											}
										</React.Fragment>

									)
								}
								)
							}
							{(localstate.griditems.length === 0) &&
								<tr className="flexgridrow"><td colSpan="100%"
									style={{ padding: "12px", fontSize: "18px" }}>No Results</td></tr>
							}
						</tbody>
					}
					{(localstate.dbreload) &&
						<tbody>
							<tr>
								<td colSpan="100%">
									<div style={{ padding: "20px", textAlign: "center", margin: "auto" }}>
										<CircularProgress />
									</div>
								</td>
							</tr>
						</tbody>
					}
				</table>
			</div>



			{(localstate.totalitems > localstate.rowsperpage) &&
				<TablePagination className={classes.paginationalign}
					component="div"
					count={localstate.totalitems}
					page={localstate.page}
					onPageChange={handleChangePage}
					rowsPerPage={localstate.rowsperpage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					rowsPerPageOptions={[100, 250, 500]}
				/>
			}

		</div>
	);
}

export default ListingsTable;

//Item View

/* ##########################  Configuration Sections  ########################## */
//## UseState Variables
//## Column States
//##Column Configuration
//##Column Toggles
//##Row Design
//##Search Inputs
//##Button Functions

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";
import { styled } from '@mui/material/styles';

import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import fetch from 'cross-fetch';

//Datetime formatting
import Moment from 'react-moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

//Common Utilities
import RestrictInputNumber from "../common/RestrictInputNumber";

//Inventory Toolbar
import InventoryToolbar from './InventoryToolbar';

//Contexts
import { AppContext, AppProvider } from "../Auth/contexts/AppContext";
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


//Tables
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';

//Icons
import PendingIcon from '@mui/icons-material/Pending';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckIcon from '@mui/icons-material/Check';

//Buttons
import ButtonGroup from '@mui/material/ButtonGroup';

//Accordions
import Accordion from '@mui/material/Accordion';
//import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion from '@mui/material/Accordion';

//Export
import ExportCSV from '../common/ExportCSV';


/* ##########################  Configuration  ########################## */

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	//timeout:11800,
	timeout: 300000
};

//Helper Functions
//Have not used sleep just yet - is currently on auto-complete sample
function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}

function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this, args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

//Experiments in scaling
function resizeListener() {
	// heightOutput.textContent = window.innerHeight;
	// widthOutput.textContent = window.innerWidth;
}

window.addEventListener("resize", resizeListener);


const Item = (props) => {
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole, userName } = appContext;

	//URL Param for ItemID
	let { id } = useParams();

	let history = useHistory();

	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);
	const isPrintView = useMediaQuery("print");


	const rowRefs = useRef([]);
	const btnSave = useRef();
	const btnPendingSave = useRef();

	/* ##########################  UseState Variables  ########################## */
	const [state, setState] = useState({
		//Need to initialize a blank item
		dbreload: true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		id: id, //TRY - Init new id via URL params. If we update ID from results on SaveChanges, we can avoid having to deal with URL params on history.push (which doesn't seem to change much more than change the current URL, not useState variables.)
		pagereload: false,
		itemloaded: false, //POSSIBLY MOST IMPORTANT! Using defaultValue on an input causes issues if you try to render before item is loaded.
		pendingupdate: false,
		productsearchterm: '',
		itemdata: {
			product: {
				Name: 'Default'
			},
			damagedconditions: [],
			majorconditions: [],
			minorconditions: [],
			Status: "",
			lot: {
				LotName: ""
			},
			manifests: [],
			specs: []
		},

		UpdateID: undefined,
		ProductNamePlaceholder: "Default Name1",
		ItemStatusPlaceholder: "",
		//New Conditions
		newconditiontype: "Minor",
		setcondition: false, //For condition updates, we set this to true temporarily. After reload, set this back to false.
		newconditiontext: "",
		//Delete Conditions
		deletecondition: false,
		deleteconditionid: '',
		historymode: false,

		//Ability to Hide/Show Record History
		loadhistory: false

	});

	//Some inputs will need to work via refs. Let's try to codify it, possibly even use it elsewhere.
	//Try Object....
	const itemRefs = useRef({
		CostEl: "" //Will be Cost input ref element, not blank string?
	})

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Item
	function LoadItem(itemid) {

		//To Do: Chain load item and previous item state.
		//Warn user if the current record is old.
		//If admin, allow rollbacks.
		const postdata = {
			itemid: itemid,
			loadhistory: localstate.loadhistory
		};
		axios.post(dbendpoint + "/items/getitembyid", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {



					localstate.itemdata = res.data.item;
					localstate.itemhistory = res.data.itemhistory;


					//Evaluate Editable:
					localstate.editable = true;
					console.log(localstate.itemdata.Current === 0);
					console.log(localstate.itemdata.RecordType === "Draft" && userPerms.createItem === 0);
					console.log(localstate.itemdata.RecordType !== "Draft" && userPerms.updateItem === 0);
					if (localstate.itemdata.Current === 0 ||
						(localstate.itemdata.RecordType === "Draft" && userPerms.createItem === 0) ||
						(localstate.itemdata.RecordType !== "Draft" && userPerms.updateItem === 0)) {
						localstate.editable = false;
						console.log("THIS" + localstate.editable);
					}

					console.log("THIS" + localstate.editable);




					//Set value for Product Selection
					if (res.data.item.product) {
						//setProductSearchTerm(res.data.item.product.Name)
						localstate.productsearchterm = res.data.item.product.Name;
						localstate.modelplaceholder = res.data.item.product.Name;
						//To Do: if condition for db.product.ProductOverridesModel, setModelPlaceholder
						//setModelPlaceholder(res.data.item.product.Name);
					}
					//Cost is always nice to have pre-formatted
					localstate.itemdata.Cost = parseFloat(res.data.item.Cost).toFixed(2);
					// SetGrade(res.data.item.Grade, true);
					// if (res.data.item.BodyGrade){
					// 	SetBodyGrade(res.data.item.BodyGrade, true);
					// }

					//Provision for Draft Items
					if (localstate.itemdata.RecordType === "Draft") {
						localstate.itemdata.SerialNumber = "";
					}

					//Reset condition flag
					localstate.setcondition = false;
					localstate.newconditiontext = "";

					//I am suspecting we should recover current conditions for each history entry so we can easily render them.

					if (localstate.loadhistory) {
						EvaluateConditions();
					}

					localstate.dbreload = false;
					localstate.itemloaded = true; //Now allow render
					UpdateState(localstate);
				}
				//Provision to push user to newest URL of item:
				if (res.data.Status === "Reload") {
					localstate.id = res.data.item["ItemID"];
					localstate.dbreload = true;
					UpdateState(localstate);
					history.push(res.data.item["ItemID"].toString());
				}
				if (res.data.Status === "Failure") {
					//Failure error
					localstate.dbreload = false;
					UpdateState(localstate);
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
		});
	}

	//We have loads and saves that need to reevaluate localstate.itemdata and localstate.itemhistory.
	const EvaluateConditions = () => {
		console.log("Evaluating conditions.");
		//Re-evaluating Conditions. We can wipe out current, most recent history of components, then add/remove according to history
		localstate.itemhistory[0].minorconditions = [];
		localstate.itemhistory[0].majorconditions = [];
		localstate.itemhistory[0].damagedconditions = [];


		//We will go in chronological order, reverse the history array
		localstate.itemhistory = localstate.itemhistory.reverse();

		//Loop through current conditions and add to previous history records
		var i = 0;
		var j = 0;
		var k = 0;
		var l = 0;

		//Marshal IN
		for (i = 0; i < localstate.itemhistory.length; i++) {
			//console.log(localstate.itemhistory[i].ItemID);
			for (j = 0; j < localstate.itemhistory[i].addedminorconditions.length; j++) {
				for (k = (i + 1); k < localstate.itemhistory.length; k++) {
					console.log("I - K: " + i + " - " + k);
					localstate.itemhistory[k].minorconditions.push(localstate.itemhistory[i].addedminorconditions[j]);
				}
			}
		}
		for (i = 0; i < localstate.itemhistory.length; i++) {
			for (j = 0; j < localstate.itemhistory[i].addedmajorconditions.length; j++) {
				for (k = (i + 1); k < localstate.itemhistory.length; k++) {
					localstate.itemhistory[k].majorconditions.push(localstate.itemhistory[i].addedmajorconditions[j]);
				}
			}
		}
		for (i = 0; i < localstate.itemhistory.length; i++) {
			for (j = 0; j < localstate.itemhistory[i].addeddamagedconditions.length; j++) {
				for (k = (i + 1); k < localstate.itemhistory.length; k++) {
					localstate.itemhistory[k].damagedconditions.push(localstate.itemhistory[i].addeddamagedconditions[j]);
				}
			}
		}

		//Marshal OUT
		for (i = 0; i < localstate.itemhistory.length; i++) {
			for (j = 0; j < localstate.itemhistory[i].removedminorconditions.length; j++) {
				for (k = i; k < localstate.itemhistory.length; k++) {
					for (l = 0; l < localstate.itemhistory[k].minorconditions.length; l++) {
						if (localstate.itemhistory[i].removedminorconditions[j].ConditionID === localstate.itemhistory[k].minorconditions[l].ConditionID) {
							localstate.itemhistory[k].minorconditions.splice(l, 1);
						}
					}
				}
			}
		}
		for (i = 0; i < localstate.itemhistory.length; i++) {
			for (j = 0; j < localstate.itemhistory[i].removedmajorconditions.length; j++) {
				for (k = i; k < localstate.itemhistory.length; k++) {
					for (l = 0; l < localstate.itemhistory[k].majorconditions.length; l++) {
						if (localstate.itemhistory[i].removedmajorconditions[j].ConditionID === localstate.itemhistory[k].majorconditions[l].ConditionID) {
							localstate.itemhistory[k].majorconditions.splice(l, 1);
						}
					}
				}
			}
		}
		for (i = 0; i < localstate.itemhistory.length; i++) {
			for (j = 0; j < localstate.itemhistory[i].removeddamagedconditions.length; j++) {
				for (k = i; k < localstate.itemhistory.length; k++) {
					for (l = 0; l < localstate.itemhistory[k].damagedconditions.length; l++) {
						if (localstate.itemhistory[i].removeddamagedconditions[j].ConditionID === localstate.itemhistory[k].damagedconditions[l].ConditionID) {
							localstate.itemhistory[k].damagedconditions.splice(l, 1);
						}
					}
				}
			}
		}
	}

	//Try: Possible to traverse item's history without reloads? (using PreviousID and forward traversal NextID?)
	const LoadFromHistory = (id) => {
		//Find ID in stack of item history
		//console.log(localstate.itemhistory.find(({ ItemID }) => ItemID === id));
		var thisthing = localstate.itemhistory.find(({ ItemID }) => ItemID === id);
		console.log(thisthing);
		localstate.itemdata = localstate.itemhistory.find(({ ItemID }) => ItemID === id);

		//Evaluate Editable:
		localstate.editable = true;
		if (localstate.itemdata.Current === 0 ||
			(localstate.itemdata.RecordType === "Draft" && userPerms.createItem === 0) ||
			(localstate.itemdata.RecordType !== "Draft" && userPerms.updateItem === 0)) {
			localstate.editable = false;
			console.log("THIS" + localstate.editable);
		}

		console.log("THIS" + localstate.editable);

		//Set Placeholders
		//Not sure if needed, but maybe?
		if (localstate.itemdata.product) {
			localstate.productsearchterm = localstate.itemdata.product.Name;
			localstate.modelplaceholder = localstate.itemdata.product.Name;
		}

		//TO DO: 
		//Conditions AddedID less than current ItemID AND without RemovedID are Current. 
		//Conditions AddedID equal to current ItemID are Added. (Already in AddedConditions)
		//Conditions RemovedID equal to current ItemID are Removed. (Already in RemovedConditions)
		// for (var i=0; i<localstate.itemhistory.length;i++){
		// 	console.log("this");
		// 	for (var j=0; j<localstate.itemhistory[i].minorconditions.length;j++){
		// 		console.log("that");
		// 		if ((localstate.itemhistory[i].minorconditions[j].ItemID < localstate.itemdata.ItemID) && localstate.itemhistory[i].minorconditions[j].RemovedID===null){
		// 			console.log("Added component to current: "+localstate.itemhistory[i].minorconditions[j].ConditionText);
		// 		}
		// 	}
		// }

		// for (var i=0; localstate.itemdata.minorconditions.length; i++){
		// 	if ((localstate.itemdata.minorconditions[i].ItemID <= localstate.itemdata.ItemID) && (localstate.itemdata.minorconditions[i].RemovedID===null)){
		// 		console.log("this");
		// 		localstate.itemdata.minorconditions.push(localstate.itemdata.minorconditions[i]);
		// 	}
		// }

		// for (var i=0; localstate.itemdata.majorconditions.length; i++){
		// 	if ((localstate.itemdata.majorconditions[i].ItemID <= localstate.itemdata.ItemID) && (localstate.itemdata.majorconditions[i].RemovedID===null)){
		// 		console.log("that");
		// 		localstate.itemdata.majorconditions.push(localstate.itemdata.majorconditions[i]);
		// 	}
		// }



		/*
		
		localstate.itemdata = res.data.item;
							localstate.itemhistory = res.data.itemhistory;
							//Set value for Product Selection
							if (res.data.item.product) {
								//setProductSearchTerm(res.data.item.product.Name)
								localstate.productsearchterm = res.data.item.product.Name;
								localstate.modelplaceholder = res.data.item.product.Name;
								//To Do: if condition for db.product.ProductOverridesModel, setModelPlaceholder
								//setModelPlaceholder(res.data.item.product.Name);
							}
							//Cost is always nice to have pre-formatted
							localstate.itemdata.Cost = parseFloat(res.data.item.Cost).toFixed(2);
							// SetGrade(res.data.item.Grade, true);
							// if (res.data.item.BodyGrade){
							// 	SetBodyGrade(res.data.item.BodyGrade, true);
							// }
		
							//Provision for Draft Items
							if (localstate.itemdata.RecordType === "Draft") {
								localstate.itemdata.SerialNumber = "";
							}
		
							//Reset condition flag
							localstate.setcondition = false;
							localstate.newconditiontext = "";
							localstate.dbreload = false;
							localstate.itemloaded = true; //Now allow render
							UpdateState(localstate);
		
		
		
				*/

		//Set history mode so our conditions render differently
		localstate.historymode = true;
		localstate.itemdata.Cost = parseFloat(localstate.itemdata.Cost).toFixed(2);
		UpdateState(localstate);

	}



	//IMPORTANT: A big fix here was avoiding DB reloads when the state hasn't changed. Making this useEffect dependent on state changes avoids duplicate loads.
	useEffect(() => {
		//To Do: On load of item, set title to the serial number.
		document.title = "Item";
		if (state.dbreload) {
			//Avoid duplicate loads.
			localstate.dbreload = false;
			//Diagnostic:
			console.log("Localstate ID: " + localstate.id);
			LoadItem(localstate.id);
		} else {
			//Data Loaded:
			//Sync Grade btns
			SetGrade(localstate.itemdata.Grade, true);
			if (localstate.itemdata.AssetType === "Laptop" || localstate.itemdata.AssetType === "AIO") {
				SetBodyGrade(localstate.itemdata.BodyGrade, true);
				SetLCDGrade(localstate.itemdata.LCDGrade, true);
			}
		}
	}, [state]);





	//Restrict Number (too many places past the decimal)
	const RestrictNumber = (newvalue, oldvalue) => {
		var len = newvalue.length;
		var index = newvalue.indexOf('.');
		if (index > 0) {
			var decimalchars = (len - 1) - index;
			if (decimalchars > 2) {
				return oldvalue;
			}
		}
		return newvalue;
	}

	//Restrict Number to Integer - Don't accept any decimal.
	const RestrictNumberInteger = (newvalue, oldvalue) => {
		console.log(newvalue);
		if (newvalue < 0) {
			return 0;
		} else {
			return parseInt(newvalue);
		}
	}

	const DetectBlankNumber = (event, index, column) => {
		if (event.target.value === "") {
			rowRefs.current[index + column].value = "0.00";
			localstate.griditems[index][column] = "0.00";
		}
	}

	const SaveChanges = (checkbackinbool) => {
		/* About:
		Changes may OR MAY NOT reload the database item. Updates are cheaper if they don't
		involve a data fetch. Assuming a successful update, we can simple hold our view.
		New fetches of data involve a heavy set of relationships that would all need to be reloaded.
		Key: When any item changes ID, we reload the data. We hold a consistent ID after the first update.
		We know we need to reload the data if the API returns an OldID (the outdated\placeholder ID).
		*/

		/* Unfortunately it looks like we need to add conditions in our view before we go on to update other fields. */

		//Basic form check:
		if (localstate.itemdata.SerialNumber === "") {
			errors.NewError({ errmsg: "Serial number can not be blank!", errshow: true, errtimeout: 15, errtype: "warning" });
		} else {
			//Append UpdateID
			localstate.itemdata.UpdateID = localstate.UpdateID;
			var postdata = {
				item: localstate.itemdata
			};
			if (checkbackinbool) {
				postdata.item.CheckBackIn = true;
			}
			if (deleteitem) {
				postdata.item.DeleteItem = true;
			}
			if (localstate.setcondition) {
				postdata.item.NewCondition = true; //Flag in API to trigger new condition.
				postdata.item.NewConditionType = localstate.newconditiontype;
				postdata.item.NewConditionText = localstate.newconditiontext;
			}
			if (localstate.deletecondition) {
				postdata.item.DeleteCondition = true;
				postdata.item.DeleteConditionID = localstate.deleteconditionid;
			}
			postdata.item.loadhistory = localstate.loadhistory;


			axios.post(dbendpoint + "/items/updateitem", postdata, defaultpostoptions).then(res => {
				//API should be setup to send 200 response with status. Merge paginated requests.
				if (res.status === 200) {
					//If ValidateUser() fails to verify user, it sends back 'login' error. 
					if (res.data.Status === "login") {
						//Not logged in. Reload page causes redirect to /login
						window.location.reload(false);
					}
					//All new API calls should return a status.
					if (res.data.Status === "Success") {
						//Shortcut to Inventory Manager if the item was marked for deletion
						btnSave.current.style.display = "";
						btnPendingSave.current.style.display = "none";
						//Attempt to replace: btnSave.current.disabled = true;
						localstate.pendingsaves = false;
						localstate.historymode = false;

						if (res.data.OldID) {
							//Consider: We have the state of the item in the result.
							localstate.itemdata.ItemID = res.data.item['ItemID'];

							//Lock in ID for subequent updates
							localstate.UpdateID = res.data.item["ItemID"];
							//Lock in new ID for DB reload:
							localstate.id = res.data.item["ItemID"];

							//API has been modified to send the needed itemdata (including nested relationships)
							localstate.itemdata = res.data.item;
							if (localstate.loadhistory) {
								localstate.itemhistory = res.data.itemhistory;
								EvaluateConditions();
							}
							UpdateState(localstate);

							//Update URL
							window.history.replaceState(null, "New Page Title", "/item/" + res.data.item["ItemID"]);
						} else {
							//CheckBackIn will change manifest and Status values and needs resync.
							localstate.dbreload = true;
							UpdateState(localstate);
						}
						conditiontextref.current.value = "";
					}
					if (res.data.Status === "Deleted") {
						history.push("/inventorymanager");
						errors.NewError({ errmsg: '', errshow: false, errtimeout: 0, errtype: "ok" })
					}
					if (res.data.Status === "Failure") {
						//Failure error
						errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 15, errtype: "warning" })
					}
				} else {
					//Non-200 message from server.
					errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 15, errtype: "warning" })
				}
			});
		}
	}



	//Changes to item
	const onChangeValue = (event, itemkey) => {
		var oldvalue = localstate.itemdata[itemkey];
		console.log("Oldvalue: " + oldvalue);
		var newvalue = event.target.value;

		if (event.key !== "Tab" &&
			event.key !== "ArrowDown" &&
			event.key !== "ArrowUp" &&
			event.key !== "ShiftLeft" &&
			event.key !== "ShiftRight"
		) {

			if (itemkey === "Cost") {
				if (!oldvalue) {
					oldvalue = "0";
				}
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float"); //All arguments required. New universal function for all pages. However handle result below.
				if (newvalue) {
					if (oldvalue.indexOf(".") > -1 && newvalue.indexOf(".") === -1 && event.key === "Backspace") {
						localstate.itemdata[itemkey] = newvalue;
					} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)) {
						itemRefs.current[itemkey + "El"].value = newvalue;
						localstate.itemdata[itemkey] = newvalue;
					}
				} else {
					//Handle possible backspace.
					//We need to update the localstate, but NOT the ref. The input ref can't take values like "2." and messes up the cursor position if you try.
					if (event.key === "Backspace") {
						//If the newvalue doesn't have a decimal, but the old one did, we try to keep the decimal value by ignoring the ref.
						if ((oldvalue.indexOf(".") > -1)) {
							localstate.itemdata[itemkey] = parseFloat(event.target.value).toFixed(2);
						}
					}
				}
			}

			if (itemkey === "RepairNote") {
				setRepairWarning(true);
				//We don't need this, DB should make new date. localstate.itemdata.RepairDate = new Date();
				localstate.itemdata.RepairedByName = userName;
			}


			//Update Value
			localstate.itemdata[itemkey] = newvalue;

			//localstate.griditems[index].unsaved = true;
			localstate.pendingsaves = true;
			//btnSave.current.disabled = false;
			//Old
			// btnSave.current.style.color="white";
			// btnSave.current.style.backgroundColor="#01579B";
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
			UpdateState(localstate);
		}

	}



	const [openproductoptions, openProductOptions] = React.useState(false);
	const [productoptions, setProductOptions] = React.useState([]);
	const [loadingproductoptions, setLoadingProductOptions] = useState(false);
	const [modelplaceholder, setModelPlaceholder] = useState("");
	const InitProductOptions = () => {
		if (productoptions.length === 0) {
			ProductSearch("");
		}
		openProductOptions(true);
	}

	const onCloseProductOptions = (event) => {
		openProductOptions(false);
	}

	const onChangeProductOption = (event, newvalue) => {
		//Set value for current search
		// Example:
		// localstate.searchoptions.searchpairs.searchpairX.type=autocompletesearchtypeX;
		// localstate.searchoptions.searchpairs.searchpairX.value=newvalue[autocompletesearchtypeX];
		// localstate.dbreload = true;
		// UpdateState(localstate);

		//Or set value for your item:
		//localstate.itemdata['ProductID'] = newvalue['ProductID'];

		//Or just yell at the user, the ProductID they just set
		//alert(newvalue['ProductID']);
		if (newvalue['ProductOverridesModel']) {
			localstate.itemdata['Model'] = newvalue['Name'];
			localstate.modelplaceholder = newvalue['Name'];
		}

		localstate.itemdata['ProductID'] = newvalue['ProductID'];
		localstate.itemdata['AssetType'] = newvalue['ProductType'];

		//btnSave.current.disabled = false;
		//Old
		// btnSave.current.style.color="white";
		// btnSave.current.style.backgroundColor="#01579B";
		btnSave.current.style.display = "none";
		btnPendingSave.current.style.display = "";
		localstate.pendingsaves = true;
		UpdateState(localstate);
	}

	const ProductSearch = debounce(function (searchvalue) {
		//setProductSearchTerm(searchvalue);
		localstate.productsearchterm = searchvalue;
		setLoadingProductOptions(true);
		const postdata = {
			search: searchvalue,
			limit: 20
		};
		axios.post(dbendpoint + "/items/searchproducts", postdata, defaultpostoptions).then(res => {
			if (res.status === 200) {
				if (res.data.Status === "login") {
					window.location.reload(false);
				}
				if (res.data.Status === "Success") {
					setProductOptions(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
			setLoadingProductOptions(false);
		});
	}, 600);

	/* GRADES */
	const btnGradeA = useRef();
	const btnGradeB = useRef();
	const btnGradeC = useRef();
	const btnGradeRepair = useRef();
	const btnGradeScrap = useRef();

	const SetGrade = (grade, init) => {
		if (!init) {
			localstate.itemdata.Grade = grade;
		}
		if (grade === "A") {
			btnGradeA.current.classList.add(classes.flexactivebtn);
			btnGradeB.current.classList.remove(classes.flexactivebtn);
			btnGradeC.current.classList.remove(classes.flexactivebtn);
			btnGradeRepair.current.classList.remove(classes.flexactivebtn);
			btnGradeScrap.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "B") {
			btnGradeA.current.classList.remove(classes.flexactivebtn);
			btnGradeB.current.classList.add(classes.flexactivebtn);
			btnGradeC.current.classList.remove(classes.flexactivebtn);
			btnGradeRepair.current.classList.remove(classes.flexactivebtn);
			btnGradeScrap.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "C") {
			btnGradeA.current.classList.remove(classes.flexactivebtn);
			btnGradeB.current.classList.remove(classes.flexactivebtn);
			btnGradeC.current.classList.add(classes.flexactivebtn);
			btnGradeRepair.current.classList.remove(classes.flexactivebtn);
			btnGradeScrap.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "Repair") {
			btnGradeA.current.classList.remove(classes.flexactivebtn);
			btnGradeB.current.classList.remove(classes.flexactivebtn);
			btnGradeC.current.classList.remove(classes.flexactivebtn);
			btnGradeRepair.current.classList.add(classes.flexactivebtn);
			btnGradeScrap.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "Scrap") {
			btnGradeA.current.classList.remove(classes.flexactivebtn);
			btnGradeB.current.classList.remove(classes.flexactivebtn);
			btnGradeC.current.classList.remove(classes.flexactivebtn);
			btnGradeRepair.current.classList.remove(classes.flexactivebtn);
			btnGradeScrap.current.classList.add(classes.flexactivebtn);
		}

		if (!init) {
			//btnSave.current.disabled = false;
			//Old
			// btnSave.current.style.color="white";
			// btnSave.current.style.backgroundColor="#01579B";
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
			localstate.pendingsaves = true;
			UpdateState(localstate);
		}
	}

	/* Body Grades */
	const btnBodyGradeA = useRef();
	const btnBodyGradeB = useRef();
	const btnBodyGradeC = useRef();
	const btnBodyGradeBad = useRef();

	const SetBodyGrade = (grade, init) => {
		if (!init) {
			localstate.itemdata.BodyGrade = grade;
		}
		if (grade === "A") {
			btnBodyGradeA.current.classList.add(classes.flexactivebtn);
			btnBodyGradeB.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeC.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeBad.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "B") {
			btnBodyGradeA.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeB.current.classList.add(classes.flexactivebtn);
			btnBodyGradeC.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeBad.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "C") {
			btnBodyGradeA.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeB.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeC.current.classList.add(classes.flexactivebtn);
			btnBodyGradeBad.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "Bad") {
			btnBodyGradeA.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeB.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeC.current.classList.remove(classes.flexactivebtn);
			btnBodyGradeBad.current.classList.add(classes.flexactivebtn);
		}

		if (!init) {
			//btnSave.current.disabled = false;
			//Old
			// btnSave.current.style.color="white";
			// btnSave.current.style.backgroundColor="#01579B";
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
			localstate.pendingsaves = true;
			UpdateState(localstate);
		}
	}

	/* LCD Grades */
	const btnLCDGradeA = useRef();
	const btnLCDGradeB = useRef();
	const btnLCDGradeC = useRef();
	const btnLCDGradeBad = useRef();

	const SetLCDGrade = (grade, init) => {
		if (!init) {
			localstate.itemdata.LCDGrade = grade;
		}
		if (grade === "A") {
			btnLCDGradeA.current.classList.add(classes.flexactivebtn);
			btnLCDGradeB.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeC.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeBad.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "B") {
			btnLCDGradeA.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeB.current.classList.add(classes.flexactivebtn);
			btnLCDGradeC.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeBad.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "C") {
			btnLCDGradeA.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeB.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeC.current.classList.add(classes.flexactivebtn);
			btnLCDGradeBad.current.classList.remove(classes.flexactivebtn);
		}
		if (grade === "Bad") {
			btnLCDGradeA.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeB.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeC.current.classList.remove(classes.flexactivebtn);
			btnLCDGradeBad.current.classList.add(classes.flexactivebtn);
		}

		if (!init) {
			//localstate.pendingsaves = true;
			//btnSave.current.disabled = false;
			//Old
			// btnSave.current.style.color="white";
			// btnSave.current.style.backgroundColor="#01579B";
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
			localstate.pendingsaves = true;
			UpdateState(localstate);
		}
	}



	/* CONDITIONS */
	const btnMinor = useRef();
	const btnMajor = useRef();
	const btnDamaged = useRef();
	const conditiontextref = useRef();

	const SelectCondition = (conditiontype) => {
		localstate.newconditiontype = conditiontype;
		if (conditiontype === "Minor") {
			btnMinor.current.classList.add(classes.flexactivebtn);
			btnMajor.current.classList.remove(classes.flexactivebtn);
			btnDamaged.current.classList.remove(classes.flexactivebtn);
		}
		if (conditiontype === "Major") {
			btnMinor.current.classList.remove(classes.flexactivebtn);
			btnMajor.current.classList.add(classes.flexactivebtn);
			btnDamaged.current.classList.remove(classes.flexactivebtn);
		}
		if (conditiontype === "Damaged") {
			btnMinor.current.classList.remove(classes.flexactivebtn);
			btnMajor.current.classList.remove(classes.flexactivebtn);
			btnDamaged.current.classList.add(classes.flexactivebtn);
		}
	}

	const onChangeCondition = (event, newvalue) => {
		//console.log(event.target.value);
		if (event.key === "Enter") {
			if (event.target.value.length < 2) {
				errors.NewError({ errmsg: "Conditions must be 2 characters or more.", errshow: true, errtimeout: 5, errtype: "warning" })
			} else {
				console.log(event.target.value);
				//To Do: Query DB for duplicate before saving changes:

				var conditiondetails = {
					ItemID: localstate.itemdata.ItemID,
					ConditionText: event.target.value,
					ConditionType: localstate.newconditiontype
				}

				axios.post(dbendpoint + "/items/checkdupecondition", { conditiondetails: conditiondetails }, defaultpostoptions).then(res => {
					if (res.status === 200) {
						if (res.data.Status === "login") {
							window.location.reload(false);
						}
						if (res.data.Status === "Success") {
							//Assume we're moving to do the update immediately.
							localstate.setcondition = true;
							localstate.newconditiontext = event.target.value;
							SaveChanges(false);
						} else {
							errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "warning" })
						}
					} else {
						//Non-200 message from server.
						errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
					}
				});
			}







			//AddCondition(localstate.newconditiontype, event.target.value);
		}
	}

	const AddCondition = (conditiontype, conditiontext) => {
		var conditiondetails = {
			ItemID: localstate.itemdata.ItemID,
			ConditionText: conditiontext,
			ConditionType: conditiontype
		}
		axios.post(dbendpoint + "/items/setcondition", { conditiondetails: conditiondetails }, defaultpostoptions).then(res => {
			if (res.status === 200) {
				if (res.data.Status === "login") {
					window.location.reload(false);
				}
				if (res.data.Status === "Success") {
					//console.log(res.data);
					//console.log(conditiontextref);
					conditiontextref.current.value = "";
					localstate.dbreload = true;
					//On DB reload, we're going to get a whole new dataset, including history.
					//Even in HistoryMode, we allow edits to the last record (which show conditions in historical terms). This doesn't suit us
					//since we'll be back to the most recent live record and we don't want to show gray conditions.
					localstate.historymode = false;
					UpdateState(localstate);
				} else {
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "warning" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
		});
	}

	const DeleteCondition = (conditionid) => {
		localstate.deletecondition = true;
		localstate.deleteconditionid = conditionid;
		SaveChanges(false);


		/* Replace:
		axios.post(dbendpoint+"/items/deletecondition", {ConditionID:conditionid, ItemID:localstate.itemdata.ItemID}, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					console.log(res.data);

					//console.log(conditiontextref);
					//conditiontextref.current.value="";
					localstate.dbreload = true;
					UpdateState(localstate);
				} else {
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"warning"})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
		*/
	}



	const [deleteitem, setDeleteItem] = useState(false);
	const DeleteItem = () => {
		if (!deleteitem) {
			errors.NewError({ errmsg: "Item has been marked for deletion. Click 'Save Changes' to complete deletion.", errshow: true, errtimeout: 300, errtype: "warning" });
		} else {
			errors.NewError({ errmsg: "Canceled.", errshow: true, errtimeout: 1, errtype: "OK" });
		}
		setDeleteItem(!deleteitem);
		//Old
		// btnSave.current.style.color="white";
		// btnSave.current.style.backgroundColor="#01579B";
		btnSave.current.style.display = "none";
		btnPendingSave.current.style.display = "";
		localstate.pendingsaves = true;
		UpdateState(localstate);
	}


	//Currently not used - could be setup, but don't really want people changing already deleted items.
	const [undeleteitem, setUndeleteItem] = useState(false);
	const UndeleteItem = () => {
		if (!undeleteitem) {
			errors.NewError({ errmsg: "Item has been marked for deletion recovery. Click 'Save Changes' to complete recovery.", errshow: true, errtimeout: 300, errtype: "warning" });
		} else {
			errors.NewError({ errmsg: "Canceled.", errshow: true, errtimeout: 1, errtype: "OK" });
		}
		setUndeleteItem(!undeleteitem);
		//Old
		// btnSave.current.style.color="white";
		// btnSave.current.style.backgroundColor="#01579B";
		btnSave.current.style.display = "none";
		btnPendingSave.current.style.display = "";
		localstate.pendingsaves = true;
		UpdateState(localstate);
	}

	const [repairwarning, setRepairWarning] = useState(false);


	//Accordions
	const Accordion = styled((props) => (
		<MuiAccordion disableGutters elevation={0} square {...props} />
	))(({ theme }) => ({
		border: `1px solid ${theme.palette.divider}`,
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
	}));

	const AccordionSummary = styled((props) => (
		<MuiAccordionSummary
			expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
			{...props}
		/>
	))(({ theme }) => ({
		backgroundColor:
			theme.palette.mode === 'dark'
				? 'rgba(255, 255, 255, .05)'
				: 'rgba(0, 0, 0, .03)',
		flexDirection: 'row-reverse',
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(90deg)',
		},
		'& .MuiAccordionSummary-content': {
			marginLeft: theme.spacing(1),
		},
	}));

	const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
		padding: theme.spacing(0),
		borderTop: '1px solid rgba(0, 0, 0, .125)',
	}));

	const PrintLabel = (type) => {
		var params = {
			itemid: localstate.itemdata.ItemID,
			labeltype: type,
			autoclose: true
		};
		// Creating a form
		var form = document.createElement("form");
		form.setAttribute("method", "post");
		form.setAttribute("action", dbendpoint + "/labels");
		form.setAttribute("target", "NewLabel");
		for (var i in params) {
			if (params.hasOwnProperty(i)) {
				// Creating the input
				var input = document.createElement('input');
				input.type = 'hidden';
				input.name = i;
				input.value = params[i];

				// Attach input to form
				form.appendChild(input);
			}
		}
		document.body.appendChild(form);
		form.submit();

	}


	/* Utility Function - Attempt to Update Tables: */
	/* Not used.
	const UpdateManifests = () =>{
		axios.post(dbendpoint+"/items/updatemanifests", {}, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					console.log(res.data);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}
	*/


	/* ##########################  Render Function  ########################## */
	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			{(localstate.itemloaded) &&
				<div style={{ padding: "8px", minWidth: "750px", maxWidth: "1200px", textAlign: "center", margin: "auto", marginBottom: "100px" }}>

					{/* Standard Page Header with right floated error message space */}
					<div style={{ position: "relative", height: "85px", paddingTop: "5px" }}>
						<div style={{ textAlign: "center" }}>
							<h3>Inventory Item</h3>
						</div>
						<div>
							<Box sx={{ '& button': { m: 1 } }}>
								<Button
									className={(userPerms.updateItem === 1) ? classes.bluebtn : classes.hidden}
									color="primary" variant="contained"
									onClick={() => SaveChanges()}
									ref={el => btnSave.current = el}>
									<SaveIcon sx={{ color: "lightgray" }}></SaveIcon>&nbsp;Save Changes
								</Button>
								<Button
									className={(userPerms.updateItem === 1 || userPerms.createItem === 1) ? classes.bluebtn : classes.hidden}
									color="primary" variant="contained"
									style={{ display: "none" }}
									onClick={() => SaveChanges()}
									ref={el => btnPendingSave.current = el}>
									<PendingIcon sx={{ color: "orange" }}></PendingIcon>&nbsp;Save Changes
								</Button>
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => PrintLabel('inventory-sm')}>
									<PrintIcon />&nbsp;&nbsp;Small Label
								</Button>
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => PrintLabel('inventory')}>
									<PrintIcon />&nbsp;&nbsp;Large Label
								</Button>



								{(localstate.itemdata.SoftDelete === 1 && true) &&
									<Button className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => UndeleteItem()} style={{ float: "right" }}>
										<SettingsBackupRestoreIcon />&nbsp;&nbsp;Undelete Item
									</Button>
								}

							</Box>
						</div>
						<div style={{ height: "26px" }}>
							{(errors.currenterror.errshow) &&
								<React.Fragment>
									<ErrorMessage />
								</React.Fragment>
							}
						</div>


					</div>

					<Typography variant="h6">
						<Grid container spacing={0}>
							<div className={classes.itemtableheader}>
								<div style={{ display: "inline-block" }}>Item Details</div>
								{(userRole === "admin" && localstate.loadhistory) &&
									<div style={{ float: "right", position: "relative", top: "-7px", height: "12px" }}>

										<div style={{ display: "inline-block" }}>
											<a className={((localstate.itemdata.PreviousID) ? classes.hoverunit : classes.disabledhoverunit) + " " + classes.unselectable}
												onClick={() => (localstate.itemdata.PreviousID) ? LoadFromHistory(localstate.itemdata.PreviousID) : null}
											>
												<ChevronLeftIcon style={{ position: "relative", top: "4px", fontSize: "18px" }} />Previous Record&nbsp;&nbsp;
											</a>
										</div>
										<div style={{ display: "inline-block", color: "#4183c4" }} >|</div>
										<div style={{ display: "inline-block" }}>
											<a className={((localstate.itemdata.NextID) ? classes.hoverunit : classes.disabledhoverunit) + " " + classes.unselectable}
												onClick={() => (localstate.itemdata.NextID) ? LoadFromHistory(localstate.itemdata.NextID) : null}
											>
												&nbsp;&nbsp;Next Record<ChevronRightIcon style={{ position: "relative", top: "4px", fontSize: "18px" }} />
											</a>
										</div>
									</div>
								}
								{/* TO DO: ALLOW LOADING RECORD HISTORY */}

							</div>
							<Grid item sm={12} md={6}>
								<div className={classes.flextabledebug}>
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td style={{ width: "140px" }}>
													Serial Number:
												</td>
												<td>
													{/* Only admin can change serial  */}
													{(userRole === 'admin' && (localstate.itemdata.Current === 1)) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.SerialNumber}
															onKeyUp={(event) => onChangeValue(event, "SerialNumber")}
														/>
													}
													{(userRole !== 'admin' || (localstate.itemdata.Current !== 1)) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.SerialNumber}
														</div>
													}
												</td>
											</tr>
											<tr>
												<td>
													Mfg:
												</td>
												<td>
													{(localstate.editable) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.Mfg}
															onKeyUp={(event) => onChangeValue(event, "Mfg")}
														/>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.Mfg}
														</div>
													}
												</td>
											</tr>
											<tr>
												<td>
													Model:
												</td>
												<td>
													{(localstate.itemdata.product) &&
														<React.Fragment>
															{(localstate.itemdata.product.ProductOverridesModel === 1 || !localstate.editable) &&
																<div className={classes.flexitemstaticinput}>
																	{localstate.modelplaceholder}
																</div>
															}
															{(localstate.itemdata.product.ProductOverridesModel === 0 && localstate.editable) &&
																<input className={classes.flexiteminput}
																	type="text"
																	defaultValue={localstate.itemdata.Model}
																	onKeyUp={(event) => onChangeValue(event, "Model")}
																/>
															}
														</React.Fragment>
													}
													{(!localstate.itemdata.product && localstate.editable) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.Model}
															onKeyUp={(event) => onChangeValue(event, "Model")}
														/>
													}
													{(!localstate.itemdata.product && !localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.modelplaceholder}
														</div>
													}
												</td>
											</tr>
											<tr>
												<td>
													Product:
												</td>
												<td>
													{(localstate.editable) &&
														<Autocomplete freeSolo forcePopupIcon={false} disableClearable style={{ width: "100%" }}
															className={classes.flexitemautocompleteinput}
															open={openproductoptions} onOpen={() => { InitProductOptions(); }} onClose={() => openProductOptions(false)}
															//Not sure how to avoid passing event, then newvalue... seems to break when removing event.
															onChange={(event, newValue) => onChangeProductOption(event, newValue)}
															onInputChange={(event) => ProductSearch(event.target.value)}
															isOptionEqualToValue={(option, value) => option['Name'] === value}
															getOptionLabel={(option) => option['Name']}
															options={productoptions}
															loading={loadingproductoptions}
															//Setting value or defaultValue kinda breaks this. Use placeholder instead based on a useState variable
															renderInput={(params) => (
																<div ref={params.InputProps.ref}>
																	<input placeholder={localstate.productsearchterm} {...params.inputProps} />
																</div>
															)}
														/>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.productplaceholder}
														</div>
													}

												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</Grid>
							<Grid item sm={12} md={6}>
								<div className={classes.flextabledebug}>
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td style={{ width: "140px" }}>
													Status:
												</td>
												<td>
													{(localstate.editable) &&
														<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
															<Select
																displayEmpty
																onChange={(event) => onChangeValue(event, "Status")}
																//Setting value means React will try to consider this a controlled component.
																//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
																//This will present an error whent he component tries to go back to controlled.
																value={localstate.itemdata.Status}
																disabled={localstate.itemdata.Status === "Sold"}
																renderValue={
																	(selected) => {
																		return <Typography variant="h6">{localstate.itemdata.Status}</Typography>;
																	}
																}
															>
																<MenuItem value={"Checked In"}>Checked In</MenuItem>
																<MenuItem value={"Sold"}>Sold</MenuItem>
																<MenuItem value={"Scrap"}>Scrap</MenuItem>
																<MenuItem value={"Returned to Vendor"}>Returned to Vendor</MenuItem>
															</Select>
														</FormControl>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.Status}
														</div>
													}
												</td>
											</tr>
											<tr>
												<td>
													Location:
												</td>
												<td>
													{(localstate.editable) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.Location}
															onKeyUp={(event) => onChangeValue(event, "Location")}
														/>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.Location}
														</div>
													}
												</td>
											</tr>
											<tr>
												<td>
													Cost:
												</td>
												<td>
													{(localstate.editable) &&
														<input className={classes.flexiteminput}
															ref={el => itemRefs.current.CostEl = el}
															type="number"
															step="0.01"
															defaultValue={localstate.itemdata.Cost}
															onKeyUp={(event) => onChangeValue(event, "Cost")}
														/>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.Cost}
														</div>
													}
												</td>
											</tr>
											<tr>
												<td>
													Asset Type:
												</td>
												<td>
													{(localstate.editable) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.AssetType}
															onKeyUp={(event) => onChangeValue(event, "AssetType")}
														/>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.AssetType}
														</div>
													}
												</td>
											</tr>
											<tr>
												<td>

												</td>
												<td>
													{(localstate.editable && localstate.itemdata.Status === "Sold") &&
														<Button variant="contained" size="small" onClick={() => SaveChanges(true)}>Check Back In</Button>

													}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</Grid>



							<div className={classes.itemtableheader}>Condition</div>
							<Grid item sm={12} md={6}>
								<div className={classes.flextabledebug}>
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td style={{ width: "140px" }}>
													Overall:
												</td>
												<td>
													<div>
														{(localstate.editable) &&
															<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																<Button ref={el => btnGradeA.current = el} onClick={() => SetGrade('A')} key="gradeabtn">A</Button>
																<Button ref={el => btnGradeB.current = el} onClick={() => SetGrade('B')} key="gradebbtn">B</Button>
																<Button ref={el => btnGradeC.current = el} onClick={() => SetGrade('C')} key="gradecbtn">C</Button>
																<Button ref={el => btnGradeRepair.current = el} onClick={() => SetGrade('Repair')} key="graderepairbtn">Repair</Button>
																<Button ref={el => btnGradeScrap.current = el} onClick={() => SetGrade('Scrap')} key="gradescrapbtn">Scrap</Button>
															</ButtonGroup>
														}
														{(!localstate.editable) &&
															<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																<Button ref={el => btnGradeA.current = el} key="gradeabtn">A</Button>
																<Button ref={el => btnGradeB.current = el} key="gradebbtn">B</Button>
																<Button ref={el => btnGradeC.current = el} key="gradecbtn">C</Button>
																<Button ref={el => btnGradeRepair.current = el} key="graderepairbtn">Repair</Button>
																<Button ref={el => btnGradeScrap.current = el} key="gradescrapbtn">Scrap</Button>
															</ButtonGroup>
														}
													</div>

												</td>
											</tr>
											{(localstate.itemdata.AssetType === "Laptop" || localstate.itemdata.AssetType === "AIO") &&
												<React.Fragment>
													<tr>
														<td>
															Body Grade:
														</td>
														<td>
															<div>
																{(localstate.editable) &&
																	<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																		<Button ref={el => btnBodyGradeA.current = el} onClick={() => SetBodyGrade('A')} key="bodygradeabtn">A</Button>
																		<Button ref={el => btnBodyGradeB.current = el} onClick={() => SetBodyGrade('B')} key="bodygradebbtn">B</Button>
																		<Button ref={el => btnBodyGradeC.current = el} onClick={() => SetBodyGrade('C')} key="bodygradecbtn">C</Button>
																		<Button ref={el => btnBodyGradeBad.current = el} onClick={() => SetBodyGrade('Bad')} key="bodygradebadbtn">Bad</Button>
																	</ButtonGroup>
																}
																{(!localstate.editable) &&
																	<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																		<Button ref={el => btnBodyGradeA.current = el} key="bodygradeabtn">A</Button>
																		<Button ref={el => btnBodyGradeB.current = el} key="bodygradebbtn">B</Button>
																		<Button ref={el => btnBodyGradeC.current = el} key="bodygradecbtn">C</Button>
																		<Button ref={el => btnBodyGradeBad.current = el} key="bodygradebadbtn">Bad</Button>
																	</ButtonGroup>
																}
															</div>

														</td>
													</tr>
													<tr>
														<td>
															LCD Grade:
														</td>
														<td>
															<div>
																{(localstate.editable) &&
																	<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																		<Button ref={el => btnLCDGradeA.current = el} onClick={() => SetLCDGrade('A')} key="lcdgradeabtn">A</Button>
																		<Button ref={el => btnLCDGradeB.current = el} onClick={() => SetLCDGrade('B')} key="lcdgradebbtn">B</Button>
																		<Button ref={el => btnLCDGradeC.current = el} onClick={() => SetLCDGrade('C')} key="lcdgradecbtn">C</Button>
																		<Button ref={el => btnLCDGradeBad.current = el} onClick={() => SetLCDGrade('Bad')} key="lcdgradebadbtn">Bad</Button>
																	</ButtonGroup>
																}
																{(!localstate.editable) &&
																	<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																		<Button ref={el => btnLCDGradeA.current = el} key="lcdgradeabtn">A</Button>
																		<Button ref={el => btnLCDGradeB.current = el} key="lcdgradebbtn">B</Button>
																		<Button ref={el => btnLCDGradeC.current = el} key="lcdgradecbtn">C</Button>
																		<Button ref={el => btnLCDGradeBad.current = el} key="lcdgradebadbtn">Bad</Button>
																	</ButtonGroup>
																}

															</div>

														</td>
													</tr>
												</React.Fragment>
											}
											<tr>
												<td>
													Notes:
												</td>
												<td>
													{(localstate.editable) &&
														<TextareaAutosize
															style={{ minHeight: "50px", backgroundColor: "#EEE" }}
															className={classes.flexiteminput}
															defaultValue={localstate.itemdata.Notes}
															onKeyUp={(event) => onChangeValue(event, "Notes")}
														/>
													}
													{(!localstate.editable) &&
														<TextareaAutosize disabled
															style={{ minHeight: "50px", backgroundColor: "#EEE" }}
															className={classes.flexiteminput}
															defaultValue={localstate.itemdata.Notes}
															onKeyUp={(event) => onChangeValue(event, "Notes")}
														/>
													}

												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</Grid>
							<Grid item sm={12} md={6}>
								<div className={classes.flextabledebug}>
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td style={{ width: "140px" }}>
													Add:
												</td>
												<td>
													<div>
														{(localstate.editable) &&
															<React.Fragment>
																<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																	<Button ref={el => btnMinor.current = el} onClick={() => SelectCondition('Minor')} key="minorbtn" className={classes.flexactivebtn}>Minor</Button>
																	<Button ref={el => btnMajor.current = el} onClick={() => SelectCondition('Major')} key="majorbtn">Major</Button>
																	<Button ref={el => btnDamaged.current = el} onClick={() => SelectCondition('Damaged')} key="damagedbtn">Damaged</Button>
																</ButtonGroup>
																<input className={classes.flexiteminput + " " + classes.flexinputsmallplaceholder}
																	type="text"
																	ref={el => conditiontextref.current = el}
																	onKeyUp={(event) => onChangeCondition(event, "AssetType")}
																	maxLength="64"
																	placeholder="Enter text and press enter"
																/>
															</React.Fragment>
														}
														{(!localstate.editable) &&
															<ButtonGroup size="small" aria-label="small button group" sx={{ marginBottom: "5px" }}>
																<Button ref={el => btnMinor.current = el} key="minorbtn" className={classes.flexactivebtn}>Minor</Button>
																<Button ref={el => btnMajor.current = el} key="majorbtn">Major</Button>
																<Button ref={el => btnDamaged.current = el} key="damagedbtn">Damaged</Button>
															</ButtonGroup>
														}
													</div>
												</td>
											</tr>
											{((localstate.itemdata.damagedconditions.length > 0) || (localstate.itemdata.addeddamagedconditions?.length > 0) || (localstate.itemdata.removeddamagedconditions?.length > 0)) &&
												<tr>
													<td style={{ width: "140px" }}>
														Damaged:
													</td>
													<td>
														{(!localstate.historymode) &&
															<React.Fragment>
																{localstate.itemdata.damagedconditions.map((condition) => (
																	<div key={condition.ConditionID} className={classes.damagedcondition}>
																		{condition.ConditionText}&nbsp;
																		{(localstate.editable && localstate.itemdata.Current === 1) &&
																			<CloseIcon className={classes.conditiondeleteicon} onClick={() => DeleteCondition(condition.ConditionID)} />
																		}
																	</div>
																))}
															</React.Fragment>
														}
														{(localstate.historymode) &&
															<React.Fragment>
																{localstate.itemdata.damagedconditions.map((condition) => (
																	<div key={condition.ConditionID} className={classes.staticcondition}>
																		{condition.ConditionText}&nbsp;
																		{(localstate.editable && localstate.itemdata.Current === 1) &&
																			<CloseIcon className={classes.conditiondeleteicon} onClick={() => DeleteCondition(condition.ConditionID)} />
																		}
																	</div>
																))}
																{localstate.itemdata.addeddamagedconditions?.map((condition) => (
																	<div key={condition.ConditionID} className={classes.damagedcondition}>
																		{condition.ConditionText}&nbsp;
																		{(localstate.editable && localstate.itemdata.Current === 1) &&
																			<CloseIcon className={classes.conditiondeleteicon} onClick={() => DeleteCondition(condition.ConditionID)} />
																		}
																	</div>
																))}
																{localstate.itemdata.removeddamagedconditions?.map((condition) => (
																	<div key={condition.ConditionID} className={classes.removedcondition}>
																		{condition.ConditionText}&nbsp;
																	</div>
																))}
															</React.Fragment>
														}

													</td>
												</tr>
											}
											{(localstate.itemdata.majorconditions.length > 0 || localstate.itemdata.addedmajorconditions?.length > 0 || localstate.itemdata.removedmajorconditions?.length > 0) &&
												<tr>
													<td style={{ width: "140px" }}>
														Major:
													</td>
													<td>



														{(!localstate.historymode) &&
															<React.Fragment>
																{localstate.itemdata.majorconditions.map((condition) => (
																	<div key={condition.ConditionID} className={classes.majorcondition}>
																		{condition.ConditionText}&nbsp;
																		{(localstate.editable && localstate.itemdata.Current === 1) &&
																			<CloseIcon className={classes.conditiondeleteicon} onClick={() => DeleteCondition(condition.ConditionID)} />
																		}
																	</div>
																))}
															</React.Fragment>
														}
														{(localstate.historymode) &&
															<React.Fragment>
																{localstate.itemdata.majorconditions.map((condition) => (
																	<div key={condition.ConditionID} className={classes.staticcondition}>
																		{condition.ConditionText}&nbsp;
																		{(localstate.editable && localstate.itemdata.Current === 1) &&
																			<CloseIcon className={classes.conditiondeleteicon} onClick={() => DeleteCondition(condition.ConditionID)} />
																		}
																	</div>
																))}
																{localstate.itemdata.addedmajorconditions?.map((condition) => (
																	<div key={condition.ConditionID} className={classes.majorcondition}>
																		{condition.ConditionText}&nbsp;
																		{(localstate.editable && localstate.itemdata.Current === 1) &&
																			<CloseIcon className={classes.conditiondeleteicon} onClick={() => DeleteCondition(condition.ConditionID)} />
																		}
																	</div>
																))}
																{localstate.itemdata.removedmajorconditions?.map((condition) => (
																	<div key={condition.ConditionID} className={classes.removedcondition}>
																		{condition.ConditionText}&nbsp;
																	</div>
																))}
															</React.Fragment>
														}
													</td>
												</tr>
											}
											{(localstate.itemdata.minorconditions.length > 0 || localstate.itemdata.addedminorconditions?.length > 0 || localstate.itemdata.removedminorconditions?.length > 0) &&
												<tr>
													<td style={{ width: "140px" }}>
														Minor:
													</td>
													<td>
														{(!localstate.historymode) &&
															<React.Fragment>
																{localstate.itemdata.minorconditions.map((condition) => (
																	<div key={condition.ConditionID} className={classes.minorcondition}>
																		{condition.ConditionText}&nbsp;
																		{(localstate.editable && localstate.itemdata.Current === 1) &&
																			<CloseIcon className={classes.conditiondeleteicon} onClick={() => DeleteCondition(condition.ConditionID)} />
																		}
																	</div>
																))}
															</React.Fragment>
														}
														{(localstate.historymode) &&
															<React.Fragment>
																{localstate.itemdata.minorconditions.map((condition) => (
																	<div key={condition.ConditionID} className={classes.staticcondition}>
																		{condition.ConditionText}&nbsp;
																		{(localstate.editable && localstate.itemdata.Current === 1) &&
																			<CloseIcon className={classes.conditiondeleteicon} onClick={() => DeleteCondition(condition.ConditionID)} />
																		}
																	</div>
																))}
																{localstate.itemdata.addedminorconditions?.map((condition) => (
																	<div key={condition.ConditionID} className={classes.minorcondition}>
																		{condition.ConditionText}&nbsp;
																		{(localstate.editable && localstate.itemdata.Current === 1) &&
																			<CloseIcon className={classes.conditiondeleteicon} onClick={() => DeleteCondition(condition.ConditionID)} />
																		}
																	</div>
																))}
																{localstate.itemdata.removedminorconditions?.map((condition) => (
																	<div key={condition.ConditionID} className={classes.removedcondition}>
																		{condition.ConditionText}&nbsp;
																	</div>
																))}
															</React.Fragment>
														}
													</td>
												</tr>
											}
											<tr>
												<td>&nbsp;</td>
												<td>&nbsp;</td>
											</tr>
											{(localstate.editable) &&
												<tr>
													<td style={{ width: "140px" }}>
														Repair:
													</td>
													<td>

														<input className={classes.flexiteminput + " " + classes.flexinputsmallplaceholder}
															type="text"
															placeholder="Short description of repairs"
															onKeyUp={(event) => onChangeValue(event, "RepairNote")}
														/>
													</td>
												</tr>
											}

											{(localstate.itemdata.Repaired === 1) &&
												<tr>
													<td style={{ width: "140px" }}>

													</td>
													<td style={{ fontSize: "12px", textAlign: "left" }}>
														Most recent repair:<br></br>
														{localstate.itemdata.RepairedByName}: {localstate.itemdata.RepairNote}<br></br>
														Date: <Moment element='span' format="MMMM D, YYYY [at] h:mma">{localstate.itemdata.RepairDate}</Moment>
													</td>
												</tr>
											}

											{(repairwarning) &&
												<tr>
													<td colSpan="2" style={{ fontSize: "12px", fontWeight: "bold", textAlign: "center" }}>
														* Changes to repair notes will invalidate previous repairs.
													</td>
												</tr>

											}
										</tbody>
									</table>
								</div>
							</Grid>

							<div className={classes.itemtableheader}>Specifications</div>
							<Grid item sm={12} md={6}>
								<div className={classes.flextabledebug}>
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td style={{ width: "140px" }}>
													CPU:
												</td>
												<td>
													{(localstate.editable) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.CPU}
															onKeyUp={(event) => onChangeValue(event, "CPU")}
														/>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.CPU}
														</div>
													}

												</td>
											</tr>
											<tr>
												<td>
													RAM:
												</td>
												<td>
													{(localstate.editable) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.RAM}
															onKeyUp={(event) => onChangeValue(event, "RAM")}
														/>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.RAM}
														</div>
													}

												</td>
											</tr>
											<tr>
												<td>
													HDD 1:
												</td>
												<td>
													{(localstate.editable) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.HDD1Size}
															onKeyUp={(event) => onChangeValue(event, "HDD1Size")}
														/>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.HDD1Size}
														</div>
													}

												</td>
											</tr>
											<tr>
												<td>
													HDD 2:
												</td>
												<td>
													{(localstate.editable) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.HDD2Size}
															onKeyUp={(event) => onChangeValue(event, "HDD2Size")}
														/>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.HDD2Size}
														</div>
													}

												</td>
											</tr>
											<tr>
												<td>
													Optical:
												</td>
												<td>
													{(localstate.editable) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.Optical}
															onKeyUp={(event) => onChangeValue(event, "Optical")}
														/>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.Optical}
														</div>
													}

												</td>
											</tr>
											<tr>
												<td>
													Batt 1 Health:
												</td>
												<td>
													{(localstate.editable) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.Batt1}
															onKeyUp={(event) => onChangeValue(event, "Batt1")}
														/>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.Batt1}
														</div>
													}

												</td>
											</tr>
											<tr>
												<td>
													Batt 2 Health:
												</td>
												<td>
													{(localstate.editable) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.Batt2}
															onKeyUp={(event) => onChangeValue(event, "Batt2")}
														/>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.Batt2}
														</div>
													}

												</td>
											</tr>
											{/* Some values are read-only. Most of this comes from spec dumps. */}
											{/* Keep in mind, Laravel doesn't care for blank strings and will update the DB with NULL - making this conditional way easier. */}
											{(localstate.itemdata.Network1) &&
												<tr>
													<td>
														Network 1:
													</td>
													<td>
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.Network1}
														</div>
													</td>
												</tr>
											}
											{(localstate.itemdata.Network2) &&
												<tr>
													<td>
														Network 2:
													</td>
													<td>
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.Network2}
														</div>
													</td>
												</tr>
											}
											{(localstate.itemdata.Network3) &&
												<tr>
													<td>
														Network 3:
													</td>
													<td>
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.Network3}
														</div>
													</td>
												</tr>
											}
											{(localstate.itemdata.Network4) &&
												<tr>
													<td>
														Network 4:
													</td>
													<td>
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.Network4}
														</div>
													</td>
												</tr>
											}
											{(localstate.itemdata.Network5) &&
												<tr>
													<td>
														Network 5:
													</td>
													<td>
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.Network5}
														</div>
													</td>
												</tr>
											}
											{(localstate.itemdata.Network6) &&
												<tr>
													<td>
														Network 6:
													</td>
													<td>
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.Network6}
														</div>
													</td>
												</tr>
											}
										</tbody>
									</table>
								</div>
							</Grid>
							<Grid item sm={12} md={6}>
								<div className={classes.flextabledebug}>
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td style={{ width: "140px" }}>
													Webcam:
												</td>
												<td>
													{(localstate.editable) &&
														<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
															<Select
																displayEmpty
																onChange={(event) => onChangeValue(event, "Webcam")}
																//Setting value means React will try to consider this a controlled component.
																//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
																//This will present an error whent he component tries to go back to controlled.
																defaultValue={localstate.itemdata.Webcam}
																renderValue={
																	(selected) => {
																		if (!selected) {
																			//Placeholder!
																			return <Typography variant="h6">
																				{(localstate.itemdata.Webcam === 1) &&
																					<React.Fragment>Yes</React.Fragment>
																				}
																				{(localstate.itemdata.Webcam === 0) &&
																					<React.Fragment>No</React.Fragment>
																				}
																			</Typography>;
																		} else {
																			return <Typography variant="h6">
																				{(localstate.itemdata.Webcam === 1) &&
																					<React.Fragment>Yes</React.Fragment>
																				}
																				{(localstate.itemdata.Webcam === 0) &&
																					<React.Fragment>No</React.Fragment>
																				}
																			</Typography>;
																		}
																	}
																}
															>
																<MenuItem value={1}>Yes</MenuItem>
																<MenuItem value={0}>No</MenuItem>
															</Select>
														</FormControl>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{(localstate.itemdata.Webcam === 1) &&
																<React.Fragment>Yes</React.Fragment>
															}
															{(localstate.itemdata.Webcam === 0) &&
																<React.Fragment>No</React.Fragment>
															}
														</div>
													}

												</td>
											</tr>
											<tr>
												<td style={{ width: "140px" }}>
													Battery:
												</td>
												<td>
													{(localstate.editable) &&
														<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
															<Select
																displayEmpty
																onChange={(event) => onChangeValue(event, "Battery")}
																//Setting value means React will try to consider this a controlled component.
																//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
																//This will present an error whent he component tries to go back to controlled.
																defaultValue={localstate.itemdata.Battery}
																renderValue={
																	(selected) => {
																		if (!selected) {
																			//Placeholder!
																			return <Typography variant="h6">
																				{(localstate.itemdata.Battery === 1) &&
																					<React.Fragment>Yes</React.Fragment>
																				}
																				{(localstate.itemdata.Battery === 0) &&
																					<React.Fragment>No</React.Fragment>
																				}
																			</Typography>;
																		} else {
																			return <Typography variant="h6">
																				{(localstate.itemdata.Battery === 1) &&
																					<React.Fragment>Yes</React.Fragment>
																				}
																				{(localstate.itemdata.Battery === 0) &&
																					<React.Fragment>No</React.Fragment>
																				}
																			</Typography>;
																		}
																	}
																}
															>
																<MenuItem value={1}>Yes</MenuItem>
																<MenuItem value={0}>No</MenuItem>
															</Select>
														</FormControl>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{(localstate.itemdata.Battery === 1) &&
																<React.Fragment>Yes</React.Fragment>
															}
															{(localstate.itemdata.Battery === 0) &&
																<React.Fragment>No</React.Fragment>
															}
														</div>
													}

												</td>
											</tr>
											<tr>
												<td style={{ width: "140px" }}>
													Touchscreen:
												</td>
												<td>
													{(localstate.editable) &&
														<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
															<Select
																displayEmpty
																onChange={(event) => onChangeValue(event, "Touchscreen")}
																//Setting value means React will try to consider this a controlled component.
																//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
																//This will present an error whent he component tries to go back to controlled.
																defaultValue={localstate.itemdata.Touchscreen}
																renderValue={
																	(selected) => {
																		if (!selected) {
																			//Placeholder!
																			return <Typography variant="h6">
																				{(localstate.itemdata.Touchscreen === 1) &&
																					<React.Fragment>Yes</React.Fragment>
																				}
																				{(localstate.itemdata.Touchscreen === 0) &&
																					<React.Fragment>No</React.Fragment>
																				}
																			</Typography>;
																		} else {
																			return <Typography variant="h6">
																				{(localstate.itemdata.Touchscreen === 1) &&
																					<React.Fragment>Yes</React.Fragment>
																				}
																				{(localstate.itemdata.Touchscreen === 0) &&
																					<React.Fragment>No</React.Fragment>
																				}
																			</Typography>;
																		}
																	}
																}
															>
																<MenuItem value={1}>Yes</MenuItem>
																<MenuItem value={0}>No</MenuItem>
															</Select>
														</FormControl>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{(localstate.itemdata.Touchscreen === 1) &&
																<React.Fragment>Yes</React.Fragment>
															}
															{(localstate.itemdata.Touchscreen === 0) &&
																<React.Fragment>No</React.Fragment>
															}
														</div>
													}

												</td>
											</tr>
											<tr>
												<td style={{ width: "140px" }}>
													Bluetooth:
												</td>
												<td>
													{(localstate.editable) &&
														<FormControl variant="standard" className={classes.flexitembasicselect} sx={{ m: 0, width: "100%", border: "0px" }}>
															<Select
																displayEmpty
																onChange={(event) => onChangeValue(event, "Bluetooth")}
																//Setting value means React will try to consider this a controlled component.
																//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
																//This will present an error whent he component tries to go back to controlled.
																defaultValue={localstate.itemdata.Touchscreen}
																renderValue={
																	(selected) => {
																		if (!selected) {
																			//Placeholder!
																			return <Typography variant="h6">
																				{(localstate.itemdata.Bluetooth === 1) &&
																					<React.Fragment>Yes</React.Fragment>
																				}
																				{(localstate.itemdata.Bluetooth === 0) &&
																					<React.Fragment>No</React.Fragment>
																				}
																			</Typography>;
																		} else {
																			return <Typography variant="h6">
																				{(localstate.itemdata.Bluetooth === 1) &&
																					<React.Fragment>Yes</React.Fragment>
																				}
																				{(localstate.itemdata.Bluetooth === 0) &&
																					<React.Fragment>No</React.Fragment>
																				}
																			</Typography>;
																		}
																	}
																}
															>
																<MenuItem value={1}>Yes</MenuItem>
																<MenuItem value={0}>No</MenuItem>
															</Select>
														</FormControl>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{(localstate.itemdata.Bluetooth === 1) &&
																<React.Fragment>Yes</React.Fragment>
															}
															{(localstate.itemdata.Bluetooth === 0) &&
																<React.Fragment>No</React.Fragment>
															}
														</div>
													}

												</td>
											</tr>
											<tr>
												<td>
													Resolution:
												</td>
												<td>
													{(localstate.editable) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.Resolution}
															onKeyUp={(event) => onChangeValue(event, "Resolution")}
														/>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.Resolution}
														</div>
													}

												</td>
											</tr>
											<tr>
												<td>
													Video 1:
												</td>
												<td>
													{(localstate.editable) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.Video1Desc}
															onKeyUp={(event) => onChangeValue(event, "Video1Desc")}
														/>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.Video1Desc}
														</div>
													}
												</td>
											</tr>
											<tr>
												<td>
													Video 2:
												</td>
												<td>
													{(localstate.editable) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.Video2Desc}
															onKeyUp={(event) => onChangeValue(event, "Video2Desc")}
														/>
													}
													{(!localstate.editable) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.Video2Desc}
														</div>
													}

												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</Grid>
							<div className={classes.itemtableheader}>Record Details</div>
							<Grid item sm={12} md={6}>
								<div className={classes.flextabledebug}>
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td style={{ width: "140px" }}>
													Created At:
												</td>
												<td>
													<div className={classes.flexitemstaticinput}>
														<Moment element='span' format="MMMM D, YYYY [at] h:mma">{localstate.itemdata.ItemCreationDate}</Moment>
													</div>
												</td>
											</tr>
											<tr>
												<td>
													Created By:
												</td>
												<td>
													<div className={classes.flexitemstaticinput}>
														{localstate.itemdata.CreatedBy}
													</div>
												</td>
											</tr>
											{(localstate.itemdata.lot) &&
												<tr>
													<td>
														Lot:
													</td>
													<td>
														<div className={classes.flexitemstaticinput}>
															<a target="_blank" href={"/inventorymanager?LotName=" + localstate.itemdata.lot.LotName}>{localstate.itemdata.lot.LotName}</a>
															{(localstate.itemdata.SkidNumber) &&
																<React.Fragment>
																	, skid #{localstate.itemdata.SkidNumber}
																</React.Fragment>
															}
														</div>
													</td>
												</tr>
											}
											{(localstate.itemdata.lot) &&
												<tr>
													<td>
														Vendor:
													</td>
													<td>
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.Vendor}
														</div>
													</td>
												</tr>
											}
											{(!localstate.itemdata.lot) &&
												<tr>
													<td>
														Vendor:
													</td>
													<td>
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.Vendor}
															onKeyUp={(event) => onChangeValue(event, "Vendor")}
														/>
													</td>
												</tr>
											}
										</tbody>
									</table>
								</div>
							</Grid>
							<Grid item sm={12} md={6}>
								<div className={classes.flextabledebug}>
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td style={{ width: "140px" }}>
													Last Audit:
												</td>
												<td>
													<div className={classes.flexitemstaticinput}>
														<Moment element='span' format="MMMM D, YYYY [at] h:mma">{localstate.itemdata.LastAudit}</Moment>
													</div>
												</td>
											</tr>
											<tr>
												<td>
													Updated at:
												</td>
												<td>
													<div className={classes.flexitemstaticinput}>
														<Moment element='span' format="MMMM D, YYYY [at] h:mma">{localstate.itemdata.updated_at}</Moment>
													</div>
												</td>
											</tr>

											<tr>
												<td>
													Updated By:
												</td>
												<td>
													<div className={classes.flexitemstaticinput}>
														{localstate.itemdata.updated_by}
													</div>
												</td>
											</tr>
											<tr>
												<td>
													Item ID:
												</td>
												<td>
													<div className={classes.flexitemstaticinput}>
														{localstate.itemdata.ItemID}
													</div>
												</td>
											</tr>
											<tr>
												<td>
													Previous ID:
												</td>
												<td>
													<div className={classes.flexitemstaticinput}>
														{localstate.itemdata.PreviousID}
													</div>
												</td>
											</tr>
											<tr>
												<td>
													Next ID:
												</td>
												<td>
													<div className={classes.flexitemstaticinput}>
														{localstate.itemdata.NextID}
													</div>
												</td>
											</tr>
											{(userPerms.deleteItem === 1) &&
												<tr>
													<td colSpan="2">
														<div style={{ float: "right" }}>
															<Button className={classes.bluebtn}
																color="primary" variant="contained"
																onClick={() => DeleteItem()}>
																<DeleteIcon />&nbsp;&nbsp;Delete Item
															</Button>
														</div>
													</td>
												</tr>
											}
										</tbody>
									</table>
								</div>
							</Grid>
							{/*
					<Grid item sm={12} md={6}>
						<div className={classes.flextabledebug}>
							<table className={classes.itemtable}>
								<tbody>
									<tr><td style={{ width: "140px" }}></td></tr>
								</tbody>
							</table>
						</div>
					</Grid>
					<Grid item sm={12} md={6}>
						<div className={classes.flextabledebug}>
							<table className={classes.itemtable}>
								<tbody>
									<tr><td style={{ width: "140px" }}></td></tr>
								</tbody>
							</table>
						</div>
					</Grid>
					*/}
							{(localstate.itemdata.manifests.length > 0 && !localstate.historymode) &&
								<React.Fragment>
									<div className={classes.itemtableheader}>Manifests</div>
									<div style={{ padding: "0px", width: "100%" }}>
										<table className={classes.simpletable} style={{ textAlign: "left" }}>
											<thead>
												<tr key="mdefault">
													<td style={{ paddingLeft: "16px" }}>
														Order Number
													</td>
													<td>
														Name
													</td>
													<td>
														Date
													</td>
													<td>Assigned
													</td>
												</tr>
											</thead>
											<tbody>
												{localstate.itemdata.manifests.map((manifest) => (
													<React.Fragment key={"m" + manifest.ID}>
														<tr style={manifest.Valid ? { backgroundColor: "#c8efc8" } : {}}>
															<td style={{ paddingLeft: "16px" }}>
																<a target="_blank" href={"/order/" + manifest.InternalOrderID}>{manifest.order.OrderID}&nbsp;</a>
															</td>
															<td>
																{/* Systems integration prefers a Billing Full Name, BFullName. It is possible this could be empty however. */}
																{(manifest.order.BFullName) &&
																	<span>{manifest.order.BFullName}&nbsp;</span>
																}
																{!(manifest.order.BFullName) &&
																	<span>{manifest.order.BFirstName} {manifest.order.BLastName}&nbsp;</span>
																}
															</td>
															<td>
																<Moment element='span' format="MMMM D, YYYY">{manifest.order.OrderDate}</Moment>
															</td>
															<td>
																{(manifest.Valid ? 'Yes' : 'No')}&nbsp;
															</td>
														</tr>
													</React.Fragment>
												))}
											</tbody>
										</table>
									</div>
								</React.Fragment>
							}

							{(localstate.itemdata.specs.length > 0) &&
								<React.Fragment>
									<div className={classes.itemtableheader}>Captured Specs</div>
									{localstate.itemdata.specs.map((spec) => (
										<Accordion key={spec.ID} sx={{ width: "100%" }} className={classes.flexaccordion}>
											<AccordionSummary
												expandIcon={<ArrowForwardIosSharpIcon />}
												aria-controls="panel1a-content"
												id="panel1a-header"
											>
												<div style={{ textAlign: "center", width: "100%" }}>
													<Moment element='span' format="MMMM D, YYYY">{spec.created_at}</Moment>&nbsp;-&nbsp;{spec.SpecStep}
												</div>
											</AccordionSummary>
											<AccordionDetails>
												<Grid container spacing={0}>
													<Grid item sm={12} md={6}>
														<div className={classes.flextabledebug}>
															<table className={classes.itemtable}>
																<tbody>
																	<tr>
																		<td style={{ width: "140px" }}>
																			Mfg:
																		</td>
																		<td>
																			<div className={classes.flexitemstaticinput}>
																				{spec.Manufacturer}
																			</div>
																		</td>
																	</tr>
																	<tr>
																		<td style={{ width: "140px" }}>
																			Model:
																		</td>
																		<td>
																			<div className={classes.flexitemstaticinput}>
																				{spec.Model}
																			</div>
																		</td>
																	</tr>
																	<tr>
																		<td style={{ width: "140px" }}>
																			CPU:
																		</td>
																		<td>
																			<div className={classes.flexitemstaticinput}>
																				{spec.CPU}
																			</div>
																		</td>
																	</tr>
																	<tr>
																		<td style={{ width: "140px" }}>
																			TotalRAM:
																		</td>
																		<td>
																			<div className={classes.flexitemstaticinput}>
																				{spec.TotalRAM}
																			</div>
																		</td>
																	</tr>
																	{(spec.HDD1Size) &&
																		<tr>
																			<td style={{ width: "140px" }}>
																				HDD 1 Size:
																			</td>
																			<td>
																				<div className={classes.flexitemstaticinput}>
																					{spec.HDD1Size}
																				</div>
																			</td>
																		</tr>
																	}
																	{(spec.HDD2Size) &&
																		<tr>
																			<td style={{ width: "140px" }}>
																				HDD 2 Size:
																			</td>
																			<td>
																				<div className={classes.flexitemstaticinput}>
																					{spec.HDD2Size}
																				</div>
																			</td>
																		</tr>
																	}
																	{(spec.Video1Desc) &&
																		<tr>
																			<td style={{ width: "140px" }}>
																				Video 1:
																			</td>
																			<td>
																				<div className={classes.flexitemstaticinput}>
																					{spec.Video1Desc}
																				</div>
																			</td>
																		</tr>
																	}
																	{(spec.Video2Desc) &&
																		<tr>
																			<td style={{ width: "140px" }}>
																				Video 2:
																			</td>
																			<td>
																				<div className={classes.flexitemstaticinput}>
																					{spec.Video2Desc}
																				</div>
																			</td>
																		</tr>
																	}
																	{(spec.HorizontalResolution) &&
																		<tr>
																			<td style={{ width: "140px" }}>
																				Resolution:
																			</td>
																			<td>
																				<div className={classes.flexitemstaticinput}>
																					{spec.HorizontalResolution}x{spec.VerticalResolution}
																				</div>
																			</td>
																		</tr>
																	}

																	{(spec.Batt1Health) &&
																		<React.Fragment>
																			<tr>
																				<td style={{ width: "140px" }}>

																				</td>
																				<td>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																						Battery 1
																					</div>
																				</td>
																				<td>
																					<React.Fragment>&nbsp;</React.Fragment>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Health:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.Batt1Health}
																					</div>
																				</td>
																			</tr>
																			{(spec.Batt1Name) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Name:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Batt1Name}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.Batt1Manufacturer) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Manufacturer:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Batt1Manufacturer}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.Batt1SerialNumber) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Serial:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Batt1SerialNumber}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.Batt1PowerState) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Power State:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Batt1PowerState}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.Batt1ChargeCycles) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Cycles:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Batt1ChargeCycles}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.Batt1Charge) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Charge:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Batt1Charge}
																						</div>
																					</td>
																				</tr>
																			}
																		</React.Fragment>
																	}

																	{(spec.Batt2Health) &&
																		<React.Fragment>
																			<tr>
																				<td>
																					<React.Fragment>&nbsp;</React.Fragment>
																				</td>
																				<td>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																						Battery 2
																					</div>
																				</td>
																				<td>
																					<React.Fragment>&nbsp;</React.Fragment>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Health:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.Batt2Health}
																					</div>
																				</td>
																			</tr>
																			{(spec.Batt2Name) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Name:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Batt2Name}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.Batt2Manufacturer) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Manufacturer:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Batt2Manufacturer}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.Batt2SerialNumber) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Serial:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Batt2SerialNumber}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.Batt2PowerState) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Power State:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Batt2PowerState}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.Batt2ChargeCycles) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Cycles:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Batt2ChargeCycles}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.Batt2Charge) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Charge:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Batt2Charge}
																						</div>
																					</td>
																				</tr>
																			}
																		</React.Fragment>
																	}


																	{(spec.HDD1Size) &&
																		<React.Fragment>
																			<tr>
																				<td>
																					<React.Fragment>&nbsp;</React.Fragment>
																				</td>
																				<td>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																						Hard Drive 1
																					</div>
																				</td>
																				<td>
																					<React.Fragment>&nbsp;</React.Fragment>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Size:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.HDD1Size}
																					</div>
																				</td>
																			</tr>
																			{(spec.HDD1Manufacturer) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Manufacturer:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD1Manufacturer}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.HDD1Model) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Model:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD1Model}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.HDD1SerialNumber) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Serial:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD1SerialNumber}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.HDD1InterfaceType) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Interface:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD1InterfaceType}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.HDD1SMARTStatus) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						SMART Status:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD1SMARTStatus}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.HDD1ReallocatedSectors) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Reallocated:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD1ReallocatedSectors}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.HDD1PendingSectors) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Pending:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD1PendingSectors}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.HDD1Temp) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Temp:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD1Temp}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.HDD1PowerOnHours) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Hours:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD1PowerOnHours}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.HDD1PendingSectors) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Pending:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD1PendingSectors}
																						</div>
																					</td>
																				</tr>
																			}
																		</React.Fragment>
																	}
																	{(spec.HDD2Size) &&
																		<React.Fragment>
																			<tr>
																				<td>
																					<React.Fragment>&nbsp;</React.Fragment>
																				</td>
																				<td>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																						Hard Drive 2
																					</div>
																				</td>
																				<td>
																					<React.Fragment>&nbsp;</React.Fragment>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Size:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.HDD2Size}
																					</div>
																				</td>
																			</tr>
																			{(spec.HDD2Manufacturer) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Manufacturer:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD2Manufacturer}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.HDD2Model) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Model:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD2Model}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.HDD2SerialNumber) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Serial:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD2SerialNumber}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.HDD2InterfaceType) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Interface:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD2InterfaceType}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.HDD2SMARTStatus) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						SMART Status:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD2SMARTStatus}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.HDD2ReallocatedSectors) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Reallocated:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD2ReallocatedSectors}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.HDD2PendingSectors) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Pending:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD2PendingSectors}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.HDD2Temp) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Temp:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD2Temp}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.HDD2PowerOnHours) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Hours:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD2PowerOnHours}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.HDD2PendingSectors) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Pending:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.HDD2PendingSectors}
																						</div>
																					</td>
																				</tr>
																			}
																		</React.Fragment>
																	}
																	{(spec.Network1) &&
																		<React.Fragment>
																			<tr>
																				<td>
																					<React.Fragment>&nbsp;</React.Fragment>
																				</td>
																				<td>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																						Network
																					</div>
																				</td>
																				<td>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Device 1:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.Network1}
																					</div>
																				</td>
																			</tr>
																			{(spec.Network2) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Device 2:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Network2}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.Network3) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Device 3:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Network3}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.Network4) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Device 4:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Network4}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.Network5) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Device 5:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Network5}
																						</div>
																					</td>
																				</tr>
																			}
																			{(spec.Network6) &&
																				<tr>
																					<td style={{ width: "140px" }}>
																						Device 6:
																					</td>
																					<td>
																						<div className={classes.flexitemstaticinput}>
																							{spec.Network6}
																						</div>
																					</td>
																				</tr>
																			}
																		</React.Fragment>
																	}
																	<tr>
																		<td style={{ width: "140px" }}>
																			Mfg:
																		</td>
																		<td>
																			<div className={classes.flexitemstaticinput}>
																				{spec.Mfg}
																			</div>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</Grid>

													<Grid item sm={12} md={6}>
														<div className={classes.flextabledebug}>
															<table className={classes.itemtable}>
																<tbody>
																	{(spec.SMBIOSVersion) &&
																		<tr>
																			<td style={{ width: "140px" }}>
																				SMBIOS Version:
																			</td>
																			<td>
																				<div className={classes.flexitemstaticinput}>
																					{spec.SMBIOSVersion}
																				</div>
																			</td>
																		</tr>
																	}
																	{(spec.OSVersion) &&
																		<tr>
																			<td style={{ width: "140px" }}>
																				OS:
																			</td>
																			<td>
																				<div className={classes.flexitemstaticinput}>
																					{spec.OS}
																				</div>
																			</td>
																		</tr>
																	}
																	{(spec.WindowsProductKey) &&
																		<tr>
																			<td style={{ width: "140px" }}>
																				Win Key:
																			</td>
																			<td>
																				<div className={classes.flexitemstaticinput}>
																					{spec.WindowsProductKey}
																				</div>
																			</td>
																		</tr>
																	}
																	{(spec.WindowsBiosKey) &&
																		<tr>
																			<td style={{ width: "140px" }}>
																				Win BIOS Key:
																			</td>
																			<td>
																				<div className={classes.flexitemstaticinput}>
																					{spec.WindowsBiosKey}
																				</div>
																			</td>
																		</tr>
																	}
																	{(spec.OSVersion) &&
																		<tr>
																			<td style={{ width: "140px" }}>
																				OS Version:
																			</td>
																			<td>
																				<div className={classes.flexitemstaticinput}>
																					{spec.OSVersion}
																				</div>
																			</td>
																		</tr>
																	}
																	{(spec.ThermalState) &&
																		<tr>
																			<td style={{ width: "140px" }}>
																				Thermal State
																			</td>
																			<td>
																				<div className={classes.flexitemstaticinput}>
																					{spec.ThermalState}
																				</div>
																			</td>
																		</tr>
																	}
																	{(spec.ClientIP) &&
																		<tr>
																			<td style={{ width: "140px" }}>
																				Client IP:
																			</td>
																			<td>
																				<div className={classes.flexitemstaticinput}>
																					{spec.ClientIP}
																				</div>
																			</td>
																		</tr>
																	}

																	{/* Section for Complete Step Testing Results */}
																	{(spec.SpecStep === "Complete") &&
																		<React.Fragment>
																			<tr>
																				<td style={{ width: "140px" }}>
																					<React.Fragment>&nbsp;</React.Fragment>
																				</td>
																				<td>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																						Tests
																					</div>
																				</td>
																				<td>
																					<React.Fragment>&nbsp;</React.Fragment>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Specs:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{(spec.ChecklistSpecs === 1) &&
																							<CheckIcon color="success" />
																						}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Drivers:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{(spec.ChecklistMissingDevices === 1) &&
																							<CheckIcon color="success" />
																						}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Sound:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{(spec.ChecklistSound === 1) &&
																							<CheckIcon color="success" />
																						}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Keyboard:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{(spec.ChecklistKeyboard === 1) &&
																							<CheckIcon color="success" />
																						}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Wifi:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{(spec.ChecklistWifi === 1) &&
																							<CheckIcon color="success" />
																						}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Battery:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{(spec.ChecklistBattery === 1) &&
																							<CheckIcon color="success" />
																						}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Stress:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{(spec.ChecklistStress === 1) &&
																							<CheckIcon color="success" />
																						}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Activation:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{(spec.ChecklistActivation === 1) &&
																							<CheckIcon color="success" />
																						}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Webcam:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{(spec.ChecklistWebcam === 1) &&
																							<CheckIcon color="success" />
																						}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Keys Hit:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.KeysHit}
																					</div>
																				</td>
																			</tr>
																		</React.Fragment>
																	}

																	{(spec.RAM1Capacity) &&
																		<>
																			<tr>
																				<td style={{ width: "140px" }}>
																					<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																						RAM 1
																					</div>
																				</td>
																				<td>
																					<React.Fragment>&nbsp;</React.Fragment>
																				</td>
																			</tr>

																			<tr>
																				<td style={{ width: "140px" }}>
																					Capacity:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM1Capacity}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Mfg:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM1Manufacturer}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Model:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM1Model}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Serial:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM1SerialNumber}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Speed:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM1Speed}
																					</div>
																				</td>
																			</tr>
																		</>
																	}

																	{(spec.RAM2Capacity) &&
																		<>
																			<tr>
																				<td style={{ width: "140px" }}>
																					<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																						RAM 2
																					</div>
																				</td>
																				<td>
																					<React.Fragment>&nbsp;</React.Fragment>
																				</td>
																			</tr>

																			<tr>
																				<td style={{ width: "140px" }}>
																					Capacity:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM2Capacity}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Mfg:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM2Manufacturer}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Model:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM2Model}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Serial:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM2SerialNumber}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Speed:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM2Speed}
																					</div>
																				</td>
																			</tr>
																		</>
																	}

																	{(spec.RAM3Capacity) &&
																		<>
																			<tr>
																				<td style={{ width: "140px" }}>
																					<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																						RAM 3
																					</div>
																				</td>
																				<td>
																					<React.Fragment>&nbsp;</React.Fragment>
																				</td>
																			</tr>

																			<tr>
																				<td style={{ width: "140px" }}>
																					Capacity:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM3Capacity}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Mfg:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM3Manufacturer}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Model:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM3Model}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Serial:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM3SerialNumber}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Speed:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM3Speed}
																					</div>
																				</td>
																			</tr>
																		</>
																	}

{(spec.RAM4Capacity) &&
																		<>
																			<tr>
																				<td style={{ width: "140px" }}>
																					<div style={{ fontWeight: "500", textDecoration: "underline" }}>
																						RAM 4
																					</div>
																				</td>
																				<td>
																					<React.Fragment>&nbsp;</React.Fragment>
																				</td>
																			</tr>

																			<tr>
																				<td style={{ width: "140px" }}>
																					Capacity:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM4Capacity}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Mfg:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM4Manufacturer}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Model:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM4Model}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Serial:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM4SerialNumber}
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: "140px" }}>
																					Speed:
																				</td>
																				<td>
																					<div className={classes.flexitemstaticinput}>
																						{spec.RAM4Speed}
																					</div>
																				</td>
																			</tr>
																		</>
																	}






																</tbody>
															</table>
														</div>
													</Grid>
												</Grid>
											</AccordionDetails>
										</Accordion>
									))}
								</React.Fragment>
							}
						</Grid>
					</Typography>
					<InventoryToolbar state={state} UpdateState={UpdateState} />
				</div>
			}
		</LocalizationProvider>

	)
}

export default Item;

import React, { useEffect } from 'react';
import {useLocation, useParams} from 'react-router-dom';
//import axios from "axios";
//import Grid from '@mui/material/Grid';
//import CircularProgress from '@mui/material/CircularProgress';

//let endpoint = "http://localhost:8080/api";
var hostbase = process.env.REACT_APP_ANGULAR_LEGACY_BASE;


//MainPage receives props
const OrderViewLegacy = props => {
	//New, Try url params to shoot the user into the right checkin page
	let params = useParams();
	console.log(params.orderid);
	document.title="Order View";

	if (params.orderid){
		//lotparameter = "&lotid="+lotparameter;
		document.title="OrderID: "+params.orderid;
	} else {
		//lotparameter="";
		document.title="Error: No order found";
		params.orderid="no";
	}	

	

	//Hide Scroll Bars for injected content.
	return (
		<div style={{ textAlign: 'center', overflow: "hidden"}}>
			<div style={{ height: (window.innerHeight - 35) + "px" }}>
				<iframe src={hostbase+"/legacy/orderview/"+params.orderid} style={{ width: "100%", border:"none" }} height={window.innerHeight - 35}></iframe>
			</div>

		</div>
	)
}


export default OrderViewLegacy;
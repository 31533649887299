//React & Friends
import React, { useState, useEffect, useCallback } from 'react';

//MaterialUI
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';

//import dayjs from 'dayjs';

import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

import debounce from 'lodash/debounce';


var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 12000 //12 seconds
};

const filter = createFilterOptions();

const SearchInput = (props) => {
	const inputdefaults = props.inputdefaults;
	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);
	const [defaultsearchterm, setDefaultSearchTerm] = useState("");

	//Initialize this component with the default values sent from parent via props:
	const [searchpair, setSearchPair] = useState(props.localstate.searchoptions.searchpairs["searchpair" + props.searchinput]);

	//We can setup our own update function, just the same as any other controller.
	let searchpairstate = Object.assign({}, searchpair);
	function UpdateSearchPairState(stateobject) {
		setSearchPair(stateobject);
	}

	//These control dropdowns and option controls.
	const [openoptions, openOptions] = React.useState(false);
	const [itemoptions, setItemOptions] = React.useState([]);
	const [loadingitemoptions, setLoadingItemOptions] = useState(false);


	useEffect(() => {
		// console.log("USEEFFECT");
		// console.log(searchpairstate);
		// console.log(newvalue);
	}, [props]);


	const InitItemOptions = () => {
		if (searchpairstate.mode!=="autocompletestatic"){
			if (itemoptions.length === 0) {
				debouncedItemSearch("");
			}
		} else {
			setItemOptions(searchpairstate.menuitems);
		}
		
		openOptions(true);
	}

	const onCloseHandler = () => {
		//console.log("Test this prop:"+defaultsearchterm);
		openOptions(false);
	}

	

	// Memoize the debounced function to prevent it from being recreated on every render
	const debouncedItemSearch = useCallback(
		debounce((searchterm) => {
			setLoadingItemOptions(true);
			const postdata = {
				searchtype: searchpairstate.type,
				searchterm: searchterm,
				limit: searchpairstate.limit,
				//Specialized - Values not necessarily needed for each endpoint, but we include them here:
				producttype: (searchpairstate.producttype ?
					searchpairstate.producttype : "")
			};
			axios.post(dbendpoint + "/dbautocomplete", postdata, defaultpostoptions).then(res => {
				if (res.status === 200) {
					if (res.data.Status === "login") {
						window.location.reload(false);
					}
					if (res.data.Status === "Success") {
						setItemOptions(res.data.items);
					}
				} else {
					//Non-200 message from server.
					if (props.hasOwnProperty('errors')) {
						props.errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
					}
				}
				setLoadingItemOptions(false);
			}).catch((error) => {
				if (props.hasOwnProperty('errors')) {
					props.errors.NewError({ errmsg: "Request , possible route cache issue: " + error, errshow: true, errtimeout: 5, errtype: "warning" })
				}
			}
			);
		}, 1500), // Adjust the debounce delay as needed
		[]
	);

	const onChangeSearchType = (searchtype, searchnumber) => {
		searchpairstate = inputdefaults[searchtype];
		//Updates parent state search type for searchpair
		console.log(searchpairstate);
		UpdateSearchPairState(searchpairstate);
		props.onChangeSearchType(inputdefaults[searchtype], props.searchinput);
	}

	const onChangeSearchInputValue = (newvalue) => {
		searchpairstate.value = newvalue;
		UpdateSearchPairState(searchpairstate);
		props.onChangeSearchValue(newvalue, searchpairstate, props.searchinput);
	}

	const onChangeDateTime = (newvalue) => {
		searchpairstate.value = newvalue;
		UpdateSearchPairState(searchpairstate);
		props.onChangeSearchValue(newvalue, searchpairstate, props.searchinput);
	}

	const onChangeAutoCompleteInput = (searchterm, reason) =>{
		//Trying to save inputValue so we can keep our resultset
		//This needs to be set locally right away:
		// console.log("The other");
		// console.log("Search Term: "+searchterm);
		// console.log("Reason: "+reason);

		//MRH: Right now I'm about to try and filter only on items recieved from the parent. I'm not sure what overlap this has with our regular autocomplete! Or if this should even be attempted here!

		if (searchpairstate.mode==="autocompletestatic"){
			searchpairstate.inputValue = searchterm;
			UpdateSearchPairState(searchpairstate);
		} else {
			if (reason==="input"){
				//Leave default search term alone?
				setDefaultSearchTerm(searchterm);
				searchpairstate.inputValue = searchterm;
				UpdateSearchPairState(searchpairstate);
				//New memoized debounce, works great!
				debouncedItemSearch(searchterm);
			}

			if (reason==="reset"){
				console.log("DEFAULT SEARCH ON RESET: "+defaultsearchterm);
				searchpairstate.inputValue = defaultsearchterm;
				UpdateSearchPairState(searchpairstate);
				//New memoized debounce, works great!
				debouncedItemSearch(defaultsearchterm);
			}
		}

		
	}

	
	//Autocomplete:
	const onChangeItemOption = (event, newvalue) => {
		if (newvalue) {
			searchpairstate.value = newvalue; //New value will be entire record. You will need to adjust your API or your Table\View LoadItems() client to understand the right key.
			searchpairstate.inputValue = defaultsearchterm;
			UpdateSearchPairState(searchpairstate);
			console.log(searchpairstate);
			props.onChangeSearchValue(newvalue, searchpairstate, props.searchinput, newvalue[searchpairstate.searchkey]);
		}

		// //We might want to clear the default input! Perhaps we're adding a component to something and we want to input cleared!
		if (searchpairstate.type === "components") {
			//setDefaultSearchTerm("");
		} else {
			//At one point we'd set some defaultsearchterm, but I believe that is now being handled by parent controller.
		}
	}

	const handleChipClick = (event) => {
		event.currentTarget.focus();
	};


	return (
		<div className={classes.searchinputs} style={{
			display: "flex", flex: searchpairstate.grow + " 1 " + searchpairstate.container // Use inline-flex to keep inputs inline
			//display: 'inline-flex', flexGrow:searchpairstate.grow
		}}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<FormControl className={classes.searchtypeinput} style={{ width: searchpairstate.keysize }} variant="standard">
					{/* Value must match one of the MenuItem values, SerialNumber != Serial Number */}
					<Select
						key={searchpairstate.uuid}
						value={searchpairstate.type}
						disableUnderline
						onChange={(event) => onChangeSearchType(event.target.value, props.searchinput)}
						MenuProps={{
							style: { zIndex: 2001 }
						}}

					>
						{(props.selectinputs.map((option, index) => {
							return (<MenuItem value={option.value} key={props.searchinput + "option" + index}>
								{option.text}
								{option.html && option.html()}
							</MenuItem>)
						}))}
					</Select>
				</FormControl>

				{/* Search Inputs Dependent on Mode */}
				{/* Text Inputs - like, left, right, strict, not */}
				{(searchpairstate.mode === "like" ||
					searchpairstate.mode === "left" ||
					searchpairstate.mode === "right" ||
					searchpairstate.mode === "strict" ||
					searchpairstate.mode === "greaterthan" ||
					searchpairstate.mode === "lessthan" ||
					searchpairstate.mode === "not" ||
					searchpairstate.mode === "exactdate"
				) &&
					<TextField id="search1" variant="standard"
						key={searchpairstate.key + 1}
						value={searchpairstate.value}
						//defaultValue={searchpairstate.defaultvalue}
						className={classes.searchinput}
						InputProps={{
							disableUnderline: true,
							disableUnderline: true,
							sx: {
								flexGrow: searchpairstate.grow, // Apply flexGrow here
								display: "flex",
							},
						}}
						sx={{ flexGrow: searchpairstate.grow }}
						onChange={(event) => onChangeSearchInputValue(event.target.value)}
					/>
				}

				{/* DATETIMES */}
				{(searchpairstate.mode === "datetimeafter" || searchpairstate.mode === "datetimebefore") &&
					<DateTimePicker
						value={searchpairstate.value}
						onChange={(newvalue) => onChangeDateTime(newvalue)}
						className={classes.newdatetimepickerinput}
					/>
				}

				{/* BOOLS */}
				{(searchpairstate.mode === "bool") &&
					<FormControl variant="standard" className={classes.searchinputselect}
						sx={{
							m: 0,
							minWidth: `calc(${searchpairstate.container} - ${searchpairstate.keysize})`,
							border: "0px",
							flexGrow: searchpairstate.grow,
							display: "flex"
						}}
					>
						<Select
							variant="standard"
							disableUnderline
							onChange={(event) => onChangeSearchInputValue(event.target.value)}
							value={searchpairstate.value}
							inputProps={{
								sx: {
									display: 'flex',
									flexGrow: 1,
								}
							}}
						>
							<MenuItem value={""}>-</MenuItem>
							<MenuItem value={1}>Yes</MenuItem>
							<MenuItem value={0}>No</MenuItem>
						</Select>
					</FormControl>
				}

				{/* SELECT */}
				{(searchpairstate.mode === "select") &&
					//Demo as SomeSelectable
					<FormControl variant="standard" className={classes.searchinputselect} sx={{ m: 0, border: "0px", flexGrow: searchpairstate.grow, display: "flex" }}>
						<Select
							variant="standard"
							disableUnderline
							onChange={(event) => onChangeSearchInputValue(event.target.value)}
							//defaultValue={(searchpairstate.multiple ? [] : searchpairstate.defaultValue)}
							value={searchpairstate.value}
							sx={{
								minWidth: `calc(${searchpairstate.container} - ${searchpairstate.keysize})`,
								display: 'flex',
								flexGrow: 1,
								padding: "0px",
								'& .MuiSelect-select': {
									// Regular text needs a larger top padding:
									// Chips need a little more space
									paddingTop: (searchpairstate.usechips ? "3px!important" : "4px!important"),
									'&:focus': {
										backgroundColor: "#FFF!important"
									},
									// Center the value in the select input
									...(searchpairstate.center && {
										textAlign: 'center',
										justifyContent: 'center',
									}),
								},
							}}
							multiple={searchpairstate.multiple}
							inputProps={{
								sx: {
									display: 'flex',
									flexGrow: 1,
								}
							}}
							renderValue={(selected) => {
								if (selected.length === 0) {
									return <span></span>;
								} else {
									if (searchpairstate.multiple) {
										console.log("%%%%%%%%%%%%%%%%%");
										console.log(selected);
										console.log(searchpairstate);
										return (
											<Box sx={{ display: 'flex', gap: 0.5 }}>
												{selected.map((value, index) => {
													const option = searchpairstate.menuitems.find(opt => opt.value === value);


													{/* Chips! */ }
													if (searchpairstate.usechips) {
														return (
															<Chip
																key={option.key}
																label={option.chiplabel ? option.chiplabel : option.value}
																size={option.chipsize ? option.chipsize : 'medium'}
																clickable
																className={option.chipclass ? option.chipclass : ''}
																sx={{
																	...(option?.height && { height: option.height }),  // Conditionally set height
																	fontSize: "0.9em",
																	alignItems: "center", // Vertically center the label
																	height: "auto"
																}}
															/>
														);
													} else {
														return (
															<span>
																{option.text}
																{index < selected.length - 1 && ', '}
															</span>
														)
													}
												})}
											</Box>
										)
									} else {
										const option = searchpairstate.menuitems.find(opt => opt.value === selected);
										{/* Chips! */ }
										if (searchpairstate.usechips) {
											return (
												<Chip
													key={option.key}
													label={option.chiplabel ? option.chiplabel : option.value}
													size={option.chipsize ? option.chipsize : 'medium'}
													clickable
													className={option.chipclass ? option.chipclass : ''}
													sx={{
														...(option?.height && { height: option.height }),  // Conditionally set height
														fontSize: "0.9em",
														alignItems: "center", // Vertically center the label
														height: "auto"
													}}
												/>
											)
										} else {
											return (
												<span>{option.text}</span>
											)
										}
									}
								}
							}}
						>
							{(searchpairstate.menuitems.map((option) => {
								return (
									<MenuItem
										key={option.key}
										value={option.value}
										sx={{
											...(option.center && { justifyContent: 'center' }),  // Conditionally center content
											flexGrow: 1
										}}
									>
										{(option.usechip) &&
											<Chip size={option.chipsize}
												label={option.chiplabel}
												clickable
												className={option.chipclass}
												sx={{
													...(option.height && { height: option.height }),  // Conditionally set height
													margin: "0px 1px 0px 4px"
												}}
											/>
										}
										{(!option.usechip) &&
											<>{option.text}</>
										}
									</MenuItem>
								)
							}))}
						</Select>
					</FormControl>
				}


				{/* AUTOCOMPLETE */}
				{(searchpairstate.mode === "autocomplete" || searchpairstate.mode === "autocompletestatic") &&
					<Autocomplete
						value={searchpairstate.value}
						//Try without! Works great! inputValue={searchpairstate.inputValue}
						style={{ flexGrow: 1 }}
						//forcePopupIcon={false}
						//clearOnBlur={false}
						freeSolo
						disableCloseOnSelect
						//multiple={searchpairstate.multiple}
						multiple //Maybe leave this here and if we need limit, do it through a new prop. SearchTools counts on this for arrays.
						className={classes.searchinputautocomplete}
						open={openoptions} onOpen={() => { InitItemOptions(); }} onClose={() => onCloseHandler()}
						//Not sure how to avoid passing event, then newvalue... seems to break when removing event.
						onChange={(event, newValue) => onChangeItemOption(event, newValue)}
						onInputChange={(event, value, reason) => {
							onChangeAutoCompleteInput(event.target.value, reason);
						}}
						isOptionEqualToValue={(option, value) => {
							//console.log(option[keyedby]);
							//console.log(value[keyedby]);
							if (option[searchpairstate.keyedby] === value[searchpairstate.keyedby]) {
								return true;
							}
						}}
						getOptionLabel={(option) => option[searchpairstate.searchkey]} //This may be the thing that is sent to isOptionEqualToValue....

						renderTags={(value, getTagProps) =>
							value.map((option, index) => {
								const { key, ...rest } = getTagProps({ index });
								//console.log(option);
								return (
									<Chip 
										sx={{
											margin: "0px",
											backgroundColor: '#e0ecf9',
											padding: "0px",
											'.MuiSvgIcon-root': {
												color: '#588dc9', // Change icon color on focus
												'&: hover': {
													color:"#01579B"
												},
											},
											'& .MuiChip-label': {
												padding: '2px 7px 0px 7px',  // Optional: Adjust label padding
											},
											'&:focus': {
												backgroundColor: '#2266b5',
												color:"#FFF",
												'.MuiSvgIcon-root': {
													color: '#FFF', // Change icon color on focus
												},
											},
										}}
										clickable
										key={option[searchpairstate.keyedby]}
										size="small"
										variant="standard"
										label={option[searchpairstate.searchkey]}
										{...rest}
										onClick={handleChipClick}
										tabIndex={0}
									/>
								);
							})
						}

						options={itemoptions}
						loading={loadingitemoptions}

						renderInput={(params) => (
							<Box
								ref={params.InputProps.ref}
								sx={{
									display: "flex",
									alignItems: "top",
									borderBottom: "1px solid #EEE",
									padding: "0px",
									margin: "0px",
									width: "100%",
								}}
							>
								{params.InputProps.startAdornment} {/* Chips will render here */}
								<input
									{...params.inputProps}
									style={{
										border: "none",
										outline: "none",
										flexGrow: 0,
										minWidth: "100px",
										margin: "2px 0px 0px 3px"
									}}
								/>
							</Box>
						)}



						renderOption={(props, option) => (
							<ListItem {...props} key={option[searchpairstate.keyedby]}>
								{(option.IconURL) &&
									<div style={{display:"inline-block", marginRight:"10px"}}><img src={option.IconURL} style={{width:"25px"}} /></div>
								}
							  <ListItemText primary={option[searchpairstate.searchkey]} secondary={option.iconURL} />
							</ListItem>
						  )}





						placeholder="This"
						label="That"
						disabled={props.disabled}
						filterOptions={(options, params) => {
							const filtered = filter(options, params);

							if (props.allowadd) {
								const { inputValue } = params;
								// Suggest the creation of a new value
								const isExisting = options.some((option) => inputValue === option[searchpairstate.searchkey]);
								if (inputValue !== '' && !isExisting) {
									filtered.push({
										inputValue,
										[props.searchkey]: `Add "${inputValue}"`,
										addnewitem: true, //Flag controller, we're creating a new item - possibly set PendingItem for pending db call
										newitemvalue: inputValue
									});
								}
							}

							return filtered;
						}}
					/>
				}

			</LocalizationProvider>

		</div>
	)
}

export default SearchInput;
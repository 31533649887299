import { useDispatch } from 'react-redux';
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//let endpoint = "http://localhost:8080/api";
var hostbase = process.env.REACT_APP_ANGULAR_LEGACY_BASE;


//MainPage receives props
const OrderManifests = props => {
	//Hide Scroll Bars for injected content.
	document.title="Manifests";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Orders"));
	dispatch(setCurrentMenuItem("/ordermanifests"));
	return (
		<div style={{ textAlign: 'center', overflow: "hidden"}}>
			<div style={{ height: (window.innerHeight - 40) + "px" }}>
				<iframe src={hostbase+"/legacy/ordermanifests?react=true"} style={{ width: "100%", border:"none" }} height={window.innerHeight - 50}></iframe>
			</div>

		</div>
	)
}


export default OrderManifests;
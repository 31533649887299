//Product Types Table

/* ##########################  Configuration Sections  ########################## */	
//## UseState Variables
//## Column States
//##Column Configuration
//##Column Toggles
//##Row Design
//##Search Inputs
//##Button Functions


//React & Friends
import React, { useState, useEffect, useContext, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import PropTypes from 'prop-types';

//Redux Features
import { useSelector, useDispatch } from 'react-redux';
//ProgressBar
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import { ProgressBar } from '../../features/progressbar/ProgressBar';
import {
	newProgress,
	incrementPass,
	incrementFail,
	setProgressTimeout
  } from '../../features/progressbar/progressbarSlice';
//Main Menu
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';
//New Error Message
import { NewErrorMessage } from '../../features/error/NewErrorMessage';
import {
	newErrorMessage,
	setErrorTimeout,
	hideError
} from '../../features/error/errormessageSlice';


//Contexts
//AuthContext
import { AppContext } from "../Auth/contexts/AppContext"
//Error Context - warning, danger, ok, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";

//Datetime formatting
import Moment from 'react-moment';
import 'moment-timezone';

//Common Utilities
import RestrictInputNumber from "../common/RestrictInputNumber";
import DBAutoComplete from '../common/DBAutoComplete';

//MaterialUI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';

//Tables
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';

//Icons
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PreviewIcon from '@mui/icons-material/Preview';

//Datetime Pickers
//Try new moment adapter:
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

//Export
import ExportCSV from '../common/ExportCSV';


/* ##########################  Configuration  ########################## */

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials:true,
	withXSRFToken: true,
	crossDomain:true,
	mode:"no-cors",
	timeout:11800,
};

//Helper Functions
//Have not used sleep just yet - is currently on auto-complete sample
function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}

//Find Duplicate Example:
//This will short-circuit once some() finds a truthy value.
var values = [
	{ name: 'someName1' },
	{ name: 'someName2' },
	{ name: 'someName4' },
	{ name: 'someName1' }
];

var valueArr = values.map(function (item) { return item.name });
var isDuplicate = valueArr.some(function (item, idx) {
	return valueArr.indexOf(item) !== idx
});
//console.log(isDuplicate);

//Simple Find Duplicates (simple array of values).
const input = [1,1,2,3,3];
const GetDupeArray = (inputarray) => {
	var results = inputarray.reduce(function(acc, el, i, arr) {
		if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
	  }, []);
	return results;
}
const dupearray = GetDupeArray(input);
//console.log("Duplicates2: "+dupearray); // = 1,3 (actual array == [1, 3])

//Find and return all unique values:
const GetUniqueArray = (inputarray) => {
	return inputarray.filter((x, i, a) => a.indexOf(x) === i);
}


//Remove all instances of string from string:
String.prototype.replaceAll = function (find, replace) {
	var str = this;
	return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
};


const ProductTypesTable = (props) => {
	document.title="Product Types";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Products"));
	dispatch(setCurrentMenuItem("/producttypes"));


	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;

	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);
	const isPrintView = useMediaQuery("print");


	const rowRefs = useRef([]);

	/* ##########################  UseState Variables  ########################## */ 
	const [state, setState] = useState({
		dbreload:true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		clearselection:true,//Default clear selection everytime we reload DB. Continuous bulk edits may set this to false between updates.
		griditems:[],		//Empty object
		defaultvalues:[],   //Default values: Useful for initial render.
		totalitems:0,
		page:0, //Assume page 0, or else pagination throws an error.
		order:"asc",
		orderby:"Name",
		selectedcount:0,
		rowsperpage:100,
		selectedindexes:[],
		pendingsaves:false, //Used for parent view - Warnings about unsaved items!
		showaddremovesearch:false,
		searchoptions:{
			//New! Key-Value pair array. Easier to itterate in API.
			searchpairs:{
				//Reserved for basic searchpairs. Possible injection of search parameter 'nameparameter' here.
				//Modes: like, left, right, strict, not
				searchpair1:{type:"Name", value: "", mode:"like", uuid:uuidv4()},
				searchpair2:{type:"ProductType", value: "", mode:"like", uuid:uuidv4()},
				searchpair3:{type:"CreatedBy", value: "", mode:"like", uuid:uuidv4()},
				searchpair4:{type:"", value: "", mode:"right", uuid:uuidv4()},
				searchpair5:{type:"", value: "", mode:"like", uuid:uuidv4()},
				searchpair6:{type:"", value: "", mode:"like", uuid:uuidv4()},
				//Reserved for AutoComplete searchpairs.
				searchpair7:{type:"", value: "", mode:"strict", uuid:uuidv4()},
				searchpair8:{type:"", value: "", mode:"strict", uuid:uuidv4()},
				searchpair9:{type:"", value: "", mode:"strict", uuid:uuidv4()},
				searchpair10:{type:"", value: "", mode:"strict", uuid:uuidv4()},
			},
			nestedrelationships:{
				
			},
			itemlist:""
		}
	});

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}


	/* ##########################  Top Button Refs and Functions  ########################## */	 
	//Buttons must exist in order to disable or enable them. Hide buttons with hidden class and ternary.
	const btnSave = useRef();
	const btnPendingSave = useRef();
	const btnAddRow = useRef();
	const btnDeleteSelected = useRef();
	const btnResetSearches = useRef();
	const btnEditMode = useRef();

	//Disable Buttons
	const DisableButtons = () => {
		btnSave.current.setAttribute("disabled", true);
		btnPendingSave.current.setAttribute("disabled", true);
		btnAddRow.current.setAttribute("disabled", true);
		btnDeleteSelected.current.setAttribute("disabled", true);
		btnResetSearches.current.setAttribute("disabled", true);
		btnEditMode.current.setAttribute("disabled", true);
	}

	//Enable Buttons
	const EnableButtons = () => {
		btnSave.current.removeAttribute("disabled");
		btnPendingSave.current.removeAttribute("disabled");
		btnAddRow.current.removeAttribute("disabled");
		btnDeleteSelected.current.removeAttribute("disabled");
		btnResetSearches.current.removeAttribute("disabled");
		btnEditMode.current.removeAttribute("disabled");
	} 

	//Reusable test to be used with useState bool variables
	//Invalid for none selected or pending changes
	//Bulk Edit Selected
	//Export Selected
	//Print Selected
	const RejectIfInvalidSelected = (value, fnCallback) => {
		if (localstate.selectedindexes.length===0){
			errors.NewError({errmsg:"No items selected.", errshow:true, errtimeout: 5, errtype:'neutral'});
		} else {
			//If all tests pass, use callback
			fnCallback(value);
		}
	}

	//Edit Mode
	const [editmode, setEditMode] = useState(false); //Default: false
	const EditMode = () => {
		setEditMode(!editmode);
	}


	/* ##########################  Column States - ColStates  ########################## */	 
	//Used for hiding/showing columns. Can access using bracket notation later on! colstate[headCell.id]
	const [colstate, setColState] = useState({
		Name:true,
		created_at:true,
		CreatedBy:true,
		updated_at:true,
		UpdatedBy:true
		
	});

	const UpdateColState = (colstate) =>{
		setColState(colstate);
	}


	

	/* ##########################  Menus  ########################## */

	/* Column Menu */
	const [showcolumnmenu, setColumnMenu] = useState(null);
	const ShowColumnMenu = (event) => {
		setColumnMenu(event.currentTarget);
	}
	const CloseColumnMenu = () => {
		setColumnMenu(null);
	}
	const ToggleColumn = (key) => {
		let newcolstate = colstate;
		newcolstate[key] = ! newcolstate[key];
		UpdateColState(newcolstate);
	}
	const FlexColumnOption = (props) => {
		let columnvalue = props.value;
		return (
			<React.Fragment>
				{(colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox defaultChecked onClick={()=>{ToggleColumn(columnvalue)}} />} label={props.label} className={classes.columnselecthover} />
				}
				{(!colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox onClick={()=>{ToggleColumn(columnvalue)}} />} label={props.label} className={classes.columnselecthover}/>
				}
			</React.Fragment>
		)
	}


	/* Export Menu */
	const [showexportmenu, setExportMenu] = useState(null);
	const ShowExportMenu = (event) => {setExportMenu(event.currentTarget);}
	const CloseExportMenu = () => {setExportMenu(null);}

	

	/* ##########################  Selected Rows  ########################## */
	const SelectRow = (index) => {
		if (localstate.selectedindexes.indexOf(index)===-1){
			localstate.selectedindexes.push(index);
			//Check for condition that would check Select All Checkbox - Requires Rerender
			if (localstate.griditems.length === localstate.selectedindexes.length){
				UpdateState(localstate);			
			}
		} else {
			var spliceindex = localstate.selectedindexes.indexOf(index);
			localstate.selectedindexes.splice(spliceindex,1);
			//Check for condition that would un-check Select All Checkbox, just 1 less will do - Rerender
			if (localstate.griditems.length === (localstate.selectedindexes.length+1)){
				UpdateState(localstate);			
			}
		}

		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length===0){
			setShowExportConfirmation(false);
		}
	}

	const handleSelectAllClick = (event) => {
		//Material UI Checkbox Component won't rerender unless we force it. Set a changed GridKey so that shallow comparison fails.
		var i=0;
		if (event.target.checked) {
			localstate.selectedindexes = [];
			for (i=0; i<localstate.griditems.length; i++){
				localstate.griditems[i].isSelected = true;
				localstate.selectedindexes.push(i);
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		} else {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
			for (i=0; i<localstate.griditems.length; i++){
				localstate.griditems[i].isSelected = false;
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		}
		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length===0){
			setShowExportConfirmation(false);
		}
	};



	/* ##########################  Search Options  ########################## */


	// Returns a function, that, as long as it continues to be invoked, will not
	// be triggered. The function will be called after it stops being called for
	// N milliseconds. If `immediate` is passed, trigger the function on the
	// leading edge, instead of the trailing.
	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};

	//Pre-set Key
	//Deprecate? Move to AutoComplete?
	const onChangeSearch = debounce(function(searchtype, searchvalue, itembool, searchpair) {
		//If an item is selected, then deselected, itembool is still true, but the searchvalue becomes null.
		//If item selected from list (itembool), grab it's key's value instead 'name'
		if (itembool) {
			if (searchvalue !== null){
				searchvalue = searchvalue.name;
			} else {
				searchvalue="";
			}
		}
		//New API:
		console.log(searchtype);
		console.log(searchvalue);
		switch (searchpair) {
			case "searchpair1":
				localstate.searchoptions.searchpairs.searchpair1.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair1.value = searchvalue;
				break;
			case "searchpair2":
				localstate.searchoptions.searchpairs.searchpair2.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair2.value = searchvalue;
				break;
			case "searchpair3":
				localstate.searchoptions.searchpairs.searchpair3.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair3.value = searchvalue;
				break;
			case "searchpair4":
				localstate.searchoptions.searchpairs.searchpair4.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair4.value = searchvalue;
				break;
			default:
				break;
		}
		localstate.dbreload=true;
		UpdateState(localstate);
	}, 800);


	//API Build-out: 
	//	Old: Types [searchtype, sub1type, sub2type] , Search [search, sub1search, sub2search]
	//	New: Types [searchtype1, searchtype2, searchtype3], Search [search1, search2, search3]
	//Set Search Key:
	var mode="";
	const onChangeSearchType = (searchtype, searchnumber) => {
		//Blank the value:
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].value = "";

		//Search Mode: Each search type may have a different search mode
		//left, right, like, strict, not
		//Default Mode: LIKE
		mode = "like";
		//Provision for automatically switching search mode
		if (searchtype === "Location") {
			mode = "strict";
		}

		//Not Conditionals: Convert NotName to Name in BasicTableController
		if (searchtype === "NotName") {
			mode = "not";
		}

		//Date Searches - Simple string input and validation
		if (searchtype === "DateAfter") {
			mode = "dateafter";
		}
		if (searchtype === "DateBefore") {
			mode = "datebefore";
		}

		//DateTime Searches
		if (searchtype === "DateTimeAfter") {
			mode = "datetimeafter";
		}
		if (searchtype === "DateTimeBefore") {
			mode = "datetimebefore";
		}

		//Init date time:
		if (searchtype === "DateTimeBefore" || searchtype === "DateTimeAfter") {
			localstate.searchoptions.searchpairs["searchpair" + searchnumber].value = new Date;
		}

		if (searchtype === "Cost >") {
			mode = "greaterthan";
		}
		if (searchtype === "Cost <") {
			mode = "lessthan";
		}

		localstate.searchoptions.searchpairs["searchpair" + searchnumber].type = searchtype;
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].mode = mode;
		//Provision to add columns if selected for search.
		if (searchtype === "Name") {
			colstate["Name"] = true;
			setColumnMenu(null);
		}
		UpdateState(localstate);
	}


	const [datetimevalue, setDateTimeValue] = useState(new Date);
	//Set Search Value:
	const onChangeSearchValue = debounce(function(searchvalue, searchnumber) {
		//Clears out changes because table is about to be reloaded:
		ResetPendingSaves();

		//Provision to warn user about a bad datetime format:
		//On blank datetime, proceed to load data

		if ((localstate.searchoptions.searchpairs["searchpair"+searchnumber].type==="DateAfter" || localstate.searchoptions.searchpairs["searchpair"+searchnumber].type==="DateBefore") && searchvalue!==""){
			var count = (searchvalue.match(/-/g) || []).length;
			if (searchvalue.length===10 && count===2){
				let isValidDate = Date.parse(searchvalue);
				if (isNaN(isValidDate)) {
					errors.NewError({ errmsg: "Invalid date.", errshow: true, errtimeout: 5, errtype: "warning" });
				} else {
					localstate.page=0;
					localstate.dbreload = true;
					UpdateState(localstate);
				}
			} else {
				errors.NewError({ errmsg: "Use YYYY-MM-DD format for dates.", errshow: true, errtimeout: 5, errtype: "ok" });
			}
		} 
		
		localstate.searchoptions.searchpairs["searchpair"+searchnumber].value = searchvalue;
		localstate.page=0;
		localstate.dbreload = true;
		UpdateState(localstate);
	},1200);

	//Key-Value Inputs
	const [searchinputs, setSearchInputs] = useState({
		show1:true,
		show2:true,
		show3:true,
		show4:false,
		show5:false,
		show6:false,
		lastsearch:3
	});


	const AddSearch = () => {
		let newsearchinputs = Object.assign({}, searchinputs);
		newsearchinputs["show"+(newsearchinputs.lastsearch+1)]=true;
		newsearchinputs.lastsearch++;
		setSearchInputs(newsearchinputs);
	}

	const RemoveSearch = () => {
		let newsearchinputs = Object.assign({}, searchinputs);
		newsearchinputs["show"+(newsearchinputs.lastsearch)]=false;
		newsearchinputs.lastsearch--;
		setSearchInputs(newsearchinputs);
	}

	/* ##########################  Auto Complete  ########################## */
	// Similar to regular searchpairs, but loads results from DB - needs stateful.

	//Helpers - Blank icons for pre-set keys
	const BlankIcon = () => (<ArrowDropDownIcon style={{display:"none"}} />);

	const [autocompletesearchtype1, setKey1] = useState("Name");
	const [openoptions1, openOptions1] = React.useState(false);
	const [options1, setOptions1] = React.useState([]);
	const [loadingoptions1, setLoadingOptions1] = useState(false);
	const InitOptions1 = () => {
		if (options1.length===0){
			//Load (up to limit) options
			AutoCompleteSearch1("");
		}
		openOptions1(true);
	}

	const onChangeOption1 = (event, newvalue) =>{
		localstate.searchoptions.searchpairs.searchpair7.type=autocompletesearchtype1;
		localstate.searchoptions.searchpairs.searchpair7.value=newvalue[autocompletesearchtype1];
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	const AutoCompleteSearch1 = debounce(function(searchvalue){
		setLoadingOptions1(true);
		console.log(searchvalue);
		const postdata = {					
			key:autocompletesearchtype1,
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/producttypes/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions1(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingOptions1(false);
		});
	},600);


	const [autocompletesearchtype2, setKey2] = useState("Name");
	const [openoptions2, openOptions2] = React.useState(false);
	const [options2, setOptions2] = React.useState([]);
	const [loadingoptions2, setLoadingOptions2] = useState(false);
	const InitOptions2 = () => {
		if (options2.length===0){
			//Load (up to limit) options
			AutoCompleteSearch2("");
		}
		openOptions2(true);
	}

	const onChangeOption2 = (event, newvalue) =>{
		localstate.searchoptions.searchpairs.searchpair8.type=autocompletesearchtype2;
		localstate.searchoptions.searchpairs.searchpair8.value=newvalue[autocompletesearchtype2];
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	const AutoCompleteSearch2 = debounce(function(searchvalue){
		setLoadingOptions2(true);
		console.log(searchvalue);
		const postdata = {					
			key:autocompletesearchtype2,
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/producttypes/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions2(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingOptions2(false);
		});
	},600);


	const [autocompletesearchtype3, setKey3] = useState("Name");
	const [openoptions3, openOptions3] = React.useState(false);
	const [options3, setOptions3] = React.useState([]);
	const [loadingoptions3, setLoadingOptions3] = useState(false);
	const InitOptions3 = () => {
		if (options3.length===0){
			//Load (up to limit) options
			AutoCompleteSearch3("");
		}
		openOptions3(true);
	}

	const onChangeOption3 = (event, newvalue) =>{
		localstate.searchoptions.searchpairs.searchpair9.type=autocompletesearchtype3;
		localstate.searchoptions.searchpairs.searchpair9.value=newvalue[autocompletesearchtype3];
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	const AutoCompleteSearch3 = debounce(function(searchvalue){
		setLoadingOptions3(true);
		console.log(searchvalue);
		const postdata = {					
			key:autocompletesearchtype3,
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/producttypes/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions3(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingOptions3(false);
		});
	},600);


	const [autocompletesearchtype4, setKey4] = useState("Name");
	const [openoptions4, openOptions4] = React.useState(false);
	const [options4, setOptions4] = React.useState([]);
	const [loadingoptions4, setLoadingOptions4] = useState(false);
	const InitOptions4 = () => {
		if (options4.length===0){
			//Load (up to limit) options
			AutoCompleteSearch4("");
		}
		openOptions4(true);
	}

	const onChangeOption4 = (event, newvalue) =>{
		localstate.searchoptions.searchpairs.searchpair10.type=autocompletesearchtype4;
		localstate.searchoptions.searchpairs.searchpair10.value=newvalue[autocompletesearchtype4];
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	const AutoCompleteSearch4 = debounce(function(searchvalue){
		setLoadingOptions4(true);
		console.log(searchvalue);
		const postdata = {					
			key:autocompletesearchtype4,
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/producttypes/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions4(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingOptions4(false);
		});
	},600);


	/* ##########################  Auto Complete - Preset Keys, No usage of searchpairs from here on out. ########################## */
	const [openoptions5, openOptions5] = React.useState(false);
	const [options5, setOptions5] = React.useState([]);
	const [loadingoptions5, setLoadingOptions5] = useState(false);
	const InitOptions5 = () => {
		if (options5.length===0){
			//Load (up to limit) options
			AutoCompleteSearch5("");
		}
		openOptions5(true);
	}
	const onChangeOption5 = (event, newvalue) =>{
		//Do needful app work here, axios calls, etc
		
	}
	const AutoCompleteSearch5 = debounce(function(searchvalue){
		setLoadingOptions5(true);
		const postdata = {					
			key:"Name",
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/producttypes/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions5(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingOptions5(false);
		});
	},600);


	const [openoptions6, openOptions6] = React.useState(false);
	const [options6, setOptions6] = React.useState([]);
	const [loadingoptions6, setLoadingOptions6] = useState(false);
	const InitOptions6 = () => {
		if (options6.length===0){
			//Load (up to limit) options
			AutoCompleteSearch6("");
		}
		openOptions6(true);
	}
	const onChangeOption6 = (event, newvalue) =>{
		//Do needful app work here, axios calls, etc
		
	}
	const AutoCompleteSearch6 = debounce(function(searchvalue){
		setLoadingOptions6(true);
		const postdata = {					
			key:"Name",
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/producttypes/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions6(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 6, errtype:"warning"})
			}
			setLoadingOptions6(false);
		});
	},600);


	const [openoptions7, openOptions7] = React.useState(false);
	const [options7, setOptions7] = React.useState([]);
	const [loadingoptions7, setLoadingOptions7] = useState(false);
	const InitOptions7 = () => {
		if (options7.length===0){
			//Load (up to limit) options
			AutoCompleteSearch7("");
		}
		openOptions7(true);
	}
	const onChangeOption7 = (event, newvalue) =>{
		//Do needful app work here, axios calls, etc
		
	}
	const AutoCompleteSearch7 = debounce(function(searchvalue){
		setLoadingOptions7(true);
		const postdata = {					
			key:"Name",
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/producttypes/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions7(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 7, errtype:"warning"})
			}
			setLoadingOptions7(false);
		});
	},600);


	const [openoptions8, openOptions8] = React.useState(false);
	const [options8, setOptions8] = React.useState([]);
	const [loadingoptions8, setLoadingOptions8] = useState(false);
	const InitOptions8 = () => {
		if (options8.length===0){
			//Load (up to limit) options
			AutoCompleteSearch8("");
		}
		openOptions8(true);
	}
	const onChangeOption8 = (event, newvalue) =>{
		//Do needful app work here, axios calls, etc
		
	}
	const AutoCompleteSearch8 = debounce(function(searchvalue){
		setLoadingOptions8(true);
		const postdata = {					
			key:"Name",
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/producttypes/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions8(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 8, errtype:"warning"})
			}
			setLoadingOptions8(false);
		});
	},600);





	/* ##########################  Loading and Page Changes  ########################## */
	const handleRequestSort = (event, property) => {
		const isAsc = localstate.orderby === property && localstate.order === "asc";		
		localstate.order = (isAsc ? "desc" : "asc");
		localstate.orderby=property;
		localstate.dbreload=true;
		UpdateState(localstate);
	};

	const handleChangePage = (event, newPage) => {
		localstate.dbreload = true;
		localstate.page = newPage;
		UpdateState(localstate);		
		//
	};

	const handleChangeRowsPerPage = (event) => {
		localstate.dbreload = true;
		localstate.rowsperpage = parseInt(event.target.value, 10);
		localstate.page=0;
		UpdateState(localstate);
	};

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Items
	function LoadItems(){  
		if (localstate.clearselection){
			localstate.selectedindexes = [];
			localstate.selectedcount=0;
		} else {
			//Reset to clear selections on subsequent requests
			localstate.clearselection=true;
		}
		const postdata = {
			searchoptions:{
				limit:localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				searchpairs:localstate.searchoptions.searchpairs,
				nestedrelationships:localstate.searchoptions.nestedrelationships
			}
		};

		if (itemlistsearch.length>0){
			//Serials exist in serial list.
			postdata.searchoptions.itemlist = itemlistsearch;
		}

		axios.post(dbendpoint+"/producttypes?page="+(localstate.page+1), postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==="login"){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status==="Success"){
					//We should now have a non-0 result from the API
					//Add variables for use with table
					var resultdata = res.data.pagedata.data;
					for (var i =0; i<resultdata.length; i++){
						//Try: GridKey - Apply GridKey to components: key={row.GridKey}
						//Try: Increment GridKey between rerenders; ie: UpdateState(localstate);
						resultdata[i].GridKey=i;
						resultdata[i].unsaved = false;
						resultdata[i].ExpandRow = false;
						if (localstate.selectedindexes.includes(i)){
							resultdata[i].isSelected = true;
						} else {
							resultdata[i].isSelected = false;
						}

						//Set Defaults for selectables:
						resultdata[i].SomeSelectableDefault = resultdata[i].SomeSelectable;
					}
					localstate.griditems = resultdata;
					//Hold items for default values
					localstate.defaultvalues = resultdata;

					localstate.totalitems = res.data.pagedata.total;
					//Data freshly loaded, head off any new requests with this state change. Handle in useEffect?
					//localstate.dbreload = false;
					UpdateState(localstate);
				}
				if (res.data.Status==="Failure"){
					//Failure error
					localstate.griditems=[];
					localstate.totalitems = 0;
					UpdateState(localstate);
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}


	/* ############### RESIZER ####################*/
	const createResizableColumn = function (col, resizer) {
		// Track the current position of mouse
		let x = 0;
		let w = 0;
	
		const mouseDownHandler = function (e) {
			// Get the current mouse position
			x = e.clientX;
			// Calculate the current width of column
			const styles = window.getComputedStyle(col);
			w = parseInt(styles.width, 10);
			// Attach listeners for document's events
			document.addEventListener('mousemove', mouseMoveHandler);
			document.addEventListener('mouseup', mouseUpHandler);
		};
	
		const mouseMoveHandler = function (e) {
			// Determine how far the mouse has been moved
			const dx = e.clientX - x;
			// Update the width of column
			col.style.width = `${w + dx}px`;
		};
	
		// When user releases the mouse, remove the existing event listeners
		const mouseUpHandler = function () {
			document.removeEventListener('mousemove', mouseMoveHandler);
			document.removeEventListener('mouseup', mouseUpHandler);
		};
		resizer.addEventListener('mousedown', mouseDownHandler);
	};

	const InitColumnResizers = () => {
		//Runs after rerender
		// Query the table
		const table = document.getElementById('resizeMe');
		// Query thead:
		const thead = document.querySelector('thead');
		const cols = thead.querySelectorAll('td');
		// Loop over them
		[].forEach.call(cols, function (col) {
			// Create a resizer element
			const resizer = document.createElement('div');
			resizer.classList.add([classes.resizer]);
			// Set the height
			resizer.style.height = `${table.offsetHeight-2}px`;
			// Add a resizer element to the column
			col.appendChild(resizer);
			createResizableColumn(col, resizer);
		});
	}

	/* ############### END OF RESIZER ####################*/




	/* ##########################  App Init  ########################## */
	//Run once, don't clean up until controller dismount (unchanging init variable)
	const [appinit] = useState(true);
	useEffect(() => {
		document.title = "Product Types Table";
		//Cleanup
		return function cleanup() {
			dispatch(setProgressTimeout(0));
		}
	}, [appinit]);


	//DB Reloads
	useEffect(() => {
		if (state.dbreload){
			//Avoid duplicate loads.
			localstate.dbreload = false;
			LoadItems();
		} else {
			//console.log("Ignore DB Reload.");
			//Boostrap a resizer for the columns:
			InitColumnResizers();
		}
	},);



	/* ##########################  CRUD  ########################## */

	//New Row adds property 'PendingItem' for use in the API to add such rows.
	const AddRow = () => {
		console.log(localstate.defaultvalues);
		localstate.griditems.unshift({
			PendingItem:true,
			ID:uuidv4(),
			Name:"default", // Set this value as something that can't be taken into SaveChanges
			ProductType:"default",
			created_at:new Date().toISOString(), //IS UTC String
			CreatedBy:"",
			updated_at:new Date().toISOString(),
			UpdatedBy:""			
		});

		//All selected indexes move up by 1.
		for (var i=0; i<localstate.selectedindexes.length; i++){
			localstate.selectedindexes[i] += 1;
		}
		UpdateState(localstate);
	}



	//A Ref stays consistent throughout async function
	const requestcount = useRef(0);

	const SaveChanges = () => {
		//Clean up current errors:
		errors.HideError(errors);

		//Configuration
		requestcount.current = 0;
		var timeout = 300; //Seconds before closing final ProgressBar results - To Do: Add option to NOT timeout at all
		var requestinterval = 100; //Milliseconds

		var updatearray = [];
		//Data Validation and Update Array setup
		for (var i = 0; i < localstate.griditems.length; i++) {
			//Halt on bad Name
			if (localstate.griditems[i].Name===""){
				errors.NewError({ errmsg: "One or more of your rows has a blank Name value.", errshow: true, errtimeout: 60, errtype: "warning" });
				return;
			}
			//Halt on bad Product Type
			if (localstate.griditems[i].ProductType===""){
				errors.NewError({ errmsg: "One or more of your rows has a blank Product Type value.", errshow: true, errtimeout: 60, errtype: "warning" });
				return;
			}
			if (localstate.griditems[i].unsaved) {
				updatearray.push(localstate.griditems[i]);
			}
		}

		if (updatearray.length > 0) {
			DisableButtons();
			dispatch(newProgress({
				msg: 'Saving items...',
				show: true,
				settimeout: true, //This option allows for a timeout after the final item is pass/failed
				timeout: timeout, //Initiated on final item 
				type: 'items', //Labels your save types such as items, products, skus, parts
				finished: 0,
				percent: "0%",
				total: updatearray.length,
				pass: 0,
				fail: 0,
				faillist: [], //Can take simple values,
				faillinks: [], //Can take links to things like items or products.
				timeoutid: false,
				errors:[] //Can take strings
			}));
			i = 0;
			var limit = updatearray.length - 1;
			/* INTERVAL */
			var updateitems = setInterval(function () {
				var item = updatearray[i];
				const postdata = {
					item: item
				};
				axios.post(dbendpoint + "/producttypes/update", postdata, defaultpostoptions).then(res => {
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status === 200) {
						//If ValidateUser() fails to verify user, it sends back 'login' error. 
						if (res.data.Status === "login") {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status === "Success") {
							//Success response also includes the item!
							//If we sent new rows, we'll need to reference the old ID.
							var itemindex = 0;
							if (res.data.OldID) {
								itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems[itemindex].unsaved = false;
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
								//Set New ID
								localstate.griditems[itemindex].ID = res.data.item.ID;
							} else {
								itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.item.ID);
								localstate.griditems[itemindex].unsaved = false;
								//Refs allow us to update the grid live!
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
							}
							dispatch(incrementPass());
						}
						if (res.data.Status === "Failure") {
							dispatch(incrementFail(res.data.message));
						}
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);

					} else {
						//Non-200 message from server.
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);
						dispatch(incrementFail("Non-200 response."));
					}
				}).catch(err => {
					//Non-200, 500 Error, timeout?
					requestcount.current++;
					FinalizeRequest(requestcount.current, limit, timeout);
					dispatch(incrementFail("Non-200 or 500 response."));
				});
				//If we have looped through all items, clear this interval.
				if (i === limit) {
					clearInterval(updateitems);
				}
				i++;
			}, requestinterval);
		} else {
			errors.NewError({ errmsg: "Nothing to save.", errshow: true, errtimeout: 5, errtype: "neutral" })
			ResetPendingSaves();
		}
	}

	//Clean up View
	const FinalizeRequest = (i, limit, timeout) => {
		//The itterator after responses are incremented to n+1. Reversed below:
		if ((i-1)===limit){
			EnableButtons();
			dispatch(setProgressTimeout(timeout));
			MarkErrorItems();
			btnSave.current.style.display="";
			btnPendingSave.current.style.display="none";
		}
	} 


	//Mark Remaining Items as Warning Items
	const MarkErrorItems = () => {
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				rowRefs.current[i+'SaveStatus'].classList.remove(classes.unsavedhighlight);
				rowRefs.current[i+'SaveStatus'].classList.add(classes.errorhighlight);
			} else {
				//Resolve previously unresolved
				rowRefs.current[i+'SaveStatus'].classList.remove(classes.errorhighlight);
			}
		}
	}


	const ResetPendingSaves = () =>{
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				localstate.griditems[i].unsaved = false;
			}
		}
		EnableButtons();
		btnSave.current.style.display="";
		btnPendingSave.current.style.display="none";
	}


	{/* BULK EDIT DRAWER STATE AND FUNCTIONS */}
	const [showbulkeditdrawer, setShowBulkEditDrawer] = useState(false);
	const btnApplyBulkEdit = useRef();

	const CloseBulkEditDrawer = () =>{
		setShowBulkEditDrawer(false);
		//Reload DB
		localstate.dbreload = true;
		//Update State
		UpdateState(localstate);
	}

	//Bulk edits may incur many different types of changes.
	//Using state variables, we can allow the view to change between them.
	const [changetype, setChangeType] = React.useState('');



	//Try to replace all this?
	//In render: {(changetype==='text')}, etc. NO. We need changetype for when we go back to the DB for the update.


	//// CHANGE TYPES - Controls change type and input viewables
	const [changeistext, setChangeIsText] = React.useState(false);
	const [changeisfloat, setChangeIsFloat] = React.useState(false);
	const [changeissomeselectable, setChangeIsSomeSelectable] = React.useState(false);
	const [changeisdbitem, setChangeIsDBItem] = React.useState(false);
	const [changeisdate, setChangeIsDate] = React.useState(false);
	const [changeisbool, setChangeIsBool] = React.useState(false);


	const [changeisint, setChangeIsInt] = React.useState(false);
	const [changeisstatus, setChangeIsStatus] = React.useState(false);
	



	//// VALUES - Controls input values and value validation
	const changevalue = React.createRef();
	//These 2 refs are used to update a number input (RestrictNumber() will limit what can be put into input box)
	const changevaluefloat = React.createRef();
	const changevalueint = React.createRef();
	const changevalueselectable = React.createRef();
	const [dbitemselection, setDBItemSelection] = React.useState();


	const handleChangeEditType = (event) => {
		//Reset change value
		changevalue.current="";
		//Reset Inputs:
		setChangeIsText(false);
		setChangeIsFloat(false);
		setChangeIsSomeSelectable(false);
		setChangeIsDBItem(false);
		setChangeIsBool(false);

		//Examples
		setChangeIsInt(false);
		setChangeIsStatus(false);
		setChangeIsDate(false);

		//Reset Btn
		btnApplyBulkEdit.current.classList.remove(["MuiButton-containedPrimary"]);
		btnApplyBulkEdit.current.classList.add(["Mui-disabled"]);

		setChangeType(event.target.value);

		if (
			//Text values here.
			event.target.value==="Name"
		) {
			setChangeIsText(true);
		}

		if (event.target.value==="Cost" || event.target.value==="Price"){
			setChangeIsFloat(true);
		}

		if (event.target.value==="SomeSelectable"){
			setChangeIsSomeSelectable(true);
		}

		if (event.target.value==="NewCheckbox"){
			setChangeIsBool(true);
		}

		if (event.target.value==="DBItem"){
			setChangeIsDBItem(true);
		}

		if (event.target.value==="Date"){
			setChangeIsDate(true);
		}
		

		//Unused Examples:
		if (event.target.value==="LotID" || event.target.value==="SkidNumber"){
			setChangeIsInt(true);
		}

		if (event.target.value==="Status"){
			setChangeIsStatus(true);
		}		

		if (event.target.value==="LotID" || event.target.value==="SkidNumber"){
			setChangeIsInt(true);
		}

	};

	const handleChangeBulkValue = (event) => {
		//Currency
		if (changetype==="Cost" || changetype==="Price"){
			var newvalue = RestrictInputNumber(event.target.value, changevalue.current, event, "float");
			if (newvalue){
				changevalue.current = newvalue;
				changevaluefloat.current.value = newvalue;
			} else {
				if (event.key === "Backspace") {
					//If the newvalue doesn't have a decimal, but the old one did, we try to keep the decimal value by ignoring the ref.
					if ((changevalue.current.indexOf(".") > -1)) {
						changevalue.current = parseFloat(event.target.value).toFixed(2);
					}
				}
			}
		//Integers	
		} else if (changetype==="LotID" || changetype==="SkidNumber") {
			changevalue.current = RestrictInputNumber(event.target.value, changevalue.current, event, "positiveinteger");
			//Updates view:
			changevalueint.current.value = changevalue.current;
		
		} else if (changetype==="NewCheckbox"){
			// placeholder
			changevalue.current = event.target.value;
		} else {
			//Raw Values
			changevalue.current = event.target.value;
		}


		//Verify changes can be saved in current form.
		//Avoid certain items being saved as a blank string:
		if (changetype==="Location" && event.target.value===""){
			btnApplyBulkEdit.current.classList.remove(["MuiButton-containedPrimary"]);
			btnApplyBulkEdit.current.classList.add(["Mui-disabled"]);
			errors.NewError({errmsg:"Value cannot be blank.", errshow:true, errtimeout: 3, errtype:"neutral"});

		//Allow bulk change to be saved
		} else {
			btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
			btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
		}
	}

	const handleChangeDBItem = (event, item) => {
		setDBItemSelection(item);
		//Allow bulk change to be saved
		btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
		btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
	}

	const handleChangeSomeSelectable = (event) => {
		changevalue.current = event.target.value;
		btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
		btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
	}


	//Search for DB Item (autocomplete) - Attempt to make this reusable! Setup endpoints as (env db dbendpoint/table/getautocomplete), see also ApplyBulkEdit
	const [dbitemkey, setDBItemKey] = useState("Name");
	const [opendbitemoptions, openDBItemOptions] = React.useState(false);
	const [dbitemoptions, setDBItemOptions] = React.useState([]);
	const [loadingdbitemoptions, setLoadingDBItemOptions] = useState(false);
	const InitDBItemOptions = () => {
		if (dbitemoptions.length===0){
			//Load (up to limit) options
			DBItemSearch("");
		}
		openDBItemOptions(true);
	}

	const DBItemSearch = debounce(function(searchvalue){
		setLoadingDBItemOptions(true);
		const postdata = {					
			key:dbitemkey,
			search:searchvalue,
			limit:20
		};
		//Provisions to reuse this search:
		var table = "";
		if (changetype==="Product"){
			table="products";
		}
		if (changetype==="DBItem"){
			table="componenttypes";
		}
		//Error such as "TypeError: Cannot read property 'filter' of undefined" means we aren't handling the response correctly or the response is malformed.
		axios.post(dbendpoint+"/"+table+"/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				} else {
					setDBItemOptions(res.data.items);
					console.log(res.data);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingDBItemOptions(false);
		});
	},600);


	const ApplyBulkEdit = () => {
		var items = [];
		for (var i=0; i<localstate.selectedindexes.length; i++){
			items.push(localstate.griditems[localstate.selectedindexes[i]]);
		}

		//Simple key-value pairs:
		if (changetype==="Name" ||
			changetype==="Price" ||
			changetype==="Cost" ||
			changetype==="SomeSelectable" ||
			changetype==="Date" ||
			changetype==="NewCheckbox"
		){
			for (i=0; i<items.length; i++){
				items[i][changetype] = changevalue.current;
			}
		}

		//Database Item Selection (potential multiple values to change)
		if (changetype==="DBItem"){
			//Place your specific column values here:
			for (i=0; i<items.length; i++){
				items[i]["Name"] = dbitemselection.Name;
				//Example: items[i]["ProductID"] = dbitemselection.ProductID;
			}
		}

		//Reassign other item values if needed before pushing them to postdata.items.
		//Example provision to change Margin if price or cost change
		if (changetype==="Price"){
			for (i=0; i<items.length; i++){
				//items[i].Price = changevalue.current;
				items[i].Margin = items[i].Price - items[i].Cost;
			}
		}

		if (changetype==="Cost"){
			for (i=0; i<items.length; i++){
				//items[i].Cost = changevalue.current;
				items[i].Margin = items[i].Price - items[i].Cost;
			}
		}

		var postdata={
			items:items
		}

		

		axios.post(dbendpoint+"/producttypes/bulkedititems", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==='login'){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				if (res.data.Status==='Success'){
					//console.log(res.data);
					if (res.data['SuccessCount']>0){
						errors.NewError({
							errmsg:"Items Changed: "+res.data['SuccessCount']+", Failures: "+res.data['FailCount'],
							errshow:true,
							errtimeout: 5,
							errtype:'ok'})
						}
					}
					
				if (res.data.Status==='Failure'){
					//Failure error
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:'neutral'})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:'warning'})
			}
		});
	}



	//Delete Confirmation and Deletion
	const [showdeleteconfirmation, setShowDeleteConfirmation] = useState(false);
	const [deleteitems, setDeleteItems] = useState([]);
	const DeleteSelectedInit = () =>{
		var deleteitemsarray =[];
		//Reflect items to user for confimation.
		for (var i=0; i<state.selectedindexes.length; i++){
			deleteitemsarray.push(localstate.griditems[state.selectedindexes[i]]);
		}
		setDeleteItems(deleteitemsarray);
		setShowDeleteConfirmation(true);
	}

	const DeleteSelected = () => {
		var finishedrequests = 0;
		for (var i=0; i<localstate.selectedindexes.length; i++){
			if (localstate.griditems[localstate.selectedindexes[i]].hasOwnProperty("PendingItem")){
				//Pending items are simply removed from the view and forgotten.
				localstate.griditems.splice(localstate.selectedindexes[i], 1);
				//Count as finished request
				finishedrequests++;
			} else {
				//Make Delete request to DB
				const postdata = {				
					item:localstate.griditems[localstate.selectedindexes[i]]
				};
				axios.post(dbendpoint+"/producttypes/delete", postdata, defaultpostoptions).then(res => {
					//No matter the response, we consider the result as a 'finished request'. We can then properly do clean-up.
					finishedrequests++;	
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status===200){
						//If ValidateUser() fails to verify user, it sends back "login" error. 
						if (res.data.Status==="login"){
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status==="Success"){
							//Success response also includes the item!
							//If we're pulling the item out of grid items, we'll use the ID of the item for reference.
							if (res.data.OldID){
								//Since griditems state can be reloaded anytime, we look for the indexOf the ID
								var itemindex = localstate.griditems.map(function(o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems.splice(itemindex, 1);
								//Remove selected index as well?
							} else {
								errors.NewError({errmsg:"Could not delete one or more items.", errshow:true, errtimeout: 8, errtype:"warning"})
							}
						}
						if (res.data.Status==="Failure"){
							//Failure error
							errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"});
							//Need to clear selections as any removed grid items will mess with selectedindexes.
							//Setting selected indexes to a blank array will stall the upper for-loop
							localstate.selectedindexes = [];
							localstate.selectedcount = 0;
							for (var j=0; j<localstate.griditems.length; j++){
								localstate.griditems[j].isSelected = false;
								localstate.griditems[j].GridKey++;
							}
							UpdateState(localstate);
						}
					} else {
						//Non-200 message from server.
						errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
					}
					//After result from last request, do cleanup.
					if (finishedrequests === localstate.selectedindexes.length) {
						//Clear out all selections! Since checkboxes are controlled only by grid items index, we don't have good
						//tracking on which items are which.
						localstate.selectedindexes = [];
						localstate.selectedcount = 0;
						UpdateState(localstate);
						setShowDeleteConfirmation(false);
					}
				});
			}
		}
	}
	const CancelDelete = () => {
		setShowDeleteConfirmation(false);
	}




	/* ##########################  Cell Interaction  ########################## */


	//Tab Order: Used for horizontal tabbing below.
	//We need an order for horizontal tabbing - attempting to tab to an unavailable column (like a disabled column via colstate) will result in an error
	const taborder = ["Name", "ProductType"];


	//Excel-like functionality for grid
	const HandleKeyDown = (event, index, column) => {
		//Deny Decimals for Integer inputs!
		console.log("Key: "+event.key);
		if (event.key === "." && column==="SomePositiveInteger"){
			console.log("Prevent decimal in integer input");
			event.preventDefault();
		}
		//Handle Tabs!
		if (event.key === "Tab") {
			event.preventDefault();
			//Vertical VS Horizontal Tabbing

			//Vertical Tabbing - Checkboxes, Gross Income, Rates
			//Vertical Tabbing is never subject to the next column not being shown (we always to back to record #1 instead!)
			if (column === "Checkbox") {  //Insert each type of column you want vertically tabbed here: if (column==="Checkbox" || column==="Margin"){ etc
				//If the next row ref exists....
				if (rowRefs.current[(index + 1) + column]) {
					rowRefs.current[(index + 1) + column].focus();
				} else {
					//Go to first element
					rowRefs.current[("0" + column)].focus();
				}
			} else {
				//Horizontal Tabbing - Row Data
				//Horizontal Tabbing is subject to certain columns not being available for selection. (colstate)

				//Get index within tab order:
				var tabindex = taborder.indexOf(column);
				//Increase index until we find the next tab order column
				for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
					//If we're at the last column element, go to the next row's first available column element
					if (i === taborder.length) {
						//Start at beginning of row tab order and reitterate
						i = -1;
						//If next row exists:
						if (rowRefs.current[(index + 1) + column]) {
							index = index + 1;
						} else {
							//Go to first row.
							index = 0;
						}
					} else {
						//If there is another elemet in the taborder.... Continue. Else, go to first column.
						if (taborder[i]) {
							//If that next element is available
							if (colstate[taborder[i]]) {
								rowRefs.current[index + taborder[i]].select();
								break;
							}
						} else {
							//Start at beginning of row tab order and reitterate
							i = -1;
						}
					}
				}
			}
		}
		//Handle Down Arrow
		if (event.key === "ArrowDown"){
			event.preventDefault();
			if (rowRefs.current[(index + 1) + column]) {
				rowRefs.current[(index + 1) + column].focus();
			} else {
				//Go to first element
				rowRefs.current[("0" + column)].focus();
			}
		}
		//Handle Up Arrow
		if (event.key === "ArrowUp"){
			event.preventDefault();
			if (rowRefs.current[(index - 1) + column]) {
				rowRefs.current[(index - 1) + column].focus();
			} else {
				//Go to last element
				var lastelement = localstate.griditems.length-1;
				rowRefs.current[(lastelement + column)].focus();
			}
		}
	}


	const DetectBlankNumber = (event, index, column) => {
		if (event.target.value===""){
			rowRefs.current[index+column].value="0.00";
			localstate.griditems[index][column] = "0.00";
		}
	}

	const DetectBlankNumberInteger = (event, index, column) => {
		if (event.target.value === "") {
			rowRefs.current[index + column].value = "0";
			localstate.griditems[index][column] = "0";
		}
	}

	const DetectBlankNumberFloat = (event, index, column) => {
		if (event.target.value === "") {
			rowRefs.current[index + column].value = "0.00";
			localstate.griditems[index][column] = "0.00";
		} else {
			rowRefs.current[index + column].value = parseFloat(event.target.value).toFixed(2);
			localstate.griditems[index][column] = parseFloat(event.target.value).toFixed(2);
		}
	}

	//Catch-All Method.
	const onChangeValue = (event, index, column) => {
		var oldvalue = localstate.griditems[index][column];
		var newvalue = event.target.value;
		console.log("Event value:"+event.target.value);
		console.log("Raw:"+newvalue);
		if (event.key !== "Tab" && 
			event.key!=="ArrowDown" && 
			event.key!=="ArrowUp" && 
			event.key!=="ArrowLeft" && 
			event.key!=="ArrowRight" && 
			event.key!=="ShiftLeft" && 
			event.key!=="ShiftRight"
			){
			//Conditionals for Types:
			if (column==="Cost"){
				if (!oldvalue){
					oldvalue="0";
				}
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float");
				//If new value...
				if (newvalue){
					//If Cost changes, so does Margin
					rowRefs.current[index+"Margin"].textContent = (rowRefs.current[index+"Price"].value - newvalue).toFixed(2);
					localstate.griditems[index].Margin = rowRefs.current[index+"Margin"].textContent;
					//Backspace on a decimal should yield same value for old and new
					//console.log("Oldvalue has decimal:"+oldvalue.indexOf(".")+"   Newvalue has decimal:"+newvalue.indexOf(".")+"   Event key:"+event.key);
					if (oldvalue.indexOf(".")>-1 && newvalue.indexOf(".")===-1 && event.key==="Backspace"){
						//console.log("Scenario1");
						//No need to change front end, simply update griditem
						localstate.griditems[index][column] = newvalue;
					} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)){
						rowRefs.current[index+column].value=newvalue;
						localstate.griditems[index][column] = newvalue;
					}
				} else {
					//Handle possible backspace.
					//We need to update the localstate, but NOT the ref. The input ref can't take values like "2." and messes up the cursor position if you try.
					if (event.key === "Backspace") {
						if ((oldvalue.indexOf(".") > -1)) {
							localstate.griditems[index][column] = parseFloat(event.target.value).toFixed(2);
						}
					}
				}
			}

			//Positive integers don't need decimals! Try to preventDefault on input on keydown.
			if (column==="SomePositiveInteger"){
				//TO DO - UNFINISHED UNTESTED CODE
				//Some forms don't have any input to begin with.
				if (!oldvalue){
					oldvalue="0";
				}
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "positiveinteger");
				//If new value...
				if (newvalue){
					//If Cost changes, so does Margin
					//rowRefs.current[index+"Margin"].textContent = (rowRefs.current[index+"Price"].value - newvalue).toFixed(2);
					//localstate.griditems[index].Margin = rowRefs.current[index+"Margin"].textContent;

					//Backspace on a decimal should yield same value for old and new
					if ([oldvalue].indexOf(".")>-1 && newvalue.indexOf(".")===-1){
						//No need to change front end, simply update griditem
						localstate.griditems[index][column] = newvalue;
					} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)) {
						rowRefs.current[index+column].value=newvalue;
						localstate.griditems[index][column] = newvalue;
					}
				} else {
					//Handle possible backspace.
					//We need to update the localstate, but NOT the ref. The input ref can't take values like "2." and messes up the cursor position if you try.
					// if (event.key === "Backspace") {
					// 	if ((oldvalue.indexOf(".") > -1)) {
					// 		localstate.griditems[index][column] = parseFloat(event.target.value).toFixed(2);
					// 	}
					// }
				}
			}

			if (column==="Price"){
				if (!oldvalue){
					oldvalue="0";
				}
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float");
				if (newvalue){
					//If Price changes, so does Margin
					rowRefs.current[index+"Margin"].textContent = (newvalue - rowRefs.current[index+"Cost"].value).toFixed(2);
					localstate.griditems[index].Margin = rowRefs.current[index+"Margin"].textContent;
					//Backspace on a decimal should yield same value for old and new
					if (oldvalue.indexOf(".")>-1 && newvalue.indexOf(".")===-1 && event.key==="Backspace"){
						//No need to change front end, simply update griditem
						localstate.griditems[index][column] = newvalue;
					} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)){
						rowRefs.current[index+column].value=newvalue;
						localstate.griditems[index][column] = newvalue;
					}
				} else {
					if (event.key === "Backspace") {
						if ((oldvalue.indexOf(".") > -1)) {
							localstate.griditems[index][column] = parseFloat(event.target.value).toFixed(2);
						}
					}
				}
			}
			//Provision for Booleans that require a re-render. Expensive!
			if (column==="SomeBoolean"){
				if (event.target.checked){
					console.log("Checked = true");
					localstate.griditems[index][column] = 1;
					UpdateState(localstate);
				} else {
					console.log("Checked = false");
					localstate.griditems[index][column] = 0;
					UpdateState(localstate);
				}
				
			} else {
				//Selects already render the correct and unsaved result of selecting. (but right now they cause rerender via state.... possible change here.)
				if (column==="NewCheckbox") { //MaterialUI Checkbox
					if (event.target.checked){
						localstate.griditems[index][column] = 1;
					} else {
						localstate.griditems[index][column] = 0;
					}
				} else if (column==="SomeSelectable" || column==="Cost" || column==="Price" || column==="SomePositiveInteger") { 
					//Avoids Refs! Required for numeric input and seletables. Component takes care of view while localstate has yet to update the DB
					localstate.griditems[index][column] = newvalue;
				} else {
					//Update Refs like usual.
					rowRefs.current[index+column].value=newvalue;
					localstate.griditems[index][column] = newvalue;
				}
			}
			rowRefs.current[index+"SaveStatus"].classList.add(classes.unsavedhighlight);
			btnSave.current.style.display="none";
			btnPendingSave.current.style.display="";
			localstate.griditems[index].unsaved = true;
		}
	}


	/* ##########################  Button Functions  ########################## */

	const ExpandRowToggle = (index) => {
		localstate.griditems[index].ExpandRow = !localstate.griditems[index].ExpandRow;
		UpdateState(localstate);
	}

	const ExpandAll = () => {
		for (var i=0; i<localstate.griditems.length; i++){
			localstate.griditems[i].ExpandRow = true;
		}
		UpdateState(localstate);
	}

	const ResetSearches = () => {
		//Reset all:
		for (var i=1; i<7; i++){
			localstate.searchoptions.searchpairs["searchpair"+i].type = "";
			localstate.searchoptions.searchpairs["searchpair"+i].value = "";
			localstate.searchoptions.searchpairs["searchpair"+i].mode = "like";
			localstate.searchoptions.searchpairs["searchpair"+i].uuid = uuidv4();
		}
		//Set Defaults:
		setItemListSearch('');
		localstate.searchoptions.searchpairs.searchpair1.type = "Name"; //CONFIG: Add more resets here.
		localstate.searchoptions.searchpairs.searchpair2.type = "CreatedBy";
		localstate.searchoptions.searchpairs.searchpair3.type = "UpdatedBy";
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	function getRandomInt(max) {
		return Math.floor(Math.random() * Math.floor(max));
	}

	//Export Confirmation and Exports
	const [showexportconfirmation, setShowExportConfirmation] = useState(false);
	//Chooses between "selected" or "searchresults"
	const [exporttype, setExportType] = useState("");
	//Export Message
	const [exportmessage, setExportMessage] = useState("");
	var exportmode = "";
	var exportlimit = 2000;
	var exportfilename = "ProductTypesCSV";
	
	const InitExport = (exporttype) => {
		if (exporttype==="selected" && localstate.selectedindexes.length===0){
			//Do nothing
		} else {
			//Show export type selection
			setShowExportConfirmation(true);
			//Set export by "selected" or "searchresults"
			setExportType(exporttype);
			if (exporttype==="selected"){
				setExportMessage("");
			}
			if (exporttype==="searchresults"){
				setExportMessage("Exports of search results will be limited to "+exportlimit+" rows.");
			}
		}
	}

	const PrepareExport = (mode) =>{
		//Set global
		exportmode = mode;
		var exportdata = [];
		var clonedata=[];
		if (exporttype==="selected"){
			//Need to DEEP clone:
			var clonedata = JSON.parse(JSON.stringify(localstate.griditems))
			for (var i=0; i<localstate.selectedindexes.length; i++){
				exportdata.push(clonedata[localstate.selectedindexes[i]]);
			}
			Export(exportdata);
		}
		if (exporttype==="searchresults"){
			const postdata = {
				searchoptions:{
					//Set Max Limit Here
					limit:exportlimit,
					currentsort: localstate.orderby,
					currentsortdir: localstate.order,
					searchpairs:localstate.searchoptions.searchpairs
				}
			};
			axios.post(dbendpoint+"/producttypes?page="+(localstate.page+1), postdata, defaultpostoptions).then(res => {
				if (res.status===200){
					if (res.data.Status==="login"){
						window.location.reload(false);
					}
					if (res.data.Status==="Success"){
						exportdata = res.data.pagedata.data;
						Export(exportdata);
					}
					if (res.data.Status==="Failure"){
						errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
					}
				} else {
					errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
				}
			});
		}
	}

	const Export = (exportdata) => {
		//Remove Metas
		for (var i=0; i<exportdata.length; i++){
			removeProp(exportdata[i], "isSelected");
			removeProp(exportdata[i], "unsaved");
			removeProp(exportdata[i], "ExpandRow");
			removeProp(exportdata[i], "GridKey");
		}

		//Use Export Mode to pare data
		if (exportmode==="simple"){
			for (var i=0; i<exportdata.length; i++){
				removeProp(exportdata[i], "Date");
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode==="expanded"){
			for (var i=0; i<exportdata.length; i++){
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode==="exhaustive"){
			//Remove nothing - or possibly break down nested relationships
		}
		ExportCSV(exportdata, exportfilename);
		setShowExportConfirmation(false);
	}


	/* ########################## Lower Screen Demo Data/Functions ###### */


	//Basic Select
	const [basicselectvalue, setBasicSelectValue] = React.useState(10);

	const handleChange = (event) => {
		setBasicSelectValue(event.target.value);
	};


	//Autocomplete Simple
	//Example: Products
	const [openproductoptions, openProductOptions] = React.useState(false);
	const [productoptions, setProductOptions] = React.useState([]);
	const [loadingproductoptions, setLoadingProductOptions] = useState(false);
	const [productsearchterm, setProductSearchTerm] = useState("Default Value");
	//For loading single items that do have a value, use LoadItem function in conjunction with: setProductSearchTerm(res.data.item.product.Name);
	const InitProductOptions = () => {
		if (productoptions.length===0){
			ProductSearch("");
		}
		openProductOptions(true);
	}

	const onChangeProductOption = (event, newvalue) =>{
		//Set value for current search
			// Example:
			// localstate.searchoptions.searchpairs.searchpairX.type=autocompletesearchtypeX;
			// localstate.searchoptions.searchpairs.searchpairX.value=newvalue[autocompletesearchtypeX];
			// localstate.dbreload = true;
			// UpdateState(localstate);

		//Or set value for your item:
			//localstate.itemdata['ProductID'] = newvalue['ProductID'];
			// localstate.pendingsaves = true;
			// btnSave.current.disabled = false;
			// btnSave.current.style.color="white";
			// btnSave.current.style.backgroundColor="#01579B";

		//Or just yell at the user, the ProductID they just set
			//alert(newvalue['ProductID']);

		
			
	}

	const ProductSearch = debounce(function(searchvalue){
		setProductSearchTerm(searchvalue);
		setLoadingProductOptions(true);
		const postdata = {					
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/items/searchproducts", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setProductOptions(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingProductOptions(false);
		});
	},600);

	const [basicselectdbvalue, setBasicSelectDBValue] = useState("Default basic select value");



	//Let's try to set a default value for our AutoComplete
	const [testDBAC, setDBAC] = useState('Some UseState Default');

	const onDBAutoCompleteChange = (newvalue, searchkey) =>{
		//We can parse results by using keys - this makes the function reusable so we can insert many DBAutoComplete Components
		if (searchkey==="Vendor"){
			console.log("Set new value: "+newvalue.Vendor);
			setDBAC(newvalue.Vendor);
		}
		
	}

	

	/* ##########################  TABLE COLUMN HEADERS  ########################## */
	/* ##########################  Column Configuration  ########################## */
	const headCells = [
		//Be sure to adjust widths for cells as well.
		{ id: "Name", numeric: false, label: "Name", align:"left", allowsort:true, style:{width:"400px"}},
		{ id: "ProductType", numeric: false, label: "Product Type", align:"left", allowsort:true, style:{}},
		{ id: "created_at", numeric: false, label: "Creation",  align:"left", allowsort:true, style:{}},
		{ id: "CreatedBy", numeric: false, label: "Creator", align:"left", allowsort:true, style:{}},
		{ id: "updated_at", numeric: false, label: "Update Date",  align:"left", allowsort:true, style:{}},
		{ id: "UpdatedBy", numeric: false, label: "Updated By", align:"left", allowsort:true, style:{}},
	];

	function EnhancedTableHead(props) {
		const { classes, onSelectAllClick, order, onRequestSort } = props;
		const createSortHandler = (property) => (event) => {
			onRequestSort(event, property);
		};
	
		return (
			<thead style={{display:"table-header-group"}}>
				<tr style={{border:"1px solid #CCC",
							backgroundColor:"#DDD"}}>
					<td style={{width: "14px", padding:"none", display:"table-cell", padding:"2px 4px 2px 5px"}}>

						<Checkbox
							className={classes.gridcheckbox}
							disableRipple
							color="default"
							defaultChecked={localstate.griditems.length === localstate.selectedindexes.length}
							checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
							icon={<span className={classes.icon} />}
							onChange={onSelectAllClick}
						/>

					</td>
					{/* Map remaining table headers */}
					{headCells.map((headCell) =>
						colstate[headCell.id] && (
							<td
								key={headCell.id}
								align={headCell.align}
								style={headCell.style}
							>
								{(headCell.allowsort) &&
									<TableSortLabel
										active={localstate.orderby === headCell.id}
										direction={localstate.orderby === headCell.id ? order : "asc"}
										onClick={createSortHandler(headCell.id, headCell.allowsort)}
										hideSortIcon
									>
										{/* If current sort, show bold label */}
										{(localstate.orderby === headCell.id)
											? <span style={{ fontWeight: "bold" }}>{headCell.label}</span>
											: <span>{headCell.label}</span>
										}
									</TableSortLabel>
								}
								{(!headCell.allowsort) &&
									<span>{headCell.label}</span>
								}
							</td>
						)
					)}
				</tr>
			</thead>
		);
	}

	EnhancedTableHead.propTypes = {
		classes: PropTypes.object.isRequired,
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(["asc", "desc"]).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};

	{/* Utility Drawer state and functions */}
	const [showutilitydrawer, setShowUtilityDrawer] = useState(false);

	const CloseUtilityDrawer = () =>{
		setShowUtilityDrawer(false);
		//Reload DB?
		//localstate.dbreload = true;
		//Update State?
		//UpdateState(localstate);
	}

	{/* Item List - General purpose boilerplate */}
	const [showitemlist, setShowItemList] = useState(false);
	//We keep our original item list intact, use itemlistsearch for new DB reload
	const [itemlistsearch, setItemListSearch] = useState("");
	const [dupeitems, setDupeItems] = useState('');
	const CloseItemList = () =>{
		//Get unique items
		const result = localstate.searchoptions.itemlist.split(/\r?\n/).filter(element => element);
		const newitemlist = GetUniqueArray(result);
		setItemListSearch(newitemlist);
		setShowItemList(false);

		//Reload DB
		localstate.dbreload = true;
		//Update State
		UpdateState(localstate);
	}
	const onChangeItemList = (event) => {
		//Optional check for dupes:
		//Bust apart by line breaks!
		//Let's start sending arrays to PHP Laravel instead where possible since we're already
		//going to do work here before the API call.

		//If there is a line break at the end of the list (blank new line), you may end up with a falsy array element at the end.
		//Filter creates a new array based on passing a test function (true or false).
		//By passing a falsy array element (undefined), it won't return that element in the result array.
		//Split by Line Breaks, then filter.
		const result = event.target.value.split(/\r?\n/).filter(element => element);
		//Find Dupes, let the user know there are dupes.
		const dupes = GetDupeArray(result);
		if (dupes.length>0){
			setDupeItems(dupes);
			console.log(dupes);
		} else {
			setDupeItems('');
		}
		localstate.searchoptions.itemlist = event.target.value;
		//setItemList(event.target.value);
	}

	{/* Print Labels */}
	const PrintLabels = () =>{
		var itemids = [];
		//Grab all IDs
		for (var i=0; i<localstate.selectedindexes.length; i++){
			itemids.push(localstate.griditems[localstate.selectedindexes[i]]['ID']);
		}

		var params = {
			itemids:JSON.stringify(itemids),
			labeltype:'basiclabel',
			autoclose:false,
			seriallist:'',
			orderby:localstate.orderby,
			order:localstate.order
		};
	
		// Creating a form
		var form = document.createElement("form");
		form.setAttribute("method","post");
		form.setAttribute("action","../v3/labels");
		form.setAttribute("target", "NewLabel");
		for (var i in params) {
			if (params.hasOwnProperty(i)){
				// Creating the input
				var input = document.createElement('input');
				input.type='hidden';
				input.name=i;
				input.value=params[i];

				// Attach input to form
				form.appendChild(input);
			}
		}
		document.body.appendChild(form);
		form.submit();
	}



	/* MASS UPDATE UTILITIES */
	// const updatemanifests = (itteration) =>{
	// 	const postdata = {
	// 		itteration:itteration,
	// 		limit:100
	// 	};
	// 	axios.post(dbendpoint+"/utility/updatemanifests", postdata, defaultpostoptions).then(res => {
	// 		//API should be setup to send 200 response with status. Merge paginated requests.
	// 		if (res.status===200){
	// 			//If ValidateUser() fails to verify user, it sends back 'login' error. 
	// 			if (res.data.Status==="login"){
	// 				//Not logged in. Reload page causes redirect to /login
	// 				window.location.reload(false);
	// 			}
	// 			//All new API calls should return a status.
	// 			if (res.data.Status==="Continue"){
	// 				console.log(res);
	// 				updatemanifests(res.data.Itteration+1);
	// 			}
	// 			if (res.data.Status==="Finished"){

	// 			}
	// 			if (res.data.Status==="Failure"){
	// 				//Failure error
	// 				errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
	// 			}
	// 		} else {
	// 			//Non-200 message from server.
	// 			errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
	// 		}
	// 	});
	// }

	// const updateserialnumbers = (itteration) =>{
	// 	const postdata = {
	// 		itteration:itteration,
	// 		limit:100
	// 	};
	// 	axios.post(dbendpoint+"/utility/updateserialnumbers", postdata, defaultpostoptions).then(res => {
	// 		//API should be setup to send 200 response with status. Merge paginated requests.
	// 		if (res.status===200){
	// 			//If ValidateUser() fails to verify user, it sends back 'login' error. 
	// 			if (res.data.Status==="login"){
	// 				//Not logged in. Reload page causes redirect to /login
	// 				window.location.reload(false);
	// 			}
	// 			//All new API calls should return a status.
	// 			if (res.data.Status==="Continue"){
	// 				console.log(res);
	// 				updateserialnumbers(res.data.Itteration+1);
	// 			}
	// 			if (res.data.Status==="Finished"){

	// 			}
	// 			if (res.data.Status==="Failure"){
	// 				//Failure error
	// 				errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
	// 			}
	// 		} else {
	// 			//Non-200 message from server.
	// 			errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
	// 		}
	// 	});
	// }


	//Some requests might take longer
	const longpostoptions = {
		withCredentials:true,
		withXSRFToken: true,
		crossDomain:true,
		mode:"no-cors",
		timeout:20000,
	};

	


	/* 
	                                                                                         
         _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
        _/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
       _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
      _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
     _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
                                                                                         
                                                                                         
 	*/

	
	/* ##########################  Render Function  ########################## */
	/* General rules: The /App allows us to use 100% of the width here. We can also allow each controller to set their own min width, ie: minWidth: "350px" below */
	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<div style={{padding:"8px", overflow:"auto", minWidth:"350px"}}>
				{/* Bulk Edit Drawer - Currently unused, not sure this will ever be useful. */}
				<Drawer open={showbulkeditdrawer} style={{ width: "600px" }}>
					<div style={{height:"47px"}}></div>
					<Typography variant="h4" gutterBottom align="center">
						Bulk Edit Selected Items
					</Typography>
					<div style={{ width: "400px", padding: "10px" }}>
						<Typography variant="subtitle1" gutterBottom>
							Changes apply to all checkbox selected items in the list view. You may perform multiple changes before returning to the list. Select change type to get started.<br></br>
						</Typography>

						{/* You may want access to errors here - create parent container to hold position for you. */}
						<div style={{height:"25px"}}>
							{(errors.currenterror.errshow) &&
								<div style={{ textAlign: "center", height: "25px", fontSize: "12px" }}>
									<ErrorMessage />
								</div>
							}	
						</div>
						

						<FormControl className={classes.formControl} style={{ width: "300px" }} variant="standard">
							<InputLabel id="Change type">Change Type</InputLabel>
							<Select
								labelId="change-type"
								id="change-type"
								value={changetype}
								onChange={handleChangeEditType}
							>
								<MenuItem value={'Name'}>Name</MenuItem>
								<MenuItem value={'Cost'}>Cost</MenuItem>
								<MenuItem value={'Price'}>Price</MenuItem>
								<MenuItem value={'SomeSelectable'}>Some Selectable</MenuItem>
								<MenuItem value={'DBItem'}>DB Item (Demo of Name lookup)</MenuItem>		
								<MenuItem value={'NewCheckbox'}>New Checkbox</MenuItem>						
							</Select>
						</FormControl>

						{/* To do: Set change values. Most are just text, so we'll start with that: */}
						{(changeistext) &&
							<div>
							<TextField variant="standard" style={{ width: "300px" }} required id="standard-required" defaultValue="" onChange={handleChangeBulkValue} />
							</div>
						}

						{(changeisstatus) &&
							<FormControl className={classes.formControl} style={{width:"300px"}} variant="standard">
							<InputLabel id="demo-simple-select-label">Select Type</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								onChange={handleChangeBulkValue}
							>
								<MenuItem value={"Checked In"}>Checked In</MenuItem>
								<MenuItem value={"Sold"}>Sold</MenuItem>
								<MenuItem value={"Scrap"}>Scrap</MenuItem>
								<MenuItem value={"Returned to Vendor"}>Returned to Vendor</MenuItem>
								<MenuItem value={"Undetermined or Missing"}>Undetermined or Missing</MenuItem>
							</Select>
							</FormControl>
						}

						{/* Float: Cost, Price */}
						{(changeisfloat) &&
							<div>
								<TextField variant="standard" type="number" step="0.01"  
									inputRef={el => changevaluefloat.current = el} style={{ width: "300px" }} 
									required id="standard-required" defaultValue="Value" 
									onKeyUp={(event)=>{handleChangeBulkValue(event)}} 
								/>
							</div>
						}

						{/* EXAMPLE: Integer: LotID or SkidNumber */}
						{(changeisint) &&
							<div>
								<TextField variant="standard" type="number" step="1"  
								inputRef={el => changevalue.current = el}
								style={{ width: "300px" }}
								required 
								id="standard-required"
								defaultValue=""
								onKeyUp={(event)=>{handleChangeBulkValue(event)}} />
							</div>
						}


						{/* Change is Some Selectable SomeSelectable */}
						{(changeissomeselectable) &&
							<div>
								<FormControl variant="standard" className={classes.formControl}>
									{/* <InputLabel id="demo-controlled-open-select-label">Optional Label</InputLabel> */}
									{/* Need: Set default value if row.SomeSelectable is null. */}
									{/* Why: Some of the values may be momentarily null for some reason - DB fetch, rerender? */}

									{/* Override icon prop class to our custom 'nodisplay' to remove arrowdown icon for this select */}
									{/* Override select prop class with new padding specs */}

									<Select
										defaultValue={""}
										onChange={(event) => handleChangeSomeSelectable(event)}
										classes={{
											//icon: classes.nodisplay,
											//select: classes.selectpadding
										}}
										style={{ width: "300px" }}
									>
										<MenuItem value="">
											<em>None (blank string)</em>
										</MenuItem>
										<MenuItem value={10}>Ten (integer)</MenuItem>
										<MenuItem value={"A"}>
											<Chip size="small" label="A" clickable className={classes.gradea} />
										</MenuItem>
										<MenuItem value={"B"}>
											<Chip size="small" label="B" clickable className={classes.gradeb} />
										</MenuItem>
										<MenuItem value={"C"}>
											<Chip size="small" label="C" clickable className={classes.gradec} />
										</MenuItem>
										<MenuItem value={"Bad"}>
											<Chip size="small" label="Bad" clickable className={classes.gradebad} />
										</MenuItem>
										<MenuItem value={"Repair"}>
											<Chip size="small" label="Repair" clickable className={classes.graderepair} />
										</MenuItem>
										<MenuItem value={"Scrap"}>
											<Chip size="small" label="Scrap" clickable className={classes.gradescrap} />
										</MenuItem>
									</Select>
								</FormControl>
							</div>
						}


						{/* DB Item (Autocomplete) */}
						{(changeisdbitem) &&
							<div>
								<Autocomplete freeSolo forcePopupIcon={false} disableClearable style={{ width: "300px", padding:"0px" }}
									open={opendbitemoptions} onOpen={() => { InitDBItemOptions (); }} onClose={() => { openDBItemOptions(false); }}
									//Not sure how to avoid passing event, then newvalue... seems to break when removing event.
									//* onChange={(event, newValue) => onChangeOption1(event, newValue)} */}
									onChange={(event, newValue) => handleChangeDBItem (event, newValue)}
									onInputChange={(event) => DBItemSearch(event.target.value)}
									isOptionEqualToValue={(option, value) => option[dbitemkey] === value}
									getOptionLabel={(option) => option[dbitemkey]}
									/* Work-around for when there are no options and you get errors for getOptionLabel:
										getOptionLabel={
											(option) => {
												if (option){
													return option["Name"];
												} else {
													return "";
												}
											}
										}
									*/
									options={dbitemoptions}
									loading={loadingdbitemoptions}
									renderInput={(params) => (
										<TextField variant="standard"
											className={classes.autocompleteinput}
											style={{padding:"0px"}}
											{...params}
											InputProps={{
												disableUnderline: false,
												...params.InputProps,
												autoComplete: "new-password",
												endAdornment: (
													<React.Fragment>
														{loadingdbitemoptions ? <CircularProgress color="inherit" size={20} /> : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
											}}
										/>
									)}
								/>
							</div>
						}

						{/* Date */}
						{(changeisdate) &&
							<FormControl className={classes.formControl} style={{width:"300px"}}>
							<TextField variant="standard"
								id="datetime-local"
								type="datetime-local"
								defaultValue=""
								onChange={handleChangeBulkValue}
								sx={{ width: 300 }}
								InputLabelProps={{
								shrink: true,
								}}
							/>
							</FormControl>
						}


						{/* Change Is Bool */}
						{(changeisbool) &&
							<div style={{marginTop:"20px"}}>
								<FormControl>
									<FormLabel id="demo-radio-buttons-group-label">Set Boolean Status (customizable)</FormLabel>
									<RadioGroup
										aria-labelledby="demo-radio-buttons-group-label"
										defaultValue="0"
										name="radio-buttons-group"
										onChange={handleChangeBulkValue}
									>
										<FormControlLabel value="0" control={<Radio />} label="Disable" />
										<FormControlLabel value="1" control={<Radio />} label="Enable" />
									</RadioGroup>
								</FormControl>
							</div>
						}

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => ApplyBulkEdit()}
							ref={el => btnApplyBulkEdit.current = el}>
							Apply Change
						</Button>

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => CloseBulkEditDrawer()}>
							Close
						</Button>
						
					</div>
				</Drawer>




				{/* Standard Page Header with right floated error message space */}
				<div style={{ minHeight: "50px", paddingTop:"5px" }}>
					<Grid container justifyContent="space-between">
						<Grid item xs={12} sm={6} md={3} order={{ xs: 3, sm: 3, md: 1 }} style={{padding:"5px", minWidth:"270px"}}>{(errors.currenterror.errshow) &&
							<ErrorMessage />
						}</Grid>
						<Grid item xs={12} sm={6} md={3} order={{ xs: 1, sm: 1, md: 2 }} style={{textAlign:"center", margin:"auto", padding:"5px"}}><h2>Product Types</h2></Grid>
						<Grid item xs={12} sm={6} md={3} order={{ xs: 2, sm: 2, md: 3 }} style={{padding:"5px", minWidth:"270px"}}>
							<div style={{float:"right"}}>
								<ProgressBar />
								<NewErrorMessage />
							</div>
						</Grid>
					</Grid>
				</div>


				{/* /* ##########################  Search Inputs  ########################## */}
				{/* Search Tools should: Fall in-line, stack, have padding-right of 15px, 300px wide.*/}
				{/* CHOOSE between AutoCompletes OR Key-Value searches. Helps keep interface looking CLEAN. */}

				{(!isPrintView) &&
					<div>

						{/* Key-Value Searches */}

						{/* Search Pair 1 */}
						{(searchinputs.show1) &&
							<div className={classes.searchinputs} style={{width:"300px"}}>
								<FormControl className={classes.searchtypeinput} style={{ minWidth: "140px" }} variant="standard">
									{/* Value must match one of the MenuItem values, SerialNumber != Serial Number */}
									<Select
										key={localstate.searchoptions.searchpairs.searchpair1.uuid}
										value={localstate.searchoptions.searchpairs.searchpair1.type} disableUnderline
										onChange={(event) => onChangeSearchType(event.target.value, 1)}
										MenuProps={{
											style: {zIndex: 2001}
										}}
									>
										<MenuItem value={"Name"}>Name</MenuItem>
										<MenuItem value={"ProductType"}>Product Type</MenuItem>
										<MenuItem value={"CreatedBy"}>Creator</MenuItem>
										<MenuItem value={"UpdatedBy"}>Updated By</MenuItem>
									</Select>
								</FormControl>
								{(localstate.searchoptions.searchpairs.searchpair1.type!=="DateTimeBefore" && localstate.searchoptions.searchpairs.searchpair1.type!=="DateTimeAfter") &&
									<TextField id="search1" variant="standard"
										key={localstate.searchoptions.searchpairs.searchpair1.uuid + 1}
										defaultValue={localstate.searchoptions.searchpairs.searchpair1.value}
										className={classes.searchinput} InputProps={{ disableUnderline: true }}
										onChange={(event) => onChangeSearchValue(event.target.value, 1)} 
									/>
								}
								{(localstate.searchoptions.searchpairs.searchpair1.type==="DateTimeBefore" || localstate.searchoptions.searchpairs.searchpair1.type==="DateTimeAfter") &&
									<DateTimePicker									
										value={localstate.searchoptions.searchpairs.searchpair1.value}
										onChange={(newvalue) => onChangeSearchValue(newvalue, 1)}
										renderInput={(params) => <TextField 
											{...params} variant="standard" className={classes.searchdatetime} 
											inputProps={{
												...params.inputProps,
												placeholder: "",
												
											}}
										/>}
									/>
								}
								{(searchinputs.show1 && !searchinputs.show2 && localstate.showaddremovesearch) &&
									<div style={{ width: "30px" }}>
										<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
											<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
										</IconButton>
									</div>
								}
							</div>
						}

						{/* Search Pair 2 */}
						{(searchinputs.show2) &&
							<div className={classes.searchinputs} style={{width:"300px"}}>
								<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
									<Select
										key={localstate.searchoptions.searchpairs.searchpair2.uuid}
										value={localstate.searchoptions.searchpairs.searchpair2.type} disableUnderline
										onChange={(event) => onChangeSearchType(event.target.value, 2)}
										MenuProps={{
											style: {zIndex: 2001}
										}}
									>
										<MenuItem value={"Name"}>Name</MenuItem>
										<MenuItem value={"ProductType"}>Product Type</MenuItem>
										<MenuItem value={"CreatedBy"}>Creator</MenuItem>
										<MenuItem value={"UpdatedBy"}>Updated By</MenuItem>
									</Select>
								</FormControl>
								{(localstate.searchoptions.searchpairs.searchpair2.type!=="DateTimeBefore" && localstate.searchoptions.searchpairs.searchpair2.type!=="DateTimeAfter") &&
									<TextField id="search2" variant="standard"
										key={localstate.searchoptions.searchpairs.searchpair2.uuid + 1}
										defaultValue={localstate.searchoptions.searchpairs.searchpair2.value}
										className={classes.searchinput} InputProps={{ disableUnderline: true }}
										onChange={(event) => onChangeSearchValue(event.target.value, 2)} 
									/>
								}
								{(localstate.searchoptions.searchpairs.searchpair2.type==="DateTimeBefore" || localstate.searchoptions.searchpairs.searchpair2.type==="DateTimeAfter") &&
									<DateTimePicker									
										value={localstate.searchoptions.searchpairs.searchpair2.value}
										onChange={(newvalue) => onChangeSearchValue(newvalue, 2)}
										renderInput={(params) => <TextField 
											{...params} variant="standard" className={classes.searchdatetime} 
											inputProps={{
												...params.inputProps,
												placeholder: "",
												
											}}
										 />}
									/>
								}
								{(searchinputs.show2 && !searchinputs.show3 && localstate.showaddremovesearch) &&
									<div style={{ width: "30px" }}>
										<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
											<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
										</IconButton>
									</div>
								}
							</div>
						}

						{/* Search Pair 3 */}
						{(searchinputs.show3) &&
							<div className={classes.searchinputs} style={{width:"300px"}}>
								<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
									<Select
										key={localstate.searchoptions.searchpairs.searchpair3.uuid}
										value={localstate.searchoptions.searchpairs.searchpair3.type} disableUnderline
										onChange={(event) => onChangeSearchType(event.target.value, 3)}
										MenuProps={{
											style: {zIndex: 2001}
										}}
									>
										<MenuItem value={"Name"}>Name</MenuItem>
										<MenuItem value={"ProductType"}>Product Type</MenuItem>
										<MenuItem value={"CreatedBy"}>Creator</MenuItem>
										<MenuItem value={"UpdatedBy"}>Updated By</MenuItem>
									</Select>
								</FormControl>
								{(localstate.searchoptions.searchpairs.searchpair3.type!=="DateTimeBefore" && localstate.searchoptions.searchpairs.searchpair3.type!=="DateTimeAfter") &&
									<TextField id="search3" variant="standard"
										key={localstate.searchoptions.searchpairs.searchpair3.uuid + 1}
										defaultValue={localstate.searchoptions.searchpairs.searchpair3.value}
										className={classes.searchinput} InputProps={{ disableUnderline: true }}
										onChange={(event) => onChangeSearchValue(event.target.value, 3)} 
									/>
								}
								{(localstate.searchoptions.searchpairs.searchpair3.type==="DateTimeBefore" || localstate.searchoptions.searchpairs.searchpair3.type==="DateTimeAfter") &&
									<DateTimePicker									
										value={localstate.searchoptions.searchpairs.searchpair3.value}
										onChange={(newvalue) => onChangeSearchValue(newvalue, 3)}
										renderInput={(params) => <TextField 
											{...params} variant="standard" className={classes.searchdatetime} 
											inputProps={{
												...params.inputProps,
												placeholder: "",
												
											}}
										 />}
									/>
								}
								{(searchinputs.show3 && !searchinputs.show4 && localstate.showaddremovesearch) &&
									<div style={{ width: "30px" }}>
										<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
											<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
										</IconButton>
									</div>
								}
							</div>
						}
					</div>
				}
				
				{/* End of Search Inputs */}


				{/* Top Buttons & Pagination */}
				{(!isPrintView) &&
					<React.Fragment>
						<div style={{ height: "5px" }}>&nbsp;</div>
						<div>
							{(!showdeleteconfirmation && !showexportconfirmation) &&
								<React.Fragment>

									{/* If there is in-line Add-Row in your controller,
										you must have create OR update perms set for Save Button.
										Otherwise, just set it for update alone */}
									<Button
										className={(userPerms.updateProductType === 1 || userPerms.createProductType === 1) ? classes.bluebtn : classes.hidden }
										color="primary" variant="contained"
										onClick={() => SaveChanges()}
										ref={el => btnSave.current = el}>
										<SaveIcon sx={{color:"lightgray"}}></SaveIcon>&nbsp;Save Changes
									</Button>

								
									<Button
										className={(userPerms.updateProductType === 1 || userPerms.createProductType === 1) ? classes.bluebtn : classes.hidden }
										color="primary" variant="contained"
										style={{display:"none"}}
										onClick={() => SaveChanges()}
										ref={el => btnPendingSave.current = el}>
										<PendingIcon sx={{color:"orange"}}></PendingIcon>&nbsp;Save Changes
									</Button>
									

									<Button
										className={(userPerms.createProductType === 1) ? classes.bluebtn : classes.hidden }
										color="primary" variant="contained"
										onClick={() => AddRow()}
										ref={el => btnAddRow.current = el}>
										Add Product Type
									</Button>

									<Button
										className={(userPerms.deleteProductType === 1) ? classes.bluebtn : classes.hidden }
										color="primary" variant="contained"
										onClick={() => RejectIfInvalidSelected("",DeleteSelectedInit)}
										ref={el => btnDeleteSelected.current = el}>
										Delete Selected
									</Button>
									
									

									{/* ##########################  Column Toggles  ########################## */}
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										aria-haspopup="true"
										onClick={ShowColumnMenu}>
										Columns
									</Button>

									<Menu
										className={classes.bluebtn}
										color="primary"
										id="simple-menu"
										anchorEl={showcolumnmenu}
										keepMounted
										open={Boolean(showcolumnmenu)}
										onClose={CloseColumnMenu}
									>
										<MenuItem disableRipple className={classes.columnmenu}>
											<div style={{verticalAlign:"top"}}> {/* Optional container for 2 column menu! */}
												<div style={{display:"inline-block", maxHeight:"600px", overflow:"auto", verticalAlign:"top"}}>
													<FormGroup>
														<FlexColumnOption value="Name" label="Name"/>
														<FlexColumnOption value="ProductType" label="Product Type"/>
														<FlexColumnOption value="created_at" label="Creation Date"/>
														<FlexColumnOption value="CreatedBy" label="Creator"/>
														<FlexColumnOption value="UpdatedBy" label="Updated By"/>
														<FlexColumnOption value="updated_at" label="Update Date"/>
													</FormGroup>
												</div>
											</div>
										</MenuItem>
									</Menu>

									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => ResetSearches()}
										ref={el => btnResetSearches.current = el}>
										Reset Searches
									</Button>
								
								
									<Button
										className={(userPerms.updateProductType === 1) ? classes.bluebtn : classes.hidden }
										color="primary" variant="contained"
										{...(editmode ? {
											style: {
												textDecoration: "underline"
											}
										} :
											{
												style: {
													textDecoration: "none"
												},
												disabled: (localstate.rowsperpage > 100 ? true : false)
											})}
										onClick={() => EditMode()}
										ref={el => btnEditMode.current = el}>
										Edit Mode
									</Button>
									
									

								</React.Fragment>
							}

							{/* Delete Items Confirmation */}
							{(showdeleteconfirmation) &&
								<div>
									<b>Are you sure you want to delete these product types?</b>
									<div style={{ padding: "10px 0px" }}>
										{deleteitems.map((row, index) => {
											if (deleteitems.length === index + 1) {
												return (<span key={index}>{row.Name}</span>)
											} else {
												return (<span key={index}>{row.Name}, </span>)
											}
										})}
									</div>
									<Button className={classes.bluebtn}
										color="primary" variant="contained" onClick={() => DeleteSelected()}>Yes, Delete Product Types</Button>&nbsp;&nbsp;
									<Button className={classes.bluebtn}
										color="primary" variant="contained" onClick={() => CancelDelete()}>Cancel</Button>
								</div>
							}

							{/* Export Mode Confirmation */}
							{(showexportconfirmation) &&
								<div>
									<b>Export Mode</b><br></br>
									In most cases a simple export is appropriate as to avoid revealing information to potential buyers. {exportmessage}
									<div style={{ paddingTop: "10px" }}>
										<Button
											className={classes.bluebtn}
											color="primary" variant="contained"
											onClick={() => PrepareExport("simple")}>
											Simple
										</Button>
										<Button
											className={classes.bluebtn}
											color="primary" variant="contained"
											onClick={() => PrepareExport("expanded")}>
											Expanded
										</Button>
										<Button
											className={classes.bluebtn}
											color="primary" variant="contained"
											onClick={() => PrepareExport("exhaustive")}>
											Exhaustive
										</Button>
										<Button
											className={classes.bluebtn}
											color="primary" variant="contained"
											onClick={() => setShowExportConfirmation(false)}>
											Cancel
										</Button>
									</div>
								</div>
							}



							{(localstate.totalitems > 0) &&
								<TablePagination className={classes.paginationalign}
									SelectProps={
										(editmode && localstate.rowsperpage>=100) ? { disabled: true } : { disabled: false }
									}
									style={{ display: "inline-flex", float: "right" }}
									component="div"
									count={localstate.totalitems}
									page={localstate.page}
									onPageChange={handleChangePage}
									rowsPerPage={localstate.rowsperpage}
									onRowsPerPageChange={handleChangeRowsPerPage}
									rowsPerPageOptions={[100, 500]}
								/>
							}
						</div>
					</React.Fragment>
				}
				{/* End of Top Buttons & Pagination */}


				{/* Add container for overflow scroll bars - Allows us to set a min width for the page (better looking in many cases), and freeze controls at the top and bottom for ease of use. */}
				<div className={classes.flexgridcontainerA}>
					{/* ##########################  Start of Table  ########################## */}
					<table id="resizeMe" aria-label="caption table" size="small" className={classes.flexgrid} style={{minWidth:"100%", borderCollapse:"collapse", borderColor:"grey"}}>
						<EnhancedTableHead
							numSelected={localstate.selectedcount} 
							classes={classes}
							order={localstate.order}
							orderBy={localstate.orderby}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={state.griditems.length}
						/>
						{/* /* ##########################  Row Design  ########################## */	 }
						{/* If DB reload, don't allow view of table. */}
						{(!localstate.dbreload) &&
							<tbody style={{ display: "table-row-group" }}>
								{(localstate.griditems.length > 0) &&
									localstate.griditems.map((row, index) => {
										//Create all-new refs on each render. Helps avoid issues with grid states.
										rowRefs.current[index + "Checkbox"] = React.createRef();
										rowRefs.current[index + "SaveStatus"] = React.createRef();
										rowRefs.current[index + "Name"] = React.createRef();
										rowRefs.current[index + "ProductType"] = React.createRef();
										return (
											<React.Fragment key={row.ID}>
												<tr	className={classes.flexgridrow}>
													{/* Checkbox - Requires inner div to change background color with SaveStatus */}
													<td style={{ verticalAlign: "top" }} ref={el => rowRefs.current[index + "SaveStatus"] = el}>
														<div style={{ padding: "3px 4px 1px 4px" }}>
															{/*	MaterialUI Checkbox will pass a shallow comparison between UpdateState(s). Be sure GridKey changes if it needs to be rerendered such as selectall.	*/}
															{/* Don't show checkbox if this is a PendingItem */}
															{(!row.PendingItem) &&
																<Checkbox
																	key={row.GridKey}
																	inputRef={el => rowRefs.current[index + "Checkbox"] = el}
																	className={classes.gridcheckbox}
																	color="default"
																	defaultChecked={localstate.griditems[index].isSelected ? true : false}
																	checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onKeyDown={(event) => HandleKeyDown(event, index, "Checkbox")}
																	onChange={() => SelectRow(index)}
																/>
															}

														</div>
													</td>

													{/* UserPerms Note: If the user can't use the EditMode button nor API calls to edit items, we can simply count on editmode not being accessible while ignoring the given updateX userPerm */}

													{/* Name */}
													{((editmode && colstate.Name) || (colstate.Name && row.PendingItem && userPerms.createProductType)) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Name'] = el}
																className={classes.flexgridinput30}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Name')}
																onKeyUp={(event) => onChangeValue(event, index, 'Name')}
																defaultValue={localstate.defaultvalues[index].Name} />
														</td>
													}

													{(!editmode && colstate.Name && !row.PendingItem) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Name}
														</td>
													}
													

													{/* ProductType */}
													{((editmode && colstate.ProductType) || (colstate.ProductType && row.PendingItem && userPerms.createProductType)) &&

														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'ProductType'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'ProductType')}
																onKeyUp={(event) => onChangeValue(event, index, 'ProductType')}
																defaultValue={localstate.defaultvalues[index].ProductType} />
														</td>
													}

													{(!editmode && colstate.ProductType && !row.PendingItem) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.ProductType}
														</td>
													}




													{/* Creation Date */}
													{(colstate.created_at) &&
														<td className={classes.flexgridstaticcontainer}>
															<Moment element='span' format="MMMM D, YYYY [at] h:mma">{row.created_at}</Moment><br></br>
														</td>
													}


													{/* CreatedBy */}
													{(colstate.CreatedBy) &&
														<React.Fragment>
															{(editmode && colstate.CreatedBy) &&
																<td className={classes.flexgridinputcontainer}>
																	<input
																		ref={el => rowRefs.current[index + 'CreatedBy'] = el}
																		className={classes.flexgridinput}
																		style={{ minWidth: '50px', textAlign: "left" }}
																		onKeyDown={(event) => HandleKeyDown(event, index, 'CreatedBy')}
																		onKeyUp={(event) => onChangeValue(event, index, 'CreatedBy')}
																		defaultValue={localstate.defaultvalues[index].CreatedBy} />
																</td>
															}

															{(!editmode && colstate.CreatedBy) &&
																<td className={classes.flexgridstaticcontainer}>
																	{row.CreatedBy}
																</td>
															}
														</React.Fragment>
													}


												

													{/* Update Date */}
													{(colstate.updated_at) &&
														<td className={classes.flexgridstaticcontainer}>
															<Moment element='span' format="MMMM D, YYYY [at] h:mma">{row.updated_at}</Moment><br></br>
														</td>
													}


													{/* Updated By */}
													{(colstate.UpdatedBy) &&
														<React.Fragment>
															{(editmode && colstate.UpdatedBy) &&
																<td className={classes.flexgridinputcontainer}>
																	<input
																		ref={el => rowRefs.current[index + 'UpdatedBy'] = el}
																		className={classes.flexgridinput}
																		style={{ minWidth: '50px', textAlign: "left" }}
																		onKeyDown={(event) => HandleKeyDown(event, index, 'UpdatedBy')}
																		onKeyUp={(event) => onChangeValue(event, index, 'UpdatedBy')}
																		defaultValue={localstate.defaultvalues[index].UpdatedBy} />
																</td>
															}

															{(!editmode && colstate.UpdatedBy) &&
																<td className={classes.flexgridstaticcontainer}>
																	{row.UpdatedBy}
																</td>
															}
														</React.Fragment>
													}

												</tr>
											</React.Fragment>

										)
									}
									)
								}
								{(localstate.griditems.length === 0) &&
									<tr className="flexgridrow"><td colSpan="100%"
										style={{ padding: "12px", fontSize: "18px" }}>No Results</td></tr>
								}
							</tbody>
						}
						{(localstate.dbreload) &&
							<tbody>
								<tr>
									<td colSpan="100%">
										<div style={{padding:"20px", textAlign:"center", margin:"auto"}}>
											<CircularProgress />
										</div>
									</td>
								</tr>
							</tbody>
						}
					</table> 
				</div>
				
				
				{(localstate.totalitems>localstate.rowsperpage) &&
					<TablePagination className={classes.paginationalign}
						SelectProps={
							(editmode && localstate.rowsperpage>=100) ? { disabled: true } : { disabled: false }
						}
						component="div"
						count={localstate.totalitems}
						page={localstate.page}
						onPageChange={handleChangePage}
						rowsPerPage={localstate.rowsperpage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[100,500]}
					/>
				}
			</div>
		</LocalizationProvider>
    );
}

export default ProductTypesTable;
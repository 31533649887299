const ExportCSV = (data, filename) =>{
	var jsondata = typeof data != 'object' ? JSON.parse(data) : data;
	var CSV = '';
	var keys = Object.keys(jsondata[0]);
	//Headers
	var i;
	var row='';
	for (var i=0; i<keys.length; i++){
		row += keys[i]+',';
	}
	CSV += row;
	CSV += '\r\n';
	//Rows
	for (i=0; i<data.length; i++){
		var row = "";
		for (var j in data[i]) {
			var arrValue = data[i][j] == null ? "" : '"' + data[i][j] + '"';
			row += arrValue + ',';
		}
		row.slice(0, row.length - 1);
		CSV += row + '\r\n';
	}

	//If first header column is ID, opening in Excel will complain about it being an SYLK file.
	console.log(CSV.indexOf("ID,"));
	if (CSV.indexOf("ID,")===0){
		CSV = CSV.replace("ID,", "UniqueID,");
	}

	//Download
	var uri = 'data:application/csv;charset=utf-8,' + escape(CSV);
	var link = document.createElement("a");
	link.href = uri;
	link.style = "visibility:hidden";
	link.download = filename + ".csv";
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

export default ExportCSV;


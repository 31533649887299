import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	selectMainMenu,
	toggleCurrentMenuSelection,
	setCurrentMenuItem
} from './mainmenuSlice';

import {useHistory} from "react-router-dom";

import { AppContext } from "../../Components/Auth/contexts/AppContext"

import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';


//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';

	
export function MainMenu() {
	let history = useHistory();
	const classes = useClasses(flexstyles);
	//const count = useSelector(selectCount);
	const dispatch = useDispatch();
	const menustate = useSelector(selectMainMenu);
	//console.log(menustate);


	const appContext = useContext(AppContext);
	const { userUserName, userRole, userPerms } = appContext;

	
	const handleMenuItemClick = (menuitem) => {
		//Handle Home
		if (menuitem === "/"){
			dispatch(setCurrentMenuItem(false));
			dispatch(toggleCurrentMenuSelection(false));
		} else {
			dispatch(setCurrentMenuItem(menuitem));
		}
		history.push(menuitem);
	}


	return (
		<Drawer
		variant="permanent"
		sx={{
			width:"180px",
			flexShrink: 0,
		  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: "180px", border:"0px", backgroundColor:"#707070" },
		}}
		>	
			
			<div className={classes.toolbarspacer} />
			{/* <div className={classes.toolbarspacer}>{menustate.currentsection}</div> */}
			<Box sx={{ overflow: 'auto' }}>
				<List>
					{/* ========== Home ========== */}
					<ListItem key="Home" disablePadding>
						<ListItemButton onClick={()=>handleMenuItemClick("/")} className={classes.inactivesection}>
							<ListItemText primary="Home" />
						</ListItemButton>
					</ListItem>



					{/* ========== Orders Section ========== */}
					<ListItem key="Orders" disablePadding>
						<ListItemButton onClick={()=>dispatch(toggleCurrentMenuSelection("Orders"))} className={(menustate.currentsection==="Orders") ? classes.activesection : classes.inactivesection}>
							<ListItemText primary="Orders" />
							{menustate.currentsection==="Orders" ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
					</ListItem>
					{/* ========== Orders Items ========== */}
					<Collapse in={menustate.currentsection==="Orders"} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							<ListItem key="Orders" disablePadding>
								<ListItemButton onClick={()=>handleMenuItemClick("/orders")}  className={(menustate.currentitem==="/orders") ? classes.activemenuitem : classes.inactivemenuitem}>
									<ListItemText primary="Orders" />
								</ListItemButton>
							</ListItem>
							{(userPerms.readOrder === 1) &&
								<ListItem key="OrdersTable" disablePadding>
									<ListItemButton onClick={() => handleMenuItemClick("/orderstable")} className={(menustate.currentitem === "/orderstable") ? classes.activemenuitem : classes.inactivemenuitem}>
										<ListItemText primary="Orders (BETA)" />
									</ListItemButton>
								</ListItem>
							}
							<ListItem key="Order Processing" disablePadding>
								<ListItemButton onClick={()=>handleMenuItemClick("/orderprocessing")}  className={(menustate.currentitem==="/orderprocessing") ? classes.activemenuitem : classes.inactivemenuitem}>
									<ListItemText primary="Order Processing" />
								</ListItemButton>
							</ListItem>
							{(userPerms.readInspection === 1) &&
								<ListItem key="OrderInspection" disablePadding>
									<ListItemButton onClick={() => handleMenuItemClick("/orderinspection")} className={(menustate.currentitem === "/orderinspection") ? classes.activemenuitem : classes.inactivemenuitem}>
										<ListItemText primary="Order Inspection (BETA)" />
									</ListItemButton>
								</ListItem>
							}
							<ListItem key="Order Manifests" disablePadding>
								<ListItemButton onClick={()=>handleMenuItemClick("/ordermanifests")}  className={(menustate.currentitem==="/ordermanifests") ? classes.activemenuitem : classes.inactivemenuitem}>
									<ListItemText primary="Order Manifests" />
								</ListItemButton>
							</ListItem>
						</List>
					</Collapse>



					{/* ========== Inventory Section ========== */}
					<ListItem key="Inventory" disablePadding>
						<ListItemButton onClick={()=>dispatch(toggleCurrentMenuSelection("Inventory"))} className={(menustate.currentsection==="Inventory") ? classes.activesection : classes.inactivesection}>
							<ListItemText primary="Inventory" />
							{menustate.currentsection==="Inventory" ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
					</ListItem>
					{/* ========== Inventory Items ========== */}
					<Collapse in={menustate.currentsection==="Inventory"} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							<ListItem key="Inventory Mananger" disablePadding>
								<ListItemButton onClick={()=>handleMenuItemClick("/inventorymanager")}  className={(menustate.currentitem==="/inventorymanager") ? classes.activemenuitem : classes.inactivemenuitem}>
									<ListItemText primary="Inventory Manager" />
								</ListItemButton>
							</ListItem>
							<ListItem key="Checkin" disablePadding>
								<ListItemButton onClick={()=>handleMenuItemClick("/checkin")}  className={(menustate.currentitem==="/checkin") ? classes.activemenuitem : classes.inactivemenuitem}>
									<ListItemText primary="Checkin" />
								</ListItemButton>
							</ListItem>
							<ListItem key="Checkout" disablePadding>
								<ListItemButton onClick={()=>handleMenuItemClick("/checkout")}  className={(menustate.currentitem==="/checkout") ? classes.activemenuitem : classes.inactivemenuitem}>
									<ListItemText primary="Checkout" />
								</ListItemButton>
							</ListItem>
							{(userPerms.bulkCostItem===1) &&
								<ListItem key="Costing" disablePadding>
									<ListItemButton onClick={()=>handleMenuItemClick("/pricing")}  className={(menustate.currentitem==="/pricing") ? classes.activemenuitem : classes.inactivemenuitem}>
										<ListItemText primary="Costing" />
									</ListItemButton>
								</ListItem>
							}
							{/* <ListItem key="Listings" disablePadding>
								<ListItemButton onClick={()=>handleMenuItemClick("/listings")}  className={(menustate.currentitem==="/listings") ? classes.activemenuitem : classes.inactivemenuitem}>
									<ListItemText primary="Listings" />
								</ListItemButton>
							</ListItem> */}
							<ListItem key="Price Calculator" disablePadding>
								<ListItemButton onClick={()=>handleMenuItemClick("/pricecalculator")}  className={(menustate.currentitem==="/pricecalculator") ? classes.activemenuitem : classes.inactivemenuitem}>
									<ListItemText primary="Price Calculator" />
								</ListItemButton>
							</ListItem>
						</List>
					</Collapse>



					{/* ========== Products Section ========== */}
					<ListItem key="Products" disablePadding>
						<ListItemButton onClick={()=>dispatch(toggleCurrentMenuSelection("Products"))} className={(menustate.currentsection==="Products") ? classes.activesection : classes.inactivesection}>
							<ListItemText primary="Products" />
							{menustate.currentsection==="Products" ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
					</ListItem>
					{/* ========== Products Items ========== */}
					<Collapse in={menustate.currentsection==="Products"} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							<ListItem key="/products" disablePadding>
								<ListItemButton onClick={()=>handleMenuItemClick("/products")}  className={(menustate.currentitem==="/products") ? classes.activemenuitem : classes.inactivemenuitem}>
									<ListItemText primary="Products" />
								</ListItemButton>
							</ListItem>
							<ListItem key="Components" disablePadding>
								<ListItemButton onClick={()=>handleMenuItemClick("/components")}  className={(menustate.currentitem==="/components") ? classes.activemenuitem : classes.inactivemenuitem}>
									<ListItemText primary="Components" />
								</ListItemButton>
							</ListItem>
							<ListItem key="Skus" disablePadding>
								<ListItemButton onClick={()=>handleMenuItemClick("/skus")}  className={(menustate.currentitem==="/skus") ? classes.activemenuitem : classes.inactivemenuitem}>
									<ListItemText primary="Skus" />
								</ListItemButton>
							</ListItem>
							{(userPerms.readSkuIntegration === 1) &&
								<ListItem key="Sku Integrations" disablePadding>
									<ListItemButton onClick={()=>handleMenuItemClick("/skuintegrations")}  className={(menustate.currentitem==="/skuintegrations") ? classes.activemenuitem : classes.inactivemenuitem}>
										<ListItemText primary="Sku Integrations" />
									</ListItemButton>
								</ListItem>
							}
						</List>
					</Collapse>



					
					{/* ========== Hardware Agent Section ========== */}
					<ListItem key="Hardware Agent" disablePadding>
						<ListItemButton onClick={()=>dispatch(toggleCurrentMenuSelection("Hardware Agent"))} className={(menustate.currentsection==="Hardware Agent") ? classes.activesection : classes.inactivesection}>
							<ListItemText primary="Hardware Agent" />
							{menustate.currentsection==="Hardware Agent" ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
					</ListItem>
					{/* ========== Hardware Agent Items ========== */}
					<Collapse in={menustate.currentsection==="Hardware Agent"} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							<ListItem key="Computer Specs" disablePadding>
								<ListItemButton onClick={()=>handleMenuItemClick("/specs")}  className={(menustate.currentitem==="/specs") ? classes.activemenuitem : classes.inactivemenuitem}>
									<ListItemText primary="Computer Specs" />
								</ListItemButton>
							</ListItem>
							<ListItem key="Spec Alias" disablePadding>
								<ListItemButton onClick={()=>handleMenuItemClick("/specalias")}  className={(menustate.currentitem==="/specalias") ? classes.activemenuitem : classes.inactivemenuitem}>
									<ListItemText primary="Spec Alias" />
								</ListItemButton>
							</ListItem>
							<ListItem key="Battery Test" disablePadding>
								<ListItemButton onClick={()=>handleMenuItemClick("/batterytest")}  className={(menustate.currentitem==="/batterytest") ? classes.activemenuitem : classes.inactivemenuitem}>
									<ListItemText primary="Battery Test" />
								</ListItemButton>
							</ListItem>
                            {(userPerms.readHDDSpec === 1) &&
                                <ListItem key="HDD Specs" disablePadding>
                                    <ListItemButton onClick={() => handleMenuItemClick("/hddspecs")} className={(menustate.currentitem === "/hddspecs") ? classes.activemenuitem : classes.inactivemenuitem}>
                                        <ListItemText primary="HDD Specs" />
                                    </ListItemButton>
                                </ListItem>
                            }
						</List>
					</Collapse>



					{/* ========== Reports Section ========== */}
					<ListItem key="Reports" disablePadding>
						<ListItemButton onClick={()=>dispatch(toggleCurrentMenuSelection("Reports"))} className={(menustate.currentsection==="Reports") ? classes.activesection : classes.inactivesection}>
							<ListItemText primary="Reports" />
							{menustate.currentsection==="Reports" ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
					</ListItem>
					{/* ========== Reports Items ========== */}
					<Collapse in={menustate.currentsection==="Reports"} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							<ListItem key="Inventory Reports" disablePadding>
								<ListItemButton onClick={()=>handleMenuItemClick("/reports")}  className={(menustate.currentitem==="/reports") ? classes.activemenuitem : classes.inactivemenuitem}>
									<ListItemText primary="Inventory Reports" />
								</ListItemButton>
							</ListItem>
							<ListItem key="Sales Graph" disablePadding>
								<ListItemButton onClick={()=>handleMenuItemClick("/salesgraph")}  className={(menustate.currentitem==="/salesgraph") ? classes.activemenuitem : classes.inactivemenuitem}>
									<ListItemText primary="Sales Graph" />
								</ListItemButton>
							</ListItem>
							<ListItem key="Top Products" disablePadding>
								<ListItemButton onClick={()=>handleMenuItemClick("/topproducts")}  className={(menustate.currentitem==="/topproducts") ? classes.activemenuitem : classes.inactivemenuitem}>
									<ListItemText primary="Top Products" />
								</ListItemButton>
							</ListItem>
							<ListItem key="Product Performance" disablePadding>
								<ListItemButton onClick={()=>handleMenuItemClick("/productperformance")}  className={(menustate.currentitem==="/productperformance") ? classes.activemenuitem : classes.inactivemenuitem}>
									<ListItemText primary="Product Performance" />
								</ListItemButton>
							</ListItem>
						</List>
					</Collapse>



					{/* ========== Admin Settings Section ========== */}
					{(userRole === "admin") &&
						<React.Fragment>
							<ListItem key="Admin Settings" disablePadding>
								<ListItemButton onClick={() => dispatch(toggleCurrentMenuSelection("Admin Settings"))} className={(menustate.currentsection === "Admin Settings") ? classes.activesection : classes.inactivesection}>
									<ListItemText primary="Admin Settings" />
									{menustate.currentsection === "Admin Settings" ? <ExpandLess /> : <ExpandMore />}
								</ListItemButton>
							</ListItem>
							{/* ========== Admin Settings Items ========== */}
							<Collapse in={menustate.currentsection === "Admin Settings"} timeout="auto" unmountOnExit>
								<List component="div" disablePadding>
									<ListItem key="Users" disablePadding>
										<ListItemButton onClick={() => handleMenuItemClick("/users")} className={(menustate.currentitem === "/users") ? classes.activemenuitem : classes.inactivemenuitem}>
											<ListItemText primary="Users" />
										</ListItemButton>
									</ListItem>
									<ListItem key="Location Refs" disablePadding>
										<ListItemButton onClick={() => handleMenuItemClick("/locations")} className={(menustate.currentitem === "/locations") ? classes.activemenuitem : classes.inactivemenuitem}>
											<ListItemText primary="Location Refs" />
										</ListItemButton>
									</ListItem>
									<ListItem key="Client Access" disablePadding>
										<ListItemButton onClick={() => handleMenuItemClick("/clientaccess")} className={(menustate.currentitem === "/clientaccess") ? classes.activemenuitem : classes.inactivemenuitem}>
											<ListItemText primary="Client Access" />
										</ListItemButton>
									</ListItem>
									<ListItem key="Market Integrations" disablePadding>
										<ListItemButton onClick={() => handleMenuItemClick("/integrations")} className={(menustate.currentitem === "/integrations") ? classes.activemenuitem : classes.inactivemenuitem}>
											<ListItemText primary="Market Integrations" />
										</ListItemButton>
									</ListItem>
									<ListItem key="Item Programs" disablePadding>
										<ListItemButton onClick={() => handleMenuItemClick("/itemprograms")} className={(menustate.currentitem === "/itemprograms") ? classes.activemenuitem : classes.inactivemenuitem}>
											<ListItemText primary="Item Programs" />
										</ListItemButton>
									</ListItem>
									<ListItem key="Logs" disablePadding>
										<ListItemButton onClick={() => handleMenuItemClick("/logs")} className={(menustate.currentitem === "/logs") ? classes.activemenuitem : classes.inactivemenuitem}>
											<ListItemText primary="Logs" />
										</ListItemButton>
									</ListItem>
								</List>
							</Collapse>
						</React.Fragment>
					}




					{/* ========== Martin's Section ========== */}
					{(userUserName === 'martin') && 
						<React.Fragment>
							<ListItem key="Martin's Section" disablePadding>
								<ListItemButton onClick={()=>dispatch(toggleCurrentMenuSelection("Martin's Section"))} className={(menustate.currentsection==="Martin's Section") ? classes.activesection : classes.inactivesection}>
									<ListItemText primary="Martin's Section" />
									{menustate.currentsection==="Martin's Section" ? <ExpandLess /> : <ExpandMore />}
								</ListItemButton>
							</ListItem>
							{/* ========== Martin's Section Items ========== */}
							<Collapse in={menustate.currentsection==="Martin's Section"} timeout="auto" unmountOnExit>
								<List component="div" disablePadding>
									<ListItem key="Purchasing" disablePadding>
										<ListItemButton onClick={() => handleMenuItemClick("/purchasing")} className={(menustate.currentitem === "/purchasing") ? classes.activemenuitem : classes.inactivemenuitem}>
											<ListItemText primary="Purchasing" />
										</ListItemButton>
									</ListItem>
									<ListItem key="Boilerplate Table" disablePadding>
										<ListItemButton onClick={()=>handleMenuItemClick("/boilerplatetable")}  className={(menustate.currentitem==="/boilerplatetable") ? classes.activemenuitem : classes.inactivemenuitem}>
											<ListItemText primary="Boilerplate Table" />
										</ListItemButton>
									</ListItem>
									<ListItem key="Boilerplate KeyValue" disablePadding>
										<ListItemButton onClick={()=>handleMenuItemClick("/boilerplatekeyvalues")}  className={(menustate.currentitem==="/boilerplatekeyvalues") ? classes.activemenuitem : classes.inactivemenuitem}>
											<ListItemText primary="Boilerplate KeyValue" />
										</ListItemButton>
									</ListItem>
									<ListItem key="Chart Builder" disablePadding>
										<ListItemButton onClick={()=>handleMenuItemClick("/chartbuilder")}  className={(menustate.currentitem==="/chartbuilder") ? classes.activemenuitem : classes.inactivemenuitem}>
											<ListItemText primary="Chart Builder" />
										</ListItemButton>
									</ListItem>
									<ListItem key="Toybox" disablePadding>
										<ListItemButton onClick={()=>handleMenuItemClick("/toybox")}  className={(menustate.currentitem==="/toybox") ? classes.activemenuitem : classes.inactivemenuitem}>
											<ListItemText primary="Toybox" />
										</ListItemButton>
									</ListItem>									
								</List>
							</Collapse>
						</React.Fragment>
					}
					

				</List>
				<Divider />
			</Box>
		</Drawer>
	);
}

//React & Friends
import React, { useState, useEffect, useContext, useRef } from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import PropTypes from 'prop-types';

//Redux Features
import { useSelector, useDispatch } from 'react-redux';
//ProgressBar
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import { ProgressBar } from '../../features/progressbar/ProgressBar';
import {
	newProgress,
	incrementPass,
	incrementFail,
	setProgressTimeout
  } from '../../features/progressbar/progressbarSlice';
//Main Menu
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//Contexts
//AuthContext
import { AppContext } from "../Auth/contexts/AppContext"
//Error Context - warning, danger, ok, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';


//Datetime formatting
import Moment from 'react-moment';

//Restrict Numbers both float and integer types
import RestrictInputNumber from "../common/RestrictInputNumber";

//MaterialUI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';

//Tables
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';

//Icons
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PreviewIcon from '@mui/icons-material/Preview';

//Datetime Pickers
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

//Export
import ExportCSV from '../common/ExportCSV';

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;

function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

const ClientAccess = (props) => {
	document.title="Client Access";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Admin Settings"));
	dispatch(setCurrentMenuItem("/clientaccess"));
	const rowRefs = useRef([]);
	const btnSave = useRef();
	const btnPendingSave = useRef();

	/* ##########################  UseState Variables  ########################## */	  
	const classes = useClasses(flexstyles);

	const [state, setState] = useState({
		dbreload:true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		griditems:[],		//Defaults
		totalitems:0,
		page:0, //Assume page 0, or else pagination throws and error.
		order:'asc',
		orderby:'AppName',
		selectedcount:0,
		rowsperpage:10,
		selectedindexes:[],
		pendingsaves:false, //Used for parent view - Warnings about unsaved items!
		searchoptions:{
			searchpairs:{
				searchpair1:{type:"AppName", value: "", mode:"like", uuid:uuidv4()},
				searchpair2:{type:"", value: "", mode:"like", uuid:uuidv4()},
				searchpair3:{type:"", value: "", mode:"left", uuid:uuidv4()},
				searchpair4:{type:"", value: "", mode:"right", uuid:uuidv4()},
				searchpair5:{type:"", value: "", mode:"like", uuid:uuidv4()},
				searchpair6:{type:"", value: "", mode:"like", uuid:uuidv4()}
				}
			}
		}
	);

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);
	
	function UpdateState(stateobject) {
		setState(stateobject);
	}


	/* ##########################  Selected Rows  ########################## */
	const SelectRow = (index, row) => {
		if (row.isSelected){
			handleSelectSingle(index, false);
		} else {
			handleSelectSingle(index, true);
		}
	}

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			for (var i=0; i<localstate.griditems.length; i++){localstate.griditems[i].isSelected = true;}
			localstate.selectedindexes = localstate.griditems.map((n, index) => index);
			localstate.selectedcount = localstate.griditems.length;
			UpdateState(localstate);
		} else {
			for (var i=0; i<localstate.griditems.length; i++){localstate.griditems[i].isSelected = false;}
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
			UpdateState(localstate);
		}
	};

	const handleSelectSingle = (index, selectbool) => {
		if (selectbool){
			localstate.griditems[index].isSelected = true;
			localstate.selectedindexes.push(index);
			localstate.selectedcount = localstate.selectedindexes.length;
			UpdateState(localstate);
		} else {
			localstate.griditems[index].isSelected = false;
			localstate.selectedindexes.splice(index,1);
			localstate.selectedcount = localstate.selectedindexes.length;
			UpdateState(localstate);
		}
	}



	/* ##########################  Search Options  ########################## */
	const [opennameoptions, setOpenNameOptions] = React.useState(false);
	const [nameoptions, setNameOptions] = React.useState([]);
	const loadingnameoptions = opennameoptions && nameoptions.length === 0;

	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};


	//Set Search Key:
	const onChangeSearchType = (searchtype, searchpair) => {
		switch (searchpair) {
			case 'searchpair1':
				localstate.searchoptions.searchpairs.searchpair1.type = searchtype;
				break;
			case 'searchpair2':
				localstate.searchoptions.searchpairs.searchpair2.type = searchtype;
				break;
			case 'searchpair3':
				localstate.searchoptions.searchpairs.searchpair3.type = searchtype;
				break;
			case 'searchpair4':
				localstate.searchoptions.searchpairs.searchpair4.type = searchtype;
				break;
			default:
				break;
		}
		UpdateState(localstate);
	}

	//Set Search Value:
	const onChangeSearchValue = debounce(function(searchvalue, searchpair) {
		switch (searchpair) {
			case 'searchpair1':
				localstate.searchoptions.searchpairs.searchpair1.value = searchvalue;
				break;
			case 'searchpair2':
				localstate.searchoptions.searchpairs.searchpair2.value = searchvalue;
				break;
			case 'searchpair3':
				localstate.searchoptions.searchpairs.searchpair3.value = searchvalue;
				break;
			case 'searchpair4':
				localstate.searchoptions.searchpairs.searchpair4.value = searchvalue;
				break;
			default:
				break;
		}
		localstate.dbreload = true;
		UpdateState(localstate);
	},500);

	//Key-Value Inputs
	//const showsearch1 = useRef(true);
	//const showsearch2 = useRef(false);
	//const showsearch3 = useRef(false);

	//Try! UseState.
	const [showsearch1, setShowSearch1] = useState(true);
	const [showsearch2, setShowSearch2] = useState(false);
	const [showsearch3, setShowSearch3] = useState(false);
	const [showsearch4, setShowSearch4] = useState(false);
	const [lastsearch, setLastSearch] = useState('showsearch1');


	
	/* ##########################  Loading and Page Changes  ########################## */
	const handleRequestSort = (event, property) => {
		const isAsc = localstate.orderby === property && localstate.order === "asc";		
		localstate.order = (isAsc ? "desc" : "asc");
		localstate.orderby=property;
		localstate.dbreload=true;
		localstate.pendingsaves = false;
		UpdateState(localstate);
	};

	const handleChangePage = (event, newPage) => {
		localstate.pendingsaves = false;
		localstate.dbreload = true;
		localstate.page = newPage;
		UpdateState(localstate);		
	};

	const handleChangeRowsPerPage = (event) => {
		localstate.pendingsaves = false;
		localstate.dbreload = true;
		localstate.rowsperpage = parseInt(event.target.value, 10);
		localstate.page=0;
		UpdateState(localstate);
	};


	let history = useHistory();
	//Error Context
	const errors = useContext(ErrorContext);

	//Load Items
	function LoadItems(){  
		localstate.selectedindexes = [];
		localstate.selectedcount=0;
		const postdata = {
			searchoptions:{
				limit:localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				searchpairs:localstate.searchoptions.searchpairs
			}
		};
		var postoptions = {
			withCredentials:true,
			withXSRFToken: true,
			crossDomain:true,
			mode:'no-cors',
			timeout:11800,
		};
		
		axios.post(dbendpoint+"/hardwareagent/clientaccesslist?page="+(localstate.page+1), postdata, postoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status==200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==='login'){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status=='Success'){
					//We should now have a non-0 result from the API
					//Add variables for use with table
					for (var i =0; i<res.data.pagedata.data.length; i++){
						res.data.pagedata.data[i].isSelected = false;
						res.data.pagedata.data[i].unsaved = false;
					}
					localstate.griditems = res.data.pagedata.data;
					localstate.totalitems = res.data.pagedata.total;
					//Data freshly loaded, head off any new requests with this state change. Handle in useEffect?
					//localstate.dbreload = false;
					UpdateState(localstate);
				}
				if (res.data.Status==='Failure'){
					//Failure error
					localstate.griditems=[];
					UpdateState(localstate);
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:'neutral'})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: 'warning' })
			}
		});
	}

	useEffect(() => {
		document.title = "Client App Access";
		if (state.dbreload) {
			//Avoid duplicate loades.
			localstate.dbreload = false;
			LoadItems();
		} else {
			//console.log("Ignore DB Reload.");
		}
	},);



	/* ##########################  CRUD  ########################## */

	//New Row adds property 'PendingItem' for use in the API to add such rows.
	const AddRow = () => {
		localstate.griditems.unshift({
			id: uuidv4(),
			AppName: '',
			AppKey: uuidv4(),
			IP: "0.0.0.0",
			PendingItem: true
		});
		//All selected indexes move up by 1.
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			localstate.selectedindexes[i] += 1;
		}
		UpdateState(localstate);
	}

	const SaveChanges = () => {
		//Clean up current errors:
		errors.HideError(errors);
		
		var updatearray = [];
		for (var i = 0; i < localstate.griditems.length; i++) {
			if (localstate.griditems[i].unsaved) {
				updatearray.push(localstate.griditems[i]);
			}
		}
		//alert('save!');
		if (updatearray.length > 0) {
			i = 0;
			var limit = updatearray.length - 1;
			var updateitems = setInterval(function () {
				var item = updatearray[i];
				//Do work here, API call.
				const postdata = {
					item: item
				};
				var postoptions = {
					withCredentials: true,
					withXSRFToken: true,
					crossDomain: true,
					mode: 'no-cors',
					timeout: 11800,
				};
				axios.post(dbendpoint + "/hardwareagent/updateclientaccess", postdata, postoptions).then(res => {
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status == 200) {
						//If ValidateUser() fails to verify user, it sends back 'login' error. 
						if (res.data.Status === 'login') {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status === 'Success') {
							console.log('success');
							//Success response also includes the item!

							//If we're sending new rows, we'll need to reference the old ID.
							if (res.data.OldID) {
								var itemindex = localstate.griditems.map(function (o) { return o.id; }).indexOf(res.data.OldID);
								localstate.griditems[itemindex].unsaved = false;
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								//Set New ID
								localstate.griditems[itemindex].id = res.data.item.id;

							} else {
								var itemindex = localstate.griditems.map(function (o) { return o.id; }).indexOf(res.data.item.id);
								localstate.griditems[itemindex].unsaved = false;
								//Refs allow us to update the grid live!
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
							}
						}
						if (res.data.Status === 'Failure') {
							//Failure error
							errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: 'neutral' })
						}
					} else {
						//Non-200 message from server.
						errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: 'warning' })
					}
				});

				//If we have completed all items, clear this interval and update state.
				if (i === limit) {
					clearInterval(updateitems);
					UpdateState(localstate);
				}
				i++;
			}, 200);
		}
		btnSave.current.style.display = "";
		btnPendingSave.current.style.display = "none";
	}


	const [showconfirmation, setShowConfirmation] = useState(false);
	const [deleteitems, setDeleteItems] = useState([]);
	const DeleteSelectedInit = () =>{
		var deleteitemsarray =[];
		//Reflect items to user for confimation.
		for (var i=0; i<state.selectedindexes.length; i++){
			deleteitemsarray.push(localstate.griditems[state.selectedindexes[i]]);
		}
		setDeleteItems(deleteitemsarray);
		setShowConfirmation(true);
	}
	const DeleteSelected = () => {
		var finishedrequests = 0;
		var postoptions = {
			withCredentials:true,
			withXSRFToken: true,
			crossDomain:true,
			mode:'no-cors',
			timeout:11800,
		};

		for (var i=0; i<localstate.selectedindexes.length; i++){
			if (localstate.griditems[localstate.selectedindexes[i]].hasOwnProperty('PendingItem')){
				//Pending items are simply removed from the view and forgotten.
				localstate.griditems.splice(localstate.selectedindexes[i], 1);
				//Count as finished request
				finishedrequests++;
			} else {
				//Make Delete request to DB
				const postdata = {				
					item:localstate.griditems[localstate.selectedindexes[i]]
				};
				axios.post(dbendpoint+"/hardwareagent/deleteclientaccess", postdata, postoptions).then(res => {
					//No matter the response, we consider the result as a 'finished request'. We can then properly do clean-up.
					finishedrequests++;	
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status==200){
						//If ValidateUser() fails to verify user, it sends back 'login' error. 
						if (res.data.Status==='login'){
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status==='Success'){
							//Success response also includes the item!
							//If we're pulling the item out of grid items, we'll use the ID of the item for reference.
							if (res.data.OldID){
								//Since griditems state can be reloaded anytime, we look for the indexOf the ID
								var itemindex = localstate.griditems.map(function(o) { return o.id; }).indexOf(res.data.OldID);
								localstate.griditems.splice(itemindex, 1);		
							} else {
								errors.NewError({errmsg:"Could not delete one or more items.", errshow:true, errtimeout: 8, errtype:'warning'})
							}
						}
						if (res.data.Status==='Failure'){
							//Failure error
							errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:'neutral'})
						}
					} else {
						//Non-200 message from server.
						errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:'warning'})
					}
					//After result from last request, do cleanup.
					if (finishedrequests === localstate.selectedindexes.length) {
						//Clear out all selections! Since checkboxes are controlled only by grid items index, we don't have good
						//tracking on which items are which.
						localstate.selectedindexes = [];
						localstate.selectedcount = 0;
						UpdateState(localstate);
						setShowConfirmation(false);
					}
				});
			}
		}
	}
	const CancelDelete = () => {
		setShowConfirmation(false);
	}



	/* ##########################  Cell Interaction  ########################## */


	//Useful for capturing Tabs\Enters on inputs
	const DetectTab = (event, index, column) =>{
		if (event.key == "Tab"){
			event.preventDefault();
			//If end of table, go to first
			if (rowRefs.current[(index+1)+column]){
				if (column=="Checkbox"){
					console.log("Checkbox!"+(index+1));
					rowRefs.current[(index+1)+column].focus();
				} else {
					rowRefs.current[(index+1)+column].select();
				}
			} else {
				rowRefs.current[('0'+column)].focus();
			}
		}
	}

	//Catch-All Method.
	const onChangeValue = (event, index, column) => {
		if (event.key !== "Tab"){
			//Update ref
			rowRefs.current[index+column].value=event.target.value;
			rowRefs.current[index+'SaveStatus'].classList.add(classes.unsavedhighlight);
			//Update localstate
			localstate.griditems[index][column] = event.target.value;
			localstate.griditems[index].unsaved = true;
			//Update Save button to show pending saves
			btnSave.current.style.display="none";
			btnPendingSave.current.style.display="";
		}
	}

	//Useful for changes to related items: (cost vs margin)
	const onChangeCost = (event, index, column) => {
		if (event.key == "Tab"){
			event.preventDefault();
			//If end of table, go to first
			if (rowRefs.current[(index+1)+column]){
				rowRefs.current[(index+1)+column].focus();
			} else {
				rowRefs.current[('0'+column)].focus();
			}
		} else {
			//If Cost changes, so does Margin
			rowRefs.current[index+'Margin'].value = (rowRefs.current[index+'Price'].value - event.target.value).toFixed(2);
			//Update ref
			rowRefs.current[index+column].value=event.target.value;
			rowRefs.current[index+'SaveStatus'].classList.add(classes.unsavedhighlight);
			//Update localstate
			localstate.griditems[index][column] = event.target.value;
			console.log(localstate.griditems[index].Cost);
			localstate.pendingsaves = true;
			//Update Save button to show pending saves
			btnSave.current.style.color="white";
			btnSave.current.style.backgroundColor="#01579B";
		}
	}


	/* ##########################  TABLE HEAD  ########################## */
	//Custom Head Cells
	const headCells = [
		{ id: 'AppName', numeric: false, label: 'AppName', align:'left', allowsort:true},
		{ id: 'AppKey', numeric: true, label: 'App Key', align:'left', allowsort:true},
		{ id: 'IP', numeric: true, label: 'IP', align:'left', allowsort:true},
		{ id: 'created_at', numeric: false, label: 'Created Date',  align:'left', allowsort:true},
	];

	function EnhancedTableHead(props) {
		const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
		const createSortHandler = (property, allowsort) => (event) => {
			onRequestSort(event, property);
		};
	
		return (
			<thead style={{display:"table-header-group"}}>
				<tr style={{border:'1px solid #CCC',
							backgroundColor:'#DDD'}}>
					<td style={{width: '14px', padding:"none", display:"table-cell", padding:"6px 4px 2px 4px"}}>
						<input type="checkbox"  
							checked={state.selectedcount > 0 && state.selectedcount === state.griditems.length}
							onChange={onSelectAllClick}
						/>
					</td>
					{headCells.map((headCell) => (
						<td
							key={headCell.id}
							align={headCell.align}				
						>
							{(headCell.allowsort) && 
								<TableSortLabel
									active={localstate.orderby === headCell.id}
									direction={localstate.orderby === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id, headCell.allowsort)}
								>
									{(localstate.orderby === headCell.id) 
										? <span style={{fontWeight:"bold"}}>{headCell.label}</span>
										: <span>{headCell.label}</span>
									}
									{localstate.orderby === headCell.id ? (
										<span className={classes.visuallyHidden}>
											{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
										</span>
									) : null}
								</TableSortLabel>
							}
							{(!headCell.allowsort) && 
								<span>{headCell.label}</span>
							}
						</td>
					))}
				</tr>
			</thead>
		);
	}

	EnhancedTableHead.propTypes = {
		classes: PropTypes.object.isRequired,
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(['asc', 'desc']).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};


	


	/* ##########################  Render Function  ########################## */
	return (
        <div style={{ margin: "auto", maxWidth: "800px", minWidth: "600px", textAlign:"left", paddingTop:"8px" }}>
			{/* Standard Page Header with right floated error message space */ }
			<div style={{height:"35px"}}>
				<div style={{textAlign:'center'}}>
					<h2>Client App Access</h2>
				</div>
				{(errors.currenterror.errshow) &&
					<div style={{position:"relative", float:"right", bottom:"26px", height:"25px", fontSize:"12px"}}>
						<ErrorMessage />
					</div>
				}
			</div>


			{/* Search Tools */}
			{/* Search Tools should: Fall in-line, stack, have padding-right of 15px, 300px wide.*/}

			
			<div>
				<div style={{ fontSize: "12px" }}>
					Search:
				</div>

				{/*TRY! Selectable Key, searchable value */}

				{/* Search Pair 1 */}
				{(showsearch1) &&
					<div className={classes.searchinputs}>
						<FormControl variant="standard" className={classes.searchtypeinput} style={{minWidth:"120px"}}>
							{/* Value must match one of the MenuItem values, SerialNumber != Serial Number */}
							<Select
								key={localstate.searchoptions.searchpairs.searchpair1.uuid}
								value={localstate.searchoptions.searchpairs.searchpair1.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, "search1")}
							>
								<MenuItem value={"AppName"}>App Name</MenuItem>
								<MenuItem value={"AppKey"}>App Key</MenuItem>
								<MenuItem value={"IP"}>Client IP</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search1" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair1.uuid+1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, "search1")} />
						<div style={{ width: "30px" }}>&nbsp;</div>
					</div>
				}

			</div>


			{/* Save Changes & Pagination */}
			<div style={{height:"5px"}}>&nbsp;</div>
			<div>
				{(!showconfirmation) &&
					<React.Fragment>
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => SaveChanges()}
							ref={el => btnSave.current = el}>
							<SaveIcon sx={{ color: "lightgray" }}></SaveIcon>&nbsp;Save Changes
						</Button>


						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							style={{ display: "none" }}
							onClick={() => SaveChanges()}
							ref={el => btnPendingSave.current = el}>
							<PendingIcon sx={{ color: "orange" }}></PendingIcon>&nbsp;Save Changes
						</Button>
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained" onClick={() => AddRow()}>
							Add Row
						</Button>
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained" onClick={() => DeleteSelectedInit()}>
							Delete Selected
						</Button>
					</React.Fragment>
				}

				{/* Confirmation Space */}
				{(showconfirmation) &&
					<div>
						<b>Are you sure you want to delete these items?</b>
						<div style={{padding:"10px 0px"}}>
							{deleteitems.map((row, index) => { 
							if (deleteitems.length == index+1){
									return (<span key={index}>{row.AppName}</span>)
								} else {
									return (<span key={index}>{row.AppName}, </span>)
								}
							})}
						</div>
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained" onClick={() => DeleteSelected()}>Yes, Delete Items</Button>&nbsp;&nbsp;
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained" onClick={() => CancelDelete()}>Cancel</Button>
					</div>
				}

				{(localstate.totalitems>0) &&
					<TablePagination className={classes.paginationalign}
						style={{display:"inline-flex", float:"right"}}
						component="div"
						count={localstate.totalitems}
						page={localstate.page}
						onPageChange={handleChangePage}
						rowsPerPage={localstate.rowsperpage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[10,20]}
					/>
				}
			</div>
			

			<table aria-label="caption table" size="small" className={classes.flexgrid} style={{display:"table", tableLayout:"auto", minWidth:"100%", borderCollapse:"collapse", borderColor:"grey"}}>
				<EnhancedTableHead
					numSelected={localstate.selectedcount} 
					classes={classes}
					order={localstate.order}
					orderBy={localstate.orderby}
					onSelectAllClick={handleSelectAllClick}
					onRequestSort={handleRequestSort}
					rowCount={state.griditems.length}
				/>

				<tbody style={{display:"table-row-group"}}>
					{(localstate.griditems.length>0) &&	
						localstate.griditems.map((row, index) => { 
							//Create all-new refs on each render. Helps avoid issues with grid states.
							rowRefs.current[index+'Checkbox'] = React.createRef();
							rowRefs.current[index+'SaveStatus'] = React.createRef();
							rowRefs.current[index+'AppName'] = React.createRef();
							rowRefs.current[index+'AppKey'] = React.createRef();
							rowRefs.current[index+'IP'] = React.createRef();
							return (
								<tr
									className={classes.flexgridrow}
									key={row.id} 
								>
									{/* Checkbox */}
									<td>
										<div ref={el => rowRefs.current[index + 'SaveStatus'] = el} style={{ padding: "6px 4px 2px 4px" }}>
											<input type="checkbox"
												ref={el => rowRefs.current[index + 'Checkbox'] = el}
												checked={row.isSelected}
												onKeyDown={(event) => DetectTab(event, index, 'Checkbox')}
												onChange={() => SelectRow(index, row)}
											/>
										</div>
									</td>

									{/* Name */}
									<td className={classes.flexgridinputcontainer}>
										<input  
										ref={el=>rowRefs.current[index+'AppName']=el}
										className={classes.flexgridinput}
										style={{minWidth: '75px'}} 
										onKeyDown={(event)=>DetectTab(event, index, 'AppName')} 
										onKeyUp={(event) => onChangeValue(event, index, 'AppName')} 
										defaultValue={row.AppName} />
									</td>
								
									{/* COST */}
									<td className={classes.flexgridinputcontainer}>
										<input 
										ref={el=>rowRefs.current[index+'AppKey']=el} 
										className={classes.flexgridinput}
										style={{minWidth: '75px', textAlign:"right"}} 
										onKeyDown={(event)=>DetectTab(event, index, 'AppKey')} 
										onKeyUp={(event) => onChangeValue(event, index, 'AppKey')} 
										defaultValue={row.AppKey} />
									</td>

									{/* Price */}
									<td className={classes.flexgridinputcontainer}>
										<input 
										ref={el=>rowRefs.current[index+'IP']=el}
										className={classes.flexgridinput}
										style={{minWidth: '75px'}} 
										onKeyDown={(event)=>DetectTab(event, index, 'IP')} 
										onKeyUp={(event) => onChangeValue(event, index, 'IP')} 
										defaultValue={row.IP} />
									</td>

									{/* Date */}
									<td>
										{row.created_at}
									</td>

								</tr>
							)
						}
					)
					}
					{(localstate.griditems.length===0) &&	
						<tr className={classes.flexgridinput}><td colSpan="6"
						style={{padding:'12px', fontSize:"18px"}}>No Results</td></tr>
					}
				</tbody>
			</table> 
			{(localstate.totalitems > localstate.rowsperpage) &&
				<TablePagination className={classes.paginationalign}
					component="div"
					count={localstate.totalitems}
					page={localstate.page}
					onPageChange={handleChangePage}
					rowsPerPage={localstate.rowsperpage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					rowsPerPageOptions={[10,20]}
				/>
			}

			<div style={{marginTop:"30px"}}>Apps:<br></br>
			flexbuntu: Custom Ubuntu with hard coded app key for server requests. Changes to the key require changes to the published app.<br></br>
			
			</div>

		</div>
    );
}

export default ClientAccess;

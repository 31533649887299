import React, { useState, useEffect, useContext, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import axios from "axios";
//import Grid from '@mui/material/Grid';
//import CircularProgress from '@mui/material/CircularProgress';

//Contexts
import { AppContext, AppProvider } from "../Auth/contexts/AppContext"
//Error Context
//*Can be used for success as well!
//Types: ok, warning, danger, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

import { v4 as uuidv4 } from 'uuid';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";

import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

//Datetime Pickers
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';
//Datetime formatting
import Moment from 'react-moment';

//Icons
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';

//Buttons
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';


import '../../css/react-big-calendar.css';

//let endpoint = "http://localhost:8080/api";
var hostbase = process.env.REACT_APP_ANGULAR_LEGACY_BASE;
var dbendpoint = process.env.REACT_APP_DB_API4;


//MainPage receives props? Let's try without.
const FlexCalendar = () => {
	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;
	//Error Context
	const errors = useContext(ErrorContext);

	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);

	const btnSave = useRef();
	const btnSave2 = useRef();

	//Error Context
	//const errors = useContext(ErrorContext);

	const testevents = {
		events: [
			{
				start: moment().toDate(),
				end: moment()
					.add(1, "days")
					.toDate(),
				title: "Some title"
			}
		]
	};

	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};


	//Lets switch search to look for current month.....
	const initialdate = new Date();


	/* ##########################  UseState Variables  ########################## */
	const [state, setState] = useState({
		calendarid: uuidv4(),
		dbreload: true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		events: testevents.events,
		currentevent: null,
		clearselection: true,//Default clear selection everytime we reload DB. Continuous bulk edits may set this to false between updates.
		griditems: [],		//Empty object
		defaultvalues: [],   //Default values: Useful for initial render.
		totalitems: 0,
		page: 0, //Assume page 0, or else pagination throws an error.
		order: "asc",
		orderby: "start",
		selectedcount: 0,
		rowsperpage: 10,
		selectedindexes: [],
		pendingsaves: false, //Used for parent view - Warnings about unsaved items!
		searchoptions: {
			currentsearchdate:initialdate,
			//New! Key-Value pair array. Easier to itterate in API.
			searchpairs: {
				//Reserved for basic searchpairs. Possible injection of search parameter 'nameparameter' here.
				//Modes: like, left, right, strict, not
				searchpair1: { type: "", value: "", mode: "datetimeafter" },
				searchpair2: { type: "", value: "", mode: "datetimebefore" },
				searchpair3: { type: "", value: "", mode: "left" },
				searchpair4: { type: "", value: "", mode: "right" },
				searchpair5: { type: "", value: "", mode: "like" },
				searchpair6: { type: "", value: "", mode: "like" },
				//Reserved for AutoComplete searchpairs.
				searchpair7: { type: "", value: "", mode: "strict" },
				searchpair8: { type: "", value: "", mode: "strict" },
				searchpair9: { type: "", value: "", mode: "strict" },
				searchpair10: { type: "", value: "", mode: "strict" },
			},
			nestedrelationships: {

			},
			itemlist: ""
		}
	});

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}

	//Default Axios Post Options
	const defaultpostoptions = {
		withCredentials: true,
		withXSRFToken: true,
		crossDomain: true,
		mode: "no-cors",
		timeout: 11800,
	};

	const LoadCalendarEvents = () => {
		const postdata = {
			searchoptions: {
				limit: localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				currentsearchdate: localstate.searchoptions.currentsearchdate,
				searchpairs: localstate.searchoptions.searchpairs,
				nestedrelationships: localstate.searchoptions.nestedrelationships
			}
		};
		axios.post(dbendpoint + "/calendar/getevents", postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					//console.log(res);
					localstate.events = res.data.events;

					//Do we need to pre-format all the events to have Date types?
					//We should already have something useful from the API - Carbon formatting already performed.
					//Try to remove this:
					// for (var i=0; i<localstate.events.length; i++){
					// 	localstate.events[i].start = new Date(Date.parse(localstate.events[i].start));
					// 	localstate.events[i].end = new Date(Date.parse(localstate.events[i].end));
					// }
					UpdateState(localstate);

				}
				if (res.data.Status === "Failure") {
					//Failure error
					//errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				//errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}

	const SaveChanges = (checkbackinbool) => {
		console.log(localstate.currentevent.title);
		//Basic checks		
		if (!localstate.currentevent.title){
			errors.NewError({ errmsg: "Error: Event title is empty.", errshow: true, errtimeout: 15, errtype: "danger" });
			return;
		}
		if (!localstate.currentevent.start){
			errors.NewError({ errmsg: "Error: Start date is empty.", errshow: true, errtimeout: 15, errtype: "danger" });
			return;
		}
		if (!localstate.currentevent.end){
			errors.NewError({ errmsg: "Error: End date is empty.", errshow: true, errtimeout: 15, errtype: "danger" });
			return;
		}
		if (localstate.currentevent.start > localstate.currentevent.end){
			errors.NewError({ errmsg: "Error: Start date is after end date.", errshow: true, errtimeout: 15, errtype: "danger" });
			return;
		}

		let postdata = {
			item: localstate.currentevent
		}
		axios.post(dbendpoint + "/calendar/update", postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					btnSave.current.style.color = "#01579B";
					btnSave.current.style.backgroundColor = "rgba(0, 0, 0, 0)";
					btnSave2.current.style.color = "#01579B";
					btnSave2.current.style.backgroundColor = "rgba(0, 0, 0, 0)";
					//Attempt to replace: btnSave.current.disabled = true;
					localstate.pendingsaves = false;
					localstate.dbreload = true;

					var eventresult = res.data.item;
					//If all goes well, just remove these comments. Datetimes have been figured out.
					//Now format date so navigation doesn't crash the page
					//Probably don't need this anymore, Carbon should format the date on API update side.
					//Try removing:
					// eventresult.start = new Date(Date.parse(eventresult.start));
					// eventresult.end = new Date(Date.parse(eventresult.end));
					//Detect any changes we should make to events.
					if (res.data.OldID){
						localstate.events.push(eventresult);
					} else {
						var itemindex = localstate.events.map(function(o) { return o.id; }).indexOf(res.data.OldID);
						localstate.events[itemindex] = eventresult;
					}

					UpdateState(localstate);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 15, errtype: "warning" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 15, errtype: "warning" })
			}
		});
	}

	//Possible erroneous...
	//window.addEventListener("resize", debounce(handleWindowSize, 5000));

	const handleWindowSize = () => {
		const chartwidthelement = document.getElementById(state.calendarid);
		console.log(chartwidthelement);
		if (chartwidthelement){
			var w = chartwidthelement.offsetWidth;
			//return;
		}		
	}

	const NavigateDate = (event) => {
		//console.log(event);
		localstate.searchoptions.currentsearchdate = event;
		LoadCalendarEvents();
	}

	const DeleteEvent = () => {
		//New Events don't even exist until Save is hit. If we're dealing with a pending event, we can just close the view.
		if (localstate.currentevent.PendingItem){
			CloseEvent();
		} else {
			let postdata = {
				item: localstate.currentevent
			}
			axios.post(dbendpoint + "/calendar/delete", postdata, defaultpostoptions).then(res => {
				if (res.status === 200){
						if (res.data.Status === "login"){
						window.location.reload(false);
					}
					if (res.data.Status === "Success") {
						localstate.currentevent = null;
						var itemindex = localstate.events.map(function(o) { return o.id; }).indexOf(res.data.OldID);
						localstate.events.splice(itemindex, 1);
						UpdateState(localstate);
					}
					if (res.data.Status === "Failure") {
						//Failure error
						errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 15, errtype: "warning" })
					}
				} else {
					//Non-200 message from server.
					errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 15, errtype: "warning" })
				}
				
			})
		}
		
	}

	const CloseEvent = () => {
		localstate.currentevent = null;
		UpdateState(localstate);
	}

	const AddEvent = () => {
		localstate.currentevent = {
			PendingItem:true,
			id:uuidv4(),
			title:"New Event",
			details:"",
			start:"",
			end:""
		};

		UpdateState(localstate);
	}

	useEffect(() => {
		if (state.dbreload) {
			//Avoid duplicate loads.
			state.dbreload = false;
			LoadCalendarEvents();
		}
	}, []);

	const ViewEvent = (event) => {
		localstate.currentevent = event;
		UpdateState(localstate);
	}

	//Changes to item
	const onChangeValue = (event, itemkey) => {
		var oldvalue = localstate.currentevent[itemkey];
		var newvalue = event.target.value;
		if (event.key !== "Tab" &&
			event.key !== "ArrowDown" &&
			event.key !== "ArrowUp" &&
			event.key !== "ShiftLeft" &&
			event.key !== "ShiftRight"
		) {

			if (itemkey === "SomeBoolean") {
				if (event.target.checked) {
					localstate.currentevent.SomeBoolean = 1;
				} else {
					localstate.currentevent.SomeBoolean = 0;
				}
			}


			//All others
			if (itemkey === "title" || itemkey === "details") {
				localstate.currentevent[itemkey] = newvalue;
			}
			localstate.pendingsaves = true;
			btnSave.current.style.color = "white";
			btnSave.current.style.backgroundColor = "#01579B";
			btnSave2.current.style.color = "white";
			btnSave2.current.style.backgroundColor = "#01579B";

			UpdateState(localstate);
		}

	}

	const onChangeDatetimeValue = (newvalue, itemkey) => {
		localstate.currentevent[itemkey] = newvalue;
		if (itemkey==='start'){
			localstate.currentevent.startmodified = true;
		}
		if (itemkey==='end'){
			localstate.currentevent.endmodified = true;
		}
		localstate.pendingsaves = true;
		btnSave.current.style.color = "white";
		btnSave.current.style.backgroundColor = "#01579B";
		btnSave2.current.style.color = "white";
		btnSave2.current.style.backgroundColor = "#01579B";
		UpdateState(localstate);

	}


	const localizer = momentLocalizer(moment);
	//Hide Scroll Bars for injected content.
	return (
		<>

			<div style={{ margin: "auto", padding: "20px", textAlign: "center" }}>
				<div style={{height:"800px", minWidth:"400px"}}>
					<Calendar
					showAllEvents={true}
					id={state.calendarid}
					localizer={localizer}
					events={state.events}
					startAccessor="start"
					endAccessor="end"
					onNavigate={(event)=>{NavigateDate(event)}}
					onSelectEvent={(event) => ViewEvent(event)}
					
				/>
				</div>
				
				<div style={{ width: "100%", border: "1px solid #DDD", padding: "10px" }}>
					{(!localstate.currentevent) &&
						<Box sx={{ textAlign:"right", marginBottom:"10px" }}>
						<Button variant="contained"
						sx={{width:"180px"}}
							size="medium"
							onClick={() => AddEvent()}
							>
							<AddIcon />New Event
						</Button>
						</Box>
					
					}


					{(localstate.currentevent) &&

						<div>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
							<div className={classes.itemtableheader} style={{marginTop:"0px", height:"30px", paddingRight:"10px"}}>Event Details
								<div style={{ float:"right", height: "24px", textAlign: "center", fontSize: "12px" }}>
									{(errors.currenterror.errshow) &&
										<ErrorMessage />
									}
								</div>
							</div>
								<Typography variant="h6">
									<Grid container spacing={0}>
										<Grid item sm={12} md={6}>
											<table className={classes.itemtable}>
												<tbody>
													<tr>
														<td style={{ width: "65px" }}>
															Title:
														</td>
														<td>
															{(userPerms.updateCalendarEvent === 1) &&
																<input className={classes.flexiteminput}
																	type="text"
																	value={localstate.currentevent.title}
																	onChange={(event) => onChangeValue(event, "title")}
																/>
															}
															{(userPerms.updateCalendarEvent === 0) &&
																<div className={classes.flexitemstaticinput}>
																	{localstate.currentevent.title}
																</div>
															}
														</td>
													</tr>
													<tr>
														<td>
															Start:
														</td>
														<td>
															{(userPerms.updateCalendarEvent === 1) &&
																<div className={classes.flexitemdateinputcontainer}>
																	<DateTimePicker
																		value={localstate.currentevent.start}
																		onChange={(newvalue) => onChangeDatetimeValue(newvalue, "start")}
																		renderInput={(params) => <TextField
																			{...params} variant="standard" className={classes.flexitemdateinput} style={{width:"calc(100% - 20px)"}}
																			inputProps={{
																				...params.inputProps,
																				placeholder: ""
																			}}
																		/>}
																	/>
																</div>
															}
															{(userPerms.updateCalendarEvent === 0) &&
																<div className={classes.flexitemstaticinput}>
																	<Moment element='span' format="MMMM D, YYYY [at] h:mma">{localstate.currentevent.Date}</Moment>
																</div>
															}
														</td>
													</tr>
													<tr>
														<td>
															End:
														</td>
														<td>
															{(userPerms.updateCalendarEvent === 1) &&
																<div className={classes.flexitemdateinputcontainer}>
																	<DateTimePicker
																		value={localstate.currentevent.end}
																		onChange={(newvalue) => onChangeDatetimeValue(newvalue, "end")}
																		renderInput={(params) => <TextField
																			{...params} variant="standard" className={classes.flexitemdateinput} style={{width:"calc(100% - 20px)"}}
																			inputProps={{
																				...params.inputProps,
																				placeholder: ""
																			}}
																		/>}
																	/>
																</div>
															}
															{(userPerms.updateCalendarEvent === 0) &&
																<div className={classes.flexitemstaticinput}>
																	<Moment element='span' format="MMMM D, YYYY [at] h:mma">{localstate.currentevent.Date}</Moment>
																</div>
															}
														</td>
													</tr>
												</tbody>
											</table>
										</Grid>
										<Box
											component={Grid}
											item
											md={6}
											display={{ xs: "none", md: "block" }}
										>
											<Box sx={{ textAlign:"right", marginBottom:"10px", marginTop:"10px" }}>
												<Button variant="outlined"
												sx={{width:"180px"}}
													size="medium"
													onClick={() => CloseEvent(false)}
													>
													Close Event
												</Button>
											</Box>
											{(userPerms.updateCalendarEvent===1) &&
												<Box sx={{ textAlign:"right", marginBottom:"10px" }}>
													<Button variant="outlined"
													sx={{width:"180px"}}
														size="medium"
														disabled={localstate.pendingsaves ? false : true}
														onClick={() => SaveChanges(false)}
														ref={el => btnSave.current = el}>
														<SaveIcon />&nbsp;&nbsp;{(localstate.currentevent.PendingItem) ? "Save Event" : "Save Changes"}
													</Button>
												</Box>
											}
											{(userPerms.deleteCalendarEvent===1) &&
												<Box sx={{ textAlign:"right" }}>
													<Button variant="outlined"
														sx={{width:"180px"}}
														size="medium"
														onClick={() => DeleteEvent()}
														>
														<DeleteIcon />&nbsp;&nbsp;Delete Event
													</Button>
												</Box>
											}
											
										</Box>






										<Grid item sm={12} md={12}>
											<table className={classes.itemtable}>
												<tbody>
													<tr>
														<td style={{ width: "65px" }}>
															Details:
														</td>
														<td>
														{(userPerms.updateCalendarEvent === 1) &&
														<TextareaAutosize
															style={{ minHeight: "50px", backgroundColor: "#EEE" }}
															className={classes.flexiteminput}
															value={(localstate.currentevent.details ? localstate.currentevent.details : "")}
															onChange={(event) => onChangeValue(event, "details")}
														/>
														}
													
														{(userPerms.updateCalendarEvent === 0) &&
															<TextareaAutosize
															style={{ minHeight: "50px", backgroundColor: "#EEE" }}
															className={classes.flexiteminput}
															value={(localstate.currentevent.details ? localstate.currentevent.details : "")}
															disabled
															/>
														}
														</td>
													</tr>
													
												</tbody>
											</table>
										</Grid>

										{/* Bottom buttons if screen is too narrow */}
										<Box
											component={Grid}
											item
											md={6}
											display={{ xs: "block", md: "none" }}
										>
										<div style={{ textAlign: "left", paddingLeft: "65px" }}>
											<Box sx={{ textAlign:"right", marginBottom:"10px" }}>
												<Button variant="outlined"
												sx={{width:"180px"}}
													size="medium"
													onClick={() => CloseEvent(false)}
													>
													Close Event
												</Button>
											</Box>
											{(userPerms.updateCalendarEvent === 1) &&
												<Box sx={{ textAlign: "right", marginBottom: "10px" }}>
													<Button variant="outlined"
														sx={{ width: "180px" }}
														size="medium"
														disabled={localstate.pendingsaves ? false : true}
														onClick={() => SaveChanges(false)}
														ref={el => btnSave2.current = el}>
														<SaveIcon />&nbsp;&nbsp;{(localstate.currentevent.PendingItem) ? "Save Event" : "Save Changes"}
													</Button>
												</Box>
											}
											{(userPerms.deleteCalendarEvent === 1) &&
												<Box sx={{ textAlign: "right" }}>
													<Button variant="outlined"
														sx={{ width: "180px" }}
														size="medium"
														onClick={() => DeleteEvent()}
													>
														<DeleteIcon />&nbsp;&nbsp;Delete Event
													</Button>
												</Box>
											}
										</div>
											
										</Box>

									</Grid>
								</Typography>
							</LocalizationProvider>
							{(!localstate.currentevent.PendingItem) &&
								<div style={{textAlign:"left", paddingLeft:"65px"}}>
									Created By: {localstate.currentevent.CreatedBy} on <Moment element='span' format="MMMM D, YYYY [at] h:mma">{localstate.currentevent.created_at}</Moment><br></br>
								</div>
							}							
							
						</div>
					}

				</div>
			</div>

		</>

	)
}


export default FlexCalendar;
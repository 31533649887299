//InventoryToolbar
// New Toolbar to interact with inventory

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';

import React, { useState, useEffect, useContext, useRef } from 'react';
import {
	useLocation
} from "react-router-dom";
import axios from "axios";

//Datetime formatting
import moment from 'moment';

//Sounds
import successaudiosrc from '../assets/sounds/success2.wav';
import failureaudiosrc from '../assets/sounds/failure2.wav';

//Search Tools
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

//Bottom Toolbar:
import InputBase from '@mui/material/InputBase';

//ErrorContext
import { ErrorContext } from '../common/ErrorContext';

//Audio Message
import AudioMessage from "../common/AudioMessage";

//App Components
import Typography from '@mui/material/Typography';

//Icons
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';


//Inventory Manager
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';


//Audio
const successaudio = new Audio(successaudiosrc);
const failureaudio = new Audio(failureaudiosrc);

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials:true,
	withXSRFToken: true,
	crossDomain:true,
	mode:"no-cors",
	timeout:11800,
};

const InventoryToolbar = (props) => {
	//Get current location - Sometimes we don't want to focus on the search input!
	let location = useLocation();
	//console.log(location.pathname);

	const classes = useClasses(flexstyles);


	/* ##########################  UseState Variables  ########################## */	  
	
	//Toolbar Tool Type:
	const [tooltype, setToolType] = useState("Go To Serial");

	const serialinputref = useRef();

	const skuinputref = useRef();

	//Error Context
	const errors = useContext(ErrorContext);

	useEffect(() => {
		if (!location.pathname.indexOf("item")){
			if (tooltype==="Search Tool"){
				serialinputref.current.focus();
			}
			if (tooltype==="Go To Serial"){
				serialinputref.current.focus();
			}
			if (tooltype==="Set Location"){
				locationinputref.current.focus();
			}
			if (tooltype==="Audit"){
				locationinputref.current.focus();
				auditdateinputref.current.value = moment().format('YYYY-MM-DD');
			}
		}
	}, [tooltype, location.pathname]);


	//Bottom Toolbar Items:

	const GoToSerial = (event) => {
		//Placeholder for serial item load
		if (event.which === 13){
			if (event.target.value !== '') {
				var coderesult = CheckCodes(event.target.value);
				if (!coderesult) {
					//Check for item in DB, then if it exists:
					//Load new tab with the item

					const postdata = {
						serialnumber: serialinputref.current.value
					};
					axios.post(dbendpoint + "/items/checkforitem", postdata, defaultpostoptions).then(res => {
						//API should be setup to send 200 response with status. Merge paginated requests.
						if (res.status === 200) {
							//If ValidateUser() fails to verify user, it sends back 'login' error. 
							if (res.data.Status === "login") {
								//Not logged in. Reload page causes redirect to /login
								window.location.reload(false);
							}
							//All new API calls should return a status.
							if (res.data.Status === "Success") {
								//errors.NewError({ errmsg: "Item found!", errshow: true, errtimeout: 5, errtype: "ok" });
								window.open('/item/'+res.data.itemid, "_blank");
								serialinputref.current.value="";
							}
							if (res.data.Status === "Failure") {
								//Failure error
								errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "warning" });
								failureaudio.play();
								serialinputref.current.value="";
							}
						} else {
							//Non-200 message from server.
							errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" });
							failureaudio.play();
						}
					});
				}
			}

		} else {
			
		}
	}


	const [locationlock, setLocationLock] = useState(false);
	const ToggleLock = () => {
		setLocationLock(!locationlock);
	}
	//Refs for focus
	const locationinputref = useRef();



	const handleLocationValue = (event) => {
		/* If Enter, proceed */
		if (event.which === 13){
			if (event.target.value!==''){
				//successaudio.play();
				var coderesult = CheckCodes(event.target.value);
				if (!coderesult){
					//Try without useState variable? setLocationValue(event.target.value);	
					AudioMessage("Set");
					serialinputref.current.focus();
				}
			}
		}
	}

	const SetSerialLocation = (event) => {
		if (event.which === 13) {
			if (event.target.value!==''){
				var coderesult = CheckCodes(event.target.value);
				if (!coderesult){
					const postdata = {
						location: locationinputref.current.value,
						serial: serialinputref.current.value
					};
					axios.post(dbendpoint + "/items/setlocation", postdata, defaultpostoptions).then(res => {
						//API should be setup to send 200 response with status. Merge paginated requests.
						if (res.status === 200) {
							//If ValidateUser() fails to verify user, it sends back 'login' error. 
							if (res.data.Status === "login") {
								//Not logged in. Reload page causes redirect to /login
								window.location.reload(false);
							}
							//All new API calls should return a status.
							if (res.data.Status === "Success") {
								errors.NewError({ errmsg: "Location set successfully!", errshow: true, errtimeout: 5, errtype: "ok" });
								successaudio.play();
								serialinputref.current.value="";
								if (!locationlock){
									locationinputref.current.value="";
									locationinputref.current.focus();
								}
							}
							if (res.data.Status === "Failure") {
								//Failure error
								errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "warning" });
								failureaudio.play();
								serialinputref.current.value="";
							}
						} else {
							//Non-200 message from server.
							errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" });
							failureaudio.play();
						}
					});

				}
			}
		}
	}


	//const [auditdate, setAuditDate] = useState('');
	const auditdateinputref = useRef();

	const handleDateValue = (event) => {
		/* If Enter, proceed */
		if (event.which === 13) {
			if (event.target.value !== '') {
				//Check for code
				var coderesult = CheckCodes(event.target.value);
				//If we aren't changing systems, we're locking\unlocking locations. Remove code from date result.
				if (coderesult){
					//Remove code from value - This is really a special edge case, but codes really shouldn't be allowed to stay anywhere.
					auditdateinputref.current.value = event.target.value.replaceAll(coderesult, "");
				}
				//No code on enter, proceed with validating date.
				if (!coderesult) {
					//Check date format
					//Reads: YYYY-MM-DD
					//Extended date validation for this form.
					//Let's add provision for char count (10) and dash (2) count.
					console.log("Char count:" + event.target.value.length);
					//var temp = "This is a string.";
					var count = (event.target.value.match(/-/g) || []).length;
					if (event.target.value.length===10 && count===2){
						let isValidDate = Date.parse(event.target.value);
						if (isNaN(isValidDate)) {
							failureaudio.play();
							errors.NewError({ errmsg: "Not a valid date for audit. Use YYYY-MM-DD.", errshow: true, errtimeout: 5, errtype: "warning" });
						} else {
							//Try without: setAuditDate(event.target.value);
							locationinputref.current.focus();
						}
					} else {
						failureaudio.play();
						errors.NewError({ errmsg: "Not a valid date for audit. Use YYYY-MM-DD.", errshow: true, errtimeout: 5, errtype: "warning" });
					}
				}
			}
		}
	}


	const handleLocationAuditValue = (event) => {
		/* If Enter, proceed */
		if (event.which === 13){
			if (event.target.value!==''){
				var coderesult = CheckCodes(event.target.value);
				//If we aren't changing systems, we're locking\unlocking locations. Remove code from location result.
				if (coderesult){
					//Remove code from value - This is really a special edge case, but codes really shouldn't be allowed to stay anywhere.
					locationinputref.current.value = event.target.value.replaceAll(coderesult, "");
				}
				if (!coderesult){
					serialinputref.current.focus();
				}
			}
		}
	}

	const SetSerialAudit = (event) => {
		/* If Enter, proceed */
		if (event.which === 13) {
			var coderesult = CheckCodes(event.target.value);
			//If we aren't changing systems, we're locking\unlocking locations. Remove code from serial number result and go back to location input.
			if (coderesult) {
				//Remove code from value - This is really a special edge case, but codes really shouldn't be allowed to stay anywhere.
				serialinputref.current.value = event.target.value.replaceAll(coderesult, "");
			}
			if (!coderesult) {
				if (event.target.value !== '' && auditdateinputref.current.value !== '' && locationinputref.current.value !== '') {
					//Validate date again!
					var count = (auditdateinputref.current.value.match(/-/g) || []).length;
					if (auditdateinputref.current.value.length === 10 && count === 2) {
						let isValidDate = Date.parse(auditdateinputref.current.value);
						if (isNaN(isValidDate)) {
							failureaudio.play();
							errors.NewError({ errmsg: "Not a valid date for audit. Use YYYY-MM-DD.", errshow: true, errtimeout: 5, errtype: "warning" });
						} else {
							//Proceed with audit date set!
							const postdata = {
								auditdate: auditdateinputref.current.value + " 00:00:00",
								location: locationinputref.current.value,
								itemserial: serialinputref.current.value
							};
							axios.post(dbendpoint + "/items/newauditdate", postdata, defaultpostoptions).then(res => {
								//API should be setup to send 200 response with status. Merge paginated requests.
								if (res.status === 200) {
									//If ValidateUser() fails to verify user, it sends back 'login' error. 
									if (res.data.Status === "login") {
										//Not logged in. Reload page causes redirect to /login
										window.location.reload(false);
									}
									//All new API calls should return a status.
									if (res.data.Status === "Success") {
										errors.NewError({ errmsg: "Audit set successfully!", errshow: true, errtimeout: 5, errtype: "ok" });
										successaudio.play();
										serialinputref.current.value = "";
										if (!locationlock) {
											locationinputref.current.value = "";
											locationinputref.current.focus();
										}
									}
									//Special Provision and FIRST use of error link!
									if (res.data.Status === "Sold"){
										var errorlink = "/item/"+res.data.ItemID;
										var errortext = res.data.SerialNumber;
										errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 20, errtype: "warning",link:errorlink, linktext:errortext });
										failureaudio.play();
										serialinputref.current.value = "";
									}
									if (res.data.Status === "Failure") {
										//Failure error
										errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "warning" });
										failureaudio.play();
										serialinputref.current.value = "";
									}
								} else {
									//Non-200 message from server.
									errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" });
									failureaudio.play();
								}
							});
						}
					} else {
						failureaudio.play();
						errors.NewError({ errmsg: "Not a valid date for audit. Use YYYY-MM-DD.", errshow: true, errtimeout: 5, errtype: "warning" });
					}
				}
			}
		}
	}


	const CheckCodes = (inputtext) => {
		if (inputtext.includes('%RESET')) {
			setLocationLock(false);
			//AudioMessage("Set location");
			//Reset location form, go to location system
			if (tooltype === "Search Tool") {
				serialinputref.current.value = "";
				skuinputref.current.value="";
				serialinputref.current.focus();
			}
			if (tooltype === "Set Location" || tooltype === "Audit"){
				locationinputref.current.value="";
				serialinputref.current.value="";
				locationinputref.current.focus();
			}
			if (tooltype === "Go To Serial"){
				serialinputref.current.value="";
			}
			return '%RESET';
		}

		if (inputtext.includes('%FINDBYSERIAL')) {
			setLocationLock(false);
			//AudioMessage("Set location");
			//Reset location form, go to location system
			if (tooltype !== "Search Tool") {
				setToolType("Search Tool");
				tooltyperef.current = "Search Tool";
			} else {
				//Reset input for current system
				serialinputref.current.value = "";
				skuinputref.current.value="";
				serialinputref.current.focus();
			}
			return '%FINDBYSERIAL';
		}

		if (inputtext.includes('%GOTOSERIAL')) {
			setLocationLock(false);
			//AudioMessage("Set location");
			//Reset location form, go to location system
			if (tooltype !== "Go To Serial") {
				setToolType("Go To Serial");
				tooltyperef.current = "Go To Serial";
			}
			return '%GOTOSERIAL';
		}

		if (inputtext.includes('%AUDIT')) {
			setLocationLock(false);
			//AudioMessage("Set location");
			//Reset location form, go to location system
			if (tooltype !== "Audit") {
				setToolType("Audit");
				tooltyperef.current = "Audit";
			}
			return '%AUDIT';
		}

		if (inputtext.includes('%SETLOCATION')) {
			setLocationLock(false);
			AudioMessage("Set location");
			//Reset location form, go to location system
			if (tooltype !== "Set Location") {
				setToolType("Set Location");
				tooltyperef.current = "Set Location";
			} else {
				locationinputref.current.value = "";
				locationinputref.current.focus();
				serialinputref.current.value = "";
			}
			return '%SETLOCATION';
		}

		if (inputtext.includes('%LOCATIONLOCK')) {
			setLocationLock(true);
			AudioMessage("L Lock");
			//Reset location form, go to location system
			if (tooltype !== "Set Location" && tooltype!== "Audit") {
				setToolType("Set Location");
				tooltyperef.current = "Set Location";
			} else {
				locationinputref.current.value = "";
				locationinputref.current.focus();
				if (tooltype==="Set Location"){
					serialinputref.current.value="";
				} else {
					serialinputref.current.value="";
				}
			}
			return '%LOCATIONLOCK';
		}

		if (inputtext.includes('%LOCATIONUNLOCK')){
			setLocationLock(false);
			AudioMessage("Location unlocked");
			//Reset location form, go to location system
			if (tooltype!=="Set Location" && tooltype!== "Audit"){
				setToolType("Set Location");
				tooltyperef.current = "Set Location";
			} else {
				locationinputref.current.value="";
				locationinputref.current.focus();
				if (tooltype==="Set Location"){
					serialinputref.current.value="";
				} else {
					serialinputref.current.value="";
				}
			}
			return '%LOCATIONUNLOCK';
		}

		if (inputtext.includes('%GOTOSERIAL')){
			AudioMessage("Go to serial item");
			//Reset location form, go to location system
			if (tooltype!=="Go To Serial"){
				setToolType("Go To Serial");
				tooltyperef.current = "Go To Serial";
			} else {
				//locationref.current.value="";
				//serialinputref.current.value="";
			}
			return '%GOTOSERIAL';
		}
		return false;
	}

	//Window keystroke listener - quick 
	document.addEventListener('keydown', function (event) {
		if (event.key === '~') {
			event.preventDefault();
			console.log("Tool Type: "+ tooltype);
			if (tooltyperef.current==="Search Tool"){
				serialinputref.current.focus();
			}
			if (tooltyperef.current==="Go To Serial"){
				serialinputref.current.focus();
			}
			if (tooltyperef.current==="Set Location"){
				locationinputref.current.focus();
			}
			if (tooltyperef.current==="Audit"){
				locationinputref.current.focus();
			}
		}
	});

	

	//Bottom Bar Tools
	//Try Ref - useState giving me trouble.
	const tooltyperef = useRef('Search Tool');
	const ChangeToolType = (event) => {
		console.log("Before: "+tooltype);
		console.log(event);
		tooltyperef.current = event.target.value;
		setToolType(event.target.value);
	}


	


	/* ##########################  Render Function  ########################## */
	return (
      
			
			<AppBar position="fixed" style={{ textAlign: "left", margin: "auto", top: "auto", bottom: "0", minWidth: "700px", zIndex:"2000", backgroundColor:"#004983" }}>
				 {/* Spacer for Bottom App Bar - Bottom bar can potentially cover table results or pagination navigation */}
			<Toolbar classes={{root:classes.invmenuroot}}>
				<div style={{ flexGrow: 1 }}>
					
					{/* Pause dev on Row Monitor for now.

					<div style={{display:"inline-block", verticalAlign:"bottom", paddingBottom:"5px", marginRight:"10px"}}><h3>Tools:</h3></div>

						<a target="_blank" href={hostbase+"/react/rowmonitor"}>
							<Button variant="contained" color="secondary" style={{ margin: "0px 5px" }}>
								Row Monitor
							</Button>
						</a>

						<a target="_blank" href={hostbase+"/react/pricing"}>
							<Button variant="contained" color="secondary" style={{ margin: "0px 5px" }}>
								Pricing
							</Button>
						</a>
						*/

						/*
						IF SEARCHING BY MODEL, USE LOCATION SUMMARY
						*/
					}
					
					{(props.state.showlocations) &&
						<div style={{display:"inline-block", verticalAlign:"bottom", paddingBottom:"5px", marginRight:"10px"}}><h3>Locations: {props.state.locations}</h3></div>
					}


				</div>


				{/* Bottom Bar Tools */}

				{/* To Do: Create bottom bar drop down */}
				<FormControl variant="standard" className={classes.whitetransparentselect} sx={{ m: 0, width: "150px", border: "0px" }}>
					<Select
						MenuProps={{
							style: {zIndex: 2001}
						}}
						inputProps={{
							classes: {
								icon: classes.whiteicon,
							},
						}}
						displayEmpty
						onChange={(event) => ChangeToolType(event)}
						//Setting value means React will try to consider this a controlled component.
						//If you don't set a default value in state (before DB load), it will try to evaluate an undefined value and switch back to uncontrolled.
						//This will present an error whent he component tries to go back to controlled.
						value={tooltype}
						renderValue={
							(selected) => {
								return <Typography variant="h6">{selected}</Typography>
							}
						}
					>
						<MenuItem value={"Go To Serial"}>Go To Serial</MenuItem>
						<MenuItem value={"Set Location"}>Set Location</MenuItem>
						<MenuItem value={"Audit"}>Audit</MenuItem>
					</Select>
				</FormControl>


				{(tooltype==="Go To Serial") &&
					<React.Fragment>
						<div className={classes.whitetxtinput}>
							<InputBase
								placeholder="Serial Number"
								classes={{
									root: classes.darkinputRoot,
									input: classes.darkinputnoicon,
								}}
								inputProps={{ 'aria-label': 'search' }}
								onKeyUp={(event) => GoToSerial(event)}
								inputRef={el => serialinputref.current = el}
							/>
						</div>
					</React.Fragment>
				}

				{(tooltype==="Set Location") &&
					<React.Fragment>
						<div className={classes.whitetxtinput}>
							<InputBase
								placeholder="Location"
								classes={{
									root: classes.darkinputRoot,
									input: classes.darkinputnoicon,
								}}
								inputProps={{ 'aria-label': 'search' }}
								onKeyUp={(event) => handleLocationValue(event)}
								inputRef={el => locationinputref.current = el}
							/>
						</div>
						
						<div style={{paddingLeft:"10px"}}>
							{(!locationlock) &&
								<React.Fragment>
									<LockOpenIcon className={classes.hoverunit} onClick={()=>ToggleLock()}/>
								</React.Fragment>
							}
							{(locationlock) &&
								<React.Fragment>
									<LockIcon className={classes.hoverunit} onClick={()=>ToggleLock()}/>
								</React.Fragment>
							}
						</div>
						

						<div className={classes.whitetxtinput}>
							<InputBase
								placeholder="Serial"
								classes={{
									root: classes.darkinputRoot,
									input: classes.darkinputnoicon,
								}}
								inputProps={{ 'aria-label': 'search' }}
								onKeyUp={(event) => SetSerialLocation(event)}
								inputRef={el=>serialinputref.current=el}
							/>
						</div>
					</React.Fragment>
				}

				{(tooltype==="Audit") &&
					<React.Fragment>
						<div className={classes.whitetxtinput}>
							<InputBase
								placeholder="Date"
								classes={{
									root: classes.darkinputRoot,
									input: classes.darkinputnoicon,
								}}
								inputProps={{ 'aria-label': 'search' }}
								onKeyUp={(event) => handleDateValue(event)}
								inputRef={el => auditdateinputref.current = el}
							/>
						</div>

						<div className={classes.whitetxtinput}>
							<InputBase
								placeholder="Location"
								classes={{
									root: classes.darkinputRoot,
									input: classes.darkinputnoicon,
								}}
								inputProps={{ 'aria-label': 'search' }}
								onKeyUp={(event) => handleLocationAuditValue(event)}
								inputRef={el => locationinputref.current = el}
							/>
						</div>
						
						<div style={{paddingLeft:"10px"}}>
							{(!locationlock) &&
								<React.Fragment>
									<LockOpenIcon className={classes.hoverunit} onClick={()=>ToggleLock()}/>
								</React.Fragment>
							}
							{(locationlock) &&
								<React.Fragment>
									<LockIcon className={classes.hoverunit} onClick={()=>ToggleLock()}/>
								</React.Fragment>
							}
						</div>
						

						<div className={classes.whitetxtinput}>
							<InputBase
								placeholder="Serial Number"
								classes={{
									root: classes.darkinputRoot,
									input: classes.darkinputnoicon,
								}}
								inputProps={{ 'aria-label': 'search' }}
								onKeyUp={(event) => SetSerialAudit(event)}
								inputRef={el => serialinputref.current = el}
							/>
						</div>
					</React.Fragment>
				}
				

			</Toolbar>

		</AppBar>
    );
}

export default InventoryToolbar;

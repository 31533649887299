//NEW CONTEXT TO SHOW FANCY PROGRESS BAR FOR SAVES AND FAILURES!

//To Do: TRY TO MAINTAIN THE ABILITY TO RETRY AN UNSAVED ITEM!

import React, { useState, createContext } from 'react';


export const ProgressContext = createContext();

// This context provider is passed to any component requiring the context
export const ProgressProvider = ({children}) => {

	//Current Progress
	const [progressstate, setProgressState] = useState({
		msg: 'Default Current Progress',
		show: false,
		timeout: 2,
		type: 'items', //Labels your save types such as items, products, skus, parts
		finished:0,
		percent:"0%",
		total: 100,
		pass: 0,
		fail: 0,
		faillist:[], //Can take simple values,
		faillinks:[], //Can take links to things like items or products.
		timeoutid:false,
		errors:[]
	});

	//See if we can't just manage timeoutid here:
	const [timeoutid, setTimeoutId] = useState(false);


	const IncrementPass = (newtimeout) => {		
		setProgressState( progressstate => ({
			...progressstate,
				pass:progressstate.pass+1,
				percent:parseInt((((progressstate.finished+1)/progressstate.total)*100),10).toString()+"%",
				finished:progressstate.finished+1,
				timeout:30
			}		
		));
		return GetCurrentProgressState();
	}



	const IncrementFail = (errorstring) => {
		console.log("###############################################");
		console.log(errorstring);
		let errors = Object.assign({}, progressstate.errors);
		errors.push(errorstring);
		setProgressState( progressstate => ({
			...progressstate,
				fail:progressstate.fail+1,
				percent:parseInt((((progressstate.finished+1)/progressstate.total)*100),10).toString()+"%",
				finished:progressstate.finished+1,
				timeout:30,
				errors:errors
		}))
	}


	//Let's try another approach. Simply make the new progress when initiated.
	//We want to avoid making a new timeout if we're expecting many results.
	const NewProgressState = (newprogressstate, newtimeoutbool) => {
		//ALWAYS CLEAR CURRENT TIMEOUT?!
		clearTimeout(timeoutid);
		
		//Update progressstate -> important because we'll need to use this version of progressstate for setTimeout
		setProgressState(newprogressstate);
		if (newtimeoutbool){
			console.log("Setting new timeout!");
			NewProgressTimeout(newprogressstate.timeout);
		}
	}

	//Allow the developer to declare a timeout, perhaps after all executions of an axios method.
	const NewProgressTimeout = (timeout) => {
		//You can reference it's own timeoutid within the
		clearTimeout(timeoutid);
		let newtimeoutid = setTimeout(() => {
			setProgressState({ ...progressstate, show: false });
		}, timeout * 1000);
		//Now save timeoutid
		setTimeoutId(newtimeoutid);
		//progressstate.timeoutid = timeoutid;
		//setProgressState(progressstate);
	}

	//Do I need getters too for context?
	const GetCurrentProgressState = () => {
		return progressstate;
	}



	/*


	//Let's try another approach. Simply make the new progress when initiated.
	const NewProgressState = (newprogressstate, oldtimeoutid) => {
		if (oldtimeoutid){
			console.log("TIMEOUTID FOUND: "+oldtimeoutid);
			clearTimeout(oldtimeoutid);
		}
		setProgressState(newprogressstate);
	}

	//Allow the developer to declare a timeout, perhaps after all executions of an axios method.
	const ProgressTimeout = (newprogressstate, timeout) => {
		let timeoutid = setTimeout(() => {
			//If the old newprogressstate's timeoutid passed to this callback is still the same as current timeout id, kill the progress view
			console.log("thing1: "+newprogressstate.timeoutid);
			console.log("thing2: "+timeoutid);
			if (newprogressstate.timeoutid === timeoutid){
				setProgressState({ ...newprogressstate, show: false });
			}
		}, timeout * 1000);
		//Now save timeoutid
		setTimeoutId(timeoutid);
		newprogressstate.timeoutid = timeoutid;
		setProgressState(newprogressstate);
	}

	*/



	//Return Provider
	return (
		<ProgressContext.Provider
			value={{
				progressstate,
				NewProgressState,
				IncrementPass,
				IncrementFail,
				NewProgressTimeout,
				timeoutid,
				GetCurrentProgressState
			
			}}
		>
			{children}
		</ProgressContext.Provider>
	);
};
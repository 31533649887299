import deluxetheme1 from "../ui/theme/index"
import { alpha } from '@mui/material/styles';

import negative from '../Components/images/negative.png'

const theme = deluxetheme1;
const drawerWidth = 180;
const styles = {
	root: {
		display: 'flex',
		height: '100%'
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		//backgroundColor:'#2c76be'
		"@media print":{
			boxShadow:"none"
		}
	},

	// ========== Main Menu ==========//
	// toolbar: theme.mixins.toolbar,



	toolbarspacer: {
		height: "30px",
		backgroundColor: "#5c5c5c"
	},


	inactivesection:{
		backgroundColor: "#707070",
		color:"#FFF",
		"&:hover": {
			backgroundColor: "#727272",
    	},
	},

	activesection: {
		backgroundColor:"#878787",
		color:"#FFF",
		borderBottom:"1px solid #afafaf",
		"&:hover": {
			backgroundColor: "#878787",
    	},
	},

	activemenuitem: {
		backgroundColor:"#dbdbdb",
		fontSize:"1.5em",
		'& .MuiListItemText-root':{
			borderBottom:"2px solid #01579B",
		},
		"&:hover": {
			backgroundColor: "#dbdbdb",
    	},
	},

	inactivemenuitem: {
		backgroundColor:"#f1f1f1",
		"&:hover": {
			backgroundColor: "#FFF"
    	},
	},


	//Basic Text Link
	textlink:{
		"&:hover": {
			cursor: "pointer"
		},
		color:"#01579B"
	},

	contentwithmenus: {
		marginTop: '35px',
		flexGrow: 1,
		"@media print":{
			zIndex:"9000", //For some reason, can't see top text without this.
			marginTop:"0px",
			flexGrow: 1,
			overflow: 'auto',
		}
	},
	contentwithoutmenus: {
		//minWidth:"900px",
		//Removes marginTop: '35px',
		flexGrow: 1,
		//Removes padding: theme.spacing(1),
		overflow: 'auto'
	},
	showmenubtn: {
		position:"absolute",
		height:"20px",
		width:"100px",
		paddingRight:"15px",
		backgroundColor:"#01579B",
		borderBottom: "3px solid #01579B",
		borderLeft: "3px solid #01579B",
		borderRadius: "0px 0px 0px 4px",
		//Emotion changes... "-webkit-box-shadow": "0px 0px 0px 3px #BFBFBF",
		WebkitBoxShadow: "0px 0px 0px 3px #BFBFBF",
		boxShadow: "0px 0px 0px 3px #BFBFBF",
		"& > a":{
			color:"#FFF"
		}
	},
	inventorymanager: {
		flexGrow: 1,
		overflow: 'auto'
	},
	menubtngray:{
		color:"#333",
		backgroundColor:"#DDD",
		margin:"8px 8px 10px 0px"
	},
	menubtnblue:{
		color:"#01579B",
		backgroundColor:"#DDD",
		margin:"8px 8px 10px 0px"
	},
	buttonmargin:{
		margin:"8px 8px 10px 0px"
	},
	iconSuccess: {
		color: "#24ac00"
	},
	iconDefault: {
		color: "#5e5e5e"
	},
	iconWarning: {
		color: "#ffa70e"
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
	//Hide Unused Sort Arrow when not sorting by this 
	hidesortarrow:{
		display:"none"
	},
	unsavedhighlight: {
		backgroundColor: "#FFF7BA!important"
	},
	errorhighlight:{
		backgroundColor: "#FF9999"
	},
	savedhighlight: {
		backgroundColor:"#ceffc7"
	},
	transparenticon: {
		backgroundColor: "transparent",
    	"&:hover": {
			backgroundColor: "transparent",
			cursor: "pointer"
    	},
		padding:"0px!important"
	},

	//I place a lot of these on various <a> anchor tags.
	//However, a browser will change it to look like a blue link.
	//Perhaps we can't traverse to a next ID or something, but we need the text
	//to show like an inoperable unit, hence disabledhoverunit.
	//Used in Item System (Next/Previous Records)
	hoverunit: {
		"&:hover": {
			cursor: "pointer",
		}
	},

	disabledhoverunit: {
		"&:hover": {
			cursor: "default",
			color:"#808080"
		},
		color:"#808080"
	},

	//Some links shouldn't have text that allows selection
	unselectable: {
		//pointerEvents: "none" ,
		userSelect: "none"
	},


	/* FlexGrid */

	/* A Button I like to use at the top for options */
	bluebtn:{
		flexGrow:0, 
		flexShrink: 0,         // Prevent shrinking
        minWidth: 'auto', 
		color:"primary",
		padding:"2px 6px",
		margin:"12px 6px 4px 0px",
		borderRadius:"0px",
		"&:disabled":{
			color:"#555",
			backgroundColor:"#DDD"
		},
		//Attempt to keep button from growing:
		"& .MuiButton-root": {
			flexGrow:0
		},
		//"&"
	},

	orangebtn:{
		//color:"orange",
		padding:"2px 6px",
		margin:"12px 6px 4px 0px",
		borderRadius:"0px",
		backgroundColor:"orange",
		"&:disabled":{ //Maybe change this if ever used.
			color:"#555",
			backgroundColor:"#DDD"
		},
		"&:hover":{
			color:"#FFF",
			backgroundColor:"orange"
		}
	},

	columnselecthover:{
		"&:hover":{
			backgroundColor:"#DDD!important"
		},
		paddingRight:"30px",
		paddingLeft:"15px",
		marginRight:"0px"
	},

	columnmenu:{
		"&:hover":{
			backgroundColor: "transparent",
		},
		pointerEvent:"none",
		cursor:"default",
		padding:"0px"
	},

	/* In order to get scrollbars around our table, we'll need a container!


	*/
	flexgridcontainerA:{
		overflow:"auto",
		width:"100%"
	},

	flexgrid: {
		"& > tbody, & > tfoot":{
			"& > tr":{
				"& > td":{
					border:"1px solid #BBB",
					borderSpacing:"0px",
					verticalAlign:"top",
					//padding:"1px",
					//backgroundColor:"#FFF"
					//MRH Try: 6/27/2022
					whiteSpace: "nowrap",
					overflow: "hidden",
					textOverflow: "ellipsis"
				}
			}
		},
		"& > thead":{
			position:"relative",
			"& > tr":{
				"& > td":{
					border:"1px solid #CCC",
					padding:"3px",
					backgroundColor:"#DDD",
					position:"relative"
				}
			}
		}
	},

	flexgridrow: {
		fontFamily:"Roboto",
		fontSize:"14px",
		backgroundColor:"#FFF",
		"&:hover":{
			backgroundColor:"#EEE!important"
		},
		"& a":{
			fontFamily:"Roboto",
			textDecoration:"underline"
		}
	},
	flexgridrownohover: {
		fontFamily:"Roboto",
		fontSize:"14px",
		backgroundColor:"#FFF",
		"& a":{
			fontFamily:"Roboto",
			textDecoration:"underline"
		}
	},

	//New, expanded row can be 'selected', dark header, bold text.
	flexgridrowselected: {
		fontFamily:"Roboto",
		fontSize:"14px",
		fontWeight:"bold",
		backgroundColor:"#CCC",
		"&:hover":{
			backgroundColor:"#CCC!important"
		}
	},

	/* Static container content needs bigger padding to keep inline with inputs */
	flexgridstaticcontainer:{
		/* Attempt to change padding 6-14-2022 */
		//padding:"3px",
		padding:"4px 3px 2px 3px",
		whiteSpace: "nowrap",
		overflow:"hidden",
		textOverflow:"ellipsis"
	},

	/* Inputs need only 1px padding */
	flexgridinputcontainer:{
		padding:"1px",
		"&:focus-within":{
    		//Emotion changes.... "box-shadow":"inset 0px 0px 0px 1px #8eb7e7",
			boxShadow:"inset 0px 0px 0px 1px #8eb7e7",
			backgroundColor:"#FFF"
		}
	},

	flexgridinput:{
		border:"0px",
		width:"100%",
		height:"100%",
		padding:"4px",
		backgroundColor:"inherit",
		"&:focus":{
			border:"0px",
			/*outline:"1px solid #8eb7e7",*/
			outline:"0px",
			backgroundColor:"#FFF"
		},
		"&::-webkit-inner-spin-button, &::-webkit-outer-spin-button ":{
			//"-webkit-appearance":"none",
			WebkitAppearance: "none",
			margin:"none"
		}
	},


	flexgridinputdisabled:{
		width:"100%",
		border:"0px",
		height:"100%",
		padding:"4px",
		backgroundColor:"#EFEFEF",
		"&::-webkit-inner-spin-button, &::-webkit-outer-spin-button ":{
			//"-webkit-appearance":"none",
			WebkitAppearance: "none",
			margin:"none"
		}
	},

	/* Allows spacer, 30 pixels to the left for use with expand row icon */
	flexgridinput30:{
		border:"0px",
		width: "calc(100% - 30px)",
		height:"100%",
		padding:"4px",
		backgroundColor:"inherit",
		"&:focus":{
			border:"0px",
			outline:"0px",
			//outline:"1px solid #8eb7e7",
			backgroundColor:"#FFF"
		},
		"&::-webkit-inner-spin-button, &::-webkit-outer-spin-button ":{
			//"-webkit-appearance":"none",
			WebkitAppearance: "none",
			margin:"none"
		}
	},

	flexgridspace30:{
		width: "calc(100% - 40px)",
		display: "inline-block"
	},

	flexgridexpand:{
		width: "30px",
		display: "inline-block",
		textAlign: "center",
		fontSize: "18px",
		lineHeight: "15px",
		verticalAlign: "middle",
		//backgroundColor:"#FFF"
	},
	/* For when we need extra wide */
	flexgridexpand60:{
		width: "60px",
		display: "inline-block",
		textAlign: "center",
		fontSize: "18px",
		lineHeight: "15px",
		verticalAlign: "middle",
		//backgroundColor:"#FFF"
	},

	


	gridstaticcheck:{
		position:"relative",
		top:"1px"
	},

	//Grid Resizing
	resizer:{
		position:"absolute",
		top:"0",
		right:"0",
		width:"5px",
		cursor:"col-resize",
		userSelect:"none",
		"&:hover":{
			borderRight:"2px solid #CCC"
		}
	},

	resizing:{
		"&:hover":{
			borderRight:"2px solid #CCC"
		}
	},

	//Used for SKUs
	lowerprice:{
		padding:"4px",
		backgroundColor:"#ceffc7"
	},
	higherprice:{
		padding:"4px",
		backgroundColor:"#ff8888"
	},
	lightgreen:{
		padding:"4px",
		backgroundColor:"#ceffc7"
	},
	lightred:{
		padding:"4px",
		backgroundColor:"#ff8888"
	},
	lightyellow:{
		padding:"4px",
		backgroundColor:"#FFF7BA"
	},
	gray:{
		padding:"4px",
		backgroundColor:"#DDDDDD"
	},
	errorupdate:{
		backgroundColor:"#ff8888"
	},
	flexpopover:{
		pointerEvents: 'none',
	},
	flexpopovercontent:{
		border:"2px solid #8eb7e7",
		padding:"10px",
		width:"350px"
	},

	//Orders Table Elements:
	orderitemtable:{
		fontSize:"14px",
		//fontWeight:"bold",
		width:"100%",
		whiteSpace: "nowrap",
		overflow:"hidden",
		textOverflow:"ellipsis",
		"& > tbody, & > tfoot":{
			"& > tr":{
				"& > td":{
					border:"0px solid #BBB",
					borderSpacing:"0px",
					verticalAlign:"top",
				}
			}
		},
	},

	truncatedtextcontainer: {
		position: "relative",
		maxWidth: "100%",
		padding: "0 !important",
		display: "flex",
		verticalAlign: "text-bottom !important",
	},
	
	truncatedtext:{
		position: "absolute",
		whiteSpace: "nowrap",
		overflowY: "visible",
		overflowX: "hidden",
		textOverflow: "ellipsis",
		//"-ms-text-overflow": "ellipsis",
		//"-o-text-overflow": "ellipsis",
		msTextOverflow: "ellipsis",
		OTextOverflow:"ellipsis",
		maxWidth: "100%",
		minWidth: "0",
		width:"100%",
		top: "0",
		left: "0",
	},

	//Search Input = SearchType width + SearchInput width + 30px
	//Change the 3 widths below to adjust for your view.
	searchinputs: {
		display: "inline-flex",
		marginRight: "10px",
		marginTop: "10px",
		//width:"270px", //NEW! Set your width in your controller!
		height: '32px',
		border: "1px solid #DDD",
		backgroundColor: "#FFF"
	},
	searchtypeinput: {
		backgroundColor: "#EEEEEE", paddingLeft: "10px",
		"& .MuiSelect-select": {paddingTop: "6px", paddingBottom:"7px"},
		"& .Mui-disabled": {
			color:"#000", 
			//"-webkit-text-fill-color":"#000"
			WebkitTextFillColor: "#000"
		}
	},
	searchinput: {
		paddingLeft: "5px",
		//Attempt to disable underline on DateTimePicker
		"& .MuiInput-root":{
			padding:"3px",
			border:"none",
			'&:before':{
				borderBottom:"0px",
			
			},
			'&:after':{
				borderBottom:"0px",
			},
			'&:hover:before':{
				/* Removes default input dark bottom border */
				borderBottom:"0px"
			},
			'& input':{
				padding:"3px 0px 4px 0px" 
			}
		},
	},
	searchinputnoborder:{
		outline:"none",
		border:"none"
	},

	//Bool Select drop downs Yes\No
	searchinputselect:{
		/* Attempt to override MuiInput */
		'& .MuiInput-root':{
			borderBottom:"0px solid red",
		},
		'& .MuiInputBase-root':{
			'&:before':{
				borderBottom:"0px",
			},
			'&:hover':{
				borderBottom:"0px solid blue",
			},
			'&:hover:before':{
				/* Removes default input dark bottom border */
				borderBottom:"0px"
			}
		},
		'& .MuiSelect-select':{
			padding:"4px 8px 1px 5px",
			height:"22px"
		},
	},
	newdatetimepickerinput:{
		border:"0px",
		'& input':{
			padding:"5px 0px 3px 5px"
		},
		'& .MuiOutlinedInput-notchedOutline':{
			border:"0px"
		}
	},
	searchdatetime: {
		//padding:"0px",
		//Possible fix for datetimepickers
		// "& .MuiDateTimePicker-icon:hover": {
		// 	/* Your custom styles here */
		// 	/* For example, change background color and increase size */
		// 	backgroundColor: "blue",
		// 	transform: "scale(1.2)"
		// },
		paddingLeft:"5px",
		paddingRight:"10px",
		"& .MuiSvgIcon-root:hover":{
			backgroundColor: "transparent",
			//transform: "scale(1.2)"
			
		},

		"& .MuiSvgIcon-root":{
			backgroundColor: "transparent",
			//transform: "scale(1.2)"
			
		},

		"& .MuiInput-root":{
			'&:before':{
				borderBottom:"0px",
			},
			'&:after':{
				borderBottom:"0px",
			},
			'&:hover:before':{
				/* Removes default input dark bottom border */
				borderBottom:"0px",
			},
			border:"none",
		},
		"& .MuiInput-input":{
			backgroundColor: "transparent",
		},
		"& .MuiButtonBase-root":{
			backgroundColor: "transparent",
		}
	},
	autocompleteinput:{
		paddingLeft: "5px",
		paddingRight:"5px",
		width:"100%"
	},
	warningbutton:{
		backgroundColor:"#ff9900"
	},
	hidden:{
		display:"none"
	},
	strikethrough:{
		textDecoration:"line-through"
	},
	selectedcolumn: {
		backgroundColor: "#01579B",
		color: "#FFF",
		"&:hover": {
			backgroundColor: "#8eb7e7"
		}
	},
	flexautocomplete:{
		display: "inline-flex",
		marginRight: "10px",
		marginTop: "10px",
		height: '32px',
		border: "1px solid #DDD",
		backgroundColor: "#FFF"
	},
	//Used with pre-set key autocompletes
	undisabled:{
		//color:"rgba(0,0,0,.87)"
		"& .MuiSelect-select": {paddingTop: "6px", paddingBottom:"7px", color:"#000" },
		"& .Mui-disabled":{color:"#000"}
		//color:"#000"
	},
	//Grade Selects - Also used in Selects for PAS system chips.
	gradea: {
		color: "#FFF",
		backgroundColor: "#45b84a",
		"&:hover": {
			backgroundColor: "#4fd455"
		}
	},
	gradeb: {
		color: "#FFF",
		backgroundColor: "#ccb136",
		"&:hover": {
			backgroundColor: "#e4c63e"
		}
	},
	gradec: {
		color: "#FFF",
		backgroundColor: "#ce823e",
		"&:hover": {
			backgroundColor: "#fc9e4a"
		}
	},
	gradebad: {
		color: "#FFF",
		backgroundColor: "#777",
		"&:hover": {
			backgroundColor: "#919191"
		}
	},
	gradescrap: {
		color: "#FFF",
		backgroundColor: "#777",
		"&:hover": {
			backgroundColor: "#919191"
		}
	},
	graderepair: {
		color: "#FFF",
		backgroundColor: "#e9523a",
		"&:hover": {
			backgroundColor: "#fc573d"
		}
	},
	gradeunknown: {
		color: "#000",
		backgroundColor: "#FFF",
		"&:hover": {
			backgroundColor: "#EEE"
		},
		border:"1px solid #BBB"
	},
	/* Try display none - these are chips in the view, so we just remove view of unknown value */
	gradenone: {
		display:"none",
		color: "#FFF",
		backgroundColor: "#FFF",
		"&:hover": {
			backgroundColor: "#919191"
		}
	},
	nodisplay: {
		display: "none"
	},
	//Selects
	//No icon and slim padding:
	selectpadding:{
		//paddingRight:"5px!important",
		padding:"2px 5px!important"
	},
	//With icon
	simpleselect:{
		padding:"4px 24px 2px 2px",
	},
	//Checkboxes
	centeredcheckbox:{
		padding: "6px 4px 1px 4px", textAlign:"center"
	},
	//Checkbox Component (NewCheckbox)
	gridcheckbox:{
		padding:"0px",
		'&.Mui-disabled': {
			//backgroundColor:"#8eb7e7",
			background:"#8eb7e7",
			boxShadow:"inset 0 0 0 1px rgb(16 22 26 / 20%), inset 0 -1px 0 rgb(16 22 26 / 10%)",
			borderRadius: "3px"
		  },
	},
	icon: {
		borderRadius: 3,
		width: 16,
		height: 16,
		margin:"0px",
		boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		backgroundColor: '#f5f8fa',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		//Possibly need MUI V5 update here.... see https://mui.com/material-ui/migration/v5-style-changes/
		//The moneysign may be an issue.
		'$root.Mui-focusVisible &': {
		  outline: '2px auto rgba(19,124,189,.6)',
		  outlineOffset: 2,
		},
		'input:hover ~ &': {
		  backgroundColor: '#ebf1f5',
		},
		'input:disabled ~ &': {
		  boxShadow: 'none',
		  background: 'rgba(206,217,224,.5)',
		},
	  },
	  checkedIcon: {
		backgroundColor: '#137cbd',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
		  display: 'block',
		  width: 16,
		  height: 16,
		  backgroundImage:
			"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
			" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
			"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
		  content: '""',
		},
		'input:hover ~ &': {
		  backgroundColor: '#106ba3',
		},
	},

	//Inventory Manager Bottom Menu
	//Custom input colors:
	invmenuroot:{
		minHeight:"10px",
		flexGrow:1,
		padding:"8px 15px 8px 15px"
	},
	//Also used in Auth Login:
	whitetxtinput: {
		color:"#FFF",
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.white, 0.25),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('xs')]: {
			marginLeft: theme.spacing(1),
			width: 'auto',
		},
	},
	searchIcon: {
		height: '100%',
		//position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	darkinputRoot: {
		color: '#FFF',
	},
	darkinput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('xs')]: {
			width: '12ch',
			'&:focus': {
				width: '28ch',
			},
		},
	},
	darkinputnoicon: {
		color:"#FFF!important",
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		//paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		paddingLeft:"15px",
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('xs')]: {
			width: '12ch',
			'&:focus': {
				width: '28ch',
			},
		}
	},
	errorinput:{
		backgroundColor: "rgba(255, 0, 0, 0.15)"
	},


	//Auth Page
	authinputcontainer: {
		color:"#FFF",
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.white, 0.25),
		},
		marginLeft: 0,
		width: '100%',
	},
	authinputroot:{
		color: '#FFF',
		width:"100%"
	},
	authinput:{
		color:"#FFF!important",
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		//paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		paddingLeft:"15px",
		width: '100%',
	},


	//Row Monitor
	biginput: {
		fontSize:"50px",
		width:"50px"
	},
	rowlabel: {
		display:"inline-block", marginTop:"10px", marginRight:"20px",
		"&:hover": {
			backgroundColor: "#8eb7e7",
			cursor:"pointer"
		}
	},
	//Adjustment for TablePagination text alignment (bug with MaterialUI 5?) Paragraph elements have 1em margin bottom
	paginationalign: {
		"& p":{
			margin:"0px"
		}
	},

	//ITEMS - In the general sense, any page that needs to show an 'item' - could be inventory, parts, coas, anything, but remaining in Key-Value pairs with simple inputs.
	//flexiteminput also works for <textarea>! (See Order View, Sku Title input)
	flexiteminput:{
		/* TRY FONT SIZE HERE */
		fontSize:"18px",
		/* Color of text, even with disabled input, should be black */
		color:"#000",
		borderTop:"0px",
		borderRight:"0px",
		borderLeft:"0px",
		borderBottom:"1px solid #EEE",
		width:"100%",
		padding:"4px 4px 3px 2px",
		backgroundColor:"inherit",
		textOverflow:"ellipsis",
		"&:focus":{
			outline:"0px",
			backgroundColor:"#FFF",
		},
		"&:focus-within":{
			backgroundColor:"#FFF",
			borderBottom:"2px solid #8eb7e7",
			padding:"4px 4px 2px 2px", /* New padding offsets extra fat bottom border. */
		},
		/* Changes input placeholder value text to a lighter gray & smaller text */
		"&::placeholder":{
			color:"#AAA",
		},
		//Try to remove number increment buttons on input
		"&::-webkit-outer-spin-button":{
			//"-webkit-appearance":"none",
			WebkitAppearance: "none",
			margin:"0"
		},
		"&::-webkit-inner-spin-button":{
			//"-webkit-appearance":"none",
			WebkitAppearance: "none",
			margin:"0"
		},
		//"-moz-appearance": "textfield",
		MosAppearance:"textfield",
		//For textarea, only resize vertical?
		resize: "vertical"
	},
	flexitemdateinputcontainer:{

		border:"0px",
		'& input':{
			padding:"0px 0px 3px 0px",
			fontSize:"18px",
			fontWeight:400,
			
		},
		'& .MuiOutlinedInput-notchedOutline':{
			border:"0px"
		},


		padding:"0px 4px 1px 2px",
		borderBottom:"1px solid #EEE",
		"&:focus":{
			outline:"0px",
			backgroundColor:"#FFF",
		},
		"&:focus-within":{
			backgroundColor:"#FFF",
			borderBottom:"2px solid #8eb7e7",
			padding:"0px 4px 0px 2px", /* New padding offsets extra fat bottom border. */
		},
	},
	flexitemdateinput:{
		padding:"0px",
		//Attempt to disable underline on DateTimePicker
		"& .MuiInput-root":{
			'&:before':{
				borderBottom:"0px",
				padding:"0px"
			},
			'&:after':{
				borderBottom:"0px",
				padding:"0px"
			},
			'&:hover:before':{
				/* Removes default input dark bottom border */
				borderBottom:"0px",
				padding:"0px"
			},
			border:"none",
			fontSize:"18px",
			padding:"0px"
		},
		"& .MuiInput-input":{
			padding:"0px"
		},
		"& .MuiButtonBase-root":{
			padding:"0px"
		}
	},

	flexinputblackplaceholder:{
		'&::placeholder':{
			color:"#000"
		}
	},
	/* Placeholder text size option */
	flexinputsmallplaceholder:{
		'&::placeholder':{
			fontSize:"12px"
		}
	},

	//Neat little input background for values IMPLIED to be negative to some balance.
	flexnegativeinput: {
		background:"transparent",
		backgroundImage:`url(${negative})`,
		backgroundRepeat:"no-repeat"
	},

	//Old AutoComplete - Deprecate with new component
	flexitemautocompleteinput:{
		width:"100%",
		border:"0px",
		backgroundColor:"inherit",
		"& input":{
			borderBottom:"1px solid #EEE",
			padding:"4px 4px 2px 4px",
			width:"100%",
			border:"0px",
			"&:focus":{
				outline:"0px",
				backgroundColor:"#FFF",
			},
			"&:focus-visible":{
				border:"0px",
				backgroundColor:"#FFF",
				padding:"4px 4px 1px 4px", /* New padding offsets extra fat bottom border. */
				borderBottom:"2px solid #8eb7e7",
			},
			'&::placeholder':{
				/* Changes input placeholder value text to black - Allows initial placeholder values to come from useState variables and still look editable */
				color:"#000"
			},
			"&:disabled":{
				//Make the input look like any other static input
				backgroundColor:"#FFF"
			}
		},
	},


	//DB AUTO COMPLETE - The new way
	//Two versions: 
	//Black placeholder text because it looks like the rest of the form.
	//Gray placeholder text because some things should look incomplete
	dbautocompleteblack:{
		width:"100%",
		border:"0px",
		backgroundColor:"inherit",
		"& input":{
			borderBottom:"1px solid #EEE",
			padding:"4px 4px 2px 4px",
			width:"100%",
			border:"0px",
			"&:focus":{
				outline:"0px",
				backgroundColor:"#FFF",
			},
			"&:focus-visible":{
				border:"0px",
				backgroundColor:"#FFF",
				padding:"4px 4px 1px 4px", /* New padding offsets extra fat bottom border. */
				borderBottom:"2px solid #8eb7e7",
			},
			'&::placeholder':{
				/* Changes input placeholder value text to black - Allows initial placeholder values to come from useState variables and still look editable */
				color:"#000"
			},
			"&:disabled":{
				//Make the input look like any other static input
				backgroundColor:"#FFF"
			}
		},
	},

	dbautocompletesearchinput:{
		width:"100%",
		marginTop:"3px",
		border:"0px",
		backgroundColor:"inherit",
		border:"0px",
		"& div":{
			borderBottom:"0px !important"
		},
		"& input":{
			//borderBottom:"1px solid #EEE",
			padding:"4px 4px 2px 4px",
			width:"100%",
			border:"0px",
			"&:focus":{
				outline:"0px",
				backgroundColor:"#FFF",
			},
			"&:focus-visible":{
				border:"0px",
				backgroundColor:"#FFF",
				padding:"4px 4px 1px 4px", /* New padding offsets extra fat bottom border. */
				//borderBottom:"2px solid #8eb7e7",
			},
			'&::placeholder':{
				/* Changes input placeholder value text to black - Allows initial placeholder values to come from useState variables and still look editable */
				color:"#000"
			},
			"&:disabled":{
				//Make the input look like any other static input
				backgroundColor:"#FFF"
			}
		},
	},

	dbautocompletegray:{
		width:"100%",
		border:"0px",
		backgroundColor:"inherit",
		"& input":{
			borderBottom:"1px solid #EEE",
			padding:"4px 4px 2px 4px",
			width:"100%",
			border:"0px",
			"&:focus":{
				outline:"0px",
				backgroundColor:"#FFF",
			},
			"&:focus-visible":{
				border:"0px",
				backgroundColor:"#FFF",
				padding:"4px 4px 1px 4px", /* New padding offsets extra fat bottom border. */
				borderBottom:"2px solid #8eb7e7",
			},
			'&::placeholder':{
				/* Changes input placeholder value text to black - Allows initial placeholder values to come from useState variables and still look editable */
				color:"#CCC"
			},
			"&:disabled":{
				//Make the input look like any other static input
				backgroundColor:"#FFF"
			}
		},
	},

	searchinputautocomplete:{
		//width:"100%",
		marginTop:"0px",
		border:"0px",
		backgroundColor:"inherit",
		border:"0px",
		"& div":{
			borderBottom:"0px !important"
		},
		"& input":{
			//borderBottom:"1px solid #EEE",
			padding:"5px 0px 2px 3px",
			width:"100%",
			border:"0px",
			"&:focus":{
				outline:"0px",
				backgroundColor:"#FFF",
			},
			"&:focus-visible":{
				border:"0px",
				backgroundColor:"#FFF",
				padding:"4px 4px 1px 4px", /* New padding offsets extra fat bottom border. */
				//borderBottom:"2px solid #8eb7e7",
			},
			'&::placeholder':{
				/* Changes input placeholder value text to black - Allows initial placeholder values to come from useState variables and still look editable */
				color:"#000"
			},
			"&:disabled":{
				//Make the input look like any other static input
				backgroundColor:"#FFF"
			}
		},
	},



	flexitembasicselect:{
		/* Attempt to override MuiInput */
		'& .MuiInput-root':{
			borderBottom:"1px solid #EEE",
		},
		'& .MuiInputBase-root':{
			'&:before':{
				borderBottom:"0px",
			},
			'&:hover':{
				borderBottom:"1px solid #8eb7e7",
			},
			'&:hover:before':{
				/* Removes default input dark bottom border */
				borderBottom:"0px"
			}
		},
		'& .MuiSelect-select':{
			padding:"0px",
			height:"27px"
		},
		'& h6':{
			fontWeight:"400",
			paddingLeft:"2px"
		}
	},

	flexitemstaticinput:{
		fontSize:"18px",
		borderBottom:"1px solid #EEE",
		padding:"0px 0px 0px 4px",
		width:"100%",
		fontWeight:"400",
		minHeight:"29px"
	},

	flexitemstaticinputnoborder:{
		fontSize:"18px",
		padding:"1px 0px 0px 4px", //Add 1px padding to make up for no border
		width:"100%",
		fontWeight:"400",
		minHeight:"29px"
	},


	itemtable:{
		width:"100%",
		tableLayout:"fixed",
		borderCollapse: "collapse",
		borderSpacing:"0",
		marginTop:"10px",
		"& tr":{
			"& td:first-child":{ //First column
				fontSize:"18px",
				textAlign:"right",
				verticalAlign:"top",
				padding:"1px 5px 2px 0px"
			},
			"& td:nth-child(2)":{ //second column
				fontSize:"18px",
				textAlign:"left",
				verticalAlign:"top",
				padding:"1px 0px 2px 0px"
			}
		},
		'& td':{
			overflow:"hidden"
		}
	},
	flextabledebug:{
		//Comment below for live view
		//border:"1px solid #000"
	},

	simpletable:{
		border:"1px solid #CCC",
		width:"100%",
		borderCollapse: "collapse",
		"& thead>tr>td":{
			fontSize:"15px",
			fontWeight:"500",
			borderBottom:"1px solid #CCC",
		},
		"& tr>td":{
			fontWeight:"400",
			borderBottom:"1px solid #CCC",
		}
	},

	itemtableheader:{
		//background: "rgb(255,255,255)",
		//background: "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(236,236,236,1) 31%)",
		backgroundColor:"#DDD",
		color:"#000",
		verticalAlign:"bottom",
		lineHeight:"1",
		fontSize:"14px",
		width:"100%",
		fontWeight: "400",
		textAlign: "left",
		marginTop:"30px",
		paddingTop:"5px",
		paddingLeft:"5px",
		borderBottom:"2px solid #CCC"
	},


	flexactivebtn:{
		color:"white",
		backgroundColor:"#01579B",
		/* Avoid any background color change on hover */
		'&:hover':{
			backgroundColor:"#01579B"
		}
	},

	flexaccordion: {
		border: "1px solid #EEE",
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(90deg)',
		  },
		  '& .MuiAccordionSummary-content': {
			marginLeft: theme.spacing(1),
		  },
		  '& Mui-expanded':{
			  margin:"0px"
		  },
		  '& MuiAccordionDetails-root':{
			  padding:"8px 0px !important"
		  },
		  '& MuiPaper-root':{
			  margin:"0px"
		  },
		  '& MuiAccordion-root.Mui-expanded':{
			margin:"0px"
		},
		'& .Mui-expanded':{
		  margin:"0px"
	  	},
	},

	//Condition pills!
	damagedcondition:{
		position:"relative",
		wordBreak:"break-all",
		backgroundColor:"#D9534F",
		border:"1px solid #D43F3A",
		color:"#FFF",
		marginLeft:"2px",
		borderRadius:"3px",
		display:"inline-block",
		paddingLeft:"5px",
		// paddingRight:"25px",
		margin:"1px",
		fontWeight:"400",
		lineHeight:"27px"
	},
	majorcondition:{
		position:"relative",
		wordBreak:"break-all",
		backgroundColor:"#f0ad4e",
		border:"1px solid #eea236",
		color:"#FFF",
		marginLeft:"2px",
		borderRadius:"3px",
		display:"inline-block",
		paddingLeft:"5px",
		// paddingRight:"25px",
		margin:"1px",
		fontWeight:"400",
		lineHeight:"27px"
	},
	minorcondition:{
		position:"relative",
		wordBreak:"break-all",
		backgroundColor:"#ccbe00",
		border:"1px solid #ada100",
		color:"#FFF",
		marginLeft:"2px",
		borderRadius:"3px",
		display:"inline-block",
		paddingLeft:"5px",
		//paddingRight:"25px",
		margin:"1px",
		fontWeight:"400",
		lineHeight:"27px"
	},
	//Item view, conditions as a part of history mode
	staticcondition:{
		position:"relative",
		wordBreak:"break-all",
		backgroundColor:"#BBB",
		border:"1px solid #AAA",
		color:"#FFF",
		marginLeft:"2px",
		borderRadius:"3px",
		display:"inline-block",
		paddingLeft:"5px",
		//paddingRight:"25px",
		margin:"1px",
		fontWeight:"400",
		lineHeight:"27px"
	},
	removedcondition:{
		position:"relative",
		wordBreak:"break-all",
		backgroundColor:"#FFA1A1",
		textDecoration:"line-through",
		border:"1px solid #AAA",
		color:"#FFF",
		marginLeft:"2px",
		borderRadius:"3px",
		display:"inline-block",
		paddingLeft:"5px",
		//paddingRight:"25px",
		margin:"1px",
		fontWeight:"400",
		lineHeight:"27px"
	},

	conditiondeleteicon:{
		// position:"absolute",
		// top:"0",
		// right:"0",
		
		width:"25px",
		height:"25px",
		verticalAlign:"bottom",
		marginBottom:"2px",
		"&:hover": {
			cursor: "pointer"
		}
	},


	/* Components! */
	productcomponent:{
		display:"inline-block",
		backgroundColor:"#f0ad4e",
		color:"#FFF",
		borderRadius:"3px",
		padding:"1px 3px 0px 5px",
		marginLeft:"4px",
		position:"relative"
	},

	productcomponentdeleteicon:{
		top:"0",
		right:"0",
		width:"17px",
		height:"17px",
		backgroundColor:"#c78f3f",
		borderRadius:"3px",
		verticalAlign:"bottom",
		marginBottom:"2px",
		"&:hover": {
			cursor: "pointer"
		}
	},

	skucomponent:{
		display:"inline-block",
		backgroundColor:"#5cb85c",
		color:"#FFF",
		borderRadius:"3px",
		padding:"1px 3px 0px 5px",
		marginLeft:"4px",
		position:"relative"
	},

	skucomponentdeleteicon:{
		top:"0",
		right:"0",
		width:"17px",
		height:"17px",
		backgroundColor:"#468a46",
		borderRadius:"3px",
		verticalAlign:"bottom",
		marginBottom:"3px",
		"&:hover": {
			cursor: "pointer"
		}
	},

	/* Default Hidden Message - General Usage */
	defaulthidden: {
		display:"none",
	},

	whitetext: {
		color:"#FFF"
	},

	/* New Standard backgrounds for various messages: Used in Orders */
	greenmsg: {
		backgroundColor:"#24ac00"
	},

	warningmsg: {
		backgroundColor:"#ffa70e"
	},



	/* Used for Bottom Bar  select with white text */
	whitetransparentselect:{
		/* Attempt to override MuiInput */
		'& .MuiInput-root':{
			borderBottom:"1px solid #cce2ff",
		},
		'& .MuiInputBase-root':{
			'&:before':{
				borderBottom:"0px",
			},
			'&:hover':{
				borderBottom:"1px solid #FFF",
			},
			'&:hover:before':{
				/* Removes default input dark bottom border */
				borderBottom:"0px"
			}
		},
		'& .MuiSelect-select':{
			padding:"0px",
			height:"27px"

		},
		icon:{
			color:"fill"
		},
		'& h6':{
			fontWeight:"400",
			paddingLeft:"2px",
			color:"#cce2ff"
		}
	},
	/* White icon for select */
	whiteicon:{
		fill:"#cce2ff"
	},

	errlink:{
		color:"#FFF",
		textDecoration:"underline"
	},



	//Order View
	flexorder:{
		/*
		"@media print":{
			pageBreakAfter:"always",
			breakInside: "avoid",
			pageBreakInside:"avoid",
			"page-break-inside":"avoid"
		}
		*/
	},


	flextable:{
		//wordWrap:"break-word",
		tableLayout:"auto",
		border:"2px solid #D4D4D4",
		borderCollapse: "collapse",
		borderSpacing:"0",
		"& > thead > tr > td":{
			border:"1px solid #F9F9F9",
			borderSpacing:"0px",
			padding:"3px"
		},
		"& > tfoot > tr > td":{
			border:"1px solid #F9F9F9",
			borderSpacing:"0px",
			padding:"3px"
		},
		"& > tr > td":{
			border:"1px solid #F9F9F9",
			borderSpacing:"0px",
			padding:"3px"
		},
		/*
		"@media print":{
			breakInside: "avoid",
			pageBreakInside:"avoid",
			"page-break-inside":"avoid"
		}*/
	},

	flextableheader:{
		padding:"6px",
		borderBottom:"2px solid #D4D4D4",
		backgroundColor:"#EAEAEA",
		fontWeight:"bold"
	},




	//Inspection View
	inspectionitem:{
		fontSize: "14px",
		borderRadius:"3px",
		//border:"1px solid #AAA",
		backgroundColor:"#FFF"
	},

	whiteborder:{
		border:"3px solid #FFF"
	},


	defaultitemcontainer:{
		border:"1px solid #AAA",
		borderRadius:"3px",
		margin:"0px 5px 5px 5px",
	},
	selecteditemcontainer:{
		border:"1px solid #DDD",
		borderRadius:"5px",
		margin:"0px 5px 5px 5px",
	},




	inspectionitemheader:{
		backgroundColor:"#CCC",
		wordBreak:"break-all",
		borderBottom:"1px solid #AAA",
		padding:"6px 6px 3px 6px"
	},
	unassigneditemheader:{
		backgroundColor:"#FFF7BA",
		wordBreak:"break-all",
		borderBottom:"1px solid #AAA",
		padding:"6px 6px 3px 6px"
	},
	inspectionitemdetails:{
		padding:"3px 6px"
	},
	inspectionspecs:{
		borderCollapse: "collapse",
		borderSpacing:"0",
		"& tr":{
			"& td:first-child":{ //First column
				fontSize:"14px",
				textAlign:"right",
				fontWeight:"bold",
				verticalAlign:"top",
				padding:"1px 5px 2px 0px"
			},
			"& td:nth-child(2)":{ //second column
				fontSize:"14px",
				textAlign:"left",
				verticalAlign:"top",
				padding:"1px 0px 2px 0px"
			}
		}
	},
	inspectionrowerror:{
		borderBottom:"2px solid red"
	},

	inspectionrowwarning:{
		borderBottom:"2px solid #ffcd5b"
	},

	inspectionoverfull:{
		color:"red"
	},







	"@media print":{
		flexorder:{
			pageBreakAfter:"always",
			breakInside: "avoid",
			pageBreakInside:"avoid",
			//"page-break-inside":"avoid"
			pageBreakInside:"avoid",
		},

		flextable:{
			breakInside: "avoid",
			pageBreakInside:"avoid",
			//"page-break-inside":"avoid"
			pageBreakInside:"avoid"
		},

		hidefromprint:{
			display:"none!important",
			height:"0px"
		},
		printspacer20:{
			width:"100%",
			height:"20px",
			display:"block"
		}
	},

	//The following hides from view, but shows in print. This adjusts for some visual effects we'd like to avoid in print view such as no padding at top.
	"@media screen":{
		printspacer20:{
			display:"none"
		}
	},



}

export default styles;
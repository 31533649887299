import React from 'react';

import hdd from '../images/hwicons/hdd.png';
import ssd from '../images/hwicons/ssd.png'
import cpu from '../images/hwicons/cpu.png'
import addon from '../images/hwicons/addon.png'
import bluetooth from '../images/hwicons/bluetooth.png'
import cable from '../images/hwicons/cable.png'
import cableadapter from '../images/hwicons/cableadapter.png'
import coa from '../images/hwicons/coa.png'
import keyboard from '../images/hwicons/keyboard.png'
import monitor from '../images/hwicons/monitor.png'
import mouse from '../images/hwicons/mouse.png'
import mouseandkeyboard from '../images/hwicons/mouseandkeyboard.png'
import optical from '../images/hwicons/optical.png'
import ram from '../images/hwicons/ram.png'
import videocard from '../images/hwicons/videocard.png'
import webcam from '../images/hwicons/webcam.png'
import wificard from '../images/hwicons/wificard.png'



const HardwareIcon = (props) => {
	
	return(
		<>
			{(props.hwtype==="Hard Drive" && props.hwname.indexOf('SSD')===-1  && props.hwname.indexOf('NVMe')===-1) &&
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={hdd} />	
			}
			{(props.hwtype==="Hard Drive" && (props.hwname.indexOf('SSD')>-1 || props.hwname.indexOf('NVMe')>-1)) &&
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={ssd} />	
			}
			{(props.hwtype==="CPU") &&
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={cpu} />	
			}
			{(props.hwtype==="RAM") &&
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={ram} />
			}
			{(props.hwtype==="COA") &&
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={coa} />	
			}
			{(props.hwtype==="Optical") &&
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={optical} />
			}
			{(props.hwtype==="Graphics Card") &&
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={videocard} />
			}
			{(props.hwtype==="Keyboard") &&
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={keyboard} />
			}
			{(props.hwtype==="Keyboard & Mouse") &&
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={mouseandkeyboard} />
			}
			{(props.hwtype==="Monitor") &&
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={monitor} />
			}
			{(props.hwtype==="Mouse") &&
					<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={mouse} />
			}
			{(props.hwtype==="Add-On") &&
					<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={addon} />
			}
			{(props.hwtype==="Bluetooth") &&
					<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={bluetooth} />
			}
			{(props.hwtype==="Webcam") &&
					<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={webcam} />
			}
			{(props.hwtype==="Resolution") &&
					<span></span>
			}
			
		</>
	)
}

    


export default HardwareIcon;
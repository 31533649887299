//Order View

//The Order View can be called on it's own with only a URL param (id = InternalID)
//The Order View can also be called as a child component in other tables via props.currentids (Array of ids)

/* ##########################  Configuration Sections  ########################## */
//## UseState Variables
//## Column States
//##Column Configuration
//##Column Toggles
//##Row Design
//##Search Inputs
//##Button Functions

//React & Friends
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import PropTypes from 'prop-types';

//QR Code
import ReactDOM from "react-dom";
import QRCode from "react-qr-code";

//Inspection View Companion!
import InspectionView from "./InspectionView";

//Redux Features
import { useSelector, useDispatch } from 'react-redux';
//ProgressBar
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import { ProgressBar } from '../../features/progressbar/ProgressBar';
import {
	newProgress,
	incrementPass,
	incrementFail,
	setProgressTimeout
} from '../../features/progressbar/progressbarSlice';
//Main Menu
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';
//New Error Message
import { NewErrorMessage } from '../../features/error/NewErrorMessage';
import {
	newErrorMessage,
	setErrorTimeout
} from '../../features/error/errormessageSlice';

//Contexts
//AuthContext
import { AppContext } from "../Auth/contexts/AppContext"
//Error Context - warning, danger, ok, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

import DBAutoComplete from '../common/DBAutoComplete';


//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";

//Datetime formatting
import Moment from 'react-moment';
import dayjs from 'dayjs'; //Used with new datetimepickers

//Restrict Numbers both float and integer types
import RestrictInputNumber from "../common/RestrictInputNumber";

//MaterialUI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';

//Tables
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

//Icons
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PreviewIcon from '@mui/icons-material/Preview';
import PrintIcon from '@mui/icons-material/Print';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import IosShareIcon from '@mui/icons-material/IosShare';

//Datetime Pickers
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; //Possibly our new adapter for mui x 7 datetime


//Buttons
import ButtonGroup from '@mui/material/ButtonGroup';

//Accordions
import Accordion from '@mui/material/Accordion';
//import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion from '@mui/material/Accordion';

//Export
import ExportCSV from '../common/ExportCSV';

import { useReactToPrint } from 'react-to-print';
import Barcode from 'react-barcode';


import HardwareIcon from "../common/HardwareIcon";

//FlexDocument
import { FlexDocument } from '../Documents/FlexDocument';


/* ##########################  Configuration  ########################## */

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	//timeout:11800,
	timeout: 300000
};

//Helper Functions
//Have not used sleep just yet - is currently on auto-complete sample
function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}

function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this, args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};




const OrdersView = (props) => {
	const dispatch = useDispatch();
	//Props:
	//props.currentids - Determines what IDs we need to load.
	//props.viewmode - Determines what view we need
	//props.isChild - Determines how we link back to the orders table (hard URL change)

	//OrdersView can take a single ID from params or it can use props when it's a child of another controller.
	var currentids = [];
	var batchmode = false;

	//Either IDs are sent through props (table of orders):
	if (props.isChild) {
		currentids = props.currentids;
		if (currentids.length>1){
			batchmode = true;
			console.log("THIS"); 
		}
	}
	//Or one ID is sent through params:
	// order/{internalid}
	console.log("Where and when");
	let { id } = useParams();
	//console.log(id);
	if (id) {
		currentids.push(id);
	}

	console.log(props.isChild);
	const isSingleOrder = !props.isChild;

	//Init state from props - mutable!
	const [viewmode, setViewMode] = useState(props.viewmode);

	let history = useHistory();

	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;

	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);
	const isPrintView = useMediaQuery("print");


	const rowRefs = useRef([]);


	/* ##########################  UseState Variables  ########################## */
	const [state, setState] = useState({
		//Need to initialize a blank item
		dbreload: true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		currentids: currentids,
		batchmode: props.batchmode,
		//id: id, //TRY - Init new id via URL params. If we update ID from results on SaveChanges, we can avoid having to deal with URL params on history.push (which doesn't seem to change much more than change the current URL, not useState variables.)
		pagereload: false,
		ordersloaded: false, //POSSIBLY MOST IMPORTANT! Using defaultValue on an input causes issues if you try to render before item is loaded.
		pendingupdate: false,
		productsearchterm: '',
		ordersdata: {},
		expiryitems: 0,
		refreshexpiry: false,
		draft: props.draft,
		currentorder: {} //Used for Edit Order / Details
	});


	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Item (Order)
	//May not be used?
	function LoadItem(itemid) {
		/*
		const postdata = {
			internalid:itemid
		};
		*/
		axios.get(dbendpoint + "/order/" + itemid).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					localstate.orderdata = res.data.order[0]; //Fix later, issue is in controller - it's an old method.





					//Cost is always nice to have pre-formatted
					//localstate.itemdata.Cost = parseFloat(res.data.item.Cost).toFixed(2);

					// SetGrade(res.data.item.Grade, true);
					// if (res.data.item.BodyGrade){
					// 	SetBodyGrade(res.data.item.BodyGrade, true);
					// }

					localstate.dbreload = false;
					localstate.ordersloaded = true; //Now allow render
					UpdateState(localstate);
				}

				if (res.data.Status === "Failure") {
					//Failure error
					localstate.dbreload = false;
					UpdateState(localstate);
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
		});
	}

	function LoadOrders(ordersarray) {
		const postdata = {
			ordersarray: ordersarray,
			refreshexpiry: false
		};

		if (localstate.refreshexpiry) {
			postdata.refreshexpiry = true;
			localstate.refreshexpiry = false;
		}

		axios.post(dbendpoint + "/orders/getordersbyarray", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					//localstate.orderdata = res.data.order[0]; //Fix later, issue is in controller - it's an old method.
					localstate.ordersdata = res.data.orders;
					localstate.defaultordersdata = res.data.orders;

					//Provision to set current order if it's the only order:
					if (localstate.currentids.length === 1) {
						localstate.currentorder = res.data.orders[0];
						localstate.currentorder.showtransactions = false;
					}

					//Adjustments to data:
					//Subtotal Calculation
					//Locations needs to be array.
					var subtotal = 0;
					var chargetotal = 0;
					var total = 0;
					//Loops orders
					for (var i = 0; i < localstate.ordersdata.length; i++) {
						localstate.ordersdata[i].editorder = false; //Mark 

						//SubTotal
						subtotal = 0;
						for (var j = 0; j < localstate.ordersdata[i].orderitems.length; j++) {
							subtotal += localstate.ordersdata[i].orderitems[j].Quantity * localstate.ordersdata[i].orderitems[j].UnitPrice;
						}
						localstate.ordersdata[i].SubTotal = subtotal.toFixed(2);

						//Datetime Formatting
						localstate.ordersdata[i].OrderDate = dayjs(localstate.ordersdata[i].OrderDate);

					}

					localstate.expiryitems = res.data['ExpiryItems'];




					//Cost is always nice to have pre-formatted
					//localstate.itemdata.Cost = parseFloat(res.data.item.Cost).toFixed(2);

					localstate.dbreload = false;
					localstate.ordersloaded = true; //Now allow render
					UpdateState(localstate);
				}

				if (res.data.Status === "Failure") {
					//Failure error
					localstate.dbreload = false;
					UpdateState(localstate);
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
		});
	}

	/* ##########################  App Init  ########################## */
	//Run once, don't clean up until controller dismount (unchanging init variable)
	const [appinit] = useState(true);
	useEffect(() => {
		document.title = "Orders View";	
		
		//Cleanup
		return function cleanup() {
			dispatch(setErrorTimeout(0));
		}
	}, [appinit]);

	//IMPORTANT: A big fix here was avoiding DB reloads when the state hasn't changed. Making this useEffect dependent on state changes avoids duplicate loads.
	useEffect(() => {
		if (state.dbreload) {
			//Avoid duplicate loads.
			localstate.dbreload = false;
			if (currentids.length > 0) {
				LoadOrders(currentids);
			}
			//LoadItem(localstate.id);
		} else {
			//Data Loaded - Go ahead and get subtotal and fix any issues with calculation of order
			if (isSingleOrder){
				document.title = "Order "+localstate.currentorder.OrderID;
			} else {
				document.title = "Orders View";	
			}

		}
	}, [state]);


	//Restrict Number (too many places past the decimal)
	const RestrictNumber = (newvalue, oldvalue) => {
		var len = newvalue.length;
		var index = newvalue.indexOf('.');
		if (index > 0) {
			var decimalchars = (len - 1) - index;
			if (decimalchars > 2) {
				return oldvalue;
			}
		}
		return newvalue;
	}

	//Restrict Number to Integer - Don't accept any decimal.
	const RestrictNumberInteger = (newvalue, oldvalue) => {
		console.log(newvalue);
		if (newvalue < 0) {
			return 0;
		} else {
			return parseInt(newvalue);
		}
	}



	//Ref used for printing - reusable since none of the functional components should render at the same time.
	const componentRef = useRef();



	const EditOrder = (index) => {
		localstate.currentorder = localstate.ordersdata[index];
		UpdateState(localstate);
		setViewMode("details");
	}

	const capitalizeFirst = str => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};




	//####################  ORDER DETAILS ###################### */
	const OrderDetails = (props) => {

		//This likely needs it's own stateful things:
		//Let's try.... May be possible... If we're making changes to the front end that need to be consistent, we might sent all things together with a new object:

		//Grab sync'd order variable. Override order items!
		//var neworderobject = order;
		//neworderobject.orderitems = orderitems;
		//setCurrentOrder(neworderobject);
		const [currentorder, setCurrentOrder] = useState(props.currentorder);
		//Clone current order
		//let order = Object.assign({}, currentorder);
		let order = { ...currentorder };


		//Order Items
		//We need a stateful orderitems because we might add more order item elements to the page!
		const [currentorderitems, setOrderItems] = useState(props.currentorder.orderitems);
		//Clone current order items
		let orderitems = [...currentorderitems];

		//Order Charges
		//We need a stateful order charges because we might add more charge elements to the page!
		const [currentordercharges, setOrderCharges] = useState(props.currentorder.charges);
		//Clone current order charges
		let ordercharges = [...currentordercharges];


		//Interesting, we can push these two extrapolated variables back togther and update state:
		const RefreshOrderState = () => {
			var neworderobject = order;
			neworderobject.orderitems = [...orderitems];
			neworderobject.charges = [...ordercharges];
			setCurrentOrder(neworderobject);
		}

		//console.log(order);

		const btnSave = useRef();
		const btnPendingSave = useRef();
		const btnExportToShipStation = useRef();


		//Sync Save Button
		useEffect(() => {
			//Start out with assumption export btn should be disabled?
			btnExportToShipStation.current.style.display = "none";
			if (order.pendingsaves) {
				btnSave.current.style.display = "none";
				btnExportToShipStation.current.style.display = "none";
				btnPendingSave.current.style.display = "";
			} else {
				btnSave.current.style.display = "";
				btnPendingSave.current.style.display = "none";
				//If this particular order is missing a shipstation ID, we can try to export the order and fetch one!
				if (currentorder.SFullName!== null && currentorder.Store !== null){
					if (currentorder.SSID === null && currentorder.orderitems.length>0 && currentorder.SFullName.length >2 && currentorder.Store.length>2){
						btnExportToShipStation.current.style.display = "";
					}
				}
			}
			FactorOrder();
		},);


		const DetectBlankNumber = (event, index, itemkey) => {
			if (event.target.value === "") {
				orderitemRefs.current[index + itemkey].value = "0.00";
				orderitems[index][itemkey] = "0.00";
			}
		}

		const DetectBlankNumberInteger = (event, index, itemkey) => {
			if (event.target.value === "") {
				orderitemRefs.current[index + itemkey].value = "0";
				orderitems[index][itemkey] = "0";
			}
		}


		//Charges chargeRefs.current[chargeindex]
		const DetectBlankNumberFloat = (event, index, column) => {
			if (event.target.value === "") {
				chargeRefs.current[index].value = "0.00";
				ordercharges[index]["Amount"] = "0.00";
			} else {
				chargeRefs.current[index].value = parseFloat(event.target.value).toFixed(2);
				ordercharges[index]["Amount"] = parseFloat(event.target.value).toFixed(2);
			}
		}



		const SaveChanges = (checkbackinbool) => {
			//Shortcut to Delete
			if (order.hasOwnProperty('DeleteOrder')) {
				//alert("delete!");
				DeleteOrder();
				return;
			}
			//Basic Validation:
			for (var i = 0; i < orderitems.length; i++) {
				//Ignore a pending orderitem set for deletion
				if (!orderitems[i].hasOwnProperty('DeleteOrderItem')) {
					//We get orders all the time without product associated such as ebay or anything that doesn't send a proper SKU with an associated product. If the order item isn't generated from the 
					//imports, then we need to check for Product
					if (orderitems[i].hasOwnProperty('newsku') && !orderitems[i].sku.ProductID) {
						dispatch(newErrorMessage({ errmsg: 'One or more order items is missing a valid product.', errshow: true, errtimeout: 300, errtype: 'warning' }));
						dispatch(setErrorTimeout(3));
						return;
					}
					if (!orderitems[i].sku.Title) {
						dispatch(newErrorMessage({ errmsg: 'One or more order items is missing a valid Sku Title.', errshow: true, errtimeout: 300, errtype: 'warning' }));
						dispatch(setErrorTimeout(3));
						return;
					}
					if (!orderitems[i].UnitPrice) {
						dispatch(newErrorMessage({ errmsg: 'One or more order items is missing a valid price.', errshow: true, errtimeout: 300, errtype: 'warning' }));
						dispatch(setErrorTimeout(3));
						return;
					}
					console.log("Quantity: " + orderitems[i].Quantity);
					if (!orderitems[i].Quantity && orderitems[i].Quantity !== 0) {
						dispatch(newErrorMessage({ errmsg: 'One or more order items is missing a valid quantity.', errshow: true, errtimeout: 300, errtype: 'warning' }));
						dispatch(setErrorTimeout(3));
						return;
					}
				}
			}
			//Compact state and continue:
			RefreshOrderState();
			//Perform Final Factor
			FactorOrder();
			let postdata = {
				order: order,
				ordersarray: [order.InternalID] //Allows us to inspect the order as it exists in the DB after save - chain onto getordersbyarray because it gets all the data (manifest, shipments, charges, etc)
			}
			axios.post(dbendpoint + "/orders/update", postdata, defaultpostoptions).then(res => {
				//API should be setup to send 200 response with status. Merge paginated requests.
				if (res.status === 200) {
					//If ValidateUser() fails to verify user, it sends back 'login' error. 
					if (res.data.Status === "login") {
						//Not logged in. Reload page causes redirect to /login
						window.location.reload(false);
					}
					//All new API calls should return a status and whole order object - To Do: set new object
					if (res.data.Status === "Success") {
						btnSave.current.style.display = "";
						btnPendingSave.current.style.display = "none";
						dispatch(newErrorMessage({ errmsg: "Order Saved", errshow: true, errtimeout: 20, errtype: "ok" }));
						dispatch(setErrorTimeout(3));
						order.pendingsaves = false;
						var result = res.data.item;
						//Datetime Formatting
						result.OrderDate = dayjs(result.OrderDate);
						setCurrentOrder(result);
						setOrderItems(result.orderitems)
						setOrderCharges(result.charges);
						messageref.current.style.display = "none";
					}
					if (res.data.Status === "Failure") {
						//Failure error
						dispatch(newErrorMessage({ errmsg: res.data.message, errshow: true, errtimeout: 20, errtype: "warning" }));
					}
				} else {
					//Non-200 message from server.
					dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 20, errtype: "warning" }));
				}
			}).catch((err) => {
				console.log(err);
				dispatch(newErrorMessage({ errmsg: "Request error.", errshow: true, errtimeout: 20, errtype: "warning" }));
			});
		}

		const onInternalNoteFocus = (event) => {
			var scrollheight = orderRefs.InternalNotes.current.scrollHeight;
			orderRefs.InternalNotes.current.style.height = (scrollheight) + "px";
		}

		//Changes to order
		const onChangeValue = (event, itemkey) => {
			var oldvalue = currentorder[itemkey];
			var newvalue = event.target.value;
			if (event.key !== "Tab" &&
				event.key !== "ArrowDown" &&
				event.key !== "ArrowUp" &&
				event.key !== "ShiftLeft" &&
				event.key !== "ShiftRight"
			) {

				//All others
				if (
					itemkey === "OrderID" ||
					itemkey === "LastName" ||
					itemkey === "Date"
				) {

					//setCurrentOrder(order);
				}

				//Provision to change textarea size
				if (itemkey === "InternalNotes") {
					var scrollheight = orderRefs.InternalNotes.current.scrollHeight;
					orderRefs.InternalNotes.current.style.height = (scrollheight) + "px";
				}


				order[itemkey] = newvalue;
				order.pendingsaves = true;
				console.log(newvalue);
				btnSave.current.style.display = "none";
				btnPendingSave.current.style.display = "";
				RefreshOrderState();
			}

		}

		const onChangeDatetimeValue = (newvalue, itemkey) => {
			order[itemkey] = newvalue;
			order.pendingsaves = true;
			setCurrentOrder(order);
		}

		const onDBAutoCompleteChange = (newvalue, searchtype, itemindex) => {

			//Only certain completions of autocomplete should trigger change.
			if (searchtype === "markets" || searchtype === "products" || searchtype === "components") {
				btnSave.current.style.display = "none";
				btnPendingSave.current.style.display = "";
				order.pendingsaves = true;
			}


			if (searchtype === "markets") {
				order.Store = newvalue.Name;
				//setCurrentOrder(order);
			}

			if (searchtype === "products") {
				//Affects sku, not order item!
				console.log(newvalue);
				//defaultsearchterm={(orderitem.sku.product ? orderitem.sku.product.Name : "")}
				orderitems[itemindex].sku.ProductID = newvalue.ProductID;
				orderitems[itemindex].sku.product = newvalue;
				//We really didn't need to clear out components:
				//orderitems[itemindex].sku.skucomponents = [];

				//I think I was missing this?
				orderitems[itemindex].product = newvalue;
				orderitems[itemindex].ProductID = newvalue.ProductID;
				RefreshOrderState();
				//setCurrentOrder(order);
			}

			//This is assuming we're picking a sku that already exists!
			if (searchtype === "skus") {
				//Affects sku, not order item!
				console.log(newvalue);

				//One of Two things happens:
				//1. We create a new sku (allow product selection and title), don't set ProductID right away
				//2. We select a sku and it's awesome

				if (newvalue.hasOwnProperty('addnewitem')) { //The generic 'item'
					orderitems[itemindex].Code = newvalue.newitemvalue;
					orderitems[itemindex].ProductName = "";
					skuRefs.current[itemindex + "Title"].value = "";
					orderitems[itemindex].ProductID = null; //Let's play with null (don't allow saves!)
					orderitems[itemindex].sku = {
						Sku: newvalue.newitemvalue,
						PendingItem: true,
						Title: "",
						ProductID: null,
						product: {
							"Name": "Select Product"
						},
						skucomponents:[]
					}
					//Allow changes to SKU based on if it's a new sku!
					orderitems[itemindex].newsku = true;

					//Do we really need to say we're about to need an update? We haven't selected Product at this point.
				} else {
					//Sync Order Item
					orderitems[itemindex].Code = newvalue.Sku; //Set order item code to be the Sku
					orderitems[itemindex].ProductName = newvalue.Title; //Sku Title overtakes ProductName
					orderitems[itemindex].ProductID = newvalue.ProductID; //ProductID, a MUST HAVE. We do stats on this linkage.
					skuRefs.current[itemindex + "Title"].value = newvalue.Title; //Sync Front End
					//Assign sku to order.
					orderitems[itemindex].sku = newvalue;
					//Assign product values to order (usually in resultant of DB call, we need this to render skus)
					orderitems[itemindex].product = newvalue.product;

					//Forward pending saves so our button renders correctly. Only use pendingsaves for useEffect?
					//Flag for pending save
					order.pendingsaves = true;
				}

				//We may have a need to also syncronize the upper state as well before we update order item!				
				RefreshOrderState();
			}


			if (searchtype === "components") {
				AddComponent(newvalue, itemindex);

				//RefreshOrderState();
			}


		}

		//Creates a useRef Variable for every property of an object
		function useRefForObject(obj) {
			const refObj = {};
			for (const key in obj) {
				if (Object.prototype.hasOwnProperty.call(obj, key)) {
					refObj[key] = React.createRef();
				}
			}
			//Non-DB bound items
			refObj['subtotal'] = React.createRef();
			return refObj;
		}

		const orderRefs = useRefForObject(order);




		console.log(orderRefs);

		const CopyFromShipping = () => {
			// orderRefs.BFullName.current.value = order.BFullName = order.SFullName;
			// orderRefs.BCompany.current.value = order.BCompany = order.SCompany;
			// orderRefs.BLine1.current.value = order.BLine1 = order.SLine1;
			// orderRefs.BLine2.current.value = order.BLine2 = order.SLine2;
			// orderRefs.BLine3.current.value = order.BLine3 = order.SLine3;
			// orderRefs.BCity.current.value = order.BCity = order.SCity;
			// orderRefs.BStateCode.current.value = order.BStateCode = order.SStateCode;
			// orderRefs.BPostalCode.current.value = order.BPostalCode = order.SPostalCode;
			// orderRefs.BEmail.current.value = order.BEmail = order.SEmail;
			// orderRefs.BWebsite.current.value = order.BWebsite = order.SWebsite;
			// orderRefs.BPhone.current.value = order.BPhone = order.SPhone;
			order.BFullName = order.BFullName = order.SFullName;
			order.BCompany = order.BCompany = order.SCompany;
			order.BLine1 = order.BLine1 = order.SLine1;
			order.BLine2 = order.BLine2 = order.SLine2;
			order.BLine3 = order.BLine3 = order.SLine3;
			order.BCity = order.BCity = order.SCity;
			order.BStateCode = order.BStateCode = order.SStateCode;
			order.BPostalCode = order.BPostalCode = order.SPostalCode;
			order.BEmail = order.BEmail = order.SEmail;
			order.BWebsite = order.BWebsite = order.SWebsite;
			order.BPhone = order.BPhone = order.SPhone;

			order.pendingsaves = true;
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
			RefreshOrderState();
		}

		const CopyFromBilling = () => {
			order.SFullName = order.SFullName = order.BFullName;
			order.SCompany = order.SCompany = order.BCompany;
			order.SLine1 = order.SLine1 = order.BLine1;
			order.SLine2 = order.SLine2 = order.BLine2;
			order.SLine3 = order.SLine3 = order.BLine3;
			order.SCity = order.SCity = order.BCity;
			order.SStateCode = order.SStateCode = order.BStateCode;
			order.SPostalCode = order.SPostalCode = order.BPostalCode;
			order.SEmail = order.SEmail = order.BEmail;
			order.SWebsite = order.SWebsite = order.BWebsite;
			order.SPhone = order.SPhone = order.BPhone;

			order.pendingsaves = true;
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
			RefreshOrderState();
		}



		//Init Refs for orderitems that already exist:
		//const orderitemRefs = useRef([]);
		const skuRefs = useRef([]);
		const orderitemRefs = useRef([]);

		for (var i = 0; i < currentorder.orderitems.length; i++) {
			//orderitemRefs.current[i+"ProductName"] = React.createRef();
			//Now make refs for orderitem sku data
			skuRefs.current[i + "Title"] = React.createRef();
			skuRefs.current[i + "SkuDefaultSearchTerm"] = React.createRef();
			orderitemRefs.current[i + "UnitPrice"] = React.createRef();
		}



		//Init Refs for Charges that already exist
		const chargeRefs = useRef([]);
		for (i = 0; i < currentorder.charges.length; i++) {
			//orderitemRefs.current[i+"ProductName"] = React.createRef();
			//Now make refs for orderitem sku data
			chargeRefs.current[i] = React.createRef();
		}



		//useRefForObject(orderitems);
		//console.log(orderitemRefs);


		const handleAddOrderItem = () => {
			//Test wtithout: RefreshOrderState();
			const newOrderItem = {
				InternalOrderID: order.InternalID,
				PendingItem: true,
				ProductName: null, //We can validate this later?
				ProductID: null,
				UnitPrice: null,
				Quantity: null,
				sku: {
					PendingItem: true,
					Sku: "Select or Create New Sku",
					Title: "",
					ProductID: null,
					product: {
						Name: "Select Product"
					},
					skucomponents: []
				}
			};
			setOrderItems(prevOrderItems => [...prevOrderItems, newOrderItem]);
			//inputRef.current.value = 'Pending Item';
		};

		//Changes to Order Item
		const onChangeOrderItem = (event, itemkey, index) => {
			var oldvalue = orderitems[index][itemkey];
			var newvalue = event.target.value;
			console.log(newvalue);
			if (event.key !== "Tab" &&
				event.key !== "ArrowDown" &&
				event.key !== "ArrowUp" &&
				event.key !== "ShiftLeft" &&
				event.key !== "ShiftRight"
			) {
				//Provision for float numbers
				if (itemkey === "UnitPrice" || itemkey === "TaxCharge" || itemkey === "Discount" || itemkey === "ShippingCost") {
					if (!oldvalue) {
						oldvalue = "0";
					}
					newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float");
					//If new value...
					if (newvalue) {
						//If Unit Price changes, so does TotalPrice (Unit Price * Quantity = TotalPrice)
						//orderitemRefs.current[index+"TotalPrice"].textContent = (orderitemRefs.current[index+"Price"].value - newvalue).toFixed(2);
						//Backspace on a decimal should yield same value for old and new
						if (oldvalue.indexOf(".") > -1 && newvalue.indexOf(".") === -1) {
							//No need to change front end, simply update 
							orderitems[index][itemkey] = newvalue;
						} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)) {
							orderitemRefs.current[index + itemkey].value = newvalue;
							orderitems[index][itemkey] = newvalue;
						}
					} else {
						//Handle possible backspace.
						//We need to update the localstate, but NOT the ref. The input ref can't take values like "2." and messes up the cursor position if you try.
						if (event.key === "Backspace") {
							if ((oldvalue.indexOf(".") > -1)) {
								orderitems[index][itemkey] = parseFloat(event.target.value).toFixed(2);
							}
						}
					}
				}
				if (itemkey === "Quantity") {
					//TO DO - UNFINISHED UNTESTED CODE
					console.log("Old Value:" + oldvalue);
					if (!oldvalue) {
						oldvalue = "0";
					}
					newvalue = RestrictInputNumber(newvalue, oldvalue, event, "positiveinteger");
					//If new value...
					if (newvalue) {
						//If Cost changes, so does Margin
						//rowRefs.current[index+"Margin"].textContent = (rowRefs.current[index+"Price"].value - newvalue).toFixed(2);
						//orderitems[index].Margin = rowRefs.current[index+"Margin"].textContent;

						//Backspace on a decimal should yield same value for old and new
						//I suspect indexOf is only for proper arrays?
						if ([oldvalue].indexOf(".") > -1 && newvalue.indexOf(".") === -1) {
							//No need to change front end, simply update griditem
							orderitems[index][itemkey] = newvalue;
						} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)) {
							orderitemRefs.current[index + itemkey].value = newvalue;
							orderitems[index][itemkey] = newvalue;
						}
					} else {
						//No new value returned, we may have pressed a decimal/period. If that is the case, make sure the view doesn't show it:
						if (event.key === "." || event.key === "Period" || event.key === "NumpadDecimal") {
							//A VIOLATION of positive integer. Clear the input.
							orderitemRefs.current[index + itemkey].value = "";
						}
						//Handle possible backspace.
						//We need to update the localstate, but NOT the ref. The input ref can't take values like "2." and messes up the cursor position if you try.
						if (event.key === "Backspace") {
							if ((oldvalue.indexOf(".") > -1)) {
								orderitems[index][itemkey] = parseFloat(event.target.value).toFixed(2);
							}
						}
					}
				}

				if (
					itemkey === "OrderID" ||
					itemkey === "LastName" ||
					itemkey === "Date"
				) {

					//setCurrentOrder(order);
				}



				orderitems[index][itemkey] = newvalue;
				console.log("#############################################  FACTOR  ##########################################################");
				FactorOrder();
				btnSave.current.style.display = "none";
				btnPendingSave.current.style.display = "";
			}
		}


		//Changes to Sku
		const onChangeSkuTitle = (event, itemkey, index) => {
			var oldvalue = orderitems[index]['sku'][itemkey];
			console.log("Old value test: " + oldvalue);
			var newvalue = event.target.value;
			if (event.key !== "Tab" &&
				event.key !== "ArrowDown" &&
				event.key !== "ArrowUp" &&
				event.key !== "ShiftLeft" &&
				event.key !== "ShiftRight"
			) {

				if (
					itemkey === "OrderID" ||
					itemkey === "LastName" ||
					itemkey === "Date"
				) {

					//setCurrentOrder(order);
				}
				orderitems[index]['sku'][itemkey] = newvalue;
				orderitems[index]['ProductName'] = newvalue;
				btnSave.current.style.display = "none";
				btnPendingSave.current.style.display = "";
			}
		}
		//Changes to Order Item Note:
		const onChangeInstructions = (event, itekey, index) => {
			orderitems[index].Instructions = event.target.value;
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
		}

		const DeleteOrderItem = (index) => {
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
			order.pendingsaves = true;
			orderitems[index].DeleteOrderItem = true
			RefreshOrderState();
		}


		const AddComponent = (newcomponent, itemindex) => {
			console.log(newcomponent);
			orderitems[itemindex].sku.skucomponents.push({
				PendingItem: true,
				ComponentID: newcomponent.ID,
				ComponentType: newcomponent.ComponentType,
				details: {
					Name: newcomponent.Name,
					Cost: newcomponent.Cost
				}
			});
			order.pendingsaves = true;
			RefreshOrderState();
		}

		//Remove Component
		const RemoveComponent = (index, id, componentindex) => {
			//If the sku component has never been officially saved, we can simply remove it from view:
			if (!orderitems[index].sku.skucomponents[componentindex].PendingItem) {
				axios.post(dbendpoint + "/skus/removeskucomponent", { id: id }, defaultpostoptions).then(res => {
					if (res.status === 200) {
						//If ValidateUser() fails to verify user, it sends back 'login' error. 
						if (res.data.Status === 'login') {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status === 'Success') {
							dispatch(newErrorMessage({ errmsg: "Component removed!", errshow: true, errtimeout: 5, errtype: 'ok' }));
							//Remove Component from array
							var componentindex = orderitems[index].sku.skucomponents.map(function (o) { return o.ID; }).indexOf(id);
							orderitems[index].sku.skucomponents.splice(componentindex, 1);
							RefreshOrderState();
						}
						if (res.data.Status === 'Failure') {
							//Failure error
							dispatch(newErrorMessage({ errmsg: res.data.message, errshow: true, errtimeout: 30, errtype: 'neutral' }));
						}
					} else {
						//Non-200 message from server.
						dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 30, errtype: 'warning' }));
					}
				});
			} else {
				orderitems[index].sku.skucomponents.splice(componentindex, 1);
				RefreshOrderState();
			}

		}










		//Charges - Add Charge Btn, onChangeCharge
		// const [chargetypeEl, setChargeTypeEl] = React.useState<null | HTMLElement>(null);
		// const openchargetype = Boolean(setChargeTypeEl);
		// const handleOpenChargeType = (event: React.MouseEvent<HTMLButtonElement>) => {
		// 	setChargeTypeEl(event.currentTarget);
		//   };


		const [chargetypemenu, setChargeTypeMenu] = useState(null);
		const ShowChargeTypeMenu = (event) => {
			setChargeTypeMenu(event.currentTarget);
		}
		const CloseChargeTypeMenu = () => {
			setChargeTypeMenu(null);
		}

		//Find out if we already have a charge type like the one we want to either render or not.
		const ValidateChargeType = (charges, denyvalue) => {
			for (let i = 0; i < charges.length; i++) {
				if (charges[i]["ChargeType"] === denyvalue) {
					return false;
				}
			}
			return true;
		}


		function findValueByKey(array, key, value) {
			for (let i = 0; i < array.length; i++) {
				if (array[i][key] === value) {
					return array[i];
				}
			}
			return null;
		}

		const messageref = useRef();


		const DeleteOrderInit = () => {
			var proceeddelete = true;
			var itemserials = "";
			var multiple = "";
			var isare = " is ";
			//Loop through manifest items, look for Valid===1.
			//If none found, we can delete the order/orderitems/charges/transactions/shipments
			for (var i = 0; i < currentorder.manifest.length; i++) {
				if (currentorder.manifest[i].Valid === 1) {
					if (!proceeddelete) {
						multiple = "s";
						var isare = " are ";
					}
					var proceeddelete = false;
					itemserials += currentorder.manifest[i].AssetSerialNumber + " ";
				}
			}

			if (!proceeddelete) {
				messageref.current.textContent = "Cannot initiate deletion: serial number" + multiple + " " + itemserials + isare + "still checked out to this order.";
				messageref.current.style.display = "block";
				//alert("Serial"+multiple+" "+itemserials+isare+"still checked out to this order.");
			} else {
				messageref.current.textContent = "Order is marked for deletion. Press Save Changes to proceed with order deletion.";
				order.DeleteOrder = true;
				messageref.current.style.display = "block";
				order.pendingsaves = true;
				btnSave.current.style.display = "none";
				btnPendingSave.current.style.display = "";
				//Does it really matter if we just want to sync state?
				RefreshOrderState();
			}
		}


		const DeleteOrder = () => {
			var postdata = {
				item: currentorder
			}
			axios.post(dbendpoint + "/order/delete", postdata, defaultpostoptions).then(res => {
				//API should be setup to send 200 response with status. Merge paginated requests.
				if (res.status === 200) {
					//If ValidateUser() fails to verify user, it sends back 'login' error. 
					if (res.data.Status === "login") {
						//Not logged in. Reload page causes redirect to /login
						window.location.reload(false);
					}
					//All new API calls should return a status.
					if (res.data.Status === "Success") {
						console.log(res);
						//RefreshOrderState();
						//No scope access to this: props.ToggleOrderView();
						history.push('/orderstable');

					}
					if (res.data.Status === "Failure") {
						//Failure error
						dispatch(newErrorMessage({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" }));
					}
				} else {
					//Non-200 message from server.
					dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" }));
				}
			});

		}

		const UncancelOrderInit = () => {
			order.Canceled = 0;
			messageref.current.textContent = "Order is marked to no longer be canceled. Press the Save Changes button above to proceed.";
			messageref.current.style.display = "block";
			order.pendingsaves = true;
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
			//Does it really matter if we just want to sync state?
			RefreshOrderState();
		}

		const CancelOrderInit = () => {
			order.Canceled = 1;
			messageref.current.textContent = "Order is marked to be canceled. Press the Save Changes button above to proceed.";
			messageref.current.style.display = "block";
			order.pendingsaves = true;
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
			//Does it really matter if we just want to sync state?
			RefreshOrderState();
		}




		//Without Duplicate charge types, we only need the charge index.
		const onChangeCharge = (event, index) => {
			console.log(index);
			var oldvalue = ordercharges[index].Amount;
			console.log("Old value test: " + oldvalue);
			var newvalue = event.target.value;
			if (event.key !== "Tab" &&
				event.key !== "ArrowDown" &&
				event.key !== "ArrowUp" &&
				event.key !== "ShiftLeft" &&
				event.key !== "ShiftRight"
			) {

				//All Charges are treated as float
				if (!oldvalue) {
					oldvalue = "0";
				}
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float");
				//If new value...
				if (newvalue) {
					//If Unit Price changes, so does TotalPrice (Unit Price * Quantity = TotalPrice)
					//orderitemRefs.current[index+"TotalPrice"].textContent = (orderitemRefs.current[index+"Price"].value - newvalue).toFixed(2);
					//Backspace on a decimal should yield same value for old and new
					if ([oldvalue].indexOf(".") > -1 && newvalue.indexOf(".") === -1) {
						//No need to change front end, simply update 
						ordercharges[index].Amount = newvalue;
					} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)) {
						console.log("this");
						console.log(chargeRefs);
						chargeRefs.current[index].value = newvalue;
						ordercharges[index].Amount = newvalue;

					} else {
						console.log("that");
						chargeRefs.current[index].value = newvalue;
						ordercharges[index].Amount = newvalue;
					}

				} else {
					//Handle possible backspace.
					//We need to update the localstate, but NOT the ref. The input ref can't take values like "2." and messes up the cursor position if you try.
					if (event.key === "Backspace") {
						if (([oldvalue].indexOf(".") > -1)) {
							ordercharges[index].Amount = parseFloat(event.target.value).toFixed(2);
						}
					}
				}


				ordercharges[index].Amount = newvalue;
				FactorOrder();
				btnSave.current.style.display = "none";
				btnPendingSave.current.style.display = "";
			}
		}


		
		const handleAddCharge = (chargetype) => {
			//Test wtithout: RefreshOrderState();
			const newCharge = {
				InternalOrderID: order.InternalID,
				PendingItem: true,
				ChargeType: chargetype,
				Amount: 0,
			};
			//ordercharges.push(newCharge);
			setOrderCharges(prevOrderCharges => [...prevOrderCharges, newCharge]);
			//inputRef.current.value = 'Pending Item';
			//RefreshOrderState();
		};



		const DeleteCharge = (chargeindex) => {
			//Makes request, gets ID, removes from list
			const postdata = {
				item: ordercharges[chargeindex]
			};
			axios.post(dbendpoint + "/order/deleteordercharge", postdata, defaultpostoptions).then(res => {
				//API should be setup to send 200 response with status. Merge paginated requests.
				if (res.status === 200) {
					//If ValidateUser() fails to verify user, it sends back 'login' error. 
					if (res.data.Status === "login") {
						//Not logged in. Reload page causes redirect to /login
						window.location.reload(false);
					}
					//All new API calls should return a status.
					if (res.data.Status === "Success") {
						console.log(res);
						var itemindex = ordercharges.map(function (o) { return o.ChargeID; }).indexOf(res.data.OldID);
						//Remove from order's state
						ordercharges.splice(itemindex, 1);
						setOrderCharges(ordercharges);
						FactorOrder();
						//RefreshOrderState();

					}
					if (res.data.Status === "Failure") {
						//Failure error
						dispatch(newErrorMessage({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" }));
					}
				} else {
					//Non-200 message from server.
					dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" }));
				}
			});

		}


		const ShowTransactions = () => {
			order.showtransactions = true;
			RefreshOrderState();
		}	

		//Handle Transactions
		const handleAddTransaction = () => {
			
		}




		const FactorOrder = () => {
			//Try via console!
			let unitprice = 0;
			let quantity = 0;
			let totalprice = 0;

			//Charges
			let tax = 0;

			let runningsubtotal = 0;
			let runningtotal = 0;

			for (var i = 0; i < orderitems.length; i++) {
				//Check that orderitem isn't already set for deletion:
				if (!orderitems[i].hasOwnProperty("DeleteOrderItem")) {
					unitprice = orderitems[i].UnitPrice;
					quantity = orderitems[i].Quantity;
					totalprice = unitprice * quantity;

					console.log("Unit Price: " + orderitems[i].UnitPrice);
					console.log("Quantity: " + orderitems[i].Quantity);
					console.log("----------------------------------------");
					console.log("TotalPrice: " + totalprice);

					orderitemRefs.current[i + "TotalPrice"].value = totalprice.toFixed(2);
					orderitems[i].TotalPrice = totalprice.toFixed(2);
					runningsubtotal = (parseFloat(runningsubtotal) + parseFloat(orderitems[i].TotalPrice));
					console.log("Running Total: " + runningsubtotal);
				}
			}

			for (var j = 0; j < ordercharges.length; j++) {
				//console.log("Index here: "+j);
				//console.log(ordercharges[j]);
				//Check that the charge isn't already set for deletion!
				if (!ordercharges[j].hasOwnProperty("DeleteCharge")) {
					if (ordercharges[j].ChargeType === "shipping") {
						console.log("Shipping should run");
						//Shipping is added to subtotal so we can tax it
						runningsubtotal = (runningsubtotal + parseFloat(ordercharges[j].Amount));
					}
					if (ordercharges[j].ChargeType === "discount") {
						console.log("Discount should run");
						//Shipping is added to subtotal so we can tax it
						runningsubtotal = (runningsubtotal - parseFloat(ordercharges[j].Amount));
					}
					if (ordercharges[j].ChargeType === "tax") {
						console.log("Tax should run");
						//Shipping is added to subtotal so we can tax it
						tax = ordercharges[j].Amount;
					}

				}
			}
			runningtotal = (runningsubtotal + parseFloat(tax));
			order['Total'] = runningtotal;

			orderRefs['subtotal'].current.value = runningsubtotal.toFixed(2);
			orderRefs['Total'].current.value = runningtotal.toFixed(2);
		}


		const ExportToShipstation = () => {
			//Validation: Handled with useEffect that determines if we have a shipstation ID, SSID
			//Validation: Do we have any name or items?

			var postdata = {
				order:currentorder
			}

			axios.post(dbendpoint+"/exportshipstation", postdata, defaultpostoptions).then(res => {
				//API should be setup to send 200 response with status. Merge paginated requests.
				if (res.status===200){
					//If ValidateUser() fails to verify user, it sends back 'login' error. 
					if (res.data.Status==="login"){
						//Not logged in. Reload page causes redirect to /login
						window.location.reload(false);
					}
					//All new API calls should return a status.
					if (res.data.Status==="Success"){
						console.log(res);
						dispatch(newErrorMessage({errmsg:'Export successful!',errshow:true,errtimeout:30,errtype:'ok'}));
						//errors.NewError({errmsg:"Export successful!", errshow:true, errtimeout: 10, errtype:"success"})
						//Now we need to update our SSID. Maybe we should go ahead and do this in the API instead.
					}
					if (res.data.Status==="Failure"){
						//Failure error
						dispatch(newErrorMessage({errmsg:res.data.message,errshow:true,errtimeout:30,errtype:'warning'}));
						//errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 25, errtype:"warning"})
					}
				} else {
					//Non-200 message from server.
					dispatch(newErrorMessage({errmsg:"Bad response from server.",errshow:true,errtimeout:30,errtype:'warning'}));
					//errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
				}
			}).catch(err => {
				//Error getting any response
				dispatch(newErrorMessage({errmsg:"Caught request error.",errshow:true,errtimeout:30,errtype:'danger'}));
				//errors.NewError({errmsg:"Caught request error.", errshow:true, errtimeout: 5, errtype:"danger"})
			});

			
		}


		return (
			<Typography variant="h6">
				<div key="orderkey" className={classes.flexorder} style={{ fontSize: "14px", backgroundColor: "#FFF", padding: "0px 20px 20px 20px", marginBottom: "20px", maxWidth: "1200px", wordWrap: "break-word", pageBreakAfter: "always", margin:"auto" }}>
					<div style={{ textAlign: "center", padding: "5px 0px 10px 0px" }}>

						{(batchmode) &&
							<>
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => setViewMode('worksheet')}>
									<KeyboardBackspaceIcon sx={{ color: "lightgray" }}></KeyboardBackspaceIcon>&nbsp;Return to Batch
								</Button>
							</>
						}

						{(!batchmode) &&
							<>
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => GoToOrders()}>
									<KeyboardBackspaceIcon sx={{ color: "lightgray" }}></KeyboardBackspaceIcon>&nbsp;Back to Order Table
								</Button>
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => setViewMode('worksheet')}>
									View Worksheet
								</Button>

								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => setViewMode('invoice')}>
									View Invoice
								</Button>
							</>
						}

						<Button
							className={(userPerms.updateOrder === 1 || userPerms.updateOrder === 1) ? classes.bluebtn : classes.hidden}
							sx={{ displayPrint: 'none' }}
							color="primary" variant="contained"
							onClick={() => ExportToShipstation()}
							ref={el => btnExportToShipStation.current = el}>
							<IosShareIcon sx={{ color: "lightgray", height:"15px" }}></IosShareIcon>&nbsp;Export to ShipStation
						</Button>

						<Button
							className={(userPerms.updateOrder === 1 || userPerms.updateOrder === 1) ? classes.bluebtn : classes.hidden}
							sx={{ displayPrint: 'none' }}
							color="primary" variant="contained"
							onClick={() => SaveChanges()}
							ref={el => btnSave.current = el}>
							<SaveIcon sx={{ color: "lightgray" }}></SaveIcon>&nbsp;Save Changes
						</Button>

						<Button
							className={(userPerms.updateOrder === 1 || userPerms.updateOrder === 1) ? classes.bluebtn : classes.hidden}
							color="primary" variant="contained"
							onClick={() => SaveChanges()}
							style={{ display: "none" }}
							ref={el => btnPendingSave.current = el}>
							<PendingIcon sx={{ color: "orange" }}></PendingIcon>&nbsp;Save Changes
						</Button>
					</div>

					<div style={{ textAlign: "left", display: "inline-block", width: "50%" }}>
						{/* {order.InternalID}&nbsp; */}
						{/* To Do: Make Company Profile for Flex */}
						<b>Deluxe PCs</b><br></br>
						7049 Production Ct.<br></br>
						Florence, KY 41042<br></br>
						deluxepcs.com<br></br>
					</div>
					<div style={{ textAlign: "left", display: "inline-block", width: "50%" }}>
						<table className={classes.itemtable}>
							<tbody>
								{(order.Canceled === 1) &&
									<tr>
										<td>
											Status:
										</td>
										<td>
											<div className={classes.flexitemstaticinput + " " + classes.whitetext + " " + classes.warningmsg}>
												Canceled
											</div>
										</td>
									</tr>
								}
								<tr>
									<td>
										Order Number:
									</td>
									<td>
										{/* TO DO: Create Draft provision for Order Number edit */}
										{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
											<input className={classes.flexiteminput}
												key={"OrderID"}
												type="text"
												value={order.OrderID}
												onChange={(event) => onChangeValue(event, "OrderID")}
											/>

										}
										{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
											<div className={classes.flexitemstaticinput}>
												{order.OrderID}
											</div>
										}
									</td>
								</tr>
								<tr>
									<td>
										Order Date:
									</td>
									<td>
										{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
											<div className={classes.flexitemdateinputcontainer}>
												<DateTimePicker
													value={order.OrderDate}
													onChange={(newvalue) => onChangeDatetimeValue(newvalue, "OrderDate")}
												/>
											</div>
										}
										{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
											<div className={classes.flexitemstaticinput}>
												{dayjs(order.OrderDate).format("MMMM D, YYYY [at] h:mma")}
											</div>
										}
									</td>
								</tr>

								<tr>
									<td>
										Market:
									</td>
									<td>
										{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
											<DBAutoComplete
												searchtype="markets"
												searchkey="Name"
												grayplaceholder="Change Market"
												keyedby="ID"
												defaultsearchterm={order.Store}
												limit="10"
												errors={errors}
												allowadd={false}
												freeSolo={false}
												onDBAutoCompleteChange={onDBAutoCompleteChange}
											/>
										}
										{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
											<div className={classes.flexitemstaticinput}>
												{order.Store}
											</div>
										}
									</td>
								</tr>

								<tr>
									<td>
										Shipping Method:
									</td>
									<td>
										{/* TO DO: Create Draft provision for Order Number edit */}
										{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
											<input className={classes.flexiteminput}
												key={"RequestedShipping"}
												type="text"
												defaultValue={currentorder.RequestedShipping}
												onChange={(event) => onChangeValue(event, "RequestedShipping")}
											/>
										}
										{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
											<div className={classes.flexitemstaticinput}>
												{order.RequestedShipping}
											</div>
										}
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div style={{ width: "49%", display: "inline-block", verticalAlign: "top", marginTop: "10px" }}>
						<table className={classes.flextable} style={{ width: "100%", textAlign: "left" }}>
							<tbody>
								<tr>
									<td className={classes.flextableheader}>
										Billing
									</td>
								</tr>
								<tr>
									<td style={{ padding: "6px" }}>
										<table className={classes.itemtable}>
											<tbody>
												<tr>
													<td style={{ width: "140px" }}>
														Full Name:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"BFullName"}
																ref={el => orderRefs.BFullName.current = el}
																type="text"
																defaultValue={currentorder.BFullName}
																onChange={(event) => onChangeValue(event, "BFullName")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.BFullName}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Company:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"BCompany"}
																ref={el => orderRefs.BCompany.current = el}
																type="text"
																defaultValue={currentorder.BCompany}
																onChange={(event) => onChangeValue(event, "BCompany")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.BCompany}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Address Line 1:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"BLine1"}
																ref={el => orderRefs.BLine1.current = el}
																type="text"
																defaultValue={currentorder.BLine1}
																onChange={(event) => onChangeValue(event, "BLine1")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.BLine1}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Address Line 2:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"BLine2"}
																ref={el => orderRefs.BLine2.current = el}
																type="text"
																defaultValue={currentorder.BLine2}
																onChange={(event) => onChangeValue(event, "BLine2")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.BLine2}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Address Line 3:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"BLine3"}
																ref={el => orderRefs.BLine3.current = el}
																type="text"
																defaultValue={currentorder.BLine3}
																onChange={(event) => onChangeValue(event, "BLine3")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.BLine3}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														City:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"BCity"}
																ref={el => orderRefs.BCity.current = el}
																type="text"
																defaultValue={currentorder.BCity}
																onChange={(event) => onChangeValue(event, "BCity")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.BCity}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														State (2 char):
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"BStateCode"}
																ref={el => orderRefs.BStateCode.current = el}
																type="text"
																defaultValue={currentorder.BStateCode}
																onChange={(event) => onChangeValue(event, "BStateCode")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.BStateCode}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Zip:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"BPostalCode"}
																ref={el => orderRefs.BPostalCode.current = el}
																type="text"
																defaultValue={currentorder.BPostalCode}
																onChange={(event) => onChangeValue(event, "BPostalCode")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.BPostalCode}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Phone:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"BPhone"}
																ref={el => orderRefs.BPhone.current = el}
																type="text"
																defaultValue={currentorder.BPhone}
																onChange={(event) => onChangeValue(event, "BPhone")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.BPhone}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Email:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"BEmail"}
																ref={el => orderRefs.BEmail.current = el}
																type="text"
																defaultValue={currentorder.BEmail}
																onChange={(event) => onChangeValue(event, "BEmail")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.BEmail}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Website:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"BWebsite"}
																ref={el => orderRefs.BWebsite.current = el}
																type="text"
																defaultValue={currentorder.BWebsite}
																onChange={(event) => onChangeValue(event, "BWebsite")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{order.BWebsite}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td colSpan="2" style={{ textAlign: "center" }}>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<div className={classes.textlink} onClick={() => { CopyFromShipping() }}>Copy From Shipping</div>
														}
													</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div style={{ width: "2%", display: "inline-block" }}>&nbsp;</div>

					<div style={{ width: "49%", display: "inline-block", verticalAlign: "top", marginTop: "10px" }}>
						<table className={classes.flextable} style={{ width: "100%", textAlign: "left" }}>
							<tbody>
								<tr>
									<td className={classes.flextableheader}>
										Shipping
									</td>
								</tr>
								<tr>
									<td style={{ padding: "6px" }}>
										<table className={classes.itemtable}>
											<tbody>
												<tr>
													<td style={{ width: "140px" }}>
														Full Name:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"SFullName"}
																ref={el => orderRefs.SFullName.current = el}
																type="text"
																defaultValue={currentorder.SFullName}
																onChange={(event) => onChangeValue(event, "SFullName")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.SFullName}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Company:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"SCompany"}
																ref={el => orderRefs.SCompany.current = el}
																type="text"
																defaultValue={currentorder.SCompany}
																onChange={(event) => onChangeValue(event, "SCompany")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.SCompany}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Address Line 1:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"SLine1"}
																ref={el => orderRefs.SLine1.current = el}
																type="text"
																defaultValue={currentorder.SLine1}
																onChange={(event) => onChangeValue(event, "SLine1")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.SLine1}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Address Line 2:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"SLine2"}
																ref={el => orderRefs.SLine2.current = el}
																type="text"
																defaultValue={currentorder.SLine2}
																onChange={(event) => onChangeValue(event, "SLine2")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.SLine2}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Address Line 3:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"SLine3"}
																ref={el => orderRefs.SLine3.current = el}
																type="text"
																defaultValue={currentorder.SLine3}
																onChange={(event) => onChangeValue(event, "SLine3")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.SLine3}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														City:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"SCity"}
																ref={el => orderRefs.SCity.current = el}
																type="text"
																defaultValue={currentorder.SCity}
																onChange={(event) => onChangeValue(event, "SCity")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.SCity}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														State (2 char):
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"SStateCode"}
																ref={el => orderRefs.SStateCode.current = el}
																type="text"
																defaultValue={currentorder.SStateCode}
																onChange={(event) => onChangeValue(event, "SStateCode")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.SStateCode}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Zip:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"SPostalCode"}
																ref={el => orderRefs.SPostalCode.current = el}
																type="text"
																defaultValue={currentorder.SPostalCode}
																onChange={(event) => onChangeValue(event, "SPostalCode")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.SPostalCode}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Phone:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"SPhone"}
																ref={el => orderRefs.SPhone.current = el}
																type="text"
																defaultValue={currentorder.SPhone}
																onChange={(event) => onChangeValue(event, "SPhone")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.SPhone}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Email:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"SEmail"}
																ref={el => orderRefs.SEmail.current = el}
																type="text"
																defaultValue={currentorder.SEmail}
																onChange={(event) => onChangeValue(event, "SEmail")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.SEmail}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td>
														Website:
													</td>
													<td>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={"SWebsite"}
																ref={el => orderRefs.SWebsite.current = el}
																type="text"
																defaultValue={currentorder.SWebsite}
																onChange={(event) => onChangeValue(event, "SWebsite")}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<div className={classes.flexitemstaticinput}>
																{currentorder.SWebsite}
															</div>
														}
													</td>
												</tr>
												<tr>
													<td colSpan="2" style={{ textAlign: "center" }}>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<div className={classes.textlink} onClick={() => { CopyFromBilling() }}>Copy From Billing</div>
														}
													</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					{/* Items */}
					<table className={classes.flextable} style={{ width: "100%", textAlign: "left", marginTop: "10px" }}>
						<tbody>
							<tr>
								<td colSpan="2" className={classes.flextableheader}>
									Items
								</td>
								<td colSpan="2" className={classes.flextableheader} style={{ textAlign: "right" }}>
									{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
										<Button
											onClick={() => { handleAddOrderItem() }}
											className={classes.bluebtn}
											sx={{ margin: "0px!important", padding: "0px" }}
											color="primary" variant="contained"
											endIcon={<AddCircleIcon style={{ fontSize: "18px", marginRight: "6px" }} />}>
											Add Item
										</Button>
									}
								</td>
							</tr>
							<tr style={{ borderBottom: "2px solid #D4D4D4", fontWeight: "bold", fontSize: "12px", backgroundColor: "rgb(250 250 250)" }}>
								<td style={{ textAlign: "left", padding: "0px 5px", width: "900px" }}>
									Product
								</td>
								<td style={{ textAlign: "center", padding: "0px 5px", width: "100px" }}>
									Price
								</td>
								<td style={{ textAlign: "center", padding: "0px 5px", width: "100px" }}>
									Qty
								</td>
								<td style={{ textAlign: "right", padding: "0px 5px", width: "100px" }}>
									Total
								</td>
							</tr>
							{currentorderitems.map(function (orderitem, index) {
								return (
									<React.Fragment key={"orderitems" + index}>
										{(!orderitem.hasOwnProperty('DeleteOrderItem')) &&
											<tr style={{ borderBottom: "5px solid #D4D4D4" }} key={uuidv4()}>
												<td style={{ textAlign: "left", padding: "0px 5px" }}>
													<table className={classes.itemtable}>
														<tbody>
															<tr>
																<td style={{ width: "120px" }}> {/* Interesting: Do we give option to start with SKU first? */}
																	<div style={{ position: "relative", left: "0px", zIndex: "100000" }}>
																		<div style={{ position: "absolute", top: "-4px" }}>
																			<IconButton aria-label="delete" onClick={() => { DeleteOrderItem(index) }} size="small">
																				<DeleteIcon />
																			</IconButton>
																		</div>
																	</div>
																	Sku:
																</td>
																<td>
																	{((userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1))) &&
																		<DBAutoComplete
																			keyedby="ID"
																			searchtype="skus"
																			searchkey="Sku"
																			itemindex={index}
																			defaultsearchterm={orderitem.sku ? orderitem.sku.Sku : ""}
																			limit="100"
																			createNewText="Create Sku"
																			errors={errors}
																			allowadd={true}
																			freeSolo={false}
																			onDBAutoCompleteChange={onDBAutoCompleteChange}
																		/>
																	}
																	{((userPerms.updateOrder === 0 && userPerms.createOrder === 0)) &&
																		<div className={classes.flexitemstaticinput}>
																			{orderitem.sku.Sku}
																		</div>
																	}
																</td>
															</tr>
															<tr>
																<td>
																	Sku Title: {/* Enable changes to SKU if newsku is true (a sku is being created!) */}
																</td>
																<td>
																	{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
																		<React.Fragment>
																			<textarea className={classes.flexiteminput}
																				key={index + "Title"}
																				ref={el => skuRefs.current[index + "Title"] = el}
																				type="text"
																				disabled={(userPerms.updateSku === 1 || orderitem.hasOwnProperty('newsku')) ? false : true}
																				defaultValue={orderitem.sku ? orderitem.sku.Title : ""}
																				onChange={(event) => onChangeSkuTitle(event, "Title", index)}
																			/>
																		</React.Fragment>

																	}

																	{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
																		<textarea className={classes.flexiteminput}
																			ref={el => skuRefs.current[index + "Title"] = el}
																			type="text"
																			disabled
																			defaultValue={orderitem.sku.Title}
																			onChange={(event) => onChangeSkuTitle(event, "Title", index)}
																		/>
																	}
																</td>
															</tr>
															<tr>
																<td> {/* This should probably actually be orderitem.sku.Title */}
																	Product:
																</td>
																<td>
																	{/* Skus are automatically generated by the import process, however products aren't necessarily assigned. */}
																	{/* The old system didn't enforce a sku at all. */}
																	{((userPerms.updateOrder === 1 && userPerms.updateSku) || (userPerms.createOrder === 1 && order.Draft === 1)) &&

																		<DBAutoComplete
																			keyedby="ID"
																			searchtype="products"
																			searchkey="Name"
																			itemindex={index}
																			defaultsearchterm={(orderitem.sku ? (orderitem.sku.product ? orderitem.sku.product.Name : "") : "")}
																			limit="100"
																			disabled={(userPerms.updateSku === 1 || orderitem.hasOwnProperty('newsku')) ? false : true}
																			errors={errors}
																			allowadd={false}
																			freeSolo={false}
																			onDBAutoCompleteChange={onDBAutoCompleteChange}
																		/>
																	}
																	{/* {(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
																		<div className={classes.flexitemstaticinput}>
																			{orderitem.sku.product.Name}
																		</div>
																	} */}


																</td>
															</tr>
															{/* Components - These won't show up unless a product is selected. */}
															{(orderitem.ProductID !== 0) &&
																<tr>
																	<td>
																		Components:
																	</td>
																	<td>
																		<div className={classes.flexitemstaticinputnoborder}>
																			{(orderitem.sku) &&
																				<>
																					{(orderitem.sku.hasOwnProperty('skucomponents')) &&
																						<React.Fragment>
																							{(orderitem.sku.skucomponents.length > 0) &&
																								<React.Fragment>
																									{orderitem.sku.skucomponents.map((component, componentindex) => {
																										return (
																											<div key={componentindex} className={classes.skucomponent} style={{ fontSize: "14px" }} title={component.details.Cost}>
																												{component.details.Name} &nbsp;
																												{(userPerms.updateSku === 1 || orderitem.hasOwnProperty('newsku')) &&
																													<CloseIcon className={classes.skucomponentdeleteicon} onClick={(event) => { RemoveComponent(index, component.ID, componentindex) }} />
																												}

																											</div>
																										)
																									})}
																								</React.Fragment>
																							}
																						</React.Fragment>
																					}
																				</>
																			}
																			

																			{/* This had been set to disable if there was no product selected. Instead, we should disable the add components button, only enable when a product is selected? */}
																			{(userPerms.updateSku === 1 || orderitem.hasOwnProperty('newsku')) &&
																				<div style={{ marginTop: "4px" }}>
																					<DBAutoComplete
																						sx={{ marginTop: "10px" }}
																						keyedby="ID"
																						searchtype="components"
																						searchkey="Name"
																						producttype={(orderitem.product ? orderitem.product.ProductType : "")}
																						disabled={!orderitem.product}
																						itemindex={index}
																						grayplaceholder="Add Component"
																						limit="20"
																						errors={errors}
																						allowadd={false}
																						freeSolo={false}
																						key1="ComponentType"
																						onDBAutoCompleteChange={onDBAutoCompleteChange}
																					/>
																				</div>
																			}
																		</div>
																	</td>
																</tr>
															}
															<tr>
																<td>
																	Instructions: {/* Enable changes to SKU if disableskuselection is true (a sku is being created!) */}
																</td>
																<td>
																	{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
																		<React.Fragment>
																			<textarea className={classes.flexiteminput}
																				key={index + "Instructions"}
																				ref={el => skuRefs.current[index + "Instructions"] = el}
																				type="text"
																				defaultValue={orderitem.Instructions}
																				onChange={(event) => onChangeInstructions(event, "Instructions", index)}
																			/>
																		</React.Fragment>

																	}

																	{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&

																		<textarea className={classes.flexiteminput}
																			ref={el => skuRefs.current[index + "Instructions"] = el}
																			type="text"
																			disabled
																			defaultValue={orderitem.sku.Instructions}
																			onChange={(event) => onChangeInstructions(event, "Instructions", index)}
																		/>
																	}
																</td>
															</tr>

														</tbody>
													</table>
												</td>
												<td style={{ textAlign: "center", padding: "0px 5px", verticalAlign: "top" }}>
													<div style={{ marginTop: "10px" }}>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																key={index + "UnitPrice"}
																style={{ textAlign: "right" }}
																ref={el => orderitemRefs.current[index + "UnitPrice"] = el}
																type="number"
																step="0.01"
																placeholder="Price"
																defaultValue={orderitem.UnitPrice}
																onBlur={(event) => DetectBlankNumber(event, index, "UnitPrice")}
																onChange={(event) => onChangeOrderItem(event, "UnitPrice", index)}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<input className={classes.flexiteminput}
																key={index + "UnitPrice"}
																style={{ textAlign: "right" }}
																disabled
																type="number"
																step="0.01"
																defaultValue={orderitem.UnitPrice}
															/>
														}

													</div>
												</td>
												<td style={{ textAlign: "center", padding: "0px 5px", verticalAlign: "top" }}>
													<div style={{ marginTop: "10px" }}>
														{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
															<input className={classes.flexiteminput}
																style={{ textAlign: "center" }}
																key={index + "Quantity"}
																ref={el => orderitemRefs.current[index + "Quantity"] = el}
																type="number"
																placeholder="Qty"
																step="1"
																defaultValue={orderitem.Quantity}
																onBlur={(event) => DetectBlankNumberInteger(event, index, "Quantity")}
																onKeyUp={(event) => onChangeOrderItem(event, "Quantity", index)}
															/>
														}
														{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
															<input className={classes.flexiteminput}
																disabled
																style={{ textAlign: "center" }}
																key={index + "Quantity"}
																type="number"
																step="1"
																defaultValue={orderitem.Quantity}
															/>
														}

													</div>
												</td>
												<td style={{ textAlign: "center", padding: "0px 5px", verticalAlign: "top" }}>
													<div style={{ marginTop: "10px" }}>
														<input className={classes.flexiteminput}
															style={{ textAlign: "right" }}
															key={index + "TotalPrice"}
															ref={el => orderitemRefs.current[index + "TotalPrice"] = el}
															type="text"
															disabled={true}
															defaultValue={orderitem.TotalPrice}
														/>
													</div>
												</td>
											</tr>
										}
									</React.Fragment>


								)
							})}


							{/* Subtotal, Total, Taxes, Discount, Shipping Cost Area of Order */}
							<tr>
								<td colSpan="1">

								</td>
								<td colSpan="3">
									<table className={classes.itemtable}>
										<tbody>
											{(ordercharges.map(function (charge, chargeindex) {
												return (
													<React.Fragment key={"charges" + chargeindex}>
														{/* Shipping Charges and Discounts are BEFORE subtotal. Charge tax on shipping as well in our state */}
														{(charge.ChargeType === "shipping" || charge.ChargeType === "handling") &&
															<tr>
																<td style={{ width: "80px" }}>
																	{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
																		<IconButton aria-label="delete" onClick={() => { DeleteCharge(chargeindex) }} size="small">
																			<DeleteIcon />
																		</IconButton>
																	}
																</td>
																<td style={{ width: "100px" }}>
																	{capitalizeFirst(charge.ChargeType)}:
																</td>
																<td style={{ textAlign: "right" }}>
																	{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
																		<input className={classes.flexiteminput}
																			style={{ textAlign: "right" }}

																			ref={el => chargeRefs.current[chargeindex] = el}
																			type="number"
																			step="0.01"
																			defaultValue={charge.Amount}
																			onBlur={(event) => DetectBlankNumberFloat(event, chargeindex, charge.ChargeType)}
																			onChange={(event) => onChangeCharge(event, chargeindex)}
																		/>
																	}
																	{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
																		<div className={classes.flexitemstaticinput}>
																			{charge.Amount}
																		</div>
																	}
																</td>
															</tr>
														}
													</React.Fragment>
												)
											}))}


											{(ordercharges.map(function (charge, chargeindex) {
												return (
													<React.Fragment key={"charges" + chargeindex}>
														{/* Shipping Charges and Discounts are BEFORE subtotal. Charge tax on shipping as well in our state */}
														{(charge.ChargeType === "discount") &&
															<tr>
																<td>
																	{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
																		<IconButton aria-label="delete" onClick={() => { DeleteCharge(chargeindex) }} size="small">
																			<DeleteIcon />
																		</IconButton>
																	}
																</td>
																<td>
																	{capitalizeFirst(charge.ChargeType)}:
																</td>
																<td style={{ textAlign: "right" }}>
																	{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
																		<input className={classes.flexiteminput + " " + classes.flexnegativeinput}
																			style={{ textAlign: "right" }}

																			ref={el => chargeRefs.current[chargeindex] = el}
																			type="number"
																			step="0.01"
																			defaultValue={charge.Amount}
																			onBlur={(event) => DetectBlankNumberFloat(event, chargeindex, charge.ChargeType)}
																			onChange={(event) => onChangeCharge(event, chargeindex)}
																		/>
																	}
																	{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
																		<div className={classes.flexitemstaticinput}>
																			{charge.Amount}
																		</div>
																	}
																</td>
															</tr>
														}
													</React.Fragment>
												)
											}))}


											<tr>
												<td></td>
												<td>
													Subtotal:
												</td>
												<td>
													<input className={classes.flexiteminput}
														style={{ textAlign: "right" }}
														ref={el => orderRefs['subtotal'].current = el}
														disabled={true}
														type="text"
														defaultValue={currentorder.subtotal}
													/>
												</td>
											</tr>

											{(ordercharges.map(function (charge, chargeindex) {
												{/* Now add Tax input: */ }
												return (
													<React.Fragment key={"charges" + chargeindex}>
														{/* Shipping Charges and Discounts are BEFORE subtotal. Charge tax on shipping as well in our state */}
														{(charge.ChargeType === "tax") &&
															<tr>
																<td>
																	{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
																		<IconButton aria-label="delete" onClick={() => { DeleteCharge(chargeindex) }} size="small">
																			<DeleteIcon />
																		</IconButton>
																	}
																</td>
																<td>
																	{capitalizeFirst(charge.ChargeType)}:
																</td>
																<td style={{ textAlign: "right" }}>
																	{(userPerms.updateOrder === 1 || (userPerms.createOrder === 1 && order.Draft === 1)) &&
																		<input className={classes.flexiteminput}
																			style={{ textAlign: "right" }}

																			ref={el => chargeRefs.current[chargeindex] = el}
																			type="number"
																			step="0.01"
																			defaultValue={charge.Amount}
																			onBlur={(event) => DetectBlankNumberFloat(event, chargeindex, charge.ChargeType)}
																			onChange={(event) => onChangeCharge(event, chargeindex)}
																		/>
																	}
																	{(userPerms.updateOrder === 0 && userPerms.createOrder === 0) &&
																		<div className={classes.flexitemstaticinput}>
																			{charge.Amount}
																		</div>
																	}
																</td>
															</tr>
														}
													</React.Fragment>
												)
											}))}


											<tr>
												<td></td>
												<td>
													Order Total:
												</td>
												<td>
													<input className={classes.flexiteminput}
														style={{ textAlign: "right" }}
														ref={el => orderRefs['Total'].current = el}
														disabled={true}
														type="text"
														defaultValue={currentorder.Total}
													/>
												</td>
											</tr>
										</tbody>
									</table>

								</td>
							</tr>
							<tr>
								<td colSpan="4" className={classes.flextableheader} style={{ borderTop: "2px solid #D4D4D4" }}>
									<div style={{ minHeight: "30px" }}>
										<div style={{ float: "right" }}>

											{(userPerms.deleteOrder === 1) &&
												<Button
													className={classes.bluebtn}
													color="primary" variant="contained"
													sx={{ margin: "0px 0px 0px 6px!important", padding: "0px" }}
													aria-haspopup="true"
													onClick={DeleteOrderInit}
												>
													Delete Order
												</Button>
											}

											{(order.Canceled === 1 && userPerms.updateOrder) &&
												<Button
													className={classes.bluebtn}
													color="primary" variant="contained"
													sx={{ margin: "0px 0px 0px 6px!important", padding: "0px" }}
													aria-haspopup="true"
													onClick={UncancelOrderInit}
												>
													Remove Canceled Status
												</Button>
											}

											{(order.Canceled === 0 && userPerms.updateOrder) &&
												<Button
													className={classes.bluebtn}
													color="primary" variant="contained"
													sx={{ margin: "0px 0px 0px 6px!important", padding: "0px" }}
													aria-haspopup="true"
													onClick={CancelOrderInit}
												>
													Cancel Order
												</Button>
											}

											{(order.Canceled !== 1 && userPerms.updateOrder) &&
												<Button
													className={classes.bluebtn}
													color="primary" variant="contained"
													sx={{ margin: "0px 0px 0px 6px!important", padding: "0px" }}
													aria-haspopup="true"
													onClick={ShowChargeTypeMenu}
													disabled={(
														ValidateChargeType(ordercharges, "shipping") ||
														ValidateChargeType(ordercharges, "discount") ||
														ValidateChargeType(ordercharges, "tax")) === false}>
													Add Charge/Discount
												</Button>
											}

											<Menu
												className={classes.bluebtn}
												color="primary"
												id="simple-menu"
												anchorEl={chargetypemenu}
												keepMounted
												open={Boolean(chargetypemenu)}
												onClose={CloseChargeTypeMenu}
											>
												{(ValidateChargeType(ordercharges, "shipping")) &&
													<MenuItem onClick={() => { handleAddCharge("shipping") }}>Shipping</MenuItem>
												}
												{(ValidateChargeType(ordercharges, "tax")) &&
													<MenuItem onClick={() => { handleAddCharge("tax") }}>Tax</MenuItem>
												}
												{(ValidateChargeType(ordercharges, "discount")) &&
													<MenuItem onClick={() => { handleAddCharge("discount") }}>Discount</MenuItem>
												}
											</Menu>
										</div>

									</div>

									<div className={classes.defaulthidden + " " + classes.warningmsg + " " + classes.whitetext}
										style={{ borderRadius: "3px", textAlign: "center", margin: "10px" }}
										ref={el => messageref.current = el}>

									</div>

								</td>
							</tr>

						</tbody>
					</table>

					
					{/* Transactions */}
					{/* <div style={{ width: "100%", display: "inline-block", verticalAlign: "top", marginTop: "20px" }}>
						<table className={classes.flextable} style={{ width: "100%", textAlign: "left" }}>
							<tbody>
								<tr>
									<td className={classes.flextableheader}>
										Transactions
									</td>
								</tr>
								<tr>
									<td style={{ padding: "6px" }}>
										<textarea className={classes.flexiteminput}
											key={"InternalNotes"}
											ref={el => orderRefs.InternalNotes.current = el}
											type="text"
											style={{ height: "100%" }}
											defaultValue={currentorder.InternalNotes}
											onChange={(event) => onChangeValue(event, "InternalNotes")}
											onFocus={(event) => onInternalNoteFocus(event, "InternalNotes")}
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div> */}


					{/* Notes */}
					{/* <div style={{ width: "100%", display: "inline-block", verticalAlign: "top", marginTop: "20px" }}>
						<table className={classes.flextable} style={{ width: "100%", textAlign: "left" }}>
							<tbody>
								<tr>
									<td className={classes.flextableheader}>
										Internal Notes - Legacy System
									</td>
								</tr>
								<tr>
									<td style={{ padding: "6px" }}>
										<textarea className={classes.flexiteminput}
											key={"InternalNotes"}
											ref={el => orderRefs.InternalNotes.current = el}
											type="text"
											style={{ height: "100%" }}
											defaultValue={currentorder.InternalNotes}
											onChange={(event) => onChangeValue(event, "InternalNotes")}
											onFocus={(event) => onInternalNoteFocus(event, "InternalNotes")}
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div> */}



					{/* Shipments */}
					{(currentorder.shipments.length > 0) &&
						<div style={{ width: "100%", display: "inline-block", verticalAlign: "top", marginTop: "20px" }}>
							<table className={classes.flextable} style={{ width: "100%", textAlign: "left" }}>
								<tbody>
									<tr>
										<td className={classes.flextableheader} colspan="5">
											Shipments
										</td>
									</tr>


									<tr style={{ borderBottom: "2px solid #D4D4D4", fontWeight: "bold", fontSize: "12px", backgroundColor: "rgb(250 250 250)" }}>
										<td style={{ textAlign: "left", padding: "0px 5px" }}>
											Date
										</td>
										<td style={{ textAlign: "center", padding: "0px 5px" }}>
											Carrier
										</td>
										<td style={{ textAlign: "center", padding: "0px 5px" }}>
											Tracking Number
										</td>
										<td style={{ textAlign: "center", padding: "0px 5px" }}>
											Est Cost
										</td>
									</tr>


									{currentorder.shipments.map(function (shipment, shipmentindex) {
										return (
											<tr key={"Shipment" + shipmentindex} style={{ borderBottom: "1px solid #D4D4D4" }}>
												<td style={{ padding: "6px" }}>
													{/* <Moment element='span' format="MMMM D, YYYY [at] h:MMa">{shipment.ShipDate}</Moment> */}
													{dayjs(shipment.ShipDate).format("MMMM D, YYYY [at] h:mma")}
												</td>
												<td style={{ padding: "6px", textAlign: "center" }}>
													{shipment.Shipper}
												</td>


												<td style={{ padding: "6px", textAlign: "center" }}>
													{/* We can make links here! */}
													{(shipment.Shipper === "Fedex") &&
														<React.Fragment>
															<a target="_blank" href={"https://www.fedex.com/fedextrack/?trknbr=" + shipment.TrackingNumber}>{shipment.TrackingNumber}</a>
														</React.Fragment>
													}
													{(shipment.Shipper === "UPS") &&
														<React.Fragment>
															<a target="_blank" href={"https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum=" + shipment.TrackingNumber}>{shipment.TrackingNumber}</a>
														</React.Fragment>
													}
													{(shipment.Shipper === "USPS") &&
														<React.Fragment>
															<a target="_blank" href={"https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLabels=" + shipment.TrackingNumber}>{shipment.TrackingNumber}</a>
														</React.Fragment>
													}
													{(shipment.Shipper !== "USPS" && shipment.Shipper !== "Fedex" && shipment.Shipper !== "UPS") &&
														<React.Fragment>
															{shipment.TrackingNumber}
														</React.Fragment>
													}
												</td>

												<td style={{ padding: "6px", textAlign: "center" }}>
													${shipment.TotalCharges}
												</td>
											</tr>
										)
									})}

								</tbody>
							</table>
						</div>
					}



					{/* Manifest */}
					{(currentorder.manifest.length > 0) &&
						<div style={{ width: "100%", display: "inline-block", verticalAlign: "top", marginTop: "20px" }}>
							<table className={classes.flextable} style={{ width: "100%", textAlign: "left" }}>
								<tbody>
									<tr>
										<td className={classes.flextableheader} colspan="3">
											Manifest
										</td>
										<td className={classes.flextableheader} colspan="2" style={{textAlign:"right"}}>
											Total: {currentorder.manifest.length}
										</td>
									</tr>


									<tr style={{ borderBottom: "2px solid #D4D4D4", fontWeight: "bold", fontSize: "12px", backgroundColor: "rgb(250 250 250)" }}>
										<td style={{ textAlign: "left", padding: "0px 5px" }}>
											Date
										</td>
										<td style={{ textAlign: "center", padding: "0px 5px" }}>
											Serial Number
										</td>
										<td style={{ textAlign: "center", padding: "0px 5px" }}>
											Model
										</td>
										<td style={{ textAlign: "center", padding: "0px 5px" }}>
											User
										</td>
										<td style={{ textAlign: "center", padding: "0px 5px" }}>
											Binding
										</td>
									</tr>


									{currentorder.manifest.map(function (manifestitem, manifestindex) {
										return (
											<tr key={"Shipment" + manifestindex} style={{ borderBottom: "1px solid #D4D4D4" }}>
												<td style={{ padding: "6px" }}>
													{/* <Moment element='span' format="MMMM D, YYYY [at] h:MMa">{}</Moment> */}
													{dayjs(manifestitem.CreateDate).format("MMMM D, YYYY [at] h:mma")}
												</td>
												<td style={{ padding: "6px", textAlign: "center" }}>
													{(manifestitem.item) &&
														<a className={classes.hoverunit} target="_blank" href={"/item/" + manifestitem.item.ItemID}>
															{manifestitem.AssetSerialNumber}
														</a>
													}
													{(!manifestitem.item) &&
														<React.Fragment>
															{manifestitem.AssetSerialNumber}
														</React.Fragment>
													}

												</td>

												<td style={{ padding: "6px", textAlign: "center" }}>
													{(manifestitem.item) &&
														<React.Fragment>
															{manifestitem.item.Model}
														</React.Fragment>
													}
													{(!manifestitem.item) &&
														<React.Fragment>
															Non-Inventory Serial Number
														</React.Fragment>
													}
												</td>


												<td style={{ padding: "6px", textAlign: "center" }}>
													{manifestitem.CreatedBy}
												</td>

												<td style={{ padding: "6px", textAlign: "center" }}>
													{(manifestitem.Valid === 1) &&
														<div className={classes.skucomponent}
															style={{ fontSize: "14px", paddingRight: "5px" }}>
															Bound
														</div>
													}
													{(manifestitem.Valid === 0) &&
														<div className={classes.productcomponent}
															style={{ fontSize: "14px", paddingRight: "5px" }}>
															Unbound
														</div>
													}
												</td>

											</tr>
										)
									})}

								</tbody>
							</table>
						</div>
					}



					{/* Order Inspection */}
					{((currentorder.unassignedmanifest.length > 0) || (currentorder.manifest.length>0)) &&
						<div style={{ width: "100%", display: "inline-block", verticalAlign: "top", marginTop: "20px" }}>
							<table className={classes.flextable} style={{ width: "100%", textAlign: "left" }}>
								<tbody>
									<tr>
										<td className={classes.flextableheader}>
											Order Inspection
										</td>
									</tr>


									<tr style={{ borderBottom: "2px solid #D4D4D4", fontWeight: "normal", fontSize: "12px", backgroundColor: "#FFFFFF" }}>
										<td style={{ textAlign: "left", padding: "10px 5px" }}>
										<InspectionView order={currentorder} />
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					}


					{/* Order Documents */}
					<div style={{ width: "100%", display: "inline-block", verticalAlign: "top", marginTop: "20px" }}>
						<table className={classes.flextable} style={{ width: "100%", textAlign: "left" }}>
							<tbody>
								<tr>
									<td className={classes.flextableheader}>
										Internal Notes
									</td>
								</tr>
								<tr>
									<td style={{ padding: "6px", textAlign:"center" }}>

										<FlexDocument
											itemid={currentorder.InternalID} //May be ProductID, ItemID, etc. Also adjust your API endpoint.
											documentid={currentorder.DocumentID}
											allownew={true}
											documentendpoint={'/order'}
											dbkey={"DocumentID"}
											userPerms={{
												create: userPerms.createOrder,
												read: userPerms.readOrder,
												update: userPerms.updateOrder,
												delete: userPerms.deleteOrder
											}}
											readOnly={true} //This true, breaks dragdrop
											tools={{
												header: true,
												list: true,
												image: true,
												table: true,
												checklist: true,
												dragdrop: true, //This breaks if you start off with readOnly=true from props
												alert: true,
												toggle: true,
												code: true,
												color: false, //Disabled until plugin 2.0.4 is updated to something working.
												marker: false,//Disabled until plugin 2.0.4 is updated to something working.
												raw: true
											}}
											addbtntext={"Add Notes"}
											editbtntext={"Edit Notes"} //Document, Notes, etc
											savebtntext={"Save Notes"}
											//header={"Optional Header for Document"}
											//introduction={"Optional introduction for document usage."}
											showupdateinfo={true}
										/>

									</td>
								</tr>
							</tbody>
						</table>
					</div>




					

				</div>
			</Typography>
		)
	};

	//####################  ORDER INVOICES ###################### */
	const OrderInvoices = React.forwardRef((props, ref) => {
		return (
			<div ref={ref}>
				{
					props.ordersdata.map((row, index) => {
						return (
							<div key={"ordersdata" + index} 
								className={classes.flexorder} 
								style={{ fontSize: "14px", backgroundColor: "#FFF", padding: "0px 20px 20px 20px", marginBottom: "20px", maxWidth: "850px", wordWrap: "break-word", pageBreakAfter: "always", marginLeft:"auto", marginRight:"auto" }}>
								{/* Edit Button */}
								{(!batchmode) &&
									<div style={{ textAlign: "center", padding: "5px 0px 10px 0px" }}>
										<Button
											className={classes.bluebtn}
											color="primary" variant="contained"
											onClick={() => GoToOrders()}>
											<KeyboardBackspaceIcon sx={{ color: "lightgray" }}></KeyboardBackspaceIcon>&nbsp;Back to Order Table
										</Button>
										<Button
											className={classes.bluebtn}
											color="primary" variant="contained"
											sx={{displayPrint:'none', margin: "0px"}}
											onClick={() => setViewMode('worksheet')}>
											View Worksheet
										</Button>
										<Button
											onClick={() => EditOrder(index)}
											className={classes.bluebtn}
											sx={{displayPrint:'none', margin: "0px"}}
											color="primary" variant="contained">
											<EditIcon fontSize="inherit" />&nbsp;&nbsp;Edit
										</Button>
									</div>
								}

								{(batchmode) &&
									<div style={{ textAlign: "right", padding: "5px 0px 10px 0px" }}>
										<Button
											onClick={() => EditOrder(index)}
											className={classes.bluebtn}
											sx={{displayPrint:'none', margin: "0px"}}
											color="primary" variant="contained">
											<EditIcon fontSize="inherit" />&nbsp;&nbsp;Edit
										</Button>
									</div>
								}
								
								<div className={classes.printspacer20}></div>
								<div style={{ textAlign: "left", display: "inline-block", width: "50%" }}>
									<b>Deluxe PCs</b><br></br>
									7049 Production Ct.<br></br>
									Florence, KY 41042<br></br>
									deluxepcs.com<br></br>
								</div>
								<div style={{ textAlign: "right", display: "inline-block", width: "50%" }}>
									Order Number: {row.OrderID}<br></br>
									Order Date: {dayjs(row.OrderDate).format("MMMM D, YYYY [at] h:mma")}
									Market: {row.Store}<br></br>
									Shipping: {row.RequestedShipping}
								</div>
								<div style={{ width: "49%", display: "inline-block", verticalAlign: "top", marginTop: "10px" }}>
									<table className={classes.flextable} style={{ width: "100%", textAlign: "left" }}>
										<tbody>
											<tr>
												<td className={classes.flextableheader}>
													Billing
												</td>
											</tr>
											<tr>
												<td style={{ padding: "6px" }}>
													{(row.BFullName) ? <div>{row.BFullName}</div> : <div>{row.BFirstName} {row.BLastName}</div>}
													{(row.BCompany) ? <div>{row.BCompany}</div> : ""}
													{(row.BLine1) ? <div>{row.BLine1}</div> : ""}
													{(row.BLine2) ? <div>{row.BLine2}</div> : ""}
													{(row.BLine3) ? <div>{row.BLine3}</div> : ""}
													{(row.BCity) ? <div>{row.BCity}, {row.BStateCode} {row.BPostalCode}	</div> : ""}
													{(row.BPhone) ? <div>{row.BPhone}</div> : ""}
													{(row.BEmail) ? <div>{row.BEmail}</div> : ""}
													{(row.BWebsite) ? <div>{row.BWebsite}</div> : ""}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div style={{ width: "2%", display: "inline-block" }}>&nbsp;</div>

								<div style={{ width: "49%", display: "inline-block", verticalAlign: "top", marginTop: "10px" }}>
									<table className={classes.flextable} style={{ width: "100%", textAlign: "left" }}>
										<tbody>
											<tr>
												<td className={classes.flextableheader}>
													Shipping
												</td>
											</tr>
											<tr>
												<td style={{ padding: "6px" }}>
													{(row.SFullName) ? <div>{row.SFullName}</div> : <div>{row.SFirstName} {row.SLastName}</div>}
													{(row.SCompany) ? <div>{row.SCompany}</div> : ""}
													{(row.SLine1) ? <div>{row.SLine1}</div> : ""}
													{(row.SLine2) ? <div>{row.SLine2}</div> : ""}
													{(row.SLine3) ? <div>{row.SLine3}</div> : ""}
													{(row.SCity) ? <div>{row.SCity}, {row.SStateCode} {row.SPostalCode}	</div> : ""}
													{(row.SPhone) ? <div>{row.SPhone}</div> : ""}
													{(row.SEmail) ? <div>{row.SEmail}</div> : ""}
													{(row.SWebsite) ? <div>{row.SWebsite}</div> : ""}
												</td>
											</tr>
										</tbody>
									</table>
								</div>


								{/* Items */}
								<table className={classes.flextable} style={{ width: "100%", textAlign: "left", marginTop: "10px" }}>
									<tbody>
										<tr>
											<td colSpan="4" className={classes.flextableheader}>
												Items
											</td>
										</tr>
										<tr style={{ borderBottom: "2px solid #D4D4D4", fontWeight: "bold", fontSize: "12px" }}>
											<td style={{ textAlign: "left", padding: "0px 5px" }}>
												Product
											</td>
											<td style={{ textAlign: "center", padding: "0px 5px" }}>
												Price
											</td>
											<td style={{ textAlign: "center", padding: "0px 5px" }}>
												Qty
											</td>
											<td style={{ textAlign: "right", padding: "0px 5px" }}>
												Total
											</td>
										</tr>
										{row.orderitems.map(function (orderitem, orderitemindex) {
											return (
												<tr style={{ borderBottom: "1px solid #D4D4D4" }} key={"orderitems" + index}>
													<td style={{ textAlign: "left", padding: "0px 5px" }}>
														{orderitem.ProductName}<br></br>
														Sku: {orderitem.Code}
													</td>
													<td style={{ textAlign: "center", padding: "0px 5px" }}>
														{orderitem.UnitPrice}
													</td>
													<td style={{ textAlign: "center", padding: "0px 5px" }}>
														{orderitem.Quantity}
													</td>
													<td style={{ textAlign: "right", padding: "0px 5px" }}>
														{parseFloat(orderitem.UnitPrice * orderitem.Quantity).toFixed(2)}
													</td>
												</tr>
											)
										})}
										<tr>
											<td colSpan="4">
												<table style={{ float: "right" }}>
													<tbody>
														<tr>
															<td>
																Subtotal:
															</td>
															<td style={{ textAlign: "right" }}>
																{row.SubTotal}
															</td>
														</tr>
														{(row.charges.map(function (charge, chargeindex) {
															return (
																<tr key={"charges" + index}>
																	<td>
																		{capitalizeFirst(charge.ChargeType)}:
																	</td>
																	<td style={{ textAlign: "right" }}>
																		{charge.Amount}
																	</td>
																</tr>
															)

														}))}
														<tr>
															<td>
																Order Total:
															</td>
															<td style={{ textAlign: "right" }}>
																{row.Total}
															</td>
														</tr>

													</tbody>
												</table>
											</td>
										</tr>

									</tbody>
								</table>
							</div>
						)
					}
					)
				}
			</div>
		)
	});

	//####################  ORDER WORKSHEETS ###################### */
	const OrderWorksheets = React.forwardRef((props, ref) => {
		return (
			<div ref={ref}>
				{
					props.ordersdata.map((row, index) => {
						//Wrap the whole view with a padded container that won't be rendered on print.
						return (
							<>
							<Box
							key={"ordercontainer" + index}
							sx={{
								padding:"0px 20px 20px 20px",
								width:"770px",
								backgroundColor:"#FFF",
								margin:"auto",
								'@media print':{
									padding:"4px",
								}
							}}>
								<div key={"ordersdata" + index} className={classes.flexorder} style={{ fontSize: "14px", backgroundColor: "#FFF", marginBottom: "20px", maxWidth: "850px", wordWrap: "break-word", pageBreakAfter: "always",  marginLeft:"auto", marginRight:"auto" }}>
									{/* Edit Button */}
										{(!batchmode) &&
										<div style={{ textAlign: "center", padding: "5px 0px 10px 0px" }}>
											{/* Single Orders selected from table also get rendered this way */}
											<Button
												className={classes.bluebtn}
												color="primary" variant="contained"
												onClick={() => GoToOrders()}
												sx={{ displayPrint: 'none', margin: "0px" }}
											>
												<KeyboardBackspaceIcon sx={{ color: "lightgray" }}></KeyboardBackspaceIcon>&nbsp;Back to Order Table
											</Button>
											{((viewmode !== "details") && (localstate.expiryitems === 0)) &&
												<Button
													className={classes.bluebtn}
													color="primary" variant="contained"
													onClick={() => handlePrint()}
													sx={{ displayPrint: 'none', margin: "0px" }}
												>
													Print
												</Button>
											}

											{((viewmode !== "details") && viewmode !== "invoice" && (localstate.expiryitems === 0)) &&
												<Button
													className={classes.bluebtn}
													color="primary" variant="contained"
													onClick={() => handleConfirmPrinted()}
													sx={{ displayPrint: 'none', margin: "0px" }}
												>
													Confirm Printed
												</Button>
											}

											{((viewmode !== "details") && (localstate.expiryitems > 0) && (viewmode !== "invoice")) &&
												<React.Fragment>
													<br></br>
													<Button
														className={classes.orangebtn}
														color="primary" variant="contained"
														onClick={() => handleRefreshExpiry()}
														sx={{ displayPrint: 'none', margin: "0px" }}
													>
														Refresh {localstate.expiryitems} Process IDs (Allow New Prints)
													</Button>
												</React.Fragment>

											}
											<Button
												className={classes.bluebtn}
												color="primary" variant="contained"
												onClick={() => setViewMode('invoice')}
												sx={{ displayPrint: 'none', margin: "0px" }}
											>
												View Invoice
											</Button>
											<Button
												onClick={() => EditOrder(index)}
												className={classes.bluebtn}
												sx={{displayPrint:'none', margin: "0px"}}
												color="primary" variant="contained">
												<EditIcon fontSize="inherit" />&nbsp;&nbsp;Edit
											</Button>
										</div>
										}
										{(batchmode) &&
										<div style={{ textAlign: "right", padding: "5px 0px 10px 0px" }}>
											<Button
												onClick={() => EditOrder(index)}
												className={classes.bluebtn}
												sx={{ displayPrint: 'none', margin: "0px" }}
												color="primary" variant="contained">
												<EditIcon fontSize="inherit" />&nbsp;&nbsp;Edit
											</Button>
										</div>
										}
									
									<div className={classes.printspacer20}></div>
									<div style={{ textAlign: "right" }}>
										<Barcode value={row.OrderID}
											height={20}
											textAlign="right"
											margin={0}
											displayValue={false}
										/>
									</div>
									<div style={{ textAlign: "left", display: "inline-block", width: "50%" }}>
										{(row.SFullName) ? <div>{row.SFullName}</div> : ""}
										{(row.SCompany) ? <div>{row.SCompany}</div> : ""}
										{(row.SLine1) ? <div>{row.SLine1}</div> : ""}
										{(row.SLine2) ? <div>{row.SLine2}</div> : ""}
										{(row.SLine3) ? <div>{row.SLine3}</div> : ""}
										{(row.SCity) ? <div>{row.SCity}, {row.SStateCode} {row.SPostalCode}	</div> : ""}
										{(row.SPhone) ? <div>{row.SPhone}</div> : ""}
									</div>
									<div style={{ textAlign: "right", display: "inline-block", width: "50%", verticalAlign: "top" }}>
										Order Number: {row.OrderID}<br></br>
										Order Date: {dayjs(row.OrderDate).format("MMMM D, YYYY [at] h:mma")}
										Market: {row.Store}<br></br>
										Shipping: {row.RequestedShipping}
									</div>

									{/* Items */}
									{row.orderitems.map(function (orderitem, orderitemindex) {
										return (
											<React.Fragment key={"orderitems" + orderitemindex}>
												{(orderitem.sku) &&

													<table className={classes.flextable} style={{ width: "100%", textAlign: "left", marginTop: "10px", marginBottom: "20px" }}>
														<tbody>
															<tr>
																<td colSpan="2" className={classes.flextableheader}>
																	{(orderitem.sku.product) ? orderitem.sku.product.Name : ""}
																	{(!orderitem.sku.product) ? orderitem.sku.Title : ""}


																	<span style={{ fontWeight: "normal" }}>&nbsp;&nbsp;({orderitem.sku.Sku})</span>
																	<div style={{ float: "right" }}>Process ID: {orderitem.ProcessID}</div>
																</td>
															</tr>
															<tr style={{ borderBottom: "2px solid #D4D4D4" }}>
																<td colSpan="2" style={{ textAlign: "left", padding: "5px 5px" }}>
																	<div style={{ float: "right", marginLeft: "20px", textAlign: "center", fontSize: "10px" }}>
																		Quantity
																		<div style={{ border: "3px solid #000", textAlign: "center", height: "45px", width: ((orderitem.Quantity + 1000) > 999) ? "60px" : "45px", position: "relative" }}>

																			<div style={{ position: "absolute", top: "50%", msTransform: "translateY(-50%)", "transform": "translateY(-50%)", textAlign: "center", fontSize: "20px", width: "100%" }}>
																				{orderitem.Quantity}
																			</div>
																		</div>
																	</div>


																	{(orderitem.sku.product) ? orderitem.sku.Title : ""}
																	<br></br>
																	<div style={{ verticalAlign: "bottom" }}>
																		{(orderitem.Locations) &&
																			<>
																				<b>Locations:&nbsp;
																					{(orderitem.Locations.slice(0, 10).map(function (location, locationindex) {
																						return (
																							<span key={locationindex + "locations" + index}>{location.Location}
																								{(locationindex !== (orderitem.Locations.length - 1) && locationindex !== 9) ? ", " : ""}

																							</span>
																						)

																					}))}
																				</b>
																			</>
																		}
																	</div>

																</td>
															</tr>

															{/* Orders needing 2 columns: */}

															{(orderitem.sku.skucomponents.length > 0 && (orderitem.Instructions || orderitem.sku.Note)) &&
																<>
																	<tr>
																		<td className={classes.flextableheader} style={{ width: "50%" }}>
																			Specs
																		</td>

																		<td className={classes.flextableheader} style={{ borderLeft: (orderitem.Instructions || orderitem.sku.Note) ? "2px solid #D4D4D4" : "" }}>
																			{(orderitem.Instructions || orderitem.sku.Note) &&
																				<>Notes</>
																			}
																		</td>

																	</tr>
																	<tr>
																		<td>
																			{orderitem.sku.skucomponents.map(function (skucomponent) {
																				return (
																					<div key={orderitem.ItemID+skucomponent.ID} style={{ fontSize: "16px", fontWeight: "bold", padding: "5px 0px 2px 10px", height:"32px"}}>
																						<div style={{ display: "inline-block", width: "40px", height: "25px", verticalAlign:"bottom" }}>
																							<HardwareIcon hwtype={skucomponent.ComponentType} hwname={skucomponent.details.Name} height="20px" verticalalignment="bottom" />
																						</div>
																						<div style={{verticalAlign:"middle", display:"inline-block", paddingBottom:"5px"}}>
																							{skucomponent.ComponentType}: {skucomponent.details.Name}
																						</div>
																					</div>
																				)
																			})}

																		</td>
																		<td style={{ borderLeft: (orderitem.Instructions || orderitem.sku.Note) ? "2px solid #D4D4D4" : "", verticalAlign: "top", padding: "5px" }}>
																			{(orderitem.Instructions) ? "Item Note: " + orderitem.Instructions : ""}
																			{(orderitem.sku.Note) ? "Sku Note: " + orderitem.sku.Note : ""}
																			<div style={{float:"right", margin:"8px"}}>
																				<QRCode
																				key={orderitem.ItemID}
																					size={120}
																					style={{ height: "120px", maxWidth: "100%" }}
																					value={orderitem.Code}
																					viewBox={`0 0 256 256`}
																				/>
																			</div>
																			
																		</td>
																	</tr>
																</>
															}

															{/* Orders only needing 1 column of data: Specs */}
															{(orderitem.sku.skucomponents.length > 0 && !orderitem.Instructions && !orderitem.sku.Note) &&
																<>
																	<tr>
																		<td className={classes.flextableheader}>
																			Specs
																		</td>

																		<td className={classes.flextableheader}>
																		</td>

																	</tr>
																	<tr>
																		<td style={{width:"100%"}}>
																		<div style={{ float: "right", margin:"8px" }}>
																			<QRCode
																				key={orderitem.ItemID}
																				size={120}
																				style={{ height: "120px", maxWidth: "100%" }}
																				value={orderitem.Code}
																				viewBox={`0 0 256 256`}
																			/>
																		</div>

																			{orderitem.sku.skucomponents.map(function (skucomponent) {
																				return (
																					<div key={orderitem.ItemID + skucomponent.ID} style={{ fontSize: "16px", fontWeight: "bold", padding: "5px 0px 2px 10px", height:"32px" }}>
																						<div style={{ display: "inline-block", width: "40px", height: "25px", verticalAlign:"bottom" }}>
																							<HardwareIcon hwtype={skucomponent.ComponentType} hwname={skucomponent.details.Name} height="20px" verticalalignment="bottom" />
																						</div>
																						<div style={{verticalAlign:"bottom", display:"inline-block", paddingBottom:"5px"}}>
																							{skucomponent.ComponentType}: {skucomponent.details.Name}
																						</div>
																						
																					</div>
																				)
																			})}

																		</td>
																	</tr>
																</>
															}
															{/* Orders only needing 1 column of data: Notes */}
															{(orderitem.sku.skucomponents.length === 0 && (orderitem.Instructions || orderitem.sku.Note)) &&
																<>
																	<tr>
																		<td className={classes.flextableheader}>
																			Notes
																		</td>

																		<td className={classes.flextableheader}>
																		</td>

																	</tr>
																	<tr>
																		<td>
																			{(orderitem.Instructions) ? "Item Note: " + orderitem.Instructions : ""}
																			{(orderitem.sku.Note) ? "Sku Note: " + orderitem.sku.Note : ""}
																		</td>
																		<td>
																		</td>
																	</tr>
																</>
															}








														</tbody>
													</table>


												}

											</React.Fragment>


										)

									})}
								</div>
							</Box>
							{/* Spacer! */}
							<Box
							key={"orderspacer" + index}
							sx={{
								padding:"0px 20px 20px 20px",
								width:"810px",
								backgroundColor:"#DDD",
								displayPrint:"none"
								}}></Box>
							</>
							
						)
					}
					)
				}
			</div>
		)
	});




	/* Utility Function - Attempt to Update Tables: */
	/* Not used.
	const UpdateManifests = () =>{
		axios.post(dbendpoint+"/items/updatemanifests", {}, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					console.log(res.data);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}
	*/

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const handleConfirmPrinted = () => {
		//Set all ordersdata
		for (var i = 0; i < localstate.ordersdata.length; i++) {
			localstate.ordersdata[i]['ConfirmPrintStatus'] = true;
		}

		var postdata = {
			items: localstate.ordersdata
		}



		axios.post(dbendpoint + "/orders/bulkedititems", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === 'login') {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				if (res.data.Status === 'Success') {

					dispatch(newErrorMessage({ errmsg: "Order Prints Confirmed", errshow: true, errtimeout: 30, errtype: "ok" }));
					dispatch(setErrorTimeout(20));
				}

				if (res.data.Status === 'Failure') {
					//Failure error
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 10, errtype: 'neutral' })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 10, errtype: 'warning' })
			}
		});



	}

	const GoToOrders = () => {
		history.push('/orderstable');
	}

	const handleRefreshExpiry = () => {
		//We need to rerender orders to get new Process IDs.
		localstate.refreshexpiry = true;
		localstate.dbreload = true;
		UpdateState(localstate);
	}


	/* 
																							 
		 _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
		_/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
	   _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
	  _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
	 _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
																						 
																						 
	  */

	/* ##########################  Render Function  ########################## */
	return (
		(localstate.ordersloaded) &&
		<div style={{ backgroundColor: "#DDD"}}>
			{/* Consider this large change: */}
			{/* <div style={{ padding: "8px", minWidth: "850px", maxWidth: "1200px", textAlign: "center", margin: "auto", backgroundColor: "#DDD"}}> */}
			<div style={{ padding: "8px", width:"100%", textAlign: "center", margin: "auto", backgroundColor: "#DDD"}}>


				{/* Standard Page Header with right floated error message space - Non-Print Items */}
				{(!isPrintView) &&
					<div style={{ position: "relative", paddingTop: "5px" }}>
						<div>
								{/* Standard Page Header with right floated error message space - Non-Print Items */}

								{(currentids.length > 1 && (viewmode !== "details")) &&
									<>
										<h2>Order Batch {localstate.batchmode}</h2>

										{/* TOP MENU */}
										<div style={{ paddingBottom: "10px" }}>
											{(props.isChild) &&
												<Button
													className={classes.bluebtn}
													color="primary" variant="contained"
													onClick={() => props.ToggleOrderView()}>
													<KeyboardBackspaceIcon sx={{ color: "lightgray" }}></KeyboardBackspaceIcon>&nbsp;Back to Order Table
												</Button>
											}

											{(!props.isChild) &&
												<Button
													className={classes.bluebtn}
													color="primary" variant="contained"
													onClick={() => GoToOrders()}>
													<KeyboardBackspaceIcon sx={{ color: "lightgray" }}></KeyboardBackspaceIcon>&nbsp;Back to Order Table
												</Button>
											}

											{(viewmode !== "details" && localstate.currentids.length === 1) &&
												<Button
													className={classes.bluebtn}
													color="primary" variant="contained"
													onClick={() => setViewMode('details')}>
													View Details{(currentids.length > 1 ? "s" : "")}
												</Button>
											}

											{(viewmode !== "invoice") &&
												<Button
													className={classes.bluebtn}
													color="primary" variant="contained"
													onClick={() => setViewMode('invoice')}>
													View Invoice{(currentids.length > 1 ? "s" : "")}
												</Button>
											}

											{(viewmode !== "worksheet") &&
												<Button
													className={classes.bluebtn}
													color="primary" variant="contained"
													onClick={() => setViewMode('worksheet')}>
													View Worksheet{(currentids.length > 1 ? "s" : "")}
												</Button>
											}

											{((viewmode !== "details") && (localstate.expiryitems === 0)) &&
												<Button
													className={classes.bluebtn}
													color="primary" variant="contained"
													onClick={() => handlePrint()}>
													Print
												</Button>
											}

											{((viewmode !== "details") && viewmode !== "invoice" && (localstate.expiryitems === 0)) &&
												<Button
													className={classes.bluebtn}
													color="primary" variant="contained"
													onClick={() => handleConfirmPrinted()}>
													Confirm Printed
												</Button>
											}

											{((viewmode !== "details") && (localstate.expiryitems > 0) && (viewmode !== "invoice")) &&
												<React.Fragment>
													<br></br>
													<Button
														className={classes.orangebtn}
														color="primary" variant="contained"
														onClick={() => handleRefreshExpiry()}>
														Refresh {localstate.expiryitems} Process IDs (Allow New Prints)
													</Button>
												</React.Fragment>

											}
										</div>
									</>
								}
								<div style={{ textAlign: "center", height: "25px" }}>
									<NewErrorMessage />
									{(errors.currenterror.errshow) &&
										<ErrorMessage />
									}
								</div>







								{/* Order Detailed View won't have a top menu, just space for the Error/Success messages. */}
								{(viewmode === "details") &&
										<div style={{ textAlign: "center", height: "35px" }}>
											<NewErrorMessage />
											{(errors.currenterror.errshow) &&
												<ErrorMessage />
											}
										</div>
								}

							








						</div>
					</div>
				}

				{(viewmode === "details") &&
					<div style={{textAlign:"center"}}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<OrderDetails currentorder={localstate.currentorder} batchmode={localstate.batchmode}/>
						</LocalizationProvider>
					</div>
				}

				{(viewmode === "invoice") &&
					<div style={{textAlign:"center"}}>
						<OrderInvoices ordersdata={localstate.ordersdata} ref={componentRef} batchmode={localstate.batchmode}/>
					</div>
				}

				{/* Worksheets - hidden!     <div style={{overflow:"hidden", height:0}}> */}
				{(viewmode === "worksheet") &&
					<div style={{textAlign:"center"}}>
						<OrderWorksheets ordersdata={localstate.ordersdata} ref={componentRef} batchmode={localstate.batchmode}/>
					</div>
				}


				{localstate.ordersdata.map((row, index) => {
					return (
						<React.Fragment key={"ordersdata" + index}>


							{(false) && //##################################################################   Edit View - Probably not useful.
								<div style={{ textAlign: "right", fontSize: "14px" }}>
									Order Number: {row.OrderID}<br></br>
									InternalID: {row.InternalID}<br></br>
									Order Date: {dayjs(row.OrderDate).format("MMMM D, YYYY [at] h:mma")}
									Market: {row.Store}<br></br>
									Shipping: {row.RequestedShipping}<br></br>


									<Grid container spacing={0}>
										<Grid item sm={12} md={6}>

											LeftDKLFJADFKLJASKLDFJASKLDFJASDKLFJ
										</Grid>
										<Grid item sm={12} md={6}>

											Right
										</Grid>
									</Grid>
								</div>}
						</React.Fragment>
					)
				})
				}
				{/* End of Order Map */}







			</div>
		</div>

	)
}

export default OrdersView;

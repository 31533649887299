//Sku Integrations Table

/* ##########################  Configuration Sections  ########################## */
//## UseState Variables
//## Column States
//##Column Configuration
//##Column Toggles
//##Row Design
//##Search Inputs
//##Button Functions

//React & Friends
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import PropTypes from 'prop-types';

//Redux Features
import { useSelector, useDispatch } from 'react-redux';
//ProgressBar
import { ProgressBar } from '../../features/progressbar/ProgressBar';
import {
	newProgress,
	incrementPass,
	incrementFail,
	setProgressTimeout
} from '../../features/progressbar/progressbarSlice';
//Main Menu
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//Contexts
//AuthContext
import { AppContext } from "../Auth/contexts/AppContext"
//Error Context - warning, danger, ok, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";
import { styled } from '@mui/material/styles';

//Datetime formatting
//import Moment from 'react-moment';
import 'moment-timezone';
import dayjs from 'dayjs'; //Used with new datetimepickers
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; //Possibly our new adapter for mui x 7 datetime
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

//Restrict Numbers both float and integer types
import RestrictInputNumber from "../common/RestrictInputNumber";

//Common Utilities
import SearchInput from "../common/SearchInput"; //Don't forget localization adapter and switch to DayJS!


//Search Tools
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';


//Tables
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';


import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Popover from '@mui/material/Popover';

//Icons
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

//Export
import ExportCSV from '../common/ExportCSV';

/* ##########################  Configuration  ########################## */

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 60000//11800,
};


//Remove - Useful for completely removing object properties by key. Used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}

const CopyContent = (content) =>{
	navigator.clipboard.writeText(content);
	//alert("Copied the text: " + content);
}

const parseDate = (inputdate, format) => {
	format = format || 'yyyy-mm-dd'; // default format
	var parts = inputdate.match(/(\d+)/g),
		i = 0, fmt = {};
	// extract date-part indexes from the format
	format.replace(/(yyyy|dd|mm)/g, function (part) { fmt[part] = i++; });
	return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']]);
};


const SkuIntegrationsTable = (props) => {
	document.title = "Sku Integrations";
	const classes = useClasses(flexstyles);
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Products"));
	dispatch(setCurrentMenuItem("/skuintegrations"));

	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;
	//console.log(userPerms);


	//Container and Key Sizes
	const containersizes="260px";
	const keysizes="140px";

	//Search Configuration:
	const activesearchescount = 8;

	//Search Select Inputs
	const defaultselectinputs = [
		{value:"Sku", text:"Sku"},
		{value:"products", text:"Product"},
		{value:"markets", text:"Market"},
		{value:"components", text:"Components"},
		{value:"NotSku", text:"Not Sku"},
		{value:"SkuStarting", text:"Sku Starting"},
		{value:"SkuEnding", text:"Sku Ending"},
		{value:"Resolved", text:"Resolved"},
		{value:"Active", text:"Active"},
		{value:"UpdateStatus", text:"Mkt Status"},
		{value:"RefActive", text:"Mkt Active"},
		{value:"RefQty >", text:"Mkt Qty >"},
		{value:"RefQty <", text:"Mkt Qty <"},
		{value:"CreatedAfter", text:"Created After"},
		{value:"CreatedBefore", text:"Created Before"},
		{value:"UpdatedAfter", text:"Updated After"},
		{value:"UpdatedBefore", text:"Updated Before"}
	];

	
	//Set Input Defaults here so we can used them for useState and ResetSearches:
	const inputdefaults = {
		Sku:{
			type:"Sku",
			value: "",
			mode:"like",
			uuid:uuidv4(),			
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		//Products AutoComplete
		products: {
			type: "products",
			keyedby: "ProductID", //We'll need a key so that Autocomplete can highlight our value in the drop down after selection
			searchkey: "Name", //This is the column we are searching from the table
			value: [],
			multiple: true,
			//inputValue:"", //Text Search! This *CAN* be used... but seems nicer without it!
			mode: "autocomplete",
			limit: 10, //Database return limit, keep below ~100
			uuid: uuidv4(),
			container: containersizes,
			keysize: keysizes,
			grow: 1,
		},
		//Store (market) AutoComplete
		markets:{
			type:"markets",
			keyedby:"ID", //We'll need a key so that Autocomplete can highlight our value in the drop down after selection
			searchkey:"Name", //This is the column we are searching from the table
			value: [], 
			mode:"autocomplete",
			defaultsearchterm:"",
			limit:10, //Database return limit, keep below ~100
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			multiple:true,
			grow:1,
		},
		//Components Autocomplete - Static - Feed values manually or from a dataset (LoadItems)
		components:{
			type:"components",
			keyedby:"ID", //We'll need a key so that Autocomplete can highlight our value in the drop down after selection
			searchkey:"Name", //This is the column we are searching from the table
			value: [], //TRY: Feed values into this from DB results on grid (LoadItems)
			menuitems:[],
			mode:"autocompletestatic",
			defaultsearchterm:"",
			limit:20, //Database return limit, keep below ~100
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			multiple:true,
			grow:1,
		},
		NotSku:{
			type:"NotSku",
			value: "",
			mode:"not",
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		SkuStarting:{
			type:"SkuStarting",
			value: "",
			mode:"left",
			uuid:uuidv4(),			
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		SkuEnding:{
			type:"SkuEnding",
			value: "",
			mode:"right",
			uuid:uuidv4(),			
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		Resolved:{
			type:"Resolved",
			value: "",
			mode:"bool",
			uuid:uuidv4(),			
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		UpdateStatus:{
			type:"UpdateStatus",
			value:[],
			mode:"select",
			//Select Menu Items:
			menuitems:[
				{	
					value:"OK",
					key:uuidv4(),
					text:"OK",
					//Optional usechip - Overrides text
					usechip:true,
					chipsize:"small",
					chiplabel:"OK",
					chipclass:classes.gradea, //Test this.		
					//Optional Chip Height and Centering:
					height:"22px",
					center:true,
				},
				{	
					value:"Failed",
					key:uuidv4(),
					text:"Failed",
					//Optional usechip - Overrides text
					usechip:true,
					chipsize:"small",
					chiplabel:"Failed",
					chipclass:classes.graderepair, //Test this.		
					//Optional Chip Height and Centering:
					height:"22px",
					center:true,
				},
			],
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			//Experimental Multiple!
			multiple:true,//Change 'value' to array if true.
			center:false,
			grow:1, 
			usechips:true
		},
		Active:{
			type:"Active",
			value: "",
			mode:"bool",
			uuid:uuidv4(),			
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		RefActive:{
			type:"RefActive",
			value: "",
			mode:"bool",
			uuid:uuidv4(),			
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		"RefQty >":{ //Mkt Qty >
			type:"RefQty >",
			value: "",
			mode:"greaterthan",
			uuid:uuidv4(),			
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		"RefQty <":{ //Mkt Qty <
			type:"RefQty <",
			value: "",
			mode:"lessthan",
			uuid:uuidv4(),			
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		CreatedAfter:{
			type:"CreatedAfter",
			value: (() => {
				// let date = new Date();
				// date.setFullYear(date.getFullYear() - 50); //Set to 50 years ago
				// return date;
				return dayjs().subtract(10,'year');

			})(),
			mode:"datetimeafter",
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		CreatedBefore:{
			type:"CreatedBefore",
			value:dayjs(), //new Date, 
			mode:"datetimebefore",
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		UpdatedAfter:{
			type:"UpdatedAfter",
			value: (() => {
				// let date = new Date();
				// date.setFullYear(date.getFullYear() - 50); //Set to 50 years ago
				// return date;
				return dayjs().subtract(10,'year');

			})(),
			mode:"datetimeafter",
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		UpdatedBefore:{
			type:"UpdatedBefore",
			value:dayjs(), //new Date, 
			mode:"datetimebefore",
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
	}


	/* ##########################  UseState Variables  ########################## */
	const [state, setState] = useState({
		dbreload: true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		clearselection: true,//Default clear selection everytime we reload DB. Continuous bulk edits may set this to false between updates.
		griditems: [],		//Defaults
		totalitems: 0,
		page: 0, //Assume page 0, or else pagination throws an error.
		order: "asc",
		orderby: "Sku",
		selectedcount: 0,
		rowsperpage: 250,
		//rowsperpage:10,
		selectedindexes: [],
		expandsearch:true, //Used to control optional add/remove search button that shows within input. 
		searchoptions: {
			//New! Key-Value pair array. Easier to itterate in API.
			searchpairs: {
				searchpair1: inputdefaults.Sku,
				searchpair2: inputdefaults.NotSku,
				searchpair3: inputdefaults.SkuEnding,
				searchpair4: inputdefaults.products,
				searchpair5: inputdefaults.Sku,
				searchpair6: inputdefaults.Active,
				searchpair7: inputdefaults.components, 
				searchpair8: inputdefaults.markets			
			},
			nestedrelationships: ['integration'],
			showsales: true,
			fetchintegrations: true,
			showdeactivated: false,
			//showdeactivated:false
		},
		integrations: [],
		selectedintegrations: [],
		//Axios Recursive Requests
		//We take a copy of the grid items (deep clone) for processing just in case the user decides to start doing something else.
		updateinprogress: false,
		updatetype: "",
		updatearray: [],
		requestcount: 0,
		currentrequest: 0,
		salesdays: 90,
		recentsalethreshold: 10
	});

	
	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}


	/* ##########################  Top Button Refs and Functions  ########################## */	 
	const rowRefs = useRef([]);
	const btnSave = useRef();
	const btnPendingSave = useRef();
	const btnResetSearches = useRef();
	const btnChangeSelected = useRef();
	const btnMarketOperation = useRef();
	const btnExport = useRef();
	const btnSkuList = useRef();
	const btnCancelUpdates = useRef();
	const btnViewOptions = useRef();

	//Disable Buttons
	const DisableButtons = () => {
		btnSave.current.setAttribute("disabled", true);
		btnPendingSave.current.setAttribute("disabled", true);
		btnResetSearches.current.setAttribute("disabled", true);
		btnChangeSelected.current.setAttribute("disabled", true);
		btnMarketOperation.current.setAttribute("disabled", true);
		btnExport.current.setAttribute("disabled", true);
		btnSkuList.current.setAttribute("disabled", true);
		btnViewOptions.current.setAttribute("disabled", true);
		btnCancelUpdates.current.style.display="";
		
	}

	//Enable Buttons
	const EnableButtons = () => {
		btnSave.current.removeAttribute("disabled");
		btnPendingSave.current.removeAttribute("disabled");
		btnResetSearches.current.removeAttribute("disabled");
		btnChangeSelected.current.removeAttribute("disabled");
		btnMarketOperation.current.removeAttribute("disabled");
		btnExport.current.removeAttribute("disabled");
		btnSkuList.current.removeAttribute("disabled");
		btnViewOptions.current.removeAttribute("disabled");
		btnCancelUpdates.current.style.display="none";
	}


	/* ##########################  Column States  ########################## */
	//Used for hiding/showing columns. Can access using bracket notation later on! colstate[headCell.id]
	const [colstate, setColState] = useState({
		Sku: true,
		IntegrationName: false,
		IntegrationIcon: true,
		CommissionRate: false,
		GIModifier: true,
		PriceBy: true,
		MarkupRate: false,
		//Combined
		TargetGI: false,
		//Calculated Values
		Cost: true,
		Shipping: true,
		COGS: true,
		//Following values for inspection before setting price to SetPrice
		CalcGI: true,
		AvgPrice: true,
		CalcPrice: true, //Price calculated currently
		Diff: true,
		//Pricing
		SetPrice: true, 	//Price to set
		RefPrice: true,	//Price last checked from market
		//Quantity
		Qty: true,
		RefQty: true,
		//Active
		Active: true,
		RefActive: true,
		Resolved: true,
		//Backmarket Data
		bmbuybox: false, //Collection of data/status
		bmcondition:false,
		bmlast_update:false,
		RefDate: true,
		updated_at: false,
		UpdateStatus: true,
		Disabled: false,
		FailureCount: true,
	});

	const UpdateColState = (colstate) =>{
		setColState(colstate);
	}

	//Each SearchInput will need a key that is refreshed to force a re-render.
	//Required to drive the control of the SearchInput from the parent.
	const [key1, setKey1] = useState(uuidv4());
	const [key2, setKey2] = useState(uuidv4());
	const [key3, setKey3] = useState(uuidv4());
	const [key4, setKey4] = useState(uuidv4());
	const [key5, setKey5] = useState(uuidv4());
	const [key6, setKey6] = useState(uuidv4());
	const [key7, setKey7] = useState(uuidv4());
	const [key8, setKey8] = useState(uuidv4());

	
	//Used for <SearchInput />
	const onChangeSearchType = (searchpair, searchnumber) => {
		//Only rerender table if searchvalue wasn't blank!
		console.log("Typeof: "+ typeof localstate.searchoptions.searchpairs["searchpair" + searchnumber].value);
		if (typeof localstate.searchoptions.searchpairs["searchpair" + searchnumber].value === 'object'){
			console.log("Object");
			if (localstate.searchoptions.searchpairs["searchpair" + searchnumber].value.length>0){
				ResetPendingSaves();
				localstate.page = 0;
				localstate.dbreload = true;
			}
		}
		if (typeof localstate.searchoptions.searchpairs["searchpair" + searchnumber].value === 'string'){
			console.log("String");
			if (localstate.searchoptions.searchpairs["searchpair" + searchnumber].value!==""){
				ResetPendingSaves();
				localstate.page = 0;
				localstate.dbreload = true;
			}
		}
		if (typeof localstate.searchoptions.searchpairs["searchpair" + searchnumber].value === 'number'){
			//Doesn't matter, 0 or 1, we still need to allow this bool to clear
			console.log("number");
			ResetPendingSaves();
			localstate.page = 0;
			localstate.dbreload = true;
		}
		//Special Provision to reload if choosing components:
		if (searchpair.type === 'components'){ 
			console.log("components");
			ResetPendingSaves();
			localstate.page = 0;
			localstate.dbreload = true;
		}
		localstate.searchoptions.searchpairs["searchpair" + searchnumber] = searchpair;
		UpdateState(localstate);
		//Ensures the input is rerendered and will work correctly:
		setKey1(uuidv4());
		setKey2(uuidv4());
		setKey3(uuidv4());
		setKey4(uuidv4());
		setKey5(uuidv4());
		setKey6(uuidv4());
		setKey7(uuidv4());
		setKey8(uuidv4());
	};

	const onChangeSearchValue = debounce(function (searchvalue, searchpair, searchnumber) {
		//Clears out changes because table is about to be reloaded:
		ResetPendingSaves();
		//Possible search validation here such as proper date format or int/float...
		//For AutoComplete, you will likely need to pull some kind of ID or name before setting the searchvalue!		
		localstate.searchoptions.searchpairs["searchpair" + searchnumber] = searchpair;
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].value = searchvalue;
		localstate.page = 0;
		localstate.dbreload = true;
		UpdateState(localstate);
	}, 1200);

	//AutoComplete Still needs to send an update so that our inputValue can remain between multiple selections:
	const onChangeAutoCompleteInput = (searchpair, searchnumber) => {
		localstate.searchoptions.searchpairs["searchpair" + searchnumber] = searchpair;
		UpdateState(localstate);
	};

	const ResetSearches = () => {
		//Reset all:
		localstate.searchoptions.searchpairs.searchpair1 = inputdefaults.Sku;
		localstate.searchoptions.searchpairs.searchpair2 = inputdefaults.NotSku;
		localstate.searchoptions.searchpairs.searchpair3 = inputdefaults.SkuEnding;
		localstate.searchoptions.searchpairs.searchpair4 = inputdefaults.products;
		localstate.searchoptions.searchpairs.searchpair5 = inputdefaults.Sku;
		localstate.searchoptions.searchpairs.searchpair6 = inputdefaults.Active;
		localstate.searchoptions.searchpairs.searchpair7 = inputdefaults.components;
		localstate.searchoptions.searchpairs.searchpair8 = inputdefaults.markets;
	
		//Set Defaults:
		localstate.searchoptions.skulist = "";
		setListName("");
		localstate.dbreload = true;
		UpdateState(localstate);

		//Forcefully rerender search inputs!
		ResetKeys([], true);
	}

	const ResetKeys = (keyarray, resetallbool) =>{
		if (resetallbool){
			setKey1(uuidv4());
			setKey2(uuidv4());
			setKey3(uuidv4());
			setKey4(uuidv4());
			setKey5(uuidv4());
			setKey6(uuidv4());
			setKey7(uuidv4());
			setKey8(uuidv4());
		} else {
			for (var i=0; i<keyarray.length; i++){
				if (keyarray[i]===1){
					setKey1(uuidv4());
				}
				if (keyarray[i]===2){
					setKey2(uuidv4());
				}
				if (keyarray[i]===3){
					setKey3(uuidv4());
				}
				if (keyarray[i]===4){
					setKey4(uuidv4());
				}
				if (keyarray[i]===5){
					setKey5(uuidv4());
				}
				if (keyarray[i]===6){
					setKey6(uuidv4());
				}
				if (keyarray[i]===7){
					setKey7(uuidv4());
				}
				if (keyarray[i]===8){
					setKey8(uuidv4());
				}
			}
		}
	}


	//Expand Search
	const ToggleExpandSearch = () => {
		localstate.expandsearch = !localstate.expandsearch;

		if (!localstate.expandsearch){
			for (var i=5; i<=activesearchescount; i++){
				//Only rerender if one of the searches wasn't blank!
				if (typeof localstate.searchoptions.searchpairs["searchpair" + i].value === 'object'){
					if (localstate.searchoptions.searchpairs["searchpair" + i].value.length>0){
						ResetPendingSaves();
						localstate.page = 0;
						localstate.dbreload = true;
					}
				}
				if (typeof localstate.searchoptions.searchpairs["searchpair" + i].value === 'string'){
					console.log(localstate.searchoptions.searchpairs["searchpair" + i].value);
					if (localstate.searchoptions.searchpairs["searchpair" + i].value!==""){
						ResetPendingSaves();
						localstate.page = 0;
						localstate.dbreload = true;
					}
				}
			}
			//Proceed to Reset Partial Searches 5-8:
			localstate.searchoptions.searchpairs.searchpair5 = inputdefaults.Sku;
			localstate.searchoptions.searchpairs.searchpair6 = inputdefaults.Active;
			localstate.searchoptions.searchpairs.searchpair7 = inputdefaults.components;
			localstate.searchoptions.searchpairs.searchpair8 = inputdefaults.markets;
		}
		CloseViewOptionsMenu();
		UpdateState(localstate);
	}




	const RejectIfInvalidSelected = (value, fnCallback) => {
		if (localstate.selectedindexes.length===0){
			errors.NewError({errmsg:"No items selected.", errshow:true, errtimeout: 5, errtype:'neutral'});
		} else {
			//If all tests pass, use callback
			fnCallback(value);
		}
	}



	/* ##########################  Menus  ########################## */

	const ToggleColumn = (key) => {
		let newcolstate = colstate;
		newcolstate[key] = ! newcolstate[key];
		UpdateColState(newcolstate);
	}
	const FlexColumnOption = (props) => {
		let columnvalue = props.value;
		return (
			<React.Fragment>
				{(colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox defaultChecked onClick={()=>{ToggleColumn(columnvalue)}} />} label={props.label} className={classes.columnselecthover} />
				}
				{(!colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox onClick={()=>{ToggleColumn(columnvalue)}} />} label={props.label} className={classes.columnselecthover}/>
				}
			</React.Fragment>
		)
	}

	const SetBackMarketView = () =>{
		//Applies a few as set to true.
		let newcolstate = colstate;
		newcolstate['bmbuybox'] = true;
		newcolstate['bmlast_update'] = true;
		newcolstate['bmcondition'] = true;
		UpdateColState(newcolstate);
		CloseViewOptionsMenu(); 
	}


	/* View Options Menu */
	const [showViewOptionsMenu, setViewOptionsMenu] = useState(null);
	const ShowViewOptionsMenu = (event) => {
		setViewOptionsMenu(event.currentTarget); 
	}
	const CloseViewOptionsMenu = () => {
		//Custom for Sku Integrations: Check for changes to state:
		if (localstate.salesdays !== state.salesdays ||
			localstate.recentsalesthreshold !== state.recentsalethreshold ||
			localstate.searchoptions.showdeactivated !== state.searchoptions.showdeactivated){
			
				localstate.dbreload=true; //Reloads DB so we can re-evalueate salesdata. Inefficient right now because we probably already have the data loaded.
			}
		setViewOptionsMenu(null);
		UpdateState(localstate);
	}

	/* Edit Menu */
	const [showViewEditMenu, setViewEditMenu] = useState(null);
	const ShowViewEditMenu = (event) => {
		setViewEditMenu(event.currentTarget); 
	}
	const CloseViewEditMenu = () => {
		setViewEditMenu(null);
	}
	

	/* Export Menu */
	const [showexportmenu, setExportMenu] = useState(null);
	const ShowExportMenu = (event) => {
		setExportMenu(event.currentTarget);
	}
	const CloseExportMenu = () => {
		setExportMenu(null);
	}

	/* Update Market Menu */
	const [showupdatemarketmenu, setMarketUpdateMenu] = useState(null);
	const ShowUpdateMarketMenu = (event) => { setMarketUpdateMenu(event.currentTarget); }
	const CloseUpdateMarketMenu = () => { setMarketUpdateMenu(null); }

	/* Try reusable : */
	const [anchorEl, setAnchorEl] = useState(null);
	const [popoverid, setPopoverID] = useState(null);
	const handlePopoverOpen = (event, rowid) => {
		//setAnchorEl(event.currentTarget);
		setAnchorEl(event.target);
		setPopoverID(rowid);
		console.log(rowid);
	};
	const handlePopoverClose = () => {
		console.log("Close");
		setAnchorEl(null);
		setPopoverID(null);
	};
	const open = Boolean(anchorEl === popoverid);


	/* ##########################  Selected Rows  ########################## */
	const SelectRow = (index) => {
		if (localstate.selectedindexes.indexOf(index) === -1) {
			localstate.selectedindexes.push(index);
			//Check for condition that would check Select All Checkbox - Rerender
			if (localstate.griditems.length === localstate.selectedindexes.length) {
				UpdateState(localstate);
			}
		} else {
			var spliceindex = localstate.selectedindexes.indexOf(index);
			localstate.selectedindexes.splice(spliceindex, 1);
			//Check for condition that would un-check Select All Checkbox, just 1 less will do - Rerender
			if (localstate.griditems.length === (localstate.selectedindexes.length + 1)) {
				UpdateState(localstate);
			}
		}

		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length === 0) {
			setShowExportConfirmation(false);
		}
	}

	const handleSelectAllClick = (event) => {
		//Material UI Checkbox Component won't rerender unless we force it. Set a changed GridKey so that shallow comparison fails.
		var i = 0;
		if (event.target.checked) {
			localstate.selectedindexes = [];
			for (i = 0; i < localstate.griditems.length; i++) {
				localstate.griditems[i].isSelected = true;
				localstate.selectedindexes.push(i);
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		} else {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
			for (i = 0; i < localstate.griditems.length; i++) {
				localstate.griditems[i].isSelected = false;
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		}
		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length === 0) {
			setShowExportConfirmation(false);
		}
	};



	/* ##########################  Search Options  ########################## */

	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};


	// //API Build-out: 
	// //	Old: Types [searchtype, sub1type, sub2type] , Search [search, sub1search, sub2search]
	// //	New: Types [searchtype1, searchtype2, searchtype3], Search [search1, search2, search3]
	// //Set Search Key:
	// var mode = "";
	// const onChangeSearchType = (searchtype, searchnumber) => {
	// 	//Search Mode: Each search type may have a different search mode
	// 	//left, right, like, strict, not
	// 	//Default Mode: LIKE
	// 	mode = "like";
	// 	//Provision for automatically switching search mode
	// 	if (searchtype === "SkuEnding") {
	// 		mode = "right";
	// 	}
	// 	if (searchtype === "SkuStarting") {
	// 		mode = "left";
	// 	}
	// 	if (searchtype === "Sku") {
	// 		mode = "like";
	// 	}
	// 	//Date Searches
	// 	if (searchtype === "RefDate >") {
	// 		mode = "dateafter";
	// 	}
	// 	if (searchtype === "RefDate <") {
	// 		mode = "datebefore";
	// 	}
	// 	if (searchtype === "RefQty >") {
	// 		mode = "greaterthan";
	// 	}
	// 	if (searchtype === "Cost <") {
	// 		mode = "RefQty";
	// 	}
	// 	//Not Conditionals: Convert NotName to Name in BasicTableController
	// 	if (searchtype === "NotSku") {
	// 		mode = "not"
	// 	}
	// 	localstate.searchoptions.searchpairs["searchpair" + searchnumber].type = searchtype;
	// 	localstate.searchoptions.searchpairs["searchpair" + searchnumber].mode = mode;
	// 	//Provision to add columns if selected for search.
	// 	if (searchtype === "Name") {
	// 		colstate["Name"] = true;
	// 		setColumnMenu(null);
	// 	}
	// 	UpdateState(localstate);
	// }

	// //Set Search Value:
	// const onChangeSearchValue = debounce(function (searchvalue, searchnumber) {
	// 	//Clears out changes because table is about to be reloaded:
	// 	ResetPendingSaves();
	// 	localstate.searchoptions.searchpairs["searchpair" + searchnumber].value = searchvalue;
	// 	localstate.dbreload = true;
	// 	UpdateState(localstate);
	// }, 600);

	// //Key-Value Inputs
	// const [searchinputs, setSearchInputs] = useState({
	// 	show1: true,
	// 	show2: true,
	// 	show3: true,
	// 	show4: true,
	// 	show5: true,
	// 	show6: false,
	// 	lastsearch: 5
	// });


	// const AddSearch = () => {
	// 	let newsearchinputs = Object.assign({}, searchinputs);
	// 	newsearchinputs["show" + (newsearchinputs.lastsearch + 1)] = true;
	// 	newsearchinputs.lastsearch++;
	// 	setSearchInputs(newsearchinputs);
	// }

	// const RemoveSearch = (searchinput) => {
	// 	let newsearchinputs = Object.assign({}, searchinputs);
	// 	newsearchinputs["show" + (newsearchinputs.lastsearch)] = false;
	// 	newsearchinputs.lastsearch--;
	// 	setSearchInputs(newsearchinputs);
	// }


	/* ##########################  Auto Complete  ########################## */
	// Similar to regular searchpairs, but loads results from DB - needs stateful.

	//Helpers - Blank icons for pre-set keys
	const BlankIcon = () => (<ArrowDropDownIcon style={{ display: "none" }} />);


	/* ##########################  SKU List ########################## */
	/* Sync changes from this area to the Skus page */
	const [showskulist, setShowSkuList] = useState(false);
	const [openskulistoptions, openSkuSearch] = React.useState(false);
	const [options5, setOptions5] = React.useState([]);
	const [loadingoptions5, setLoadingOptions5] = useState(false);
	const [skulist, setSkuList] = useState("");
	const [savelistbool, setSaveListBool] = useState(false);
	const [showdeletebtn, showDeleteBtn] = useState(false);
	const [listname, setListName] = useState("");

	const InitSkuSearch = () => {
		if (options5.length === 0) {
			//Load (up to limit) options
			SkuListSearch("");
		}
		openSkuSearch(true);
	}
	const SelectSkuList = (newvalue) => {
		console.log("SelectSkuList: " + newvalue["Name"]);
		console.log("SelectSkuList: " + newvalue["List"]);
		setSkuList(newvalue["List"]);
		setListName(newvalue["Name"]);
		setSaveListBool(true);
		showDeleteBtn(true);
	}


	///Changes to sku list text area
	const onChangeSkuList = (newvalue) => {
		setSkuList(newvalue);
		if (newvalue === "") {
			setSaveListBool(false);
		} else {
			setSaveListBool(true);
		}
	}

	//On selection, searchvalue will be "0"
	const SkuListSearch = debounce(function (searchvalue) {
		if (searchvalue === "" || searchvalue === 0) {
			searchvalue = "";
		} else {
			showDeleteBtn(false);
			setSaveListBool(true);
		}
		setLoadingOptions5(true);

		const postdata = {
			search: searchvalue
		};
		axios.post(dbendpoint + "/skulist/get", postdata, defaultpostoptions).then(res => {
			if (res.status === 200) {
				if (res.data.Status === "login") {
					window.location.reload(false);
				}
				if (res.data.Status === "Success") {
					setOptions5(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
			setLoadingOptions5(false);
		});
	}, 600);

	const CloseSkuList = () => {
		setShowSkuList(false);
		//Reload DB?
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	const SaveList = () => {
		const postdata = {
			listdata: {
				Name: listname,
				List: skulist
			}
		};
		axios.post(dbendpoint + "/skulist/update", postdata, defaultpostoptions).then(res => {
			if (res.status === 200) {
				if (res.data.Status === "login") {
					window.location.reload(false);
				}
				if (res.data.Status === "Success") {
					showDeleteBtn(true);
					errors.NewError({ errmsg: "List Saved!", errshow: true, errtimeout: 5, errtype: "ok" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
			setLoadingOptions5(false);
		});
	}

	const DeleteList = () => {
		const postdata = {
			Name: listname
		};
		axios.post(dbendpoint + "/skulist/delete", postdata, defaultpostoptions).then(res => {
			if (res.status === 200) {
				if (res.data.Status === "login") {
					window.location.reload(false);
				}
				if (res.data.Status === "Success") {
					showDeleteBtn(false);
					setListName("");
					errors.NewError({ errmsg: "List Deleted!", errshow: true, errtimeout: 5, errtype: "ok" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
			setLoadingOptions5(false);
		});
	}


	/* ##########################  Loading and Page Changes  ########################## */
	const handleRequestSort = (event, property) => {
		const isAsc = localstate.orderby === property && localstate.order === "asc";
		localstate.order = (isAsc ? "desc" : "asc");
		localstate.orderby = property;
		localstate.dbreload = true;
		UpdateState(localstate);
	};

	const handleChangePage = (event, newPage) => {
		localstate.dbreload = true;
		localstate.page = newPage;
		UpdateState(localstate);
	};

	const handleChangeRowsPerPage = (event) => {
		localstate.dbreload = true;
		localstate.rowsperpage = parseInt(event.target.value, 10);
		localstate.page = 0;
		UpdateState(localstate);
	};

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Items
	function LoadItems() {
		if (localstate.clearselection) {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
		} else {
			//Reset to clear selections on subsequent requests
			localstate.clearselection = true;
		}

		//Need to DEEP clone:
		var newsearchpairs = JSON.parse(JSON.stringify(localstate.searchoptions.searchpairs));

		//Optional Search Handlers:

		//AUTOCOMPLETE Handler
		//Here, we may want to convert our SearchInput searchpairs of mode "autocomplete" to things
		//that our PHP controller SearchTools can use such as modes "like" or "strict".
		//Keep in mind, if two searchpairs have the same type and the mode is "like", it will normally morph
		//into an OR statement. There are only a few reserved non-OR types: Sku, Model, Name, NotSku, NotModel, SkuStarting
		//This list may be expanded or we may possibly write something to override non-OR types when needed.
		for (var i=1; i<(activesearchescount+1); i++){
			//Products Autocomplete
			console.log(newsearchpairs["searchpair"+i]);
			if (newsearchpairs["searchpair"+i].mode==="autocomplete" && newsearchpairs["searchpair"+i].type==="products"){
				newsearchpairs["searchpair"+i].type="ProductID";
			}
			//Markets Autocomplete
			if (newsearchpairs["searchpair"+i].mode==="autocomplete" && newsearchpairs["searchpair"+i].type==="markets" && typeof newsearchpairs["searchpair"+i].value === 'object'){
				newsearchpairs["searchpair"+i].type="Store";
			}
			//Markets Autocomplete Static
			if (newsearchpairs["searchpair"+i].mode==="autocompletestatic" && newsearchpairs["searchpair"+i].type==="components" && typeof newsearchpairs["searchpair"+i].value === 'object'){
				//newsearchpairs["searchpair"+i].value=newsearchpairs["searchpair"+i].value

			}
		}




		const postdata = {
			searchoptions: {
				limit: localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				searchpairs: newsearchpairs,
				skulist: skulist,
				selectedintegrations: localstate.selectedintegrations,
				showsales: localstate.searchoptions.showsales,
				showdeactivated: localstate.searchoptions.showdeactivated
			}
		};

		//Provision to Remove the need for recent orders:
		if (!colstate.AvgPrice) {
			postdata.searchoptions.showsales = false;
		}


		axios.post(dbendpoint + "/skuintegrations/get?page=" + (localstate.page + 1), postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					//We should now have a non-0 result from the API
					//Add variables for use with table
					var calcprice;
					var setbackdate = new Date();
					setbackdate.setDate(setbackdate.getDate() - localstate.salesdays);
					var recentsaledate = new Date();
					recentsaledate.setDate(recentsaledate.getDate() - localstate.recentsalethreshold);
					var resultdata = res.data.pagedata.data;

					//MenuItem Collection
					var menuitems = [];
					//Hold componentarray of unique componentID values
					var componentarray = []; 
					//ResetArray is to allow AutoComplete Static to take in new values from LoadItems
					var resetarray = [];
				
					for (var i = 0; i < resultdata.length; i++) {
						//Try: GridKey - Apply GridKey to components: key={row.GridKey}
						//Try: Increment GridKey between rerenders; ie: UpdateState(localstate);
						resultdata[i].GridKey = i;
						resultdata[i].unsaved = false;
						resultdata[i].ExpandRow = false;
						//Reselect Items
						if (localstate.selectedindexes.includes(i)) {
							resultdata[i].isSelected = true;
						} else {
							resultdata[i].isSelected = false;
						}

						//Get Diff & CalcPrice
						if (resultdata[i].PriceBy === "Markup") {
							// calcprice = RoundPrice(((parseFloat(resultdata[i].Cost) +
							// 	parseFloat((((1 + resultdata[i].MarkupRate / 100) * resultdata[i].Cost) -
							// 		resultdata[i].Cost))) / (1 - resultdata[i].CommissionRate / 100)).toFixed(2));
							calcprice = RoundPrice((resultdata[i].Cost*(1+(resultdata[i].MarkupRate / 100)))/(1-(resultdata[i].CommissionRate/100)).toFixed(2));
							resultdata[i].Diff = (calcprice - resultdata[i].SetPrice).toFixed(2);
							//CalcGI
							//resultdata[i].CalcGI = (calcprice - (calcprice*(resultdata[i].CommissionRate/100)) - resultdata[i].Cost).toFixed(2);
						}

						if (resultdata[i].PriceBy === "GlobalTGI") {
							calcprice = RoundPrice(((parseFloat(resultdata[i].Cost) + parseFloat(resultdata[i].GlobalTGI)) / (1 - resultdata[i].CommissionRate / 100)).toFixed(2));
							resultdata[i].Diff = (calcprice - resultdata[i].SetPrice).toFixed(2);
						}
						if (resultdata[i].PriceBy === "CustomTGI") {
							calcprice = RoundPrice(((parseFloat(resultdata[i].Cost) + parseFloat(resultdata[i].CustomTGI)) / (1 - resultdata[i].CommissionRate / 100)).toFixed(2));
							resultdata[i].Diff = (calcprice - resultdata[i].SetPrice).toFixed(2);
						}
						resultdata[i].CalcPrice = calcprice;
						resultdata[i].CalcGI = (calcprice - (calcprice*(resultdata[i].CommissionRate/100)) - resultdata[i].Cost).toFixed(2);





						//Get AvgPrice Price
						if (colstate.AvgPrice) {
							//Default go back only 90 days
							//dateformat = new SimpleDateFormat("YYYY-MM-DD H:m:s");
							var units = 0;
							var sales = 0;
							var avgprice = 0;
							var orderdate = "";
							resultdata[i]['RecentSale'] = false;
							if (resultdata[i]['orderitems'].length > 0) {
								for (var j = 0; j < resultdata[i]['orderitems'].length; j++) {
									//console.log("Order Items: "+resultdata[i]['orderitems'].length);
									//console.log(resultdata[i]['orderitems'][j]['order']);
									orderdate = parseDate(resultdata[i]['orderitems'][j]['order']['OrderDate']);
									//orderdate = dateformat.parse(resultdata[i]['orderitems'][j]['order']['OrderDate']);
									if (orderdate > setbackdate) {
										//console.log("Order Items: "+resultdata[i]['orderitems'].length);
										units += resultdata[i]['orderitems'][j]['Quantity'];
										//console.log(resultdata[i]['orderitems'][j]['Quantity']);
										sales += parseFloat(resultdata[i]['orderitems'][j]['UnitPrice'] * resultdata[i]['orderitems'][j]['Quantity']);
									}
									if (orderdate > recentsaledate) {
										resultdata[i]['RecentSale'] = true;
									}
								}
								//Avg for sku:
								if (units > 0) {
									avgprice = sales / units;
									resultdata[i]['AvgPrice'] = "(" + units + ") " + avgprice.toFixed(2);
								}
							}

							//Sort Order Dates
							resultdata[i]['orderitems'].sort(function (a, b) {
								//Only need first 10 characters of date
								var adate = a.order.OrderDate.substring(0, 10);
								//Get rid of dashes
								adate = adate.replaceAll("-", "");
								var bdate = b.order.OrderDate.substring(0, 10);
								bdate = bdate.replaceAll("-", "");
								//Sort dates descending
								return bdate - adate;
							});
						}

						if (resultdata[i]['PriceBy'] === "Markup"){
							resultdata[i]['GIModifier'] = resultdata[i]['MarkupRate'];
						}
						if (resultdata[i]['PriceBy'] === "GlobalTGI"){
							resultdata[i]['GIModifier'] = resultdata[i]['GlobalTGI'];
						}
						if (resultdata[i]['PriceBy'] === "CustomTGI"){
							resultdata[i]['GIModifier'] = resultdata[i]['CustomTGI'];
						}

						

						//Collect Components for AutoComplete Static
						//If we even have...
						if (resultdata[i].hasOwnProperty('skudetails')){
							if (resultdata[i]['skudetails']){ //Check for null
								for (var k=0; k<resultdata[i]['skudetails']['skucomponents'].length; k++){
									if (!componentarray.includes(resultdata[i]['skudetails']['skucomponents'][k]['details']['ID'])){
										//Add to array of unique component values:
										componentarray.push(resultdata[i]['skudetails']['skucomponents'][k]['details']['ID']);
										//Add to menu items:
										menuitems.push({
											Name:resultdata[i]['skudetails']['skucomponents'][k]['details']['ComponentType'] +": "+resultdata[i]['skudetails']['skucomponents'][k]['details']['Name'],
											ID:resultdata[i]['skudetails']['skucomponents'][k]['details']['ID'],
											keyedby:"ID",
											ComponentID:resultdata[i]['skudetails']['skucomponents'][k]['details']['ID'],
											ComponentType:resultdata[i]['skudetails']['skucomponents'][k]['details']['ComponentType'],
											searchkey:"Name",
											key:uuidv4(),
											text:resultdata[i]['skudetails']['skucomponents'][k]['details']['Name']+"this",
											usechip:true,
											chiplabel:resultdata[i]['skudetails']['skucomponents'][k]['details']['Name'],
											chipclass:classes.gradea,
											height:"22px",
											center:false,
											alt:resultdata[i]['skudetails']['skucomponents'][k]['details']['ComponentType']
										});
									}
								}	
							}
						}
						//Organize menuitems:
						// Define the priority order for ComponentType
						const priorityTypes = ['CPU', 'RAM', 'Hard Drive', 'Optical', 'Resolution', 'COA', 'Body Grade', 'LCD Grade'];

						menuitems.sort((a, b) => { 
							// Determine priority of ComponentType
							const aPriority = priorityTypes.indexOf(a.ComponentType);
							const bPriority = priorityTypes.indexOf(b.ComponentType);

							// Prioritize items based on the defined priorityTypes
							if (aPriority !== -1 && bPriority === -1) return -1; // a is in priorityTypes, b is not
							if (bPriority !== -1 && aPriority === -1) return 1;  // b is in priorityTypes, a is not
							if (aPriority !== -1 && bPriority !== -1) return aPriority - bPriority; // Both in priorityTypes, sort by priority

							// If neither is in priorityTypes, sort by ComponentType alphabetically
							if (a.ComponentType < b.ComponentType) return -1;
							if (a.ComponentType > b.ComponentType) return 1;

							// If ComponentType is the same, sort alphabetically by Name
							if (a.Name < b.Name) return -1;
							if (a.Name > b.Name) return 1;

							return 0;
						});
					

					}

					for (var m=1; m<(activesearchescount+1); m++){
						//Components Autocomplete Static
						//console.log(newsearchpairs["searchpair"+m]);
						if (newsearchpairs["searchpair"+m].mode==="autocompletestatic" && newsearchpairs["searchpair"+m].type==="components"){
							resetarray.push(m);
							localstate.searchoptions.searchpairs["searchpair"+m].menuitems=menuitems;
						}
					}
					//End of AutoComplete Static Code

					

					localstate.griditems = resultdata;
					localstate.totalitems = res.data.pagedata.total;
					localstate.integrations = res.data.integrations;
					//Data freshly loaded, head off any new requests with this state change. Handle in useEffect?
					//localstate.dbreload = false;
					UpdateState(localstate);
					//Ensures the inputs are rerendered and will work correctly:
					ResetKeys(resetarray, false); 
				}
				if (res.data.Status === "Failure") {
					//Failure error
					localstate.griditems = [];
					UpdateState(localstate);
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
		});
	}



	/* ############### RESIZER ####################*/
	const createResizableColumn = function (col, resizer) {
		// Track the current position of mouse
		let x = 0;
		let w = 0;

		const mouseDownHandler = function (e) {
			// Get the current mouse position
			x = e.clientX;
			// Calculate the current width of column
			const styles = window.getComputedStyle(col);
			w = parseInt(styles.width, 10);
			// Attach listeners for document's events
			document.addEventListener('mousemove', mouseMoveHandler);
			document.addEventListener('mouseup', mouseUpHandler);
		};

		const mouseMoveHandler = function (e) {
			// Determine how far the mouse has been moved
			const dx = e.clientX - x;
			// Update the width of column
			col.style.width = `${w + dx}px`;
		};

		// When user releases the mouse, remove the existing event listeners
		const mouseUpHandler = function () {
			document.removeEventListener('mousemove', mouseMoveHandler);
			document.removeEventListener('mouseup', mouseUpHandler);
		};
		resizer.addEventListener('mousedown', mouseDownHandler);
	};

	const InitColumnResizers = () => {
		//Runs after rerender
		// Query the table
		const table = document.getElementById('resizeMe');
		// Query thead:
		const thead = document.querySelector('thead');
		const cols = thead.querySelectorAll('td');
		// Loop over them
		[].forEach.call(cols, function (col) {
			// Create a resizer element
			const resizer = document.createElement('div');
			resizer.classList.add([classes.resizer]);
			// Set the height
			resizer.style.height = `${table.offsetHeight - 2}px`;
			// Add a resizer element to the column
			col.appendChild(resizer);
			createResizableColumn(col, resizer);
		});
	}

	/* ############### END OF RESIZER ####################*/
	
	/* ##########################  App Init  ########################## */
	//Run once, don't clean up until controller dismount (unchanging init variable)
	const [appinit] = useState(true);
	useEffect(() => {
		document.title = "Sku Integrations";
		//Cleanup
		return function cleanup() {
			dispatch(setProgressTimeout(0));
		}
	}, [appinit]);


	useEffect(() => {
		if (state.dbreload) {
			//Avoid duplicate loades.
			localstate.dbreload = false;
			LoadItems();
		} else {
			InitColumnResizers();
			//console.log("Ignore DB Reload.");
		}
	});




	/* ##########################  CRUD  ########################## */

	//New Row adds property 'PendingItem' for use in the API to add such rows.
	/*
	const AddRow = () => {
		localstate.griditems.unshift({
			ID:uuidv4(),
			Name:"",
			PendingItem:true,
			Cost:0.00,
			Price:0.00,
			Margin:0.00,
			SomeBoolean:0,
			SomeSelectable:"",
			NewCheckbox:false
		});
		//All selected indexes move up by 1.
		for (var i=0; i<localstate.selectedindexes.length; i++){
			localstate.selectedindexes[i] += 1;
		}
		UpdateState(localstate);
	}
	*/

	//Some requests might take longer
	const longpostoptions = {
		withCredentials:true,
		withXSRFToken: true,
		crossDomain:true,
		mode:"no-cors",
		timeout:90000,
	};

	//A Ref stays consistent throughout async function
	const requestcount = useRef(0);

	//Recursive changes to our own DB is painfully slow.
	//Use interval - send errors to front end if needed, but usually these operations are A-OK.
	const SaveChanges = () => {
		//Clean up current errors:
		errors.HideError(errors);
		
		//Configuration
		requestcount.current = 0;
		var timeout = 300; //Seconds before closing final ProgressBar results - To Do: Add option to NOT timeout at all
		var requestinterval = 100; //Milliseconds

		var updatearray = [];
		for (var i = 0; i < localstate.griditems.length; i++) {
			if (localstate.griditems[i].unsaved) {
				updatearray.push(localstate.griditems[i]);
			}
		}

		if (updatearray.length > 0) {
			dispatch(newProgress({
				msg: 'Saving items...',
				show: true,
				settimeout: true, //This option allows for a timeout after the final item is pass/failed
				timeout: timeout, //Initiated on final item 
				type: 'items', //Labels your save types such as items, products, skus, parts
				finished: 0,
				percent: "0%",
				total: updatearray.length,
				pass: 0,
				fail: 0,
				faillist: [], //Can take simple values,
				faillinks: [], //Can take links to things like items or products.
				timeoutid: false,
				errors:[] //Can take strings
			}));
			i = 0;
			var limit = updatearray.length - 1;
			var updateitems = setInterval(function () {
				console.log("Interval");
				var item = updatearray[i];
				//Do work here, API call.
				const postdata = {
					item: item
				};
				axios.post(dbendpoint + "/skuintegrations/update", postdata, longpostoptions).then(res => {
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status === 200) {
						//If ValidateUser() fails to verify user, it sends back 'login' error. 
						if (res.data.Status === "login") {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status === "Success") {
							//Success response also includes the item!
							//If we sent new rows, we'll need to reference the old ID.
							var itemindex = 0;
							if (res.data.OldID) {
								itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems[itemindex].unsaved = false;
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
								//Set New ID
								localstate.griditems[itemindex].ID = res.data.item.ID;
							} else {
								itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.item.ID);
								localstate.griditems[itemindex].unsaved = false;
								//Refs allow us to update the grid live!
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
							}
							dispatch(incrementPass());
						}
						if (res.data.Status === "Failure") {
							//Failure error - To Do: Dispatch failure messages to the ProgressBar
							dispatch(incrementFail());
						}
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);
					} else {
						//Non-200 message from server.
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);
						dispatch(incrementFail());
					}
				}).catch(err => {
					//Non-200, 500 Error, timeout?
					requestcount.current++;
					FinalizeRequest(requestcount.current, limit, timeout);
					dispatch(incrementFail());
				});
				//If we have completed all items, clear this interval and update state.
				if (i === limit) {
					clearInterval(updateitems);
				}
				i++;
			}, 50);
		} else {
			errors.NewError({ errmsg: "Nothing to save.", errshow: true, errtimeout: 5, errtype: "neutral" })
			ResetPendingSaves();
		}
	}



	//Clean up View
	const FinalizeRequest = (i, limit, timeout) => {
		//The itterator after responses are incremented to n+1. Reversed below:
		if ((i-1)===limit){
			EnableButtons();
			dispatch(setProgressTimeout(timeout));
			MarkErrorItems();
			btnSave.current.style.display="";
			btnPendingSave.current.style.display="none";
		}
	} 


	//Mark Remaining Items as Warning Items
	const MarkErrorItems = () => {
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				rowRefs.current[i+'SaveStatus'].classList.remove(classes.unsavedhighlight);
				rowRefs.current[i+'SaveStatus'].classList.add(classes.errorhighlight);
			} else {
				//Resolve previously unresolved
				rowRefs.current[i+'SaveStatus'].classList.remove(classes.errorhighlight);
			}
		}
	}


	const ResetPendingSaves = () =>{
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				localstate.griditems[i].unsaved = false;
			}
		}
		EnableButtons();
		btnSave.current.style.display="";
		btnPendingSave.current.style.display="none";
	}





	//Delete Confirmation and Deletion
	const [showdeleteconfirmation, setShowDeleteConfirmation] = useState(false);
	const [deleteitems, setDeleteItems] = useState([]);
	const DeleteSelectedInit = () => {
		var deleteitemsarray = [];
		//Reflect items to user for confimation.
		for (var i = 0; i < state.selectedindexes.length; i++) {
			deleteitemsarray.push(localstate.griditems[state.selectedindexes[i]]);
		}
		setDeleteItems(deleteitemsarray);
		setShowDeleteConfirmation(true);
	}

	const DeleteSelected = () => {
		var finishedrequests = 0;
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			if (localstate.griditems[localstate.selectedindexes[i]].hasOwnProperty("PendingItem")) {
				//Pending items are simply removed from the view and forgotten.
				localstate.griditems.splice(localstate.selectedindexes[i], 1);
				//Count as finished request
				finishedrequests++;
			} else {
				//Make Delete request to DB
				const postdata = {
					item: localstate.griditems[localstate.selectedindexes[i]]
				};
				axios.post(dbendpoint + "/skuintegrations/delete", postdata, defaultpostoptions).then(res => {
					//No matter the response, we consider the result as a 'finished request'. We can then properly do clean-up.
					finishedrequests++;
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status === 200) {
						//If ValidateUser() fails to verify user, it sends back "login" error. 
						if (res.data.Status === "login") {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status === "Success") {
							//Success response also includes the item!
							//If we're pulling the item out of grid items, we'll use the ID of the item for reference.
							if (res.data.OldID) {
								//Since griditems state can be reloaded anytime, we look for the indexOf the ID
								var itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems.splice(itemindex, 1);
							} else {
								errors.NewError({ errmsg: "Could not delete one or more items.", errshow: true, errtimeout: 8, errtype: "warning" })
							}
						}
						if (res.data.Status === "Failure") {
							//Failure error
							errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
						}
					} else {
						//Non-200 message from server.
						errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
					}
					//After result from last request, do cleanup.
					if (finishedrequests === localstate.selectedindexes.length) {
						//Clear out all selections! Since checkboxes are controlled only by grid items index, we don't have good
						//tracking on which items are which.
						localstate.selectedindexes = [];
						localstate.selectedcount = 0;
						UpdateState(localstate);
						setShowDeleteConfirmation(false);
					}
				});
			}
		}
	}
	const CancelDelete = () => {
		setShowDeleteConfirmation(false);
	}


	/* ##########################  Cell Interaction  ########################## */


	//Tab Order: Used for horizontal tabbing below.
	//We need an order for horizontal tabbing - attempting to tab to an unavailable column (like a disabled column via colstate) will result in an error
	const taborder = ["GIModifier", "SetPrice", "Qty"];


	//Excel-like functionality for grid
	const HandleKeyDown = (event, index, column) => {
		console.log("this");
		//Handle Tabs!
		if (event.key === "Tab") {
			event.preventDefault();
			//Vertical VS Horizontal Tabbing

			//Vertical Tabbing - Checkboxes, Gross Income, Rates
			//Vertical Tabbing is never subject to the next column not being shown (we always to back to record #1 instead!)
			if (column === "Checkbox") {  //Insert each type of column you want vertically tabbed here: if (column==="Checkbox" || column==="Margin"){ etc
				//If the next row ref exists....
				if (rowRefs.current[(index + 1) + column]) {
					rowRefs.current[(index + 1) + column].focus();
				} else {
					//Go to first element
					rowRefs.current[("0" + column)].focus();
				}
			} else {
				//Horizontal Tabbing - Row Data
				//Horizontal Tabbing is subject to certain columns not being available for selection. (colstate)

				//Get index of current column within tab order:
				var tabindex = taborder.indexOf(column);

				//Increase index until we find the next tab order column
				for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
					//If we're at the last column element, go to the next row's first available column element
					if (i === taborder.length) {
						//Start at beginning of row tab order and reitterate
						i = -1;
						//If next row exists:
						if (rowRefs.current[(index + 1) + column]) {
							index = index + 1;
						} else {
							//Go to first row.
							index = 0;
						}
					} else {
						//If there is another elemet in the taborder.... Continue. Else, go to first column.
						if (taborder[i]) {
							//If that next element is available
							if (colstate[taborder[i]]) {
								rowRefs.current[index + taborder[i]].select();
								break;
							}
						} else {
							//Start at beginning of row tab order and reitterate
							i = -1;
						}
					}
				}
			}
		}
		//Handle Down Arrow
		if (event.key === "ArrowDown") {
			event.preventDefault();
			if (rowRefs.current[(index + 1) + column]) {
				rowRefs.current[(index + 1) + column].select();
			} else {
				//Go to first element
				rowRefs.current[("0" + column)].select();
			}
		}
		//Handle Up Arrow
		if (event.key === "ArrowUp") {
			event.preventDefault();
			if (rowRefs.current[(index - 1) + column]) {
				rowRefs.current[(index - 1) + column].select();
			} else {
				//Go to last element
				var lastelement = localstate.griditems.length - 1;
				rowRefs.current[(lastelement + column)].select();
			}
		}
	}


	//There is an issue with trying to use tab order on things that aren't as predictable.

	//Restrict Number (too many places past the decimal)
	const RestrictNumber = (newvalue, oldvalue) => {
		var len = newvalue.length;
		var index = newvalue.indexOf('.');
		if (index > 0) {
			var decimalchars = (len - 1) - index;
			if (decimalchars > 2) {
				return oldvalue;
			}
		}
		return newvalue;
	}
	const DetectBlankNumber = (event, index, column) => {
		if (event.target.value === "") {
			rowRefs.current[index + column].value = "0.00";
			localstate.griditems[index][column] = "0.00";
		}
	}

	//Catch-All Method.
	//Note: GIModifier is just an input, it is not a DB value. It helps mix pricing methods for easier cell traversal and simpler interface.
	const onChangeValue = (event, index, column) => {
		var oldvalue = localstate.griditems[index][column];
		var newvalue = event.target.value;
		var identicalvalues = (parseFloat(newvalue)===parseFloat(oldvalue));
		var sku = localstate.griditems[index]['Sku'];
		var dbcolumn = "";
		//console.log(column);
		var calcprice = 0;
		var diff = 0;
		if (event.key !== "Tab" &&
			event.key !== "ArrowDown" &&
			event.key !== "ArrowUp" &&
			event.key !== "ShiftLeft" &&
			event.key !== "ShiftRight"
		) {
			//Conditionals for Types:
			if (column === "CommissionRate") {
				newvalue = RestrictNumber(newvalue, oldvalue);
				//TO DO: Do price calculation
			}
			if (column==="GIModifier"){

				let grossincome = 0;
				//Determine Real Column - Syncs changes for use on DB update
				if (localstate.griditems[index]['PriceBy'] === "Markup"){
					dbcolumn = "MarkupRate";
				}
				if (localstate.griditems[index]['PriceBy'] === "GlobalTGI"){
					dbcolumn = "GlobalTGI";
				}
				if (localstate.griditems[index]['PriceBy'] === "CustomTGI"){
					dbcolumn = "CustomTGI";
				}
				if (!oldvalue){
					oldvalue="0";
				}
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float");
				if (newvalue){
					//Backspacing from value "40.4" to "40." results in a newvalue="40". No decimal detected.
					if (oldvalue.indexOf(".")>-1 && newvalue.indexOf(".")===-1 && event.key==="Backspace"){
						//No need to change front end, simply update griditem
						console.log("Identical Values: "+identicalvalues);
						localstate.griditems[index][column] = newvalue;
						localstate.griditems[index][dbcolumn] = newvalue;
					} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)){
						//Value changed
						rowRefs.current[index+column].value=newvalue;
						localstate.griditems[index][column] = newvalue
						localstate.griditems[index][dbcolumn] = newvalue;
					}
					//Provision to update all other GlobalTGI if need be:
					console.log("These: "+localstate.griditems[index]['PriceBy']+", "+sku)
					if (localstate.griditems[index]['PriceBy'] === "GlobalTGI"){
						for (var j=0; j<localstate.griditems.length; j++){
							if (sku === localstate.griditems[j]['Sku'] && 
								localstate.griditems[j]['PriceBy'] === "GlobalTGI" &&
								j!==index){
								console.log("Syncronizing TGI for "+j);
								rowRefs.current[j+'GIModifier'].value=newvalue;
								localstate.griditems[j]['GIModifier'] = newvalue;
								localstate.griditems[j]['GlobalTGI'] = newvalue;
							}
						}
					}
				} else {
					if (event.key === "Backspace") {
						if ((oldvalue.indexOf(".") > -1)) {
							localstate.griditems[index][column] = parseFloat(event.target.value).toFixed(2);
							localstate.griditems[index][dbcolumn] = parseFloat(event.target.value).toFixed(2);
						}
					}
				}

				//Assuming we can do this operation here....
				//Calc price and diff!

				//Determine PriceBy type:
				if (localstate.griditems[index].PriceBy === "GlobalTGI"){
					grossincome=localstate.griditems[index]['GlobalTGI'];
					//console.log("Global Gross Income: "+grossincome);
				}
				if (localstate.griditems[index].PriceBy === "CustomTGI"){
					grossincome=localstate.griditems[index]['CustomTGI'];
					//console.log("Custom Gross Income: "+grossincome);
				}
				if (localstate.griditems[index].PriceBy === "Markup"){
					grossincome=(localstate.griditems[index].MarkupRate/100) * localstate.griditems[index].Cost;
					//console.log("Markup Gross Income: "+grossincome);
				}

				calcprice = rowRefs.current[index + "CalcPrice"].textContent = RoundPrice(((parseFloat(localstate.griditems[index].Cost) + parseFloat(grossincome)) / (1 - localstate.griditems[index].CommissionRate / 100)).toFixed(2));
				localstate.griditems[index].CalcPrice = calcprice;

				// console.log("calcprice: "+calcprice);
				// console.log("Commission rate: "+localstate.griditems[index].CommissionRate);
				// console.log("Cost: "+localstate.griditems[index].Cost);
				localstate.griditems[index].CalcGI = rowRefs.current[index + "CalcGI"].textContent = (calcprice - (calcprice*(localstate.griditems[index].CommissionRate /100)) - localstate.griditems[index].Cost).toFixed(2);


				diff = rowRefs.current[index + "Diff"].textContent = localstate.griditems[index].Diff = (calcprice - localstate.griditems[index].SetPrice).toFixed(2);
				//console.log("Diff: " + diff);
				diff > 0 ? rowRefs.current[index + "Diff"].classList.add(classes.lightred) : rowRefs.current[index + "Diff"].classList = "";
				if (diff < 0) {
					rowRefs.current[index + "Diff"].classList.add(classes.lightgreen);
				}

				//How does this calculation work?


				// Terms:
				// Gross Income - The fixed income after the sale. This can be derived from 2 pricing methods:
				//		1. Target Gross Income (Global or Custom) - A fix amount.
				//		2. MarkupRate - A percentage amount based on Sku cost.
				// Commission - The cost a market charges to sell an item.


				// Explanation:
				// Marketplace price also depends on commission rate. 
				// Commission is charged at a percentage rate on the final price - not in addition to a set price.
				// That means we need to calculate the price based on gross income or MarkupRate(% of cost) that we
				// would like to receive on each sku. For simplicity's sake, let's assume we can automatically calculate
				// gross income based on MarkupRate when we have a proper cost associated with the Sku.


				// Forumula:
				//   price = (cost + grossincome) / 1 - commissionrate
				//   Multiple both sides by (1-commissionrate)
				//   price - commrate*price = cost + grossincome
				//   price - marketplacecut = sellercut

				// Proof:
				//				[					           PRICE	 	                  ]
				//				[           Seller Cut                ][    Marketplace Cut   ]
				//              [          Cost        ][ GrossIncome ][   Price * Comm Rate  ]

				
				// Consider the following:

				// GlobalTGI and CustomTGI have the same math, however GlobalTGI is a shared resource for identical Skus.
				// Target Gross Income (TGI) is the fixed amount of income that we're targeting. This helps flatten out
				// costs and reach people's pocketbooks without a lot of stretchy markup tactics.

				// MarkupRate is based on cost. We'll have to do some work on this one, I don't believe it functions at all right now 8/1/2023
			}

		
			if (column==="SetPrice"){
				if (!oldvalue){
					oldvalue="0";
				}
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float");
				if (newvalue){
					//Backspace on a decimal should yield same value for old and new
					if (oldvalue.indexOf(".")>-1 && newvalue.indexOf(".")===-1 && event.key==="Backspace"){
						//No need to change front end, simply update griditem
						localstate.griditems[index][column] = newvalue;
					} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)){
						// console.log("This might be the issue maker:");
						// console.log("NewValue: "+newvalue);
						rowRefs.current[index+column].value=newvalue;
						localstate.griditems[index][column] = newvalue;
					}
				} else {
					if (event.key === "Backspace") {
						if ((oldvalue.indexOf(".") > -1)) {
							localstate.griditems[index][column] = parseFloat(event.target.value).toFixed(2);
						}
					}
				}
			}
		
			//DEPRECATING:
			//Conditional for Diff & CalcPrice
			if (column === "GlobalTGI" || column === "CustomTGI" || column === "MarkupRate" || column === "SetPrice") {
				let grossincome = 0;
				//Determine PriceBy type:
				if (localstate.griditems[index].PriceBy === "GlobalTGI"){
					grossincome=localstate.griditems[index]['GlobalTGI'];
					//console.log("Global Gross Income: "+grossincome);
				}
				if (localstate.griditems[index].PriceBy === "CustomTGI"){
					grossincome=localstate.griditems[index]['CustomTGI'];
					//console.log("Custom Gross Income: "+grossincome);
				}
				if (localstate.griditems[index].PriceBy === "Markup"){
					grossincome=(rowRefs.current[index + "MarkupRate"].value/100) * localstate.griditems[index].Cost;
					//console.log("Markup Gross Income: "+grossincome);
				}

				//Need calcprice:
				calcprice = rowRefs.current[index + "CalcPrice"].textContent = RoundPrice(((parseFloat(localstate.griditems[index].Cost) + parseFloat(grossincome)) / (1 - localstate.griditems[index].CommissionRate / 100)).toFixed(2));


				//Can also Set CalcPrice here:
				localstate.griditems[index].CalcPrice = calcprice;
				diff = rowRefs.current[index + "Diff"].textContent = localstate.griditems[index].Diff = (calcprice - localstate.griditems[index].SetPrice).toFixed(2);
				console.log("Diff: " + diff);
				diff > 0 ? rowRefs.current[index + "Diff"].classList.add(classes.lightred) : rowRefs.current[index + "Diff"].classList = "";
				if (diff < 0) {
					rowRefs.current[index + "Diff"].classList.add(classes.lightgreen);
				}
			}
			
			if (column==="Qty"){
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "positiveinteger");
				//If new value...
				if (newvalue){
					//If Cost changes, so does Margin
					//rowRefs.current[index+"Margin"].textContent = (rowRefs.current[index+"Price"].value - newvalue).toFixed(2);
					//localstate.griditems[index].Margin = rowRefs.current[index+"Margin"].textContent;

					//Backspace on a decimal should yield same value for old and new
					//Test oldvalue
					//console.log("Old value:"+oldvalue);
					if ([oldvalue].indexOf(".")>-1 && newvalue.indexOf(".")===-1){
						//No need to change front end, simply update griditem
						localstate.griditems[index][column] = newvalue;
					} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)) {
						rowRefs.current[index+column].value=newvalue;
						localstate.griditems[index][column] = newvalue;
					}
				} else {
					//Handle possible backspace.
					//We need to update the localstate, but NOT the ref. The input ref can't take values like "2." and messes up the cursor position if you try.
					if (event.key === "Backspace") {
						if ((oldvalue.indexOf(".") > -1)) {
							localstate.griditems[index][column] = parseFloat(event.target.value).toFixed(2);
						}
					}
				}
			}


			if (column === "Cost") {
				newvalue = RestrictNumber(newvalue, oldvalue);
				//If Cost changes, so does Margin
				rowRefs.current[index + "Margin"].textContent = (rowRefs.current[index + "Price"].value - newvalue).toFixed(2);
				localstate.griditems[index].Margin = rowRefs.current[index + "Margin"].textContent;
			}
			//Likely unused.
			if (column==="Price"){
				if (!oldvalue){
					oldvalue="0";
				}
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float");
				if (newvalue){
					//Backspace on a decimal should yield same value for old and new
					if (oldvalue.indexOf(".")>-1 && newvalue.indexOf(".")===-1){
						//No need to change front end, simply update griditem
						localstate.griditems[index][column] = newvalue;
					} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)){
						rowRefs.current[index+column].value=newvalue;
						localstate.griditems[index][column] = newvalue;
					}
				} else {
					if (event.key === "Backspace") {
						if ((oldvalue.indexOf(".") > -1)) {
							localstate.griditems[index][column] = parseFloat(event.target.value).toFixed(2);
						}
					}
				}
			}
			//Provision for Booleans that require a re-render. Expensive!
			if (column === "SomeBoolean") {
				if (event.target.checked) {
					//console.log("Checked = true");
					localstate.griditems[index][column] = 1;
					UpdateState(localstate);
				} else {
					//console.log("Checked = false");
					localstate.griditems[index][column] = 0;
					UpdateState(localstate);
				}

			} 
			
			
			//Everything Else (Text Fields, etc):
			if (column === "PriceBy") {
				//Status works similar to booleans - Component takes care of view while localstate has yet to update the DB
				localstate.griditems[index][column] = newvalue;
				errors.NewError({ errmsg: "Remember: Pricing method changes will require a reload for new CalcPrices.", errshow: true, errtimeout: 20, errtype: "ok" })
			}
			
			rowRefs.current[index + "SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[index].unsaved = true;
			btnSave.current.style.display="none";
			btnPendingSave.current.style.display="";
		}
	}



	/* ##########################  Button Functions  ########################## */

	/* Bulk Update Menu */
	const [showbulkupdate, setShowBulkUpdate] = useState(null);
	const ShowBulkUpdate = (event) => { setShowBulkUpdate(event.currentTarget); }
	const CloseBulkUpdate = () => { setShowBulkUpdate(false); }


	/* Set Quantity */
	const [newquantity, setNewQuantity] = useState(0);
	const SetQuantity = () => {
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			localstate.griditems[localstate.selectedindexes[i]].Qty = newquantity;
			rowRefs.current[localstate.selectedindexes[i] + "Qty"].value = newquantity;
			rowRefs.current[localstate.selectedindexes[i] + "SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[localstate.selectedindexes[i]].unsaved = true;
			btnSave.current.style.display="none";
			btnPendingSave.current.style.display="";
			UpdateState(localstate);
			CloseBulkUpdate();
		}
	}
	/* Set Quantity */
	const [newcommissionrate, setNewCommissionRate] = useState(10);
	const SetCommissionRate = () => {
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			localstate.griditems[localstate.selectedindexes[i]].CommissionRate = newcommissionrate;
			//rowRefs.current[localstate.selectedindexes[i] + "CommissionRate"].value = newcommissionrate;
			rowRefs.current[localstate.selectedindexes[i] + "SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[localstate.selectedindexes[i]].unsaved = true;
			btnSave.current.style.display="none";
			btnPendingSave.current.style.display="";
			UpdateState(localstate);
			CloseBulkUpdate();
		}
	}
	/* Set Active */
	const SetActive = () => {
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			localstate.griditems[localstate.selectedindexes[i]].Active = 1;
			rowRefs.current[localstate.selectedindexes[i] + "SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[localstate.selectedindexes[i]].unsaved = true;
			btnSave.current.style.display="none";
			btnPendingSave.current.style.display="";
			UpdateState(localstate);
			CloseBulkUpdate();
		}
	}
	/* Set Deactive */
	const SetDeactive = () => {
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			localstate.griditems[localstate.selectedindexes[i]].Active = 0;
			rowRefs.current[localstate.selectedindexes[i] + "SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[localstate.selectedindexes[i]].unsaved = true;
			btnSave.current.style.display="none";
			btnPendingSave.current.style.display="";
			UpdateState(localstate);
			CloseBulkUpdate();
		}
	}
	/* Set Resolved */
	const SetResolved = () => {
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			localstate.griditems[localstate.selectedindexes[i]].Resolved = 1;
			rowRefs.current[localstate.selectedindexes[i] + "SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[localstate.selectedindexes[i]].unsaved = true;
			btnSave.current.style.display="none";
			btnPendingSave.current.style.display="";
			UpdateState(localstate);
			CloseBulkUpdate();
		}
	}
	/* Set Unresolved */
	const SetUnresolved = () => {
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			localstate.griditems[localstate.selectedindexes[i]].Resolved = 0;
			rowRefs.current[localstate.selectedindexes[i] + "SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[localstate.selectedindexes[i]].unsaved = true;
			btnSave.current.style.display="none";
			btnPendingSave.current.style.display="";
			UpdateState(localstate);
			CloseBulkUpdate();
		}
	}
	/* Set Enabled */
	const SetEnabled = () => {
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			localstate.griditems[localstate.selectedindexes[i]].Disabled = 0;
			rowRefs.current[localstate.selectedindexes[i] + "SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[localstate.selectedindexes[i]].unsaved = true;
			btnSave.current.style.display="none";
			btnPendingSave.current.style.display="";
			UpdateState(localstate);
			CloseBulkUpdate();
		}
	}
	/* Set Disabled */
	const SetDisabled = () => {
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			localstate.griditems[localstate.selectedindexes[i]].Disabled = 1;
			rowRefs.current[localstate.selectedindexes[i] + "SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[localstate.selectedindexes[i]].unsaved = true;
			btnSave.current.style.display="none";
			btnPendingSave.current.style.display="";
			UpdateState(localstate);
			CloseBulkUpdate();
		}
	}

	/* Expand Row - Unused */
	const ExpandRowToggle = (index) => {
		localstate.griditems[index].ExpandRow = !localstate.griditems[index].ExpandRow;
		UpdateState(localstate);
	}

	const ExpandAll = () => {
		for (var i = 0; i < localstate.griditems.length; i++) {
			localstate.griditems[i].ExpandRow = true;
		}
		UpdateState(localstate);
	}

	// const ResetSearches = () => {
	// 	//Reset all:
	// 	for (var i = 1; i < 7; i++) {
	// 		localstate.searchoptions.searchpairs["searchpair" + i].type = "";
	// 		localstate.searchoptions.searchpairs["searchpair" + i].value = "";
	// 		localstate.searchoptions.searchpairs["searchpair" + i].mode = "like";
	// 		localstate.searchoptions.searchpairs["searchpair" + i].uuid = uuidv4();
	// 	}
	// 	//Set Defaults:
	// 	localstate.searchoptions.searchpairs.searchpair1.type = "Sku";

	// 	localstate.searchoptions.searchpairs.searchpair2.type = "NotSku";
	// 	localstate.searchoptions.searchpairs.searchpair2.mode = "not";

	// 	localstate.searchoptions.searchpairs.searchpair3.type = "SkuStarting";
	// 	localstate.searchoptions.searchpairs.searchpair3.mode = "left";

	// 	localstate.searchoptions.searchpairs.searchpair4.type = "SkuEnding";
	// 	localstate.searchoptions.searchpairs.searchpair5.mode = "right";

	// 	localstate.dbreload = true;
	// 	UpdateState(localstate);
	// }

	function getRandomInt(max) {
		return Math.floor(Math.random() * Math.floor(max));
	}



	//UseState variables don't work well inside a loop. Apparently even localstate can't be used. Cancellation of operation useRef variable
	const cancelupdate = useRef(false);
	const updatearray = useRef([]);


	const StartMarketOperation = (updatetype) => {
		//Assuming the user can get to the menu of this, we can reset cancelupdate
		cancelupdate.current = false;
		requestcount.current=0;
		//And reset status
		for (var i = 0; i < localstate.griditems.length; i++) {
			rowRefs.current[i + 'UpdateStatus'].classList.remove(classes.lightgreen, classes.lightyellow);
		}
		updatearray.current = [];
		switch (updatetype) {
			case "fetchselected":
				if (localstate.selectedindexes.length !== 0) {
					for (var i = 0; i < localstate.selectedindexes.length; i++) {
						updatearray.current.push(localstate.griditems[localstate.selectedindexes[i]].ID);
					}
					DisableButtons();
					dispatch(newProgress({
						msg: 'Fetching selected...',
						show: true,
						settimeout: true, //This option allows for a timeout after the final item is pass/failed
						timeout: 300, //Initiated on final item 
						type: 'skus', //Labels your save types such as items, products, skus, parts
						finished: 0,
						percent: "0%",
						total: localstate.selectedindexes.length,
						pass: 0,
						fail: 0,
						faillist: [], //Can take simple values,
						faillinks: [], //Can take links to things like items or products.
						timeoutid: false,
						errors:[] //Can take strings
					}));
					FetchMarketID(updatearray.current[0]);
				}
				break;
			case "fetchresults":
				for (var i = 0; i < localstate.griditems.length; i++) {
					updatearray.current.push(localstate.griditems[i].ID);
				}
				requestcount.current=0;
				DisableButtons();
				dispatch(newProgress({
					msg: 'Fetching selected...',
					show: true,
					settimeout: true, //This option allows for a timeout after the final item is pass/failed
					timeout: 300, //Initiated on final item 
					type: 'skus', //Labels your save types such as items, products, skus, parts
					finished: 0,
					percent: "0%",
					total: localstate.griditems.length,
					pass: 0,
					fail: 0,
					faillist: [], //Can take simple values,
					faillinks: [], //Can take links to things like items or products.
					timeoutid: false,
					errors:[] //Can take strings
				}));
				FetchMarketID(updatearray.current[0]);
				break;
			case "updateselected":
				if (localstate.selectedindexes.length !== 0) {
					for (var i = 0; i < localstate.selectedindexes.length; i++) {
						updatearray.current.push(localstate.griditems[localstate.selectedindexes[i]]);
					}
					DisableButtons();
					dispatch(newProgress({
						msg: 'Updating selected...',
						show: true,
						settimeout: true, //This option allows for a timeout after the final item is pass/failed
						timeout: 300, //Initiated on final item 
						type: 'skus', //Labels your save types such as items, products, skus, parts
						finished: 0,
						percent: "0%",
						total: localstate.selectedindexes.length,
						pass: 0,
						fail: 0,
						faillist: [], //Can take simple values,
						faillinks: [], //Can take links to things like items or products.
						timeoutid: false,
						errors:[] //Can take strings
					}));
					UpdateMarketID(updatearray.current[0]);
				}
				break;
			case "updateresults":
				for (var i = 0; i < localstate.griditems.length; i++) {
					updatearray.current.push(localstate.griditems[i]);
				}
				DisableButtons();
				dispatch(newProgress({
					msg: 'Updating selected...',
					show: true,
					settimeout: true, //This option allows for a timeout after the final item is pass/failed
					timeout: 300, //Initiated on final item 
					type: 'skus', //Labels your save types such as items, products, skus, parts
					finished: 0,
					percent: "0%",
					total: localstate.griditems.length,
					pass: 0,
					fail: 0,
					faillist: [], //Can take simple values,
					faillinks: [], //Can take links to things like items or products.
					timeoutid: false,
					errors:[] //Can take strings
				}));
				//Try: Send the grid item instead of just the ID. We can then run an update on the skuintegration 
				// as well as the marketplace
				UpdateMarketID(updatearray.current[0]);
				break;
			case "resetselected":
				if (localstate.selectedindexes.length !== 0) {
					for (var i = 0; i < localstate.selectedindexes.length; i++) {
						updatearray.current.push(localstate.griditems[localstate.selectedindexes[i]].ID);
					}
					localstate.currentrequest = 0;
					localstate.updateinprogress = true;
					UpdateState(localstate);
					ResetFailCountID(updatearray.current[localstate.currentrequest]);
				}
				break;
			case "resetresults":
				for (var i = 0; i < localstate.griditems.length; i++) {
					updatearray.current.push(localstate.griditems[i].ID);
				}
				localstate.currentrequest = 0;
				localstate.updateinprogress = true;
				UpdateState(localstate);
				ResetFailCountID(updatearray.current[localstate.currentrequest]);
				break;
			default:
				break;
		}

		CloseUpdateMarketMenu();
	}

	function FetchMarketID(id) {
		//Try with requestcount

		if (!cancelupdate.current) {
			//console.log("Processing ID: " + id);
		
			const postdata = {
				ID: id
			};
			return axios.post(dbendpoint + "/skuintegrations/fetchmarket", postdata, longpostoptions).then(res => {
				if (res.status === 200) {
					if (res.data.Status === "login") {
						window.location.reload(false);
					}
					if (res.data.Status === "Success") {
						//Expect res.data.skuintegration;

						//Success would usually send back the SKU Integration. It's possible we'd want to change the following:
						//RefPrice - Value and Background?
						//RefQty - Value and Background?
						//RefActive - Value and Background
						//UpdateStatus

						//We use refs instead of rerender: TextContent=Yes/No because there is a conditional on bool via rerender.				

						//Find GridItem to update
						var itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.skuintegration.ID);

						//RefDate
						rowRefs.current[itemindex + 'RefDate'].textContent = res.data.skuintegration.RefDate.substring(0, 10);

						//May not need to reset this anymore (handled from button press, MarketOperation):
						//rowRefs.current[itemindex + 'UpdateStatus'].classList.remove(classes.lightgreen, classes.lightyellow);

						if (res.data.FetchResponse.hasOwnProperty('Error')) {
							rowRefs.current[itemindex + 'FailureCount'].textContent = res.data.skuintegration.FailureCount;
							rowRefs.current[itemindex + 'UpdateStatus'].textContent = res.data.FetchResponse.Error;
							rowRefs.current[itemindex + 'UpdateStatus'].classList.add(classes.lightred);
						} else {
							rowRefs.current[itemindex + 'FailureCount'].textContent = res.data.skuintegration.FailureCount;
							rowRefs.current[itemindex + 'UpdateStatus'].classList.remove(classes.lightred);
							rowRefs.current[itemindex + 'UpdateStatus'].classList.add(classes.lightgreen);
							rowRefs.current[itemindex + 'UpdateStatus'].textContent = "OK";
							//Non-error response from market API
							//Set Active/RefActive/Resolved 

							//Rewrite
							if (res.data.FetchResponse.RefActive) {
								rowRefs.current[itemindex + 'RefActive'].textContent = "Yes";
							} else {
								rowRefs.current[itemindex + 'RefActive'].textContent = "No";
							}

							rowRefs.current[itemindex + 'Active'].classList.remove(classes.lightgreen, classes.lightyellow, classes.lightred);
							rowRefs.current[itemindex + 'RefActive'].classList.remove(classes.lightgreen, classes.lightyellow, classes.lightred);
							// console.log(localstate.griditems[itemindex].Active);
							// console.log(res.data.FetchResponse.RefActive);
							if (localstate.griditems[itemindex].Active === parseInt(res.data.FetchResponse.RefActive)) {
								//If equal, we can check just 1
								if (localstate.griditems[itemindex].Active === 1) {
									rowRefs.current[itemindex + 'Active'].classList.add(classes.lightgreen);
									rowRefs.current[itemindex + 'RefActive'].classList.add(classes.lightgreen);
								} else {
									rowRefs.current[itemindex + 'Active'].classList.add(classes.lightyellow);
									rowRefs.current[itemindex + 'RefActive'].classList.add(classes.lightyellow);
								}
							} else {
								rowRefs.current[itemindex + 'Active'].classList.add(classes.lightred);
								rowRefs.current[itemindex + 'RefActive'].classList.add(classes.lightred);
							}

							rowRefs.current[itemindex + 'Resolved'].classList.remove(classes.lightgreen, classes.lightyellow);
							if (res.data.skuintegration.Resolved) {
								rowRefs.current[itemindex + 'Resolved'].classList.add(classes.lightgreen);
								rowRefs.current[itemindex + 'Resolved'].textContent = "Yes";
							} else {
								rowRefs.current[itemindex + 'Resolved'].classList.add(classes.lightyellow);
								rowRefs.current[itemindex + 'Resolved'].textContent = "No";
							}

							rowRefs.current[itemindex + 'RefQty'].textContent = res.data.FetchResponse.RefQty;
							rowRefs.current[itemindex + 'RefPrice'].textContent = res.data.FetchResponse.RefPrice;
						}
						dispatch(incrementPass());

					}
					if (res.data.Status === "Failure"  || res.data.Status === "NoIntegration") {
						//errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
						dispatch(incrementFail(res.data.message));
					}
				} else {
					dispatch(incrementFail("Non-200, Bad response from server."));
					//errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
					console.log("Bad response from server.");
				}

				requestcount.current++;

		
				//If we aren't finished with the update array, move to next element.
				if (requestcount.current !== updatearray.current.length) {
					return FetchMarketID(updatearray.current[requestcount.current]);
				}
				if (requestcount.current === updatearray.current.length) {
					EnableButtons();
					dispatch(setProgressTimeout(300));
					//Errorstate last, else it messes with updateinprogress bool
					//errors.NewError({ errmsg: "Fetches Completed: " + updatearray.current.length + "/" + updatearray.current.length, errshow: true, errtimeout: 360, errtype: "ok" });
				}
			}
			).catch(function () {
				dispatch(incrementFail("Request failure, possible timeout."));
				//Attempt to UpdateStatus for the item in question
				//Find GridItem to update
				var itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(updatearray.current[requestcount.current]);
				rowRefs.current[itemindex + 'UpdateStatus'].textContent = "Possible network failure.";
				rowRefs.current[itemindex + 'UpdateStatus'].classList.add(classes.lightred);

				//Even if we get network errors, continue to run the updates:
				requestcount.current++;
				//If we aren't finished with the update array, move to next element.
				if (requestcount.current !== updatearray.current.length) {
					return FetchMarketID(updatearray.current[requestcount.current]);
				} else {
					EnableButtons();
					dispatch(setProgressTimeout(300));
					//Errorstate last, else it messes with updateinprogress bool
					//errors.NewError({ errmsg: "Fetches Completed: " + updatearray.current.length + "/" + updatearray.current.length, errshow: true, errtimeout: 360, errtype: "ok" });
				}
			});
		}
	};



	function UpdateMarketID(item) {
		if (!cancelupdate.current) {
			const postdata = {
				item: item
			};
			return axios.post(dbendpoint + "/skuintegrations/updatemarket", postdata, longpostoptions).then(res => {
				if (res.status === 200) {
					if (res.data.Status === "login") {
						window.location.reload(false);
					}
					if (res.data.Status === "Success") {
						//Expect res.data.skuintegration;
						//With Market Updates, we also save the skuintegration back to the system, avoiding the SaveChanges button


						//Success would usually send back the SKU Integrations. It's possible we'd want to change the following:
						//RefPrice
						//RefQty
						//RefActive
						//UpdateStatus

						//Find GridItem to update
						var itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.skuintegration.ID);

						//Mark as saved
						localstate.griditems[itemindex].unsaved = false;
						rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);

						//RefDate
						rowRefs.current[itemindex + 'RefDate'].textContent = res.data.skuintegration.RefDate.substring(0, 10);

						//Reset UpdateStatus - may not be needed anymore:
						//rowRefs.current[itemindex + 'UpdateStatus'].classList.remove(classes.lightgreen, classes.lightyellow);

						if (res.data.UpdateResponse.hasOwnProperty('Error')) {
							rowRefs.current[itemindex + 'FailureCount'].textContent = res.data.skuintegration.FailureCount;
							rowRefs.current[itemindex + 'UpdateStatus'].textContent = res.data.UpdateResponse.Error + " Fail Count: " + res.data.skuintegration.FailureCount;
							rowRefs.current[itemindex + 'UpdateStatus'].classList.add(classes.lightred);

						} else {
							//Non-error response from market API
							rowRefs.current[itemindex + 'UpdateStatus'].classList.remove(classes.lightred);
							rowRefs.current[itemindex + 'UpdateStatus'].classList.add(classes.lightgreen);
							rowRefs.current[itemindex + 'UpdateStatus'].textContent = "OK";


							if (res.data.UpdateResponse.RefActive) {
								if (res.data.UpdateResponse.RefActive === "1" || res.data.UpdateResponse.RefActive === 1) {
									rowRefs.current[itemindex + 'RefActive'].textContent = "Yes";
								} else if (res.data.UpdateResponse.RefActive === "0" || res.data.UpdateResponse.RefActive === 0) {
									rowRefs.current[itemindex + 'RefActive'].textContent = "No";
								}
							} else {
								rowRefs.current[itemindex + 'RefActive'].textContent = "No";
							}
							rowRefs.current[itemindex + 'Active'].classList.remove(classes.lightgreen, classes.lightyellow, classes.lightred);
							rowRefs.current[itemindex + 'RefActive'].classList.remove(classes.lightgreen, classes.lightyellow, classes.lightred);
							if (localstate.griditems[itemindex].Active === parseInt(res.data.UpdateResponse.RefActive)) {
								//If equal, we can check just 1
								if (localstate.griditems[itemindex].Active === 1) {
									rowRefs.current[itemindex + 'Active'].classList.add(classes.lightgreen);
									rowRefs.current[itemindex + 'RefActive'].classList.add(classes.lightgreen);
								} else {
									rowRefs.current[itemindex + 'Active'].classList.add(classes.lightyellow);
									rowRefs.current[itemindex + 'RefActive'].classList.add(classes.lightyellow);
								}
							} else {
								rowRefs.current[itemindex + 'Active'].classList.add(classes.lightred);
								rowRefs.current[itemindex + 'RefActive'].classList.add(classes.lightred);
							}

							rowRefs.current[itemindex + 'Resolved'].classList.remove(classes.lightgreen, classes.lightyellow);
							if (res.data.skuintegration.Resolved === 1) {
								rowRefs.current[itemindex + 'Resolved'].classList.add(classes.lightgreen);
								rowRefs.current[itemindex + 'Resolved'].textContent = "Yes";
							} else {
								rowRefs.current[itemindex + 'Resolved'].classList.add(classes.lightyellow);
								rowRefs.current[itemindex + 'Resolved'].textContent = "No";
							}

							rowRefs.current[itemindex + 'RefQty'].textContent = res.data.UpdateResponse.RefQty;
							rowRefs.current[itemindex + 'RefPrice'].textContent = res.data.UpdateResponse.RefPrice;
						}
						dispatch(incrementPass());
					}
					if (res.data.Status === "Failure" || res.data.Status === "NoIntegration") {
						//errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
						dispatch(incrementFail(res.data.message));
					}
				} else {
					//errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
					dispatch(incrementFail("Non-200 response from server."));
				}
				requestcount.current++;

				//If we aren't finished with the update array, move to next element.
				if (requestcount.current !== updatearray.current.length) {
					return UpdateMarketID(updatearray.current[requestcount.current]);
				} else {
					EnableButtons();
					dispatch(setProgressTimeout(300));
					//Errorstate last, else it messes with updateinprogress bool
					//errors.NewError({ errmsg: "Updates Completed: " + updatearray.current.length + "/" + updatearray.current.length, errshow: true, errtimeout: 360, errtype: "ok" });
				}
			}).catch(function (error) {
				dispatch(incrementFail("Error in request, possible timeout."));
				console.log(error);
				//Attempt to UpdateStatus for the item in question
				//Find GridItem to update
				console.log("current Request:" + requestcount.current);
				console.log("Update Array ID:" + updatearray.current[requestcount.current].ID);
				console.log(localstate.griditems);
				var itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(updatearray.current[requestcount.current].ID);
				console.log("Item Index:" + itemindex);
				rowRefs.current[itemindex + 'UpdateStatus'].textContent = "Possible network failure.";
				rowRefs.current[itemindex + 'UpdateStatus'].classList.add(classes.lightred);
				//Even if we get network errors, continue to run the updates:
				requestcount.current++;
				//If we aren't finished with the update array, move to next element.
				if ((requestcount.current !== updatearray.current.length) && !cancelupdate.current) {
					return UpdateMarketID(updatearray.current[requestcount.current]);
				} else {
					EnableButtons();
					dispatch(setProgressTimeout(300));
					//Errorstate last, else it messes with updateinprogress bool
					//errors.NewError({ errmsg: "Updates Completed: " + updatearray.current.length + "/" + updatearray.current.length, errshow: true, errtimeout: 360, errtype: "ok" });
				}
			})
		}
	};


	/* REPLACE
	function UpdateMarketID(id) {
		if (!cancelupdate.current) {
			errors.NewError({ errmsg: "Processing: " + localstate.currentrequest + "/" + localstate.updatearray.length, errshow: true, errtimeout: 360, errtype: "ok" })
			const longpostoptions = {
				withCredentials: true,
				crossDomain: true,
				mode: "no-cors",
				timeout: 120000,
			};
			const postdata = {
				ID: id
			};
			return axios.post(dbendpoint + "/skuintegrations/updatemarket", postdata, longpostoptions).then(res => {
				if (res.status === 200) {
					if (res.data.Status === "login") {
						window.location.reload(false);
					}
					if (res.data.Status === "Success") {
						//Expect res.data.skuintegration;

						//Success would usually send back the SKU Integrations. It's possible we'd want to change the following:
						//RefPrice
						//RefQty
						//RefActive
						//UpdateStatus

						//Find GridItem to update
						var itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.skuintegration.ID);

						//RefDate
						rowRefs.current[itemindex + 'RefDate'].textContent = res.data.skuintegration.RefDate.substring(0, 10);

						//Reset UpdateStatus:
						rowRefs.current[itemindex + 'UpdateStatus'].classList.remove(classes.lightgreen, classes.lightyellow);

						if (res.data.UpdateResponse.hasOwnProperty('Error')) {
							rowRefs.current[itemindex + 'FailureCount'].textContent = res.data.skuintegration.FailureCount;
							rowRefs.current[itemindex + 'UpdateStatus'].textContent = res.data.UpdateResponse.Error + " Fail Count: " + res.data.skuintegration.FailureCount;
							rowRefs.current[itemindex + 'UpdateStatus'].classList.add(classes.lightred);

						} else {
							//Non-error response from market API
							rowRefs.current[itemindex + 'UpdateStatus'].classList.add(classes.lightgreen);
							rowRefs.current[itemindex + 'UpdateStatus'].textContent = "OK";


							if (res.data.UpdateResponse.RefActive) {
								rowRefs.current[itemindex + 'RefActive'].textContent = "Yes";
							} else {
								rowRefs.current[itemindex + 'RefActive'].textContent = "No";
							}
							rowRefs.current[itemindex + 'Active'].classList.remove(classes.lightgreen, classes.lightyellow);
							rowRefs.current[itemindex + 'RefActive'].classList.remove(classes.lightgreen, classes.lightyellow);
							if (localstate.griditems[itemindex].Active === res.data.UpdateResponse.RefActive) {
								//If equal, we can check just 1
								if (localstate.griditems[itemindex].Active===1){
									rowRefs.current[itemindex + 'Active'].classList.add(classes.lightgreen);
									rowRefs.current[itemindex + 'RefActive'].classList.add(classes.lightgreen);
								} else {
									rowRefs.current[itemindex + 'Active'].classList.add(classes.lightyellow);
									rowRefs.current[itemindex + 'RefActive'].classList.add(classes.lightyellow);
								}
							} else {
								rowRefs.current[itemindex + 'Active'].classList.add(classes.lightred);
								rowRefs.current[itemindex + 'RefActive'].classList.add(classes.lightred);
							}

							rowRefs.current[itemindex + 'Resolved'].classList.remove(classes.lightgreen, classes.lightyellow);
							if (res.data.skuintegration.Resolved === 1) {
								rowRefs.current[itemindex + 'Resolved'].classList.add(classes.lightgreen);
								rowRefs.current[itemindex + 'Resolved'].textContent = "Yes";
							} else {
								rowRefs.current[itemindex + 'Resolved'].classList.add(classes.lightyellow);
								rowRefs.current[itemindex + 'Resolved'].textContent = "No";
							}

							rowRefs.current[itemindex + 'RefQty'].textContent = res.data.UpdateResponse.RefQty;
							rowRefs.current[itemindex + 'RefPrice'].textContent = res.data.UpdateResponse.RefPrice;
						}

					}
					if (res.data.Status === "Failure") {
						errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
					}
				} else {
					errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })

				}

				localstate.currentrequest++;
				//If we aren't finished with the update array, move to next element.
				if (localstate.currentrequest !== localstate.updatearray.length) {
					return UpdateMarketID(localstate.updatearray[localstate.currentrequest]);
				} else {
					localstate.updateinprogress = false;
					localstate.updatetype = "";
					UpdateState(localstate);
					//Errorstate last, else it messes with updateinprogress bool
					errors.NewError({ errmsg: "Updates Completed: " + localstate.updatearray.length + "/" + localstate.updatearray.length, errshow: true, errtimeout: 360, errtype: "ok" });
				}
			}
			).catch(function (error) {
				console.log(error);
				//Attempt to UpdateStatus for the item in question
				//Find GridItem to update
				var itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(localstate.updatearray[localstate.currentrequest]);
				rowRefs.current[itemindex + 'UpdateStatus'].textContent = "Possible network failure.";
				rowRefs.current[itemindex + 'UpdateStatus'].classList.add(classes.lightred);
				//Even if we get network errors, continue to run the updates:
				localstate.currentrequest++;
				//If we aren't finished with the update array, move to next element.
				if ((localstate.currentrequest !== localstate.updatearray.length) && !cancelupdate.current) {
					return UpdateMarketID(localstate.updatearray[localstate.currentrequest]);
				} else {
					localstate.updateinprogress = false;
					localstate.updatetype = "";
					UpdateState(localstate);
					//Errorstate last, else it messes with updateinprogress bool
					errors.NewError({ errmsg: "Updates Completed: " + localstate.updatearray.length + "/" + localstate.updatearray.length, errshow: true, errtimeout: 360, errtype: "ok" });
				}
			}
			)

		}
	};
	*/

	function ResetFailCountID(id) {
		if (!cancelupdate.current) {
			errors.NewError({ errmsg: "Resetting Failure Counts: " + localstate.currentrequest + "/" + localstate.updatearray.length, errshow: true, errtimeout: 360, errtype: "ok" })
			console.log("Processing ID: " + id);
			const postdata = {
				ID: id
			};
			return axios.post(dbendpoint + "/skuintegrations/resetfailcount", postdata, defaultpostoptions).then(res => {
				if (res.status === 200) {
					if (res.data.Status === "login") {
						window.location.reload(false);
					}
					if (res.data.Status === "Success") {
						//Find GridItem to update
						var itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(localstate.updatearray[localstate.currentrequest]);
						rowRefs.current[itemindex + "FailureCount"].textContent = "0";
					}
					if (res.data.Status === "Failure") {
						errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
					}
				} else {
					errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
					console.log("Bad response from server.");
				}

				localstate.currentrequest++;
				console.log(localstate.currentrequest);
				//If we aren't finished with the update array, move to next element.
				if (localstate.currentrequest !== localstate.updatearray.length) {
					return ResetFailCountID(localstate.updatearray[localstate.currentrequest]);
				}
				if (localstate.currentrequest === localstate.updatearray.length) {
					localstate.updateinprogress = false;
					UpdateState(localstate);
					//Errorstate last, else it messes with updateinprogress bool
					errors.NewError({ errmsg: "Resets Completed: " + localstate.updatearray.length + "/" + localstate.updatearray.length, errshow: true, errtimeout: 360, errtype: "ok" });
				}
			}
			).catch(function () {
				//Even if we get network errors, continue to run the updates:
				localstate.currentrequest++;
				//If we aren't finished with the update array, move to next element.
				if ((localstate.currentrequest !== localstate.updatearray.length) && !cancelupdate.current) {
					return ResetFailCountID(localstate.updatearray[localstate.currentrequest]);
				} {
					localstate.updateinprogress = false;
					UpdateState(localstate);
					//Errorstate last, else it messes with updateinprogress bool
					errors.NewError({ errmsg: "Resets Completed: " + localstate.updatearray.length + "/" + localstate.updatearray.length, errshow: true, errtimeout: 360, errtype: "ok" });
				}
			}
			)
		}
	};

	const AuthorizeAmazon = () => {
		const postdata = {
			ID: 'Thing'
		};
		axios.post(dbendpoint + "/skuintegrations/authamazon", postdata, defaultpostoptions).then(res => {
			if (res.status === 200) {
				if (res.data.Status === "login") {
					window.location.reload(false);
				}
				if (res.data.Status === "Success") {

				}
				if (res.data.Status === "Failure") {
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
				}
			} else {
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
				console.log("Bad response from server.");
			}
		}
		).catch(function () {
			console.log("Caught error");
		}
		)
	}


	const CancelUpdates = () => {
		//To break loop, had to use useRef
		cancelupdate.current = true;
		// localstate.updateinprogress = false;
		// UpdateState(localstate);
		//errors.NewError({ errmsg: "Operation Canceled.", errshow: true, errtimeout: 60, errtype: "ok" });
		EnableButtons();
	}

	//Export Confirmation and Exports
	const [showexportconfirmation, setShowExportConfirmation] = useState(false);
	//Chooses between "selected" or "searchresults"
	const [exporttype, setExportType] = useState("");
	//Export Message
	const [exportmessage, setExportMessage] = useState("");
	var exportmode = "";
	var exportlimit = 5000;
	var exportfilename = "SkuIntegrations";

	const InitExport = (exporttype) => {
		if (exporttype === "selected" && localstate.selectedindexes.length === 0) {
			//Do nothing
		} else {
			//Show export type selection
			setShowExportConfirmation(true);
			//Set export by "selected" or "searchresults"
			setExportType(exporttype);
			if (exporttype === "selected") {
				setExportMessage("");
			}
			if (exporttype === "searchresults") {
				setExportMessage("Exports of search results will be limited to " + exportlimit + " rows.");
			}
		}
	}

	const PrepareExport = (mode) => {
		//Set global
		exportmode = mode;
		var exportdata = [];
		var clonedata = [];
		if (exporttype === "selected") {
			//Need to DEEP clone:
			var clonedata = JSON.parse(JSON.stringify(localstate.griditems))
			for (var i = 0; i < localstate.selectedindexes.length; i++) {
				exportdata.push(clonedata[localstate.selectedindexes[i]]);
			}
			Export(exportdata);
		}
		if (exporttype === "searchresults") {
			const postdata = {
				searchoptions: {
					//Set Max Limit Here
					limit: exportlimit,
					currentsort: localstate.orderby,
					currentsortdir: localstate.order,
					searchpairs: localstate.searchoptions.searchpairs,
					selectedintegrations: localstate.selectedintegrations
				}
			};
			axios.post(dbendpoint + "/skuintegrations/get?page=" + (localstate.page + 1), postdata, defaultpostoptions).then(res => {
				if (res.status === 200) {
					if (res.data.Status === "login") {
						window.location.reload(false);
					}
					if (res.data.Status === "Success") {
						exportdata = res.data.pagedata.data;
						Export(exportdata);
					}
					if (res.data.Status === "Failure") {
						errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
					}
				} else {
					errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
				}
			});
		}
	}

	const Export = (exportdata) => {
		//Remove Metas
		for (var i = 0; i < exportdata.length; i++) {
			//Add back Title, Try:
			//Titles may have doublequotes in them that need to be handled.
			exportdata[i]['Title'] = exportdata[i]['skudetails']['Title'].replace("\"", "\"\"");

			removeProp(exportdata[i], "RecordHistory");
			removeProp(exportdata[i], "isSelected");
			removeProp(exportdata[i], "unsaved");
			removeProp(exportdata[i], "ExpandRow");
			removeProp(exportdata[i], "GridKey");		

		}

		//Use Export Mode to pare data
		if (exportmode === "simple") {
			for (var i = 0; i < exportdata.length; i++) {
				removeProp(exportdata[i], "integration");
				removeProp(exportdata[i], "skudetails");
				removeProp(exportdata[i], "orderitems");
				removeProp(exportdata[i], "AvgPrice");
				removeProp(exportdata[i], "Date");
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode === "expanded") {
			for (var i = 0; i < exportdata.length; i++) {
				removeProp(exportdata[i], "integration");
				removeProp(exportdata[i], "skudetails");
				removeProp(exportdata[i], "orderitems");
				removeProp(exportdata[i], "AvgPrice");
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode === "exhaustive") {
			//Remove nothing - or possibly break down nested relationships
		}

		if (exportmode === "reebelo") {
			//Handle how we want the data to look
			var newdata = [];
			var i=0;
			var ram = "";
			var hdd = "";
			for (var j = 0; j < exportdata.length; j++) {
				console.log(exportdata[j]);
				console.log(exportdata[j]['skudetails']['product']['ProductType']);
				
				//Get RAM:
				ram = "";
				var hdd = "";
				for (i=0; i<exportdata[j]['skudetails']['skucomponents'].length; i++){
					if (exportdata[j]['skudetails']['skucomponents'][i]['details']['ComponentType']==="RAM"){
						ram = exportdata[j]['skudetails']['skucomponents'][i]['details']['Name'];
						//RAM
						if (ram==="8GB DDR4"){
							ram="8GB RAM";
						}
						if (ram==="16GB DDR4"){
							ram="16GB RAM";
						}
						if (ram==="24GB DDR4"){
							ram="24GB RAM";
						}
						if (ram==="32GB DDR4"){
							ram="32GB RAM";
						}
						if (ram==="64GB DDR4"){
							ram="64GB RAM";
						}
						if (ram==="128GB DDR4"){
							ram="128GB RAM";
						}
						//HDD
						if (ram==="256GB M.2 NVMe"){
							ram="256GB NVMe SSD";
						}
						if (ram==="512GB M.2 NVMe"){
							ram="512GB NVMe SSD";
						}
						if (ram==="1TB M.2 NVMe"){
							ram="1TB NVMe SSD";
						}
						if (ram==="2TB M.2 NVMe"){
							ram="2TB NVMe SSD";
						}
						if (ram==="4TB M.2 NVMe"){
							ram="4TB NVMe SSD";
						}

					}
					if (exportdata[j]['skudetails']['skucomponents'][i]['details']['ComponentType']==="Hard Drive"){
						hdd = exportdata[j]['skudetails']['skucomponents'][i]['details']['Name'];
					}
				}



				newdata.push({
					//"sku": exportdata[j].Sku,

					// "Category": exportdata[j].skudetails.product.ProductType,
					// "Brand":exportdata[j].skudetails.product.Mfg
					"Category": exportdata[j]['skudetails']['product']['ProductType'],
					"Brand": exportdata[j]['skudetails']['product']['Mfg'],
					"Model": exportdata[j]['skudetails']['Title'].replace("\"", "\"\""),
					"4G/5G VERSION":"",
					"Color":"",
					"Carrier":"",
					"Sim Status":"",
					"Storage \/ Hard drive": ram+" \/ "+hdd,
					"Condition": "",
					"Variant QTY": exportdata[j].Qty,
					"Price":exportdata[j].SetPrice,
					"Min Price":exportdata[j].SetPrice,
					"Vendor Name":"Deluxe PCs",
					"SKU by Vendor":exportdata[j].Sku,
					"Remarks by Vendor":"",
					"GTIN":exportdata[j]['skudetails']['Gtin']
					
					// "###": exportdata[j]['skudetails']['product']['###'],
					// "###": exportdata[j]['skudetails']['product']['###'],
					// "###": exportdata[j]['skudetails']['product']['###'],
					// "###": exportdata[j]['skudetails']['product']['###'],
					// "###": exportdata[j]['skudetails']['product']['###'],
					// "###": exportdata[j]['skudetails']['product']['###'],
					// "###": exportdata[j]['skudetails']['product']['###'],
					// "###": exportdata[j]['skudetails']['product']['###'],
					// "###": exportdata[j]['skudetails']['product']['###'],
					// "###": exportdata[j]['skudetails']['product']['###'],



				});
			}
			exportfilename = "ReebeloExport";
			exportdata = newdata;
		}

		if (exportmode === "amazon") {
			//Handle how we want the data to look
			var newdata = [];
			for (var j = 0; j < exportdata.length; j++) {
				newdata.push({
					"sku": exportdata[j].Sku,
					"price": exportdata[j].SetPrice,
					"minimum-seller-allowed-price": "",
					"maximum-seller-allowed-price": "",
					"quantity": exportdata[j].Qty,
					"handling-time": "",
					"business-price": exportdata[j].SetPrice,
					"quantity-price-type": "",
					"quantity-lower-bound1": "",
					"quantity-price1": "",
					"quantity-lower-bound2": "",
					"quantity-price2": "",
					"quantity-lower-bound3": "",
					"quantity-price3": "",
					"quantity-lower-bound4": "",
					"quantity-price4": "",
					"quantity-lower-bound5": "",
					"quantity-price5": "",
					"pricing_action": ""
				});
			}
			exportfilename = "AmazonFeed";
			exportdata = newdata;
		}

		if (exportmode === "ebay") {
			//Handle how we want the data to look
			var newdata = [];
			for (var j = 0; j < exportdata.length; j++) {
				newdata.push({
					"Start price": exportdata[j].SetPrice,
					"Buy It Now price": "",
					"Available quantity": exportdata[j].Qty,
					"Relationship" : "",
					"Relationship details": "",
					"Custom label (SKU)": exportdata[j].Sku
				});
			}
			exportfilename = "EbayFeed";
			exportdata = newdata;
		}


		ExportCSV(exportdata, exportfilename);
		setShowExportConfirmation(false);
	}

	const RoundPrice = (price) => {
		//Set number as string
		//Round up or down to nearest integer
		var roundprice = Math.round(price);
		//console.log("round price:"+roundprice);
		var stringnumber = roundprice.toString();
		var outputarray = [];
		//Add a digits here just in case there is a round-up issue
		outputarray.push('0');
		for (var i = 0; i < stringnumber.length; i++) {
			outputarray.push(stringnumber.charAt(i));
		}
		//console.log('Lets see this array'+outputarray);
		//Check last digit
		var last = outputarray.length - 1;
		if (outputarray.length > 1) {
			switch (outputarray[last]) {
				case '0':
					break;
				case '1':
					outputarray[last] = 0;
					break;
				case '2':
					outputarray[last] = 0;
					break;
				case '3':
					outputarray[last] = 5;
					break;
				case '4':
					outputarray[last] = 5;
					break;
				case '5':
					outputarray[last] = 5;
					break;
				case '6':
					outputarray[last] = 5;
					break;
				case '7':
					outputarray[last] = 5;
					break;
				//Increment 2nd to last digit
				case '8':
					outputarray[last - 1] = (parseInt(outputarray[last - 1]) + 1);
					//The following will watch for chained 9's and move additions to the values up the array
					if (outputarray[last - 1] == '10') {
						outputarray[last - 1] = '0';
						outputarray[last - 2] = (parseInt(outputarray[last - 2]) + 1);
						if (outputarray[last - 2] == '10') {
							outputarray[last - 2] = '0';
							outputarray[last - 3] = (parseInt(outputarray[last - 3]) + 1);
							if (outputarray[last - 3] == '10') {
								outputarray[last - 3] = '0';
								outputarray[last - 4] = (parseInt(outputarray[last - 4]) + 1);
							}
						}
					}
					outputarray[last] = 0;
					break;

				//Increment 2nd to last digit and set last to 0 (and repeat steps above)
				case '9':
					outputarray[last - 1] = (parseInt(outputarray[last - 1]) + 1);
					//The following will watch for chained 9's and move additions to the values up the array
					if (outputarray[last - 1] == '10') {
						outputarray[last - 1] = '0';
						outputarray[last - 2] = (parseInt(outputarray[last - 2]) + 1);
						if (outputarray[last - 2] == '10') {
							outputarray[last - 2] = '0';
							outputarray[last - 3] = (parseInt(outputarray[last - 3]) + 1);
							if (outputarray[last - 3] == '10') {
								outputarray[last - 3] = '0';
								outputarray[last - 4] = (parseInt(outputarray[last - 4]) + 1);
							}
						}
					}
					outputarray[last] = 0;
					break;
			}
		}

		var newprice = parseInt(outputarray.join(''));
		newprice = newprice - 0.01;
		return newprice;
	};

	const CopyPrices = () => {
		//Has Similar functions for showing unsaved items, etc.
		var calcprice;
		var diff;
		var changefound = false;
		for (var i = 0; i < localstate.griditems.length; i++) {
			//Determine if we even need to change price.
			if (parseFloat(localstate.griditems[i].SetPrice).toFixed(2)!==localstate.griditems[i].CalcPrice.toFixed(2)){
				changefound = true;
				calcprice = rowRefs.current[i + "SetPrice"].value = localstate.griditems[i].SetPrice = localstate.griditems[i].CalcPrice.toFixed(2);
				diff = rowRefs.current[i + "Diff"].textContent = localstate.griditems[i].Diff = (localstate.griditems[i].CalcPrice - localstate.griditems[i].SetPrice).toFixed(2);
				//console.log("Diff: " + diff);
				diff > 0 ? rowRefs.current[i + "Diff"].classList.add(classes.lightred) : rowRefs.current[i + "Diff"].classList = "";
				if (diff < 0) {
					rowRefs.current[i + "Diff"].classList.add(classes.lightgreen);
				}
				rowRefs.current[i + "SaveStatus"].classList.add(classes.unsavedhighlight);
				localstate.griditems[i].unsaved = true;
			}	
		}
		if (changefound){
			btnSave.current.style.display="none";
			btnPendingSave.current.style.display="";
		}
	}


	/* ##########################  Drawer Search Options  ########################## */
	const [showsearchoptions, setShowSearchOptions] = useState(false);
	const ToggleSearchOptions = (showsearchoptionsbool) => {
		setShowSearchOptions(showsearchoptionsbool);
		if (!showsearchoptionsbool) {
			localstate.dbreload = true;
			UpdateState(localstate);
		}
	}

	const ToggleIntegration = (event, integrationid) => {
		//Check if ID is already in list
		if (localstate.selectedintegrations.indexOf(integrationid) > -1) {
			localstate.selectedintegrations.splice(localstate.selectedintegrations.indexOf(integrationid), 1);
		} else {
			localstate.selectedintegrations.push(integrationid);
		}
		UpdateState(localstate);
	}

	const ChangeSalesDays = (event) => {
		localstate.salesdays = event.target.value;
	}

	const ChangeRecentSalesThreshold = (event) => {
		localstate.recentsalethreshold = event.target.value;
	}

	const ChangeShowDisabled = (event) => {
		localstate.searchoptions.showdeactivated = event.target.checked;
	}



	/* ##########################  TABLE HEAD  ########################## */
	/* ##########################  Column Configuration  ########################## */
	const headCells = [
		//Be sure to adjust widths for cells as well.
		{ id: "Sku", numeric: false, label: "Sku", align: "left", allowsort: true, style: {} },
		{ id: "IntegrationName", numeric: true, label: "Integration", align: "left", allowsort: false, style: {} },
		{ id: "IntegrationIcon", numeric: true, label: "Mkt", align: "left", allowsort: false, style: { width: "30px" } },
		{ id: "Cost", numeric: false, label: "Cost", align: "right", allowsort: true, style: { width: "60px" } }, //To do: Create method for recalculating cost for each SKU. Saves also back to flexsku.
		{ id: "CommissionRate", numeric: true, label: "Comm%", align: "right", allowsort: true, style: { width: "50px" } },
		{ id: "GIModifier", numeric: false, label: "GI Modifier", align: "center", allowsort: false, style: { width: "80px" } },
		{ id: "PriceBy", numeric: false, label: "Price Method", align: "left", allowsort: false, style: { width: "80px" } },
		{ id: "MarkupRate", numeric: true, label: "Markup%", align: "right", allowsort: true, style: { width: "60px" } }, //To do: Make a Copy GI to Global or custom TGI based on markup rate (introductory sku pricing)
		{ id: "TargetGI", numeric: false, label: "GlobalTGI / CustomTGI", align: "left", allowsort: false, style: { width: "140px" } }, //Combine UseGlobalTGI bool, GlobalTGI, and CustomTGI (enable/disable input)
		{ id: "CalcGI", numeric: true, label: "CalcGI", align: "right", allowsort: false, style: {} }, //Calculated in View
		{ id: "AvgPrice", numeric: true, label: "AvgPrice", align: "right", allowsort: false, style: { width: "80px" } }, //Calculated in View
		{ id: "CalcPrice", numeric: true, label: "CalcPrice", align: "right", allowsort: false, style: {} },
		{ id: "Diff", numeric: true, label: "Diff", align: "center", allowsort: false, style: { width: "60px" } },
		{ id: "SetPrice", numeric: true, label: "SetPrice", align: "right", allowsort: false, style: { width: "60px" } },
		{ id: "RefPrice", numeric: false, label: "RefPrice", align: "right", allowsort: false, style: {} },
		{ id: "Qty", numeric: true, label: "Qty", align: "right", allowsort: true, style: { width: "50px" } },
		{ id: "RefQty", numeric: true, label: "RefQty", align: "right", allowsort: true, style: {} },
		{ id: "Active", numeric: true, label: "Active", align: "center", allowsort: true, style: {} },
		{ id: "RefActive", numeric: true, label: "RefActive", align: "center", allowsort: true, style: {} },
		{ id: "Resolved", numeric: true, label: "Resolved", align: "center", allowsort: true, style: {} },
		{ id: "bmbuybox", numeric: true, label: "BM Buybox", align: "center", allowsort: true, style: {} },
		{ id: "bmcondition", numeric: true, label: "BM Condition", align: "center", allowsort: true, style: {} },
		{ id: "bmlast_update", numeric: true, label: "BM Update", align: "center", allowsort: true, style: {} },
		{ id: "RefDate", numeric: true, label: "Ref Date", align: "right", allowsort: true, style: {} },
		{ id: "updated_at", numeric: true, label: "Last Updated", align: "right", allowsort: true, style: {} },
		{ id: "UpdateStatus", numeric: true, label: "Status", align: "center", allowsort: true, style: {} },
		{ id: "FailureCount", numeric: true, label: "FailCount", align: "center", allowsort: true, style: {} },
		{ id: "Disabled", numeric: true, label: "Disabled", align: "center", allowsort: true, style: {} },

	];

	function EnhancedTableHead(props) {
		const { classes, onSelectAllClick, order, onRequestSort } = props;
		const createSortHandler = (property) => (event) => {
			onRequestSort(event, property);
		};

		return (
			<thead style={{ display: "table-header-group" }}>
				<tr style={{
					border: "1px solid #CCC",
					backgroundColor: "#DDD"
				}}>
					<td style={{ width: "14px", padding: "none", display: "table-cell", padding: "2px 4px 2px 5px" }}>

						<Checkbox
							className={classes.gridcheckbox}
							disableRipple
							color="default"
							defaultChecked={localstate.griditems.length === localstate.selectedindexes.length}
							checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
							icon={<span className={classes.icon} />}
							onChange={onSelectAllClick}
						/>

					</td>
					{/* Map remaining table headers */}
					{headCells.map((headCell) =>
						colstate[headCell.id] &&
						(
							<td
								key={headCell.id}
								align={headCell.align}
								style={headCell.style}
							>
								{(headCell.allowsort) &&
									<TableSortLabel
										active={localstate.orderby === headCell.id}
										direction={localstate.orderby === headCell.id ? order : "asc"}
										onClick={createSortHandler(headCell.id, headCell.allowsort)}
										hideSortIcon
									>
										{/* This is a conditional for headCell where the id is "Name". This allows us to put a spacer in here for the expand icon button */}
										{/* The expand button is optional, this can be removed if needed! */}
										{(headCell.id === "Name") &&
											<div style={{ width: "30px", display: "inline-block" }}>
											</div>
										}
										{/* If current sort, show bold label */}
										{(localstate.orderby === headCell.id)
											? <span style={{ fontWeight: "bold" }}>{headCell.label}</span>
											: <span>{headCell.label}</span>
										}
									</TableSortLabel>
								}
								{(!headCell.allowsort) &&
									<span>{headCell.label}</span>
								}
								{(headCell.id === "CalcPrice") &&
									<IconButton className={classes.transparenticon} size="medium" onClick={() => CopyPrices()}>
										<ArrowForwardIcon color="primary" fontSize="default" style={{ paddingLeft: "5px" }}></ArrowForwardIcon>
									</IconButton>
								}
							</td>
						))}
				</tr>
			</thead>
		);
	}

	EnhancedTableHead.propTypes = {
		classes: PropTypes.object.isRequired,
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(["asc", "desc"]).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};


	/* 
																							 
		 _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
		_/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
	   _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
	  _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
	 _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
																						 
																						 
	  */


	/* ##########################  Render Function  ########################## */
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<div style={{ padding: "8px", overflow: "auto", minWidth: "750px" }}>

				{/* Sku List Drawer*/}
				<Drawer open={showskulist} style={{ width: "600px" }}>
					<div style={{ height: "47px" }}></div>
					<Typography variant="h4" gutterBottom align="center">
						Edit Sku List
					</Typography>
					<div style={{ width: "400px", padding: "10px" }}>
						<Typography variant="subtitle1" gutterBottom>
							You may load, create, or delete SKU lists here.<br></br>
						</Typography>

						{(errors.currenterror.errshow) &&
							<div style={{ textAlign: "center", height: "25px", fontSize: "12px" }}>
								<ErrorMessage />
							</div>
						}

						<div className={classes.flexautocomplete} style={{ width: "380px" }}>
							<FormControl className={classes.searchtypeinput} variant="standard" style={{ minWidth: "80px" }}>
								{/* Value must match one of the MenuItem values, SerialNumber != Serial Number */}
								<Select
									value={"List"} disableUnderline
									disabled classes={{ disabled: classes.undisabled }}
									IconComponent={BlankIcon}
								>
									<MenuItem value={"List"}>List</MenuItem>
								</Select>
							</FormControl>

							<Autocomplete forcePopupIcon={false} disableClearable style={{ width: "100%" }}
								open={openskulistoptions} onOpen={() => { InitSkuSearch(); }} onClose={() => { openSkuSearch(false); }}
								//Not sure how to avoid passing event, then newvalue... seems to break when removing event.
								onChange={(event, newValue) => SelectSkuList(newValue)}
								onInputChange={(event, value, reason) => {
									//We avoid a reset of the AutoComplete be only passing the listname value for reason===input
									if (reason === 'input') {
										setListName(event.target.value);
										SkuListSearch(event.target.value);
									}
								}
								}
								isOptionEqualToValue={(option, value) => option["Name"] === value}
								getOptionLabel={(option) => option["Name"]}
								options={options5}
								loading={loadingoptions5}
								defaultValue={listname}
								inputValue={listname}
								renderInput={(params) => (
									<TextField variant="standard"
										className={classes.autocompleteinput}
										{...params}
										InputProps={{
											disableUnderline: true,
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{loadingoptions5 ? <CircularProgress color="inherit" size={20} /> : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</div>
						{(savelistbool) &&
							<Button
								className={classes.bluebtn}
								color="primary" variant="contained"
								onClick={() => SaveList()}>
								Save List
							</Button>
						}

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => CloseSkuList()}>
							Close
						</Button>

						<TextareaAutosize style={{ width: "100%" }} minRows={10} placeholder="Paste SKUs from spreadsheet here."
							onChange={(event) => onChangeSkuList(event.target.value)}
							value={skulist}
						/>
						{(showdeletebtn) &&
							<React.Fragment>
								Warning: Deleting a list cannot be undone!<br></br>
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => DeleteList()}>
									Delete List
								</Button>
							</React.Fragment>
						}
					</div>
				</Drawer>

			
				{/* Start of Page: Standard Page Header with right floated error message space */}
				{/* Standard Page Header with right floated error message space */}
				<div style={{ minHeight: "50px", paddingTop: "5px" }}>
					<Grid container justifyContent="space-between">
						<Grid item xs={12} sm={6} md={3} order={{ xs: 3, sm: 3, md: 1 }} style={{ padding: "5px", minWidth: "270px" }}>{(errors.currenterror.errshow) &&
							<ErrorMessage />
						}</Grid>
						<Grid item xs={12} sm={6} md={3} order={{ xs: 1, sm: 1, md: 2 }} style={{ textAlign: "center", margin: "auto", padding: "5px" }}><h2>Sku Integrations</h2></Grid>
						<Grid item xs={12} sm={6} md={3} order={{ xs: 2, sm: 2, md: 3 }} style={{ padding: "5px", minWidth: "270px" }}>
							<div style={{ float: "right" }}>
								<ProgressBar />
							</div>
						</Grid>
					</Grid>
				</div>

				{/* /* ##########################  Search Inputs  ########################## */}
				{/* Search Tools should: Fall in-line, stack, have padding-right of 15px, 300px wide.*/}
				{/* CHOOSE between AutoCompletes OR Key-Value searches. Helps keep interface looking CLEAN. */}

				<>
						{/* Inputs can be Grouped inside of new flex containers: */}
						<div style={{ display: "flex", flexGrow: "1", flexDirection: "row", flexWrap: "wrap", justifyContent:"space-evenly" }}>
							{/* Group 1 */}
							<div style={{ display: "flex", flexGrow: "1", flexDirection: "row", flexWrap: "wrap", justifyContent:"space-evenly" }}>							
								<SearchInput
									searchinput={1}
									key={key1}
									localstate={state}
									inputdefaults={inputdefaults}
									onChangeSearchType={onChangeSearchType}
									onChangeSearchValue={onChangeSearchValue}
									onChangeAutoCompleteInput={onChangeAutoCompleteInput}
									selectinputs={defaultselectinputs}
								/>
								<SearchInput
										searchinput={2}
										key={key2}
										localstate={state}
										inputdefaults={inputdefaults}
										onChangeSearchType={onChangeSearchType}
										onChangeSearchValue={onChangeSearchValue}
										onChangeAutoCompleteInput={onChangeAutoCompleteInput}
										selectinputs={defaultselectinputs}
								/>
								<SearchInput
										searchinput={3}
										key={key3}
										localstate={localstate}
										inputdefaults={inputdefaults}
										onChangeSearchType={onChangeSearchType}
										onChangeSearchValue={onChangeSearchValue}
										onChangeAutoCompleteInput={onChangeAutoCompleteInput}
										selectinputs={defaultselectinputs}
									/>
								<SearchInput
									searchinput={4}
									key={key4}
									localstate={localstate}
									inputdefaults={inputdefaults}
									onChangeSearchType={onChangeSearchType}
									onChangeSearchValue={onChangeSearchValue}
									onChangeAutoCompleteInput={onChangeAutoCompleteInput}
									selectinputs={defaultselectinputs}
								/>
							</div>

							{/* Group 2 */}
							{(localstate.expandsearch) &&
								<div style={{ display: "flex", flexGrow: "1", flexDirection: "row", flexWrap: "wrap", justifyContent:"space-evenly" }}>
									<SearchInput
										searchinput={5}
										key={key5}
										localstate={localstate}
										inputdefaults={inputdefaults}
										onChangeSearchType={onChangeSearchType}
										onChangeSearchValue={onChangeSearchValue}
										onChangeAutoCompleteInput={onChangeAutoCompleteInput}
										selectinputs={defaultselectinputs}
									/>
								
									<SearchInput
										searchinput={6}
										key={key6}
										localstate={localstate}
										inputdefaults={inputdefaults}
										onChangeSearchType={onChangeSearchType}
										onChangeSearchValue={onChangeSearchValue}
										onChangeAutoCompleteInput={onChangeAutoCompleteInput}
										selectinputs={defaultselectinputs}
									/>
									<SearchInput
										searchinput={7}
										key={key7}
										localstate={localstate}
										inputdefaults={inputdefaults}
										onChangeSearchType={onChangeSearchType}
										onChangeSearchValue={onChangeSearchValue}
										onChangeAutoCompleteInput={onChangeAutoCompleteInput}
										selectinputs={defaultselectinputs}
									/>
									<SearchInput
										searchinput={8}
										key={key8}
										localstate={localstate}
										inputdefaults={inputdefaults}
										onChangeSearchType={onChangeSearchType}
										onChangeSearchValue={onChangeSearchValue}
										onChangeAutoCompleteInput={onChangeAutoCompleteInput}
										selectinputs={defaultselectinputs}
									/>
								</div>
							}
						</div>
					</>


				{/* ##########################  Buttons and Pagination  ########################## */}
				<div style={{ height: "5px" }}>&nbsp;</div>
				<div>
					{(!showdeleteconfirmation && !showexportconfirmation) &&
						<React.Fragment>


							<Button
								className={(userPerms.updateSkuIntegration === 1) ? classes.bluebtn : classes.hidden}
								color="primary" variant="contained"
								onClick={() => SaveChanges()}
								ref={el => btnSave.current = el}>
								<SaveIcon sx={{ color: "lightgray" }}></SaveIcon>&nbsp;Save Changes
							</Button>


							<Button
								className={(userPerms.updateSkuIntegration === 1) ? classes.bluebtn : classes.hidden}
								color="primary" variant="contained"
								style={{ display: "none" }}
								onClick={() => SaveChanges()}
								ref={el => btnPendingSave.current = el}>
								<PendingIcon sx={{ color: "orange" }}></PendingIcon>&nbsp;Save Changes
							</Button>

							{/* New View Options Menu */}
									{/* Compact a lot of table view options into this one menu to save space! */}
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										aria-haspopup="true"
										onClick={ShowViewOptionsMenu}
										ref={el=>btnViewOptions.current=el}>
										View Options
									</Button>

									<Menu
										className={classes.bluebtn}
										sx={{ zIndex: 3000 }} // Adjust the z-index here
										color="primary"
										id="view-options-menu"
										anchorEl={showViewOptionsMenu}
										keepMounted
										open={Boolean(showViewOptionsMenu)}
										onClose={CloseViewOptionsMenu}
									>
										<div style={{ display: "inline-block", overflow: "auto", verticalAlign: "top" }}>
											<div style={{ fontWeight: "bold", textAlign: "center" }}>Columns</div>
											<MenuItem disableRipple className={classes.columnmenu}>
												<div style={{verticalAlign:"top"}}> {/* Optional container for 2 column menu! */}
													<div style={{display:"inline-block", maxHeight:"600px", overflow:"auto", verticalAlign:"top"}}>
														<FormGroup>
															{/* ##########################  Column Toggles  ########################## */}
															<FlexColumnOption value="IntegrationName" label="Market Name" />
												<FlexColumnOption value="IntegrationIcon" label="Market Icon" />
												<FlexColumnOption value="Cost" label="Cost" />
												<FlexColumnOption value="CommissionRate" label="Commission Rate" />
												<FlexColumnOption value="PriceBy" label="Pricing Method" />
												<FlexColumnOption value="CalcGI" label="Calculated Gross Income" />
												<FlexColumnOption value="AvgPrice" label="Average Price" />
												<FlexColumnOption value="RefDate" label="Market Data (Ref) Date" />
												<FlexColumnOption value="updated_at" label="Last Update" />
												<FlexColumnOption value="Disabled" label="Disabled Status" />
														</FormGroup>
													</div>
												</div>
											</MenuItem>
										</div>




											<div style={{ display: "inline-block", maxHeight: "600px", overflow: "auto", verticalAlign: "top", minWidth:"235px", maxWidth: "200px", padding: "0px 8px" }}>
												<div style={{ fontWeight: "bold", textAlign: "center",}}>Search Options</div>
												<Button
													className={classes.bluebtn}
													sx={{ width: "100%" }}
													color="primary" variant="contained"
													onClick={() => ToggleExpandSearch()}
												>
													{(!localstate.expandsearch) &&
														<>Expand Search</>
													}
													{(localstate.expandsearch) &&
														<>Collapse Search</>
										}
									</Button>
									<Button className={classes.bluebtn}
												color="primary"
												variant="contained"
												sx={{ width: "100%" }}
												onClick={() => SetBackMarketView()}>
												View Backmarket Details
											</Button>
									<hr></hr>
									<div style={{ fontWeight: "bold", textAlign: "center" }}>Sales Data</div>
									
										<div style={{display:"flex"}}>
											<div style={{flexGrow:1, alignContent:"flex-end", fontSize:"16px"}}>
												Days Back:
											</div>
											<input
												type="number" step="1"
												className={classes.flexiteminput}
												style={{ width: "60px", textAlign: "right" }}
												onChange={(event) => ChangeSalesDays(event)}
												defaultValue={localstate.salesdays} />
										</div>
										<div style={{display:"flex", fontSize:"16px"}}>
											<div style={{flexGrow:1,alignContent:"flex-end",}}>
												Recent Threshold:
											</div>
											<input
												type="number" step="1"
												className={classes.flexiteminput}
												style={{ width: "60px", textAlign: "right" }}
												onChange={(event) => ChangeRecentSalesThreshold(event)}
												defaultValue={localstate.recentsalethreshold} />
										</div>
								
									<hr></hr>
									<div style={{display:"flex", fontSize:"16px"}}>
											<div style={{flexGrow:1,alignContent:"flex-end",}}>
												Show Disabled:
											</div>
											<Checkbox
												className={classes.gridcheckbox}
												disableRipple
												color="default"
												defaultChecked={localstate.searchoptions.showdeactivated}
												checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
												icon={<span className={classes.icon} />}
												onChange={(event) => ChangeShowDisabled(event)}
											/>
										</div>			
									</div>
									</Menu>
									{/* END OF VIEW OPTIONS MENU */} 


	

							<Button
								className={(userPerms.updateSkuIntegration === 1) ? classes.bluebtn : classes.hidden}
								color="primary" variant="contained"
								aria-haspopup="true"
								onClick={ShowBulkUpdate}
								ref={el => btnChangeSelected.current = el}>
								Change Selected
							</Button>


							<Menu id="simple-menu"
								anchorEl={showbulkupdate}
								keepMounted
								open={Boolean(showbulkupdate)}
								onClose={CloseBulkUpdate}>
								<div style={{ fontWeight: "bold", textAlign: "center" }}>Values</div>
								<MenuItem style={{ width: "160px", display: "inline-block" }} onClick={() => SetQuantity()}>Set Quantity</MenuItem>
								<div className={classes.flexgridinputcontainer} style={{ width: "40px", display: "inline-block" }}>
									<input
										type="number" step="1"
										className={classes.flexgridinput}
										style={{ width: "40px", textAlign: "center" }}
										onChange={(event) => setNewQuantity(event.target.value)}
										defaultValue={newquantity} />
								</div>
								<br></br>
								<MenuItem style={{ width: "160px", display: "inline-block" }} onClick={() => SetCommissionRate()}>Set Commission Rate</MenuItem>
								<div className={classes.flexgridinputcontainer} style={{ width: "40px", display: "inline-block" }}>
									<input
										type="number" step="0.01"
										className={classes.flexgridinput}
										style={{ width: "40px", textAlign: "center" }}
										onChange={(event) => setNewCommissionRate(event.target.value)}
										defaultValue={newcommissionrate} />
								</div>

								<hr></hr>
								<div style={{ fontWeight: "bold", textAlign: "center" }}>Marketplace Status</div>
								<MenuItem onClick={() => SetActive()}>Set as Active</MenuItem>
								<MenuItem onClick={() => SetDeactive()}>Set as Deactive</MenuItem>
								<hr></hr>
								<div style={{ fontWeight: "bold", textAlign: "center" }}>Marketplace Verification</div>
								<MenuItem onClick={() => SetResolved()}>Set as Resolved</MenuItem>
								<MenuItem onClick={() => SetUnresolved()}>Set as Unresolved</MenuItem>
								<hr></hr>
								<div style={{ fontWeight: "bold", textAlign: "center" }}>Enable\Disable Integrations</div>
								<MenuItem onClick={() => SetEnabled()}>Set as Enabled</MenuItem>
								<MenuItem onClick={() => SetDisabled()}>Set as Disabled</MenuItem>
								<hr></hr>
								<MenuItem onClick={() => RejectIfInvalidSelected("", DeleteSelectedInit)}>Delete Sku Integrations</MenuItem>
							</Menu>


							<Button
								className={(userPerms.updateSkuIntegration === 1) ? classes.bluebtn : classes.hidden}
								color="primary" variant="contained"
								aria-haspopup="true"
								onClick={ShowUpdateMarketMenu}
								ref={el => btnMarketOperation.current = el}>
								Market Operation
							</Button>



							<Menu id="simple-menu"
								anchorEl={showupdatemarketmenu}
								keepMounted
								open={Boolean(showupdatemarketmenu)}
								onClose={CloseUpdateMarketMenu}>
								<div style={{ fontWeight: "bold", textAlign: "center" }}>Get Market Data</div>
								<MenuItem onClick={() => StartMarketOperation("fetchselected")}>Fetch Selected Skus</MenuItem>
								<MenuItem onClick={() => StartMarketOperation("fetchresults")}> Fetch Results - Limit {localstate.rowsperpage}</MenuItem>
								<hr></hr>
								<div style={{ fontWeight: "bold", textAlign: "center" }}>Set Market Data</div>
								<MenuItem onClick={() => StartMarketOperation("updateselected")}>Update Selected Skus</MenuItem>
								<MenuItem onClick={() => StartMarketOperation("updateresults")}>Update Results - Limit {localstate.rowsperpage}</MenuItem>
								<hr></hr>
								<div style={{ fontWeight: "bold", textAlign: "center" }}>Reset Failure Count</div>
								<MenuItem onClick={() => StartMarketOperation("resetselected")}>Reset Selected Skus</MenuItem>
								<MenuItem onClick={() => StartMarketOperation("resetresults")}>Reset Results - Limit {localstate.rowsperpage}</MenuItem>
							</Menu>



							<Button
								className={classes.bluebtn}
								color="primary" variant="contained"
								aria-haspopup="true"
								onClick={ShowExportMenu}
								ref={el => btnExport.current = el}>
								Export
							</Button>
							<Menu id="simple-menu"
								anchorEl={showexportmenu}
								keepMounted
								open={Boolean(showexportmenu)}
								onClose={CloseExportMenu}>
								<MenuItem onClick={() => InitExport("selected")}>Export Selected</MenuItem>
								<MenuItem onClick={() => InitExport("searchresults")}>Export All (limit 5000)</MenuItem>
							</Menu>

							<Button
								className={classes.bluebtn}
								color="primary" variant="contained"
								onClick={() => setShowSkuList(!showskulist)}
								ref={el => btnSkuList.current = el}>
								Sku List
							</Button>

							{(false) &&
								<React.Fragment>
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => AuthorizeAmazon()}>
										Authorize Amazon
									</Button>

									<a href="https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.00536522-8ce0-481f-a382-e22b7759ca2a&version=beta">

										<Button
											className={classes.bluebtn}
											color="primary" variant="contained"
										>
											Authorize Amazon Direct
										</Button>
									</a>
								</React.Fragment>
							}

							<Button
								className={classes.bluebtn}
								color="primary" variant="contained"
								onClick={() => ResetSearches()}
								ref={el => btnResetSearches.current = el}>
								<RestartAltIcon />&nbsp;Reset
							</Button>	

						
							<Button
								className={classes.bluebtn} style={{display:"none"}}
								color="primary" variant="contained"
								onClick={() => CancelUpdates()}
								ref={el => btnCancelUpdates.current = el}>
								Cancel Updates
							</Button>
							
							


						</React.Fragment>
					}

					{/* Delete Items Confirmation */}
					{(showdeleteconfirmation) &&
						<div>
							<b>Are you sure you want to delete these items?</b>
							<div style={{ padding: "10px 0px" }}>
								{deleteitems.map((row, index) => {
									if (deleteitems.length === index + 1) {
										return (<span key={index}>{row.Sku}</span>)
									} else {
										return (<span key={index}>{row.Sku}, </span>)
									}
								})}
							</div>
							<Button
								className={classes.bluebtn}
								color="primary" variant="contained"
								onClick={() => DeleteSelected()}>Yes, Delete Items</Button>&nbsp;&nbsp;
							<Button
								className={classes.bluebtn}
								color="primary" variant="contained"
								onClick={() => CancelDelete()}>Cancel</Button>
						</div>
					}

					{/* Export Mode Confirmation */}
					{(showexportconfirmation) &&
						<div>
							<b>Export Mode</b><br></br>
							In most cases a simple export is appropriate as to avoid revealing information to potential buyers. {exportmessage}
							<div style={{ paddingTop: "10px" }}>
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => PrepareExport("simple")}>
									Simple
								</Button>
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => PrepareExport("expanded")}>
									Expanded
								</Button>
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => PrepareExport("exhaustive")}>
									Exhaustive
								</Button>
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => PrepareExport("amazon")}>
									Amazon Feed
								</Button>
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => PrepareExport("ebay")}>
									Ebay Feed
								</Button>
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => PrepareExport("reebelo")}>
									Reebelo Export
								</Button>
								<Button
									className={classes.bluebtn}
									color="primary" variant="contained"
									onClick={() => setShowExportConfirmation(false)}>
									Cancel
								</Button>
							</div>
						</div>
					}


					{(localstate.totalitems > 0) &&
						<TablePagination className={classes.paginationalign}
							style={{ display: "inline-flex", float: "right" }}
							component="div"
							count={localstate.totalitems}
							page={localstate.page}
							onPageChange={handleChangePage}
							rowsPerPage={localstate.rowsperpage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							rowsPerPageOptions={[250, 500]}
						/>
					}
				</div>


				{/* End of Top Buttons & Pagination */}


				{/* Add container for overflow scroll bars - Allows us to set a min width for the page (better looking in many cases), and freeze controls at the top and bottom for ease of use. */}
				<div className={classes.flexgridcontainerA}>
					{/* ##########################  Start of Table  ########################## */}
					<table id="resizeMe" aria-label="caption table" size="small" className={classes.flexgrid} style={{ minWidth: "100%", borderCollapse: "collapse", borderColor: "grey" }}>
						<EnhancedTableHead
							numSelected={localstate.selectedcount}
							classes={classes}
							order={localstate.order}
							orderBy={localstate.orderby}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={state.griditems.length}
						/>
						{/* /* ##########################  Row Design  ########################## */}
						{/* If DB reload, don't allow view of table. */}
						{(!localstate.dbreload) &&
							<tbody style={{ display: "table-row-group" }}>
								{(localstate.griditems.length > 0) &&
									localstate.griditems.map((row, index) => {
										//Create all-new refs on each render. Helps avoid issues with grid states.
										rowRefs.current[index + "Checkbox"] = React.createRef();
										rowRefs.current[index + "SaveStatus"] = React.createRef();
										rowRefs.current[index + "Sku"] = React.createRef();
										rowRefs.current[index + "MarkupRate"] = React.createRef();
										rowRefs.current[index + "GlobalTGI"] = React.createRef();
										rowRefs.current[index + "CustomTGI"] = React.createRef();
										rowRefs.current[index + "GI"] = React.createRef();
										rowRefs.current[index + "CalcPrice"] = React.createRef();
										rowRefs.current[index + "Diff"] = React.createRef();
										rowRefs.current[index + "SetPrice"] = React.createRef();
										rowRefs.current[index + "Qty"] = React.createRef();
										rowRefs.current[index + "RefQty"] = React.createRef();
										rowRefs.current[index + "Active"] = React.createRef();
										rowRefs.current[index + "RefActive"] = React.createRef();
										rowRefs.current[index + "Resolved"] = React.createRef();
										rowRefs.current[index + "RefDate"] = React.createRef();
										rowRefs.current[index + "FailureCount"] = React.createRef();
										rowRefs.current[index + "Disabled"] = React.createRef();

										return (
											<React.Fragment key={row.ID}>
												<tr className={classes.flexgridrow}>
													{/* Checkbox - Requires inner div to change background color with SaveStatus */}
													<td style={{ verticalAlign: "top" }} ref={el => rowRefs.current[index + "SaveStatus"] = el}>
														<div style={{ padding: "3px 4px 1px 4px" }}>
															{/*	MaterialUI Checkbox will pass a shallow comparison between UpdateState(s). Be sure GridKey changes if it needs to be rerendered such as selectall.	*/}
															{/* Don't show checkbox if this is a PendingItem */}
															{(!row.PendingItem) &&
																<Checkbox
																	key={row.GridKey}
																	inputRef={el => rowRefs.current[index + "Checkbox"] = el}
																	className={classes.gridcheckbox}
																	color="default"
																	defaultChecked={localstate.griditems[index].isSelected ? true : false}
																	checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onKeyDown={(event) => HandleKeyDown(event, index, "Checkbox")}
																	onChange={() => SelectRow(index)}
																/>
															}

														</div>
													</td>

													{/* Sku */}
													{(colstate.Sku) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Sku}
															<ContentCopyIcon
																className={classes.hoverunit}
																style={{ fontSize: "14px", float: "right", margin: "3px 0px 0px 3px" }}
																onClick={() => CopyContent(row.Sku)} />
														</td>
													}

													{/* IntegrationName */}
													{(colstate.IntegrationName) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.integration.Name}
														</td>
													}

													{/* IntegrationIcon */}
													{(colstate.IntegrationIcon) &&
														<td style={{ textAlign: "center" }}>
															<img style={{ height: "20px", padding: "2px 2px 0px 2px" }} src={row.integration.IconURL} />
														</td>
													}


													{/* Cost */}
													{(colstate.Cost) &&
														<td className={classes.flexgridstaticcontainer} style={{ textAlign: "right" }}>
															{row.Cost}
														</td>
													}

													{/* CommRate - Removed Edit for Efficiency.
												{(colstate.CommissionRate) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															type="number" step="0.01"
															ref={el => rowRefs.current[index + "CommissionRate"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "50px", textAlign: "right" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "CommissionRate")}
															onKeyUp={(event) => onChangeValue(event, index, "CommissionRate")}
															onBlur={(event)=> DetectBlankNumber(event, index, "CommissionRate")}
															defaultValue={row.CommissionRate} />
													</td>
												}
												*/}


													{/* Commission Rate */}
													{(colstate.CommissionRate) &&
														<td className={classes.flexgridstaticcontainer} style={{ textAlign: "right" }}>
															{row.CommissionRate}
														</td>
													}


													{/* GIModifier - Takes input for: MarkupRate, GlobalTGI, and CustomTGI */}
													{(colstate.GIModifier) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																type="number" step="0.01"
																ref={el => rowRefs.current[index + "GIModifier"] = el}
																className={classes.flexgridinput}
																style={{ textAlign: "right" }}
																onKeyDown={(event) => HandleKeyDown(event, index, "GIModifier")}
																onKeyUp={(event) => onChangeValue(event, index, "GIModifier")}
																onBlur={(event) => DetectBlankNumber(event, index, "GIModifier")}
																defaultValue={row.GIModifier} />

														</td>
													}

													{/* GI PriceBy Method */}
													{(colstate.PriceBy) &&
														<td className={classes.flexgridstaticcontainer} style={{ textAlign: "center" }}>
															{row.PriceBy}


															{/* 
															Disabled due to performance issues with drop downs within grids.
															Possible fix: Enable bulk edits in this controller or break out single sku integration pages for granular edits.
															

															Notes:
															{/* <InputLabel id="demo-controlled-open-select-label">Optional Label</InputLabel> */}
															{/* Need: Set default value if row.SomeSelectable is null. */}
															{/* Why: Some of the values may be momentarily null for some reason - DB fetch, rerender? */}

															{/* Override icon prop class to our custom 'nodisplay' to remove arrowdown icon for this select */}
															{/* Override select prop class with new padding specs

															<FormControl className={classes.formControl}>
															<Select variant="standard"
																defaultValue={row.PriceBy ? row.PriceBy : "--"}
																disableUnderline
																onChange={(event) => onChangeValue(event, index, "PriceBy")}
																classes={{
																	//icon: classes.nodisplay,
																	select: classes.simpleselect
																}}
															>
																<MenuItem value="--">
																	<em>None</em>
																</MenuItem>
																<MenuItem value={"Markup"}>Markup</MenuItem>
																<MenuItem value={"GlobalTGI"}>GlobalTGI</MenuItem>
																<MenuItem value={"CustomTGI"}>CustomTGI</MenuItem>
															</Select>
														</FormControl>

													
														*/}



														</td>
													}

													{/* MarkupRate */}
													{(colstate.MarkupRate) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																type="number" step="0.01"
																ref={el => rowRefs.current[index + "MarkupRate"] = el}
																className={row.PriceBy === "CustomTGI" || row.PriceBy === "GlobalTGI" ? classes.flexgridinputdisabled : classes.flexgridinput}
																disabled={row.PriceBy === "CustomTGI" || row.PriceBy === "GlobalTGI"}
																style={{ textAlign: "right" }}
																onKeyDown={(event) => HandleKeyDown(event, index, "MarkupRate")}
																onKeyUp={(event) => onChangeValue(event, index, "MarkupRate")}
																onBlur={(event) => DetectBlankNumber(event, index, "MarkupRate")}
																defaultValue={row.MarkupRate} />
														</td>
													}

													{/* TargetGI */}
													{(colstate.TargetGI) &&
														<td style={{ padding: "0px 3px" }}>

															<input
																type="number" step="0.01"
																ref={el => rowRefs.current[index + "GlobalTGI"] = el}
																className={row.PriceBy === "CustomTGI" || row.PriceBy === "Markup" ? classes.flexgridinputdisabled : classes.flexgridinput}
																disabled={row.PriceBy === "CustomTGI" || row.PriceBy === "Markup"}
																style={{ width: "50px", textAlign: "right", display: "inline-block", marginLeft: "5px" }}
																onKeyDown={(event) => HandleKeyDown(event, index, "GlobalTGI")}
																onKeyUp={(event) => onChangeValue(event, index, "GlobalTGI")}
																onBlur={(event) => DetectBlankNumber(event, index, "GlobalTGI")}
																defaultValue={row.GlobalTGI} />

															<div style={{ display: "inline-block", width: "20px", textAlign: "center" }}>
																/
															</div>
															<input
																type="number" step="0.01"
																ref={el => rowRefs.current[index + "CustomTGI"] = el}
																className={row.PriceBy === "GlobalTGI" || row.PriceBy === "Markup" ? classes.flexgridinputdisabled : classes.flexgridinput}
																disabled={row.PriceBy === "GlobalTGI" || row.PriceBy === "Markup"}
																style={{ width: "50px", textAlign: "right", display: "inline-block" }}
																onKeyDown={(event) => HandleKeyDown(event, index, "CustomTGI")}
																onKeyUp={(event) => onChangeValue(event, index, "CustomTGI")}
																onBlur={(event) => DetectBlankNumber(event, index, "CustomTGI")}
																defaultValue={row.CustomTGI} />
														</td>
													}


													{/* CalcGI-old */}
													{(colstate.CalcGI_old) &&
														<td className={classes.flexgridstaticcontainer} ref={el => rowRefs.current[index + "CalcGI"] = el} style={{ textAlign: "right" }}>
															{(row.PriceBy === "Markup") &&
																<React.Fragment>
																	{(((1 + row.MarkupRate / 100) * row.Cost) - row.Cost).toFixed(2)}
																</React.Fragment>
															}
															{(row.PriceBy === "GlobalTGI") &&
																<React.Fragment>
																	{row.GlobalTGI}
																</React.Fragment>
															}
															{(row.PriceBy === "CustomTGI") &&
																<React.Fragment>
																	{row.CustomTGI}
																</React.Fragment>
															}
														</td>
													}

													{/* CalcGI */}
													{(colstate.CalcGI) &&
														<td className={classes.flexgridstaticcontainer} ref={el => rowRefs.current[index + "CalcGI"] = el} style={{ textAlign: "right" }}>
															{row.CalcGI}
														</td>
													}

													{/* AvgPrice  onMouseLeave={(event)=>handlePopoverClose(event, row.ID)}*/}
													{(colstate.AvgPrice) &&
														<React.Fragment>
															{(row.AvgPrice) &&
																<td className={row.RecentSale ? classes.lightyellow : classes.flexgridstaticcontainer} style={{ textAlign: "right" }}
																	onMouseEnter={(event) => handlePopoverOpen(event, row.ID)} onMouseLeave={(event) => handlePopoverClose(event, row.ID)}>
																	{row.AvgPrice}
																	<Popover className={classes.flexpopover} classes={{ paper: classes.flexpopovercontent }}
																		open={popoverid === row.ID} anchorEl={anchorEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
																		transformOrigin={{ vertical: 'top', horizontal: 'left' }}
																		onClose={(event) => handlePopoverClose(event, row.ID)} disableRestoreFocus
																	>
																		<Grid container style={{ fontWeight: "bold" }}>
																			<Grid item xs={3}>
																				Date
																			</Grid>
																			<Grid item xs={2} style={{ textAlign: "center" }}>
																				Qty
																			</Grid>
																			<Grid item xs={4}>
																				Market
																			</Grid>
																			<Grid item xs={3} style={{ textAlign: "right" }}>
																				Price Each
																			</Grid>
																		</Grid>
																		<hr></hr>
																		{row.orderitems.map((orderitem, index) => {
																			return (
																				<Grid container key={index}>
																					<Grid item xs={3}>
																						{orderitem.order.OrderDate.substring(10, 0)}
																					</Grid>
																					<Grid item xs={2} style={{ textAlign: "center" }}>
																						{orderitem.Quantity}
																					</Grid>
																					<Grid item xs={4}>
																						{orderitem.order.Store}
																					</Grid>
																					<Grid item xs={3} style={{ textAlign: "right" }}>
																						{orderitem.UnitPrice}
																					</Grid>
																				</Grid>
																			)
																		})}

																	</Popover>
																</td>

															}
															{(!row.AvgPrice) &&
																<td className={classes.flexgridstaticcontainer} style={{ textAlign: "right" }}>

																</td>
															}
														</React.Fragment>


													}


													{/* CalcPrice */}
													{(colstate.CalcPrice) &&
														<td className={classes.flexgridstaticcontainer} ref={el => rowRefs.current[index + "CalcPrice"] = el} style={{ textAlign: "right" }}>
															{row.CalcPrice}
														</td>
													}


													{/* Diff - Use Diff from LoadItems function to initiate class */}
													{(colstate.Diff) &&
														<td className={classes.flexgridstaticcontainer + " " + (row.Diff > 0 ? classes.lightred : (row.Diff === "0.00" ? "" : classes.lightgreen))}
															ref={el => rowRefs.current[index + "Diff"] = el}
															style={{ textAlign: "right" }}>
															{row.Diff}
														</td>
													}


													{/* SetPrice */}
													{(colstate.SetPrice) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																type="number" step="0.01"
																ref={el => rowRefs.current[index + "SetPrice"] = el}
																className={classes.flexgridinput}
																style={{ textAlign: "right" }}
																onKeyDown={(event) => HandleKeyDown(event, index, "SetPrice")}
																onKeyUp={(event) => onChangeValue(event, index, "SetPrice")}
																onBlur={(event) => DetectBlankNumber(event, index, "SetPrice")}
																defaultValue={row.SetPrice} />
														</td>
													}


													{/* RefPrice */}
													{(colstate.RefPrice) &&
														<td className={classes.flexgridstaticcontainer} style={{ textAlign: "right" }}>
															<span ref={el => rowRefs.current[index + "RefPrice"] = el}>
																{row.RefPrice}
															</span>
														</td>
													}


													{/* Qty (SetQty) */}
													{(colstate.Qty) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																type="number" step="1"
																ref={el => rowRefs.current[index + "Qty"] = el}
																className={classes.flexgridinput}
																style={{ textAlign: "right" }}
																onKeyDown={(event) => HandleKeyDown(event, index, "Qty")}
																onKeyUp={(event) => onChangeValue(event, index, "Qty")}
																onBlur={(event) => DetectBlankNumber(event, index, "Qty")}
																defaultValue={row.Qty} />
														</td>
													}


													{/* RefQty */}
													{(colstate.RefQty) &&
														<td className={classes.flexgridstaticcontainer} style={{ textAlign: "right" }}>
															<span ref={el => rowRefs.current[index + "RefQty"] = el}>
																{row.RefQty}
															</span>
														</td>
													}


													{/* Active */}
													{(colstate.Active) &&
														<td style={{ textAlign: "center" }} ref={el => rowRefs.current[index + "Active"] = el}
															className={row.Active === row.RefActive ? (row.Active === 1 ? classes.lightgreen : classes.lightyellow) : classes.lightred}>
															{(row.Active === 0) &&
																<React.Fragment>No</React.Fragment>
															}
															{(row.Active === 1) &&
																<React.Fragment>Yes</React.Fragment>
															}
														</td>
													}



													{/* RefActive */}
													{(colstate.RefActive) &&
														<td style={{ textAlign: "center" }} ref={el => rowRefs.current[index + "RefActive"] = el}
															className={row.Active === row.RefActive ? (row.RefActive === 1 ? classes.lightgreen : classes.lightyellow) : classes.lightred}>
															{(row.RefActive === 0) &&
																<React.Fragment>No</React.Fragment>
															}
															{(row.RefActive === 1) &&
																<React.Fragment>Yes</React.Fragment>
															}
														</td>
													}



													{/* Resolved */}
													{(colstate.Resolved) &&
														<td style={{ textAlign: "center" }} ref={el => rowRefs.current[index + "Resolved"] = el}
															className={row.Resolved === 1 ? classes.lightgreen : classes.lightred}>
															{(row.Resolved === 0) &&
																<React.Fragment>No</React.Fragment>
															}
															{(row.Resolved === 1) &&
																<React.Fragment>Yes</React.Fragment>
															}
														</td>
													}

													{/* BMBuyBox */}
													{(colstate.bmbuybox) &&
														<td style={{ textAlign: "center" }}
															className={row.bmbuybox === 1 ? classes.lightgreen : classes.lightred}>
															{(row.bmbuybox === 0) &&
																<React.Fragment>No</React.Fragment>
															}
															{(row.bmbuybox === 1) &&
																<React.Fragment>Yes</React.Fragment>
															}
															&nbsp;-&nbsp;
															{row.bmbbprice}
															&nbsp;	&#40;
															{(row.bmbbprice - row.SetPrice).toFixed(2)}
															&#41;
														</td>
													}


													{/* BM condition - buy box condition */}
													{(colstate.bmcondition) &&
														<td className={classes.flexgridstaticcontainer} style={{ textAlign: "right" }}>
															{row.bmcondition}
														</td>
													}

													{/* BM Last Update */}
													{(colstate.bmlast_update) &&
														<td className={classes.flexgridstaticcontainer} style={{ textAlign: "right" }}>
															{(row.bmlast_update) &&
																<React.Fragment>
																	{row.bmlast_update.substring(0, 10)}
																</React.Fragment>
															}
														</td>
													}


													{/* Ref Date */}
													{(colstate.RefDate) &&
														<td className={classes.flexgridstaticcontainer} style={{ textAlign: "right" }} ref={el => rowRefs.current[index + "RefDate"] = el}>
															{(row.RefDate) &&
																<React.Fragment>
																	{dayjs(row.RefDate).isValid() ? dayjs(row.RefDate).format("YYYY-MM-DD H:m") : ""}
																</React.Fragment>
															}
														</td>
													}


													{/* updated_at */}
													{(colstate.updated_at) &&
														<td className={classes.flexgridstaticcontainer} style={{ textAlign: "right" }}>
															{(row.updated_at) &&
																<React.Fragment>
																	{dayjs(row.updated_at).isValid() ? dayjs(row.updated_at).format("YYYY-MM-DD H:m") : ""}
																</React.Fragment>
															}
														</td>
													}

													{/* UpdateStatus */}
													{(colstate.UpdateStatus) &&
														<td className={classes.flexgridstaticcontainer} style={{ textAlign: "center" }} ref={el => rowRefs.current[index + "UpdateStatus"] = el}>
															{(row.UpdateStatus === "Failed") &&
																<React.Fragment>
																	Failed: {row.FailureMessage}
																</React.Fragment>
															}
															{(row.UpdateStatus === "OK") &&
																<React.Fragment>
																	OK
																</React.Fragment>
															}
														</td>
													}

													{/* FailureCount */}
													{(colstate.FailureCount) &&
														<td className={classes.flexgridstaticcontainer} style={{ textAlign: "center" }} ref={el => rowRefs.current[index + "FailureCount"] = el}>
															{row.FailureCount}
														</td>
													}

													{/* Disabled */}
													{(colstate.Disabled) &&
														<td className={classes.flexgridstaticcontainer} style={{ textAlign: "center" }} ref={el => rowRefs.current[index + "Disabled"] = el}>
															{(row.Disabled === 0) &&
																<React.Fragment>No</React.Fragment>
															}
															{(row.Disabled === 1) &&
																<React.Fragment>Yes</React.Fragment>
															}
														</td>
													}


												</tr>
											</React.Fragment>
										)
									}
									)
								}
								{(localstate.griditems.length === 0) &&
									<tr className="flexgridrow"><td colSpan="100%"
										style={{ padding: "12px", fontSize: "18px" }}>No Results</td></tr>
								}
							</tbody>
						}
						{(localstate.dbreload) &&
							<tbody>
								<tr>
									<td colSpan="100%">
										<div style={{ padding: "20px", textAlign: "center", margin: "auto" }}>
											<CircularProgress />
										</div>
									</td>
								</tr>
							</tbody>
						}
					</table>
				</div>




				{(localstate.rowsperpage > 249 && localstate.totalitems > 250) &&
					<TablePagination className={classes.paginationalign}
						component="div"
						count={localstate.totalitems}
						page={localstate.page}
						onPageChange={handleChangePage}
						rowsPerPage={localstate.rowsperpage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[250, 500]}
					/>
				}

				<div>
					Markup vs TGI: Markup is based on a percentage markup on cost. TGI - Target Gross Income is a fixed amount.
					Client/Server update lifecycle: Set items to unresolved. Successful updates will assume resolution of item, no fetch needed to confirm resolution. <br></br>
					Too many failures will also result in a resolution of the item and the Update Status will reflect the error given.<br></br><br></br>
					Failure counts over 4 will result in automatic resolution and will cause server updates to ignore the item - this is a safety feature of the<br></br>
					system as API failures may cause account level issues (suspension of API access).
				</div>



			</div>
		</LocalizationProvider>
		

	);
}

export default SkuIntegrationsTable;

import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './features/counter/counterSlice';
import mainmenuReducer from './features/mainmenu/mainmenuSlice';
import progressbarReducer from './features/progressbar/progressbarSlice';
import errormessageReducer from './features/error/errormessageSlice';
import flexDocumentReducer from './features/document/flexDocumentSlice';
//import inspectionReducer from './features/orders/inspectionSlice';

export default configureStore({
  reducer: {
	counter:counterReducer,
	mainmenu:mainmenuReducer,
	progressbar:progressbarReducer,
	errormessage:errormessageReducer,
	document:flexDocumentReducer,
	//inspection:inspectionReducer

  },
})
//Boilerplate Table

/* ##########################  Configuration Sections  ########################## */
//## UseState Variables
//## Column States
//##Column Configuration
//##Column Toggles
//##Row Design
//##Search Inputs
//##Button Functions

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";

import React, { useState, useEffect, useContext, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";

import { useDispatch } from 'react-redux';
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//Error Context
//*Can be used for success as well!
//Types: ok, warning, danger, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//Search Tools
import CircularProgress from '@mui/material/CircularProgress';


//Tables
import TableSortLabel from '@mui/material/TableSortLabel';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import TextareaAutosize from '@mui/material/TextareaAutosize';

//Export
import ExportCSV from '../common/ExportCSV';


/* ##########################  Configuration  ########################## */

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 360000,
};

//Helper Functions
//Have not used sleep just yet - is currently on auto-complete sample
function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}


const Costing = (props) => {
	document.title = "Costing";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Inventory"));
	dispatch(setCurrentMenuItem("/pricing"));


	const rowRefs = useRef([]);
	const btnSave = useRef();

	/* ##########################  UseState Variables  ########################## */
	const classes = useClasses(flexstyles);
	const [state, setState] = useState({
		dbreload: false, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		clearselection: true,//Default clear selection everytime we reload DB. Continuous bulk edits may set this to false between updates.
		griditems: [],		//Defaults
		totalitems: 0,
		page: 0, //Assume page 0, or else pagination throws an error.
		order: "asc",
		orderby: "SerialNumber",
		selectedcount: 0,
		rowsperpage: 500,
		selectedindexes: [],
		pendingsaves: false, //Used for parent view - Warnings about unsaved items!
		searchoptions: {
			//New! Key-Value pair array. Easier to itterate in API.
			searchpairs: {
				//Reserved for basic searchpairs.
				searchpair1: { type: "SerialNumber", value: "", mode: "like", uuid: uuidv4() },
				searchpair2: { type: "Model", value: "", mode: "like", uuid: uuidv4() },
				searchpair3: { type: "", value: "", mode: "left", uuid: uuidv4() },
				searchpair4: { type: "", value: "", mode: "right", uuid: uuidv4() },
				searchpair5: { type: "", value: "", mode: "like", uuid: uuidv4() },
				searchpair6: { type: "", value: "", mode: "like", uuid: uuidv4() },
				//Reserved for AutoComplete searchpairs.
				searchpair7: { type: "", value: "", mode: "strict", uuid: uuidv4() },
				searchpair8: { type: "", value: "", mode: "strict", uuid: uuidv4() },
				searchpair9: { type: "", value: "", mode: "strict", uuid: uuidv4() },
				searchpair10: { type: "", value: "", mode: "strict", uuid: uuidv4() },
			},
			nestedrelationships: {

			}
		}
	});

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}


	/* ##########################  Column States  ########################## */
	//Used for hiding/showing columns. Can access using bracket notation later on! colstate[headCell.id]
	const [colstate, setColState] = useState({
		SerialNumber: true,
		Model: true, //If found
		Cost: true,
		OldCost: true,
		Spacer: true
	});




	/* ##########################  Menus  ########################## */

	/* Column Menu */
	const [showcolumnmenu, setColumnMenu] = useState(null);

	const ShowColumnMenu = (event) => {
		setColumnMenu(event.currentTarget);
	}
	const CloseColumnMenu = () => {
		setColumnMenu(null);
	}
	const ToggleColumn = (key) => {
		colstate[key] = !colstate[key];
		CloseColumnMenu();
	}



	/* ##########################  Selected Rows  ########################## */
	//Explanation: Since we're using <Checkbox> from materialui in an uncontrolled manner (avoiding state, avoiding rerenders...)
	//we are assuming the only way to show a "checked" value of the checkbox is by a user clicking the actual checkbox.

	//Simply using rowRefs.current[index+"Checkbox"].checked can be set to "true", but the view WILL NOT REFLECT THE CHANGE.
	//So for manual clicks on <Checkbox> we register in localstate
	//For SelectAll, we have to run through all items and set isSelected, then rerender.
	//Non-direct interaction with checkbox components will result in difficulty trying to access it through some kind
	//of ref/DOM manipulation. Therefore we pass changes to these components through state changes, mutating localstate.

	const SelectRow = (index) => {
		if (localstate.selectedindexes.indexOf(index) === -1) {
			localstate.selectedindexes.push(index);
			//Check for condition that would check Select All Checkbox - Rerender
			if (localstate.griditems.length === localstate.selectedindexes.length) {
				UpdateState(localstate);
			}
		} else {
			var spliceindex = localstate.selectedindexes.indexOf(index);
			localstate.selectedindexes.splice(spliceindex, 1);
			//Check for condition that would un-check Select All Checkbox, just 1 less will do - Rerender
			if (localstate.griditems.length === (localstate.selectedindexes.length + 1)) {
				UpdateState(localstate);
			}
		}

		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length === 0) {
			setShowExportConfirmation(false);
		}
	}

	const handleSelectAllClick = (event) => {
		//Material UI Checkbox Component won't rerender unless we force it. Set a changed GridKey so that shallow comparison fails.
		var i = 0;
		if (event.target.checked) {
			localstate.selectedindexes = [];
			for (i = 0; i < localstate.griditems.length; i++) {
				localstate.griditems[i].isSelected = true;
				localstate.selectedindexes.push(i);
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		} else {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
			for (i = 0; i < localstate.griditems.length; i++) {
				localstate.griditems[i].isSelected = false;
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		}
		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length === 0) {
			setShowExportConfirmation(false);
		}
	};



	/* ##########################  Search Options  ########################## */


	// Returns a function, that, as long as it continues to be invoked, will not
	// be triggered. The function will be called after it stops being called for
	// N milliseconds. If `immediate` is passed, trigger the function on the
	// leading edge, instead of the trailing.
	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};


	//API Build-out: 
	//	Old: Types [searchtype, sub1type, sub2type] , Search [search, sub1search, sub2search]
	//	New: Types [searchtype1, searchtype2, searchtype3], Search [search1, search2, search3]
	//Set Search Key:
	var mode = "";
	const onChangeSearchType = (searchtype, searchnumber) => {
		//Search Mode: Each search type may have a different search mode
		//left, right, like, strict, not
		//Default Mode: LIKE
		mode = "like";
		//Provision for automatically switching search mode
		if (searchtype === "Location") {
			mode = "strict";
		}

		//Not Conditionals: Convert NotName to Name in BasicTableController
		if (searchtype === "NotName") {
			mode = "not"
		}
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].type = searchtype;
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].mode = mode;
		//Provision to add columns if selected for search.
		if (searchtype === "Name") {
			colstate["Name"] = true;
			setColumnMenu(null);
		}
		UpdateState(localstate);
	}

	//Set Search Value:
	const onChangeSearchValue = debounce(function (searchvalue, searchnumber) {
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].value = searchvalue;
		localstate.dbreload = true;
		UpdateState(localstate);
	}, 600);

	//Key-Value Inputs
	const [searchinputs, setSearchInputs] = useState({
		show1: true,
		show2: true,
		show3: false,
		show4: false,
		show5: false,
		show6: false,
		lastsearch: 2
	});


	const AddSearch = () => {
		let newsearchinputs = Object.assign({}, searchinputs);
		newsearchinputs["show" + (newsearchinputs.lastsearch + 1)] = true;
		newsearchinputs.lastsearch++;
		setSearchInputs(newsearchinputs);
	}

	const RemoveSearch = () => {
		let newsearchinputs = Object.assign({}, searchinputs);
		newsearchinputs["show" + (newsearchinputs.lastsearch)] = false;
		newsearchinputs.lastsearch--;
		setSearchInputs(newsearchinputs);
	}




	/* ##########################  Loading and Page Changes  ########################## */
	const handleRequestSort = (event, property) => {
		const isAsc = localstate.orderby === property && localstate.order === "asc";
		localstate.order = (isAsc ? "desc" : "asc");
		localstate.orderby = property;
		localstate.dbreload = true;
		localstate.pendingsaves = false;
		UpdateState(localstate);
	};

	const handleChangePage = (event, newPage) => {
		localstate.pendingsaves = false;
		localstate.dbreload = true;
		localstate.page = newPage;
		UpdateState(localstate);
	};

	const handleChangeRowsPerPage = (event) => {
		localstate.pendingsaves = false;
		localstate.dbreload = true;
		localstate.rowsperpage = parseInt(event.target.value, 10);
		localstate.page = 0;
		UpdateState(localstate);
	};

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Items
	function LoadItems() {
		//Prepare fetch array from itemcosting string:
		var rows = itemcosting.split("\n");
		var rowvalues = "";
		var costing = [];
		for (var i = 0; i < rows.length; i++) {
			rowvalues = "";
			if (rows[i] !== "") {
				rowvalues = rows[i].split("\t");
				//Test if cost exists:
				if (rowvalues[1] !== "") {
					costing.push({
						SerialNumber: rowvalues[0],
						Cost: rowvalues[1]
					})
				}
				//console.log(rowvalues);
			}
		}

		//Sort costing:
		//localstate.order:"asc",
		//localstate.orderby:"SerialNumber"
		costing.sort(function (a, b) {
			//Numeric Values
			if (localstate.orderby === 'Cost') {
				return a[localstate.orderby] - b[localstate.orderby];
			} else {
				//Alphabetic
				if (a[localstate.orderby] < b[localstate.orderby]) { return -1; }
				if (a[localstate.orderby] > b[localstate.orderby]) { return 1; }
				return 0;
			}
		});
		//Reverse if needed
		if (localstate.order === "desc") {
			costing.reverse();
		}


		if (costing.length > 0) {
			//Fetch serials from DB:
			const postdata = {
				costing: costing
			};

			axios.post(dbendpoint + "/items/costing", postdata, defaultpostoptions).then(res => {
				//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
				if (res.status === 200) {
					//If ValidateUser() fails to verify user, it sends back 'login' error. 
					if (res.data.Status === "login") {
						//Not logged in. Reload page causes redirect to /login
						window.location.reload(false);
					}
					//All new API calls should return a status.
					if (res.data.Status === "Success") {
						//We should now have a non-0 result from the API
						//Add variables for use with table
						var resultdata = res.data.items;
						for (var i = 0; i < resultdata.length; i++) {
							//Try: GridKey - Apply GridKey to components: key={row.GridKey}
							//Try: Increment GridKey between rerenders; ie: UpdateState(localstate);
							resultdata[i].GridKey = i;
							//resultdata[i].unsaved = false;
							resultdata[i].ExpandRow = false;
						}

						//Find duplicates?
						var testvalue = "";
						var j = 0;
						var unsavedfound = false;
						//Loops through each row
						for (i = 0; i < resultdata.length; i++) {
							//Test on remaingvalues:
							for (j = 0; j < resultdata.length; j++) {
								if (i !== j) {
									console.log("Serial: " + resultdata[i].SerialNumber + "  \\  " + resultdata[j].SerialNumber);
									if (resultdata[i].SerialNumber === resultdata[j].SerialNumber) {
										console.log("Duplicate found!");
										resultdata[j].Duplicate = resultdata[i].Duplicate = true;
									}
								}
							}
							//Test for any potential saves
							if (resultdata[i].unsaved && !unsavedfound) {
								btnSave.current.style.color = "white";
								btnSave.current.style.backgroundColor = "#01579B";
								localstate.pendingsaves = true;
								//Avoid future changes
								unsavedfound = true;
							}
						}

						//Eliminate duplicates
						const ids = resultdata.map(o => o.ItemID);
						const filtered = resultdata.filter(({ ItemID }, index) => !ids.includes(ItemID, index + 1));



						localstate.griditems = filtered;
						localstate.totalitems = res.data.items.length;
						//Data freshly loaded, head off any new requests with this state change. Handle in useEffect?
						localstate.dbreload = false;
						UpdateState(localstate);
					}
					if (res.data.Status === "Failure") {
						//Failure error
						localstate.griditems = [];
						UpdateState(localstate);
						errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
					}
				} else {
					//Non-200 message from server.
					errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" });
					localstate.dbreload = false;
					UpdateState(localstate);
				}
			});

		} else {
			errors.NewError({ errmsg: "Serials and costs could not be parsed.", errshow: true, errtimeout: 5, errtype: "warning" });
			localstate.dbreload = false;
			UpdateState(localstate);
		}


		//localstate.griditems = griditems;
		//localstate.dbreload=false;
		//UpdateState(localstate);
		//console.log(rows);
	}



	/* This page acts differently. Replace this:
	function LoadItems(){  
		if (localstate.clearselection){
			localstate.selectedindexes = [];
			localstate.selectedcount=0;
		} else {
			//Reset to clear selections on subsequent requests
			localstate.clearselection=true;
		}
		const postdata = {
			searchoptions:{
				limit:localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				searchpairs:localstate.searchoptions.searchpairs,
				nestedrelationships:localstate.searchoptions.nestedrelationships
			}
		};
		axios.post(dbendpoint+"/basictable?page="+(localstate.page+1), postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==="login"){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status==="Success"){
					//We should now have a non-0 result from the API
					//Add variables for use with table
					var resultdata = res.data.pagedata.data;
					for (var i =0; i<resultdata.length; i++){
						//Try: GridKey - Apply GridKey to components: key={row.GridKey}
						//Try: Increment GridKey between rerenders; ie: UpdateState(localstate);
						resultdata[i].GridKey=i;
						resultdata[i].unsaved = false;
						resultdata[i].ExpandRow = false;
						if (localstate.selectedindexes.includes(i)){
							resultdata[i].isSelected = true;
						} else {
							resultdata[i].isSelected = false;
						}
					}
					localstate.griditems = resultdata;
					localstate.totalitems = res.data.pagedata.total;
					//Data freshly loaded, head off any new requests with this state change. Handle in useEffect?
					//localstate.dbreload = false;
					UpdateState(localstate);
				}
				if (res.data.Status==="Failure"){
					//Failure error
					localstate.griditems=[];
					UpdateState(localstate);
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	} */

	useEffect(() => {
		document.title = "Item Costing";
		if (state.dbreload) {
			//Avoid duplicate loades.
			localstate.dbreload = false;
			LoadItems();
		} else {
			//console.log("Ignore DB Reload.");
		}

		//Apply unsaved highlight to items:
		/* Replace with ternary on mapped grid item unsaved state coming from DB.
		setTimeout(function(){ 
			for (var i=0; i<localstate.griditems.length; i++){
				if (localstate.griditems[i].unsaved){
					rowRefs.current[i+"SaveStatus"].classList.add(classes.unsavedhighlight);
				}
			}
		 }, 1000);
		 */

	},);



	/* ##########################  CRUD  ########################## */

	//New Row adds property 'PendingItem' for use in the API to add such rows.
	const AddRow = () => {
		localstate.griditems.unshift({
			ID: uuidv4(),
			Name: "",
			PendingItem: true,
			Cost: 0.00,
			Price: 0.00,
			Margin: 0.00,
			SomeBoolean: 0,
			SomeSelectable: "",
			NewCheckbox: false
		});
		//All selected indexes move up by 1.
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			localstate.selectedindexes[i] += 1;
		}
		UpdateState(localstate);
	}

	const SaveChanges = () => {
		//Clean up current errors:
		errors.HideError(errors);
		
		var updatearray = [];
		for (var i = 0; i < localstate.griditems.length; i++) {
			if (localstate.griditems[i].unsaved) {
				localstate.griditems[i]['Costing'] = true; //Send key so that the system doesn't try to set new audit date - costing does not equal auditing.
				updatearray.push(localstate.griditems[i]);
			}
		}
		if (localstate.pendingsaves) {
			if (updatearray.length > 0) {
				i = 0;
				var limit = updatearray.length - 1;
				var updateitems = setInterval(function () {
					var item = updatearray[i];
					//Do work here, API call.
					const postdata = {
						item: item
					};
					axios.post(dbendpoint + "/items/updateitem", postdata, defaultpostoptions).then(res => {
						//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
						if (res.status === 200) {
							//If ValidateUser() fails to verify user, it sends back 'login' error. 
							if (res.data.Status === "login") {
								//Not logged in. Reload page causes redirect to /login
								window.location.reload(false);
							}
							if (res.data.Status === "Success") {
								//Success response also includes the item!
								//If we sent new rows, we'll need to reference the old ID.
								var itemindex = 0;
								if (res.data.OldID) {
									itemindex = localstate.griditems.map(function (o) { return o.ItemID; }).indexOf(res.data.OldID);
									localstate.griditems[itemindex].unsaved = false;
									rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
									//Set New ID
									localstate.griditems[itemindex].ItemID = res.data.item.ItemID;
								} else {
									itemindex = localstate.griditems.map(function (o) { return o.ItemID; }).indexOf(res.data.item.ItemID);
									localstate.griditems[itemindex].unsaved = false;
									//Refs allow us to update the grid live!
									rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								}
							}
							if (res.data.Status === "Failure") {
								//Failure error
								errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "warning" })
							}
						} else {
							//Non-200 message from server.
							errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
						}
					});
					//If we have completed all items, clear this interval and update state.
					if (i === limit) {
						localstate.pendingsaves = false;
						clearInterval(updateitems);
						UpdateState(localstate);
					}
					i++;
				}, 200);
			}
			btnSave.current.style.color = "rgba(0, 0, 0, 0.87)";
			btnSave.current.style.backgroundColor = "#DFDFDF";
		} else {
			alert("Nothing to save!");
		}
	}


	//Delete Confirmation and Deletion
	const [showdeleteconfirmation, setShowDeleteConfirmation] = useState(false);
	const [deleteitems, setDeleteItems] = useState([]);
	const DeleteSelectedInit = () => {
		var deleteitemsarray = [];
		//Reflect items to user for confimation.
		for (var i = 0; i < state.selectedindexes.length; i++) {
			deleteitemsarray.push(localstate.griditems[state.selectedindexes[i]]);
		}
		setDeleteItems(deleteitemsarray);
		setShowDeleteConfirmation(true);
	}

	const DeleteSelected = () => {
		var finishedrequests = 0;
		for (var i = 0; i < localstate.selectedindexes.length; i++) {
			if (localstate.griditems[localstate.selectedindexes[i]].hasOwnProperty("PendingItem")) {
				//Pending items are simply removed from the view and forgotten.
				localstate.griditems.splice(localstate.selectedindexes[i], 1);
				//Count as finished request
				finishedrequests++;
			} else {
				//Make Delete request to DB
				const postdata = {
					item: localstate.griditems[localstate.selectedindexes[i]]
				};
				axios.post(dbendpoint + "/basictable/delete", postdata, defaultpostoptions).then(res => {
					//No matter the response, we consider the result as a 'finished request'. We can then properly do clean-up.
					finishedrequests++;
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status === 200) {
						//If ValidateUser() fails to verify user, it sends back "login" error. 
						if (res.data.Status === "login") {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status === "Success") {
							//Success response also includes the item!
							//If we're pulling the item out of grid items, we'll use the ID of the item for reference.
							if (res.data.OldID) {
								//Since griditems state can be reloaded anytime, we look for the indexOf the ID
								var itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems.splice(itemindex, 1);
							} else {
								errors.NewError({ errmsg: "Could not delete one or more items.", errshow: true, errtimeout: 8, errtype: "warning" })
							}
						}
						if (res.data.Status === "Failure") {
							//Failure error
							errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
						}
					} else {
						//Non-200 message from server.
						errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
					}
					//After result from last request, do cleanup.
					if (finishedrequests === localstate.selectedindexes.length) {
						//Clear out all selections! Since checkboxes are controlled only by grid items index, we don't have good
						//tracking on which items are which.
						localstate.selectedindexes = [];
						localstate.selectedcount = 0;
						UpdateState(localstate);
						setShowDeleteConfirmation(false);
					}
				});
			}
		}
	}
	const CancelDelete = () => {
		setShowDeleteConfirmation(false);
	}


	/* ##########################  Cell Interaction  ########################## */


	//Tab Order: Used for horizontal tabbing below.
	//We need an order for horizontal tabbing - attempting to tab to an unavailable column (like a disabled column via colstate) will result in an error
	const taborder = ["SerialNumber", "Cost"];


	//Excel-like functionality for grid
	const HandleKeyDown = (event, index, column) => {
		//Handle Tabs!
		if (event.key === "Tab") {
			event.preventDefault();
			//Vertical VS Horizontal Tabbing

			//Vertical Tabbing - Checkboxes, Gross Income, Rates
			//Vertical Tabbing is never subject to the next column not being shown (we always to back to record #1 instead!)
			if (column === "Checkbox") {  //Insert each type of column you want vertically tabbed here: if (column==="Checkbox" || column==="Margin"){ etc
				//If the next row ref exists....
				if (rowRefs.current[(index + 1) + column]) {
					rowRefs.current[(index + 1) + column].focus();
				} else {
					//Go to first element
					rowRefs.current[("0" + column)].focus();
				}
			} else {
				//Horizontal Tabbing - Row Data
				//Horizontal Tabbing is subject to certain columns not being available for selection. (colstate)

				//Get index within tab order:
				var tabindex = taborder.indexOf(column);
				//Increase index until we find the next tab order column
				for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
					//If we're at the last column element, go to the next row's first available column element
					if (i === taborder.length) {
						//Start at beginning of row tab order and reitterate
						i = -1;
						//If next row exists:
						if (rowRefs.current[(index + 1) + column]) {
							index = index + 1;
						} else {
							//Go to first row.
							index = 0;
						}
					} else {
						//If there is another elemet in the taborder.... Continue. Else, go to first column.
						if (taborder[i]) {
							//If that next element is available
							if (colstate[taborder[i]]) {
								rowRefs.current[index + taborder[i]].select();
								break;
							}
						} else {
							//Start at beginning of row tab order and reitterate
							i = -1;
						}
					}
				}
			}
		}
		//Handle Down Arrow
		if (event.key === "ArrowDown") {
			event.preventDefault();
			if (rowRefs.current[(index + 1) + column]) {
				rowRefs.current[(index + 1) + column].select();
			} else {
				//Go to first element
				rowRefs.current[("0" + column)].select();
			}
		}
		//Handle Up Arrow
		if (event.key === "ArrowUp") {
			event.preventDefault();
			if (rowRefs.current[(index - 1) + column]) {
				rowRefs.current[(index - 1) + column].select();
			} else {
				//Go to last element
				var lastelement = localstate.griditems.length - 1;
				rowRefs.current[(lastelement + column)].select();
			}
		}
	}

	//Restrict Number (too many places past the decimal)
	const RestrictNumber = (newvalue, oldvalue) => {
		var len = newvalue.length;
		var index = newvalue.indexOf('.');
		if (index > 0) {
			var decimalchars = (len - 1) - index;
			if (decimalchars > 2) {
				return oldvalue;
			}
		}
		return newvalue;
	}
	const DetectBlankNumber = (event, index, column) => {
		if (event.target.value === "") {
			rowRefs.current[index + column].value = "0.00";
			localstate.griditems[index][column] = "0.00";
		}
	}

	//Catch-All Method.
	const onChangeValue = (event, index, column) => {
		var oldvalue = localstate.griditems[index][column];
		var newvalue = event.target.value;
		if (event.key !== "Tab" &&
			event.key !== "ArrowDown" &&
			event.key !== "ArrowUp" &&
			event.key !== "ArrowLeft" &&
			event.key !== "ArrowUp" &&
			event.key !== "ArrowRight" &&
			event.key !== "ShiftLeft" &&
			event.key !== "ShiftRight"
		) {
			//Conditionals for Types:
			if (column === "Cost") {
				newvalue = RestrictNumber(newvalue, oldvalue);
			}

			//Provision for Booleans that require a re-render. Expensive!
			if (column === "SomeBoolean") {
				if (event.target.checked) {
					console.log("Checked = true");
					localstate.griditems[index][column] = 1;
					UpdateState(localstate);
				} else {
					console.log("Checked = false");
					localstate.griditems[index][column] = 0;
					UpdateState(localstate);
				}

			} else {
				//Selects already render the correct and unsaved result of selecting. (but right now they cause rerender via state.... possible change here.)
				if (column === "NewCheckbox") { //MaterialUI Checkbox
					if (event.target.checked) {
						localstate.griditems[index][column] = 1;
					} else {
						localstate.griditems[index][column] = 0;
					}
				} else if (column === "SomeSelectable") {
					//Status works similar to booleans - Component takes care of view while localstate has yet to update the DB
					localstate.griditems[index][column] = newvalue;
				} else {
					//Update Refs like usual.
					rowRefs.current[index + column].value = newvalue;
					localstate.griditems[index][column] = newvalue;
				}
			}
			rowRefs.current[index + "SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[index].unsaved = true;
			localstate.pendingsaves = true;
			btnSave.current.style.color = "white";
			btnSave.current.style.backgroundColor = "#01579B";
		}
	}


	/* ##########################  Button Functions  ########################## */

	const ExpandRowToggle = (index) => {
		localstate.griditems[index].ExpandRow = !localstate.griditems[index].ExpandRow;
		UpdateState(localstate);
	}

	const ExpandAll = () => {
		for (var i = 0; i < localstate.griditems.length; i++) {
			localstate.griditems[i].ExpandRow = true;
		}
		UpdateState(localstate);
	}

	const ResetSearches = () => {
		//Reset all:
		for (var i = 1; i < 7; i++) {
			localstate.searchoptions.searchpairs["searchpair" + i].type = "";
			localstate.searchoptions.searchpairs["searchpair" + i].value = "";
			localstate.searchoptions.searchpairs["searchpair" + i].mode = "like";
			localstate.searchoptions.searchpairs["searchpair" + i].uuid = uuidv4();
		}
		//Set Defaults:
		localstate.searchoptions.searchpairs.searchpair1.type = "SerialNumber";
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	function getRandomInt(max) {
		return Math.floor(Math.random() * Math.floor(max));
	}

	const BulkUpdate = () => {
		//Explanation:
		//Currently checkboxes are uncontrolled, they simply add the indexes to the localstate.selectedindexes, uncommitted to state.
		//This avoids an expensive rerender for large grids.
		var i;
		var random;
		for (i = 0; i < localstate.selectedindexes.length; i++) {
			console.log(localstate.griditems[localstate.selectedindexes[i]].Name);
			random = getRandomInt(3);
			//Update view to reflect pending changes
			rowRefs.current[localstate.selectedindexes[i] + "Cost"].value = random;
			rowRefs.current[localstate.selectedindexes[i] + "SaveStatus"].classList.add("unsavedhighlight");
			//Update localstate
			localstate.griditems[localstate.selectedindexes[i]]["Cost"] = random;
			localstate.griditems[localstate.selectedindexes[i]].unsaved = true;
		}
		localstate.pendingsaves = true;
		btnSave.current.style.color = "white";
		btnSave.current.style.backgroundColor = "#01579B";

	}

	//Export Confirmation and Exports
	const [showexportconfirmation, setShowExportConfirmation] = useState(false);
	//Chooses between "selected" or "searchresults"
	const [exporttype, setExportType] = useState("");
	//Export Message
	const [exportmessage, setExportMessage] = useState("");
	var exportmode = "";
	var exportlimit = 2000;
	var exportfilename = "BoilerplateCSV";

	const InitExport = (exporttype) => {
		if (exporttype === "selected" && localstate.selectedindexes.length === 0) {
			//Do nothing
		} else {
			//Show export type selection
			setShowExportConfirmation(true);
			//Set export by "selected" or "searchresults"
			setExportType(exporttype);
			if (exporttype === "selected") {
				setExportMessage("");
			}
			if (exporttype === "searchresults") {
				setExportMessage("Exports of search results will be limited to " + exportlimit + " rows.");
			}
		}
	}

	const PrepareExport = (mode) => {
		//Set global
		exportmode = mode;
		var exportdata = [];
		var clonedata = [];
		if (exporttype === "selected") {
			//Need to DEEP clone:
			var clonedata = JSON.parse(JSON.stringify(localstate.griditems))
			for (var i = 0; i < localstate.selectedindexes.length; i++) {
				exportdata.push(clonedata[localstate.selectedindexes[i]]);
			}
			Export(exportdata);
		}
		if (exporttype === "searchresults") {
			const postdata = {
				searchoptions: {
					//Set Max Limit Here
					limit: exportlimit,
					currentsort: localstate.orderby,
					currentsortdir: localstate.order,
					searchpairs: localstate.searchoptions.searchpairs
				}
			};
			axios.post(dbendpoint + "/basictable?page=" + (localstate.page + 1), postdata, defaultpostoptions).then(res => {
				if (res.status === 200) {
					if (res.data.Status === "login") {
						window.location.reload(false);
					}
					if (res.data.Status === "Success") {
						exportdata = res.data.pagedata.data;
						Export(exportdata);
					}
					if (res.data.Status === "Failure") {
						errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
					}
				} else {
					errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
				}
			});
		}
	}

	const Export = (exportdata) => {
		//Remove Metas
		for (var i = 0; i < exportdata.length; i++) {
			removeProp(exportdata[i], "isSelected");
			removeProp(exportdata[i], "unsaved");
			removeProp(exportdata[i], "ExpandRow");
			removeProp(exportdata[i], "GridKey");
		}

		//Use Export Mode to pare data
		if (exportmode === "simple") {
			for (var i = 0; i < exportdata.length; i++) {
				removeProp(exportdata[i], "Date");
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode === "expanded") {
			for (var i = 0; i < exportdata.length; i++) {
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode === "exhaustive") {
			//Remove nothing - or possibly break down nested relationships
		}
		ExportCSV(exportdata, exportfilename);
		setShowExportConfirmation(false);
	}



	/* ##########################  TABLE HEAD  ########################## */
	/* ##########################  Column Configuration  ########################## */
	const headCells = [
		//Be sure to adjust widths for cells as well.
		{ id: "SerialNumber", numeric: false, label: "Serial Number", align: "left", allowsort: true, style: { width: "400px" } },
		{ id: "Cost", numeric: true, label: "Cost", align: "right", allowsort: true, style: { width: "100px" } },
		{ id: "OldCost", numeric: true, label: "Old Cost", align: "right", allowsort: true, style: { width: "100px" } },
		{ id: "Spacer", numeric: true, label: "", align: "right", allowsort: true, style: {} },
	];

	function EnhancedTableHead(props) {
		const { classes, onSelectAllClick, order, onRequestSort } = props;
		const createSortHandler = (property) => (event) => {
			onRequestSort(event, property);
		};

		return (
			<thead style={{ display: "table-header-group" }}>
				<tr style={{
					border: "1px solid #CCC",
					backgroundColor: "#DDD"
				}}>
					<td style={{ width: "14px", padding: "none", display: "table-cell", padding: "2px 4px 2px 5px" }}>

						<Checkbox
							className={classes.gridcheckbox}
							disableRipple
							color="default"
							defaultChecked={localstate.griditems.length === localstate.selectedindexes.length}
							checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
							icon={<span className={classes.icon} />}
							onChange={onSelectAllClick}
						/>

					</td>
					{/* Map remaining table headers */}
					{headCells.map((headCell) =>
						colstate[headCell.id] &&
						(
							<td
								key={headCell.id}
								align={headCell.align}
								style={headCell.style}
							>
								{(headCell.allowsort) &&
									<TableSortLabel
										active={localstate.orderby === headCell.id}
										direction={localstate.orderby === headCell.id ? order : "asc"}
										onClick={createSortHandler(headCell.id, headCell.allowsort)}
										hideSortIcon
									>
										{/* This is a conditional for headCell where the id is "Name". This allows us to put a spacer in here for the expand icon button */}
										{/* The expand button is optional, this can be removed if needed! */}
										{(headCell.id === "Name") &&
											<div style={{ width: "30px", display: "inline-block" }}></div>
										}
										{/* If current sort, show bold label */}
										{(localstate.orderby === headCell.id)
											? <span style={{ fontWeight: "bold" }}>{headCell.label}</span>
											: <span>{headCell.label}</span>
										}
									</TableSortLabel>
								}
								{(!headCell.allowsort) &&
									<span>{headCell.label}</span>
								}
							</td>
						))}
				</tr>
			</thead>
		);
	}

	EnhancedTableHead.propTypes = {
		classes: PropTypes.object.isRequired,
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(["asc", "desc"]).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};

	{/* Utility Drawer state and functions */ }
	const [showutilitydrawer, setShowUtilityDrawer] = useState(false);

	const CloseUtilityDrawer = () => {
		setShowUtilityDrawer(false);
		//Reload DB?
		localstate.dbreload = true;
		//Update State?
		UpdateState(localstate);
	}

	const [itemcosting, setItemCosting] = useState("");



	///Changes to sku list text area
	const onChangeCostingList = (newvalue) => {
		setItemCosting(newvalue);
	}


	/* ##########################  Render Function  ########################## */
	return (
		<div style={{ padding: "8px" }}>

			{/* Utility Drawer, good for search options */}
			<Drawer open={showutilitydrawer} style={{ width: "600px" }}>
				<Typography variant="h4" gutterBottom align="center">
					Import Data
				</Typography>
				<div style={{ width: "400px", padding: "10px" }}>
					<Typography variant="subtitle1" gutterBottom>
						Copy and paste from Excel columns Serial Number and Cost<br></br>
					</Typography>

					{/* You may want access to errors here */}
					{(errors.currenterror.errshow) &&
						<div style={{ textAlign: "center", height: "25px", fontSize: "12px" }}>
							<ErrorMessage />
						</div>
					}

					<Button
						className={classes.bluebtn}
						color="primary" variant="contained"
						onClick={() => CloseUtilityDrawer()}>
						Close
					</Button>

					<TextareaAutosize style={{ width: "100%" }} minRows={10} placeholder="Paste from Excel: SerialNumber and Cost rows."
						onChange={(event) => onChangeCostingList(event.target.value)}
						value={itemcosting}
					/>

				</div>
			</Drawer>




			{/* Standard Page Header with right floated error message space */}
			<div style={{ height: "35px" }}>
				<div style={{ textAlign: "center" }}>
					<h2>Item Costing</h2>
				</div>
				{(errors.currenterror.errshow) &&
					<div style={{ position: "relative", float: "right", bottom: "26px", height: "25px", fontSize: "12px" }}>
						<ErrorMessage />
					</div>
				}
			</div>


			{/* Top Buttons & Pagination */}
			<div style={{ height: "5px" }}>&nbsp;</div>
			<div>
				{(!showdeleteconfirmation && !showexportconfirmation) &&
					<React.Fragment>

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => SaveChanges()}
							ref={el => btnSave.current = el}>
							Save Changes
						</Button>

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => setShowUtilityDrawer(true)}>
							Import Values
						</Button>

					</React.Fragment>
				}

				{/* Delete Items Confirmation */}
				{(showdeleteconfirmation) &&
					<div>
						<b>Are you sure you want to delete these items?</b>
						<div style={{ padding: "10px 0px" }}>
							{deleteitems.map((row, index) => {
								if (deleteitems.length === index + 1) {
									return (<span key={index}>{row.Name}</span>)
								} else {
									return (<span key={index}>{row.Name}, </span>)
								}
							})}
						</div>
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained" onClick={() => DeleteSelected()}>Yes, Delete Items</Button>&nbsp;&nbsp;
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained" onClick={() => CancelDelete()}>Cancel</Button>
					</div>
				}

				{/* Export Mode Confirmation */}
				{(showexportconfirmation) &&
					<div>
						<b>Export Mode</b><br></br>
						In most cases a simple export is appropriate as to avoid revealing information to potential buyers. {exportmessage}
						<div style={{ paddingTop: "10px" }}>
							<Button
								className={classes.bluebtn}
								color="primary" variant="contained"
								onClick={() => PrepareExport("simple")}>
								Simple
							</Button>
							<Button
								className={classes.bluebtn}
								color="primary" variant="contained"
								onClick={() => PrepareExport("expanded")}>
								Expanded
							</Button>
							<Button
								className={classes.bluebtn}
								color="primary" variant="contained"
								onClick={() => PrepareExport("exhaustive")}>
								Exhaustive
							</Button>
							<Button
								className={classes.bluebtn}
								color="primary" variant="contained"
								onClick={() => setShowExportConfirmation(false)}>
								Cancel
							</Button>
						</div>
					</div>
				}


			</div>

			{/* ##########################  Start of Table  ########################## */}
			<table aria-label="caption table" size="small" className={classes.flexgrid} style={{ display: "table", tableLayout: "fixed", minWidth: "100%", borderCollapse: "collapse", borderColor: "grey" }}>
				<EnhancedTableHead
					numSelected={localstate.selectedcount}
					classes={classes}
					order={localstate.order}
					orderBy={localstate.orderby}
					onSelectAllClick={handleSelectAllClick}
					onRequestSort={handleRequestSort}
					rowCount={state.griditems.length}
				/>
				{/* /* ##########################  Row Design  ########################## */}
				{/* If DB reload, don't allow view of table. */}
				{(!localstate.dbreload) &&
					<tbody style={{ display: "table-row-group" }}>
						{(localstate.griditems.length > 0) &&
							localstate.griditems.map((row, index) => {
								//Create all-new refs on each render. Helps avoid issues with grid states.
								rowRefs.current[index + "Checkbox"] = React.createRef();
								rowRefs.current[index + "SaveStatus"] = React.createRef();
								rowRefs.current[index + "SerialNumber"] = React.createRef();
								rowRefs.current[index + "Cost"] = React.createRef();
								return (
									<React.Fragment key={row.ItemID}>
										<tr className={classes.flexgridrow}>
											{/* Checkbox - Requires inner div to change background color with SaveStatus */}
											<td style={{ verticalAlign: "top" }} ref={el => rowRefs.current[index + "SaveStatus"] = el} className={row.unsaved ? classes.unsavedhighlight : ""}>
												<div style={{ padding: "3px 4px 1px 4px" }}>
													{/*	MaterialUI Checkbox will pass a shallow comparison between UpdateState(s). Be sure GridKey changes if it needs to be rerendered such as selectall.	*/}
													{/* Don't show checkbox if this is a PendingItem */}
													{(!row.PendingItem) &&
																<Checkbox
																	key={row.GridKey}
																	inputRef={el=>rowRefs.current[index+"Checkbox"]=el} 
																	className={classes.gridcheckbox}
																	color="default"
																	defaultChecked={localstate.griditems[index].isSelected ? true : false}
																	checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onKeyDown={(event) => HandleKeyDown(event, index, "Checkbox")}
																	onChange={() => SelectRow(index)}
																/>
															}

												</div>
											</td>

											{/* Name - Optional Expand toggle! Change flexgridinput-30 to flexgridinput to remove icon spacer. 
											className={row.Duplicate ? classes.lightred : ""}
											
											*/}
											{(colstate.SerialNumber) &&
												<td className={classes.flexgridstaticcontainer}>
													<span ref={el => rowRefs.current[index + "SerialNumber"] = el} className={row.Duplicate ? classes.gray : ""}>
														<span className={row.NotFound ? classes.strikethrough : ""}>{row.SerialNumber}</span>
													</span>
												</td>
											}


											{/* COST */}
											{(colstate.Cost) &&
												<td className={classes.flexgridinputcontainer}>
													<input
														disabled={row.NotFound}
														type="number" step="0.01"
														ref={el => rowRefs.current[index + "Cost"] = el}
														className={classes.flexgridinput}
														style={{ minWidth: "50px", textAlign: "right" }}
														onKeyDown={(event) => HandleKeyDown(event, index, "Cost")}
														onKeyUp={(event) => onChangeValue(event, index, "Cost")}
														onBlur={(event) => DetectBlankNumber(event, index, "Cost")}
														defaultValue={row.Cost} />
												</td>
											}

											{/* Old COST */}
											{(colstate.OldCost) &&
												<td className={classes.flexgridstaticcontainer} style={{ textAlign: "right" }}>
													<span>{row.OldCost}</span>
												</td>
											}



											{(colstate.Spacer) &&
												<td className={classes.flexgridinputcontainer}>

												</td>
											}



										</tr>
										{/* Try: conditional for any render whatsoever! */}
										{(row.ExpandRow === true) &&
											<tr>
												<td colSpan="100%">
													<div style={{ margin: "25px" }}>
														Expanded column container!
													</div>
												</td>
											</tr>
										}
									</React.Fragment>

								)
							}
							)
						}
						{(localstate.griditems.length === 0) &&
							<tr className="flexgridrow"><td colSpan="100%"
								style={{ padding: "12px", fontSize: "18px" }}>No Results</td></tr>
						}
					</tbody>
				}
				{(localstate.dbreload) &&
					<tbody>
						<tr>
							<td colSpan="100%">
								<div style={{ padding: "20px", textAlign: "center", margin: "auto" }}>
									<CircularProgress />
								</div>
							</td>
						</tr>
					</tbody>
				}
			</table>


			<div style={{ fontSize: "16px" }}>
				<br></br>

				How to use: Use a spreadsheet with only 2 columns (SerialNumber, Cost). Copy only the values into the import values text field. Close the input text field and the system will load the items matching your serials as well as serials not found.


				<br></br>
				Duplicates serials found are highlighted in gray. Review these serials carefully before saving changes. <div style={{ display: "inline-block", width: "10px", height: "10px", backgroundColor: "#DDDDDD" }}></div><br></br>
				Serials not found in database will be marked with <s>strikethrough</s> text.<br></br>
			</div>

		</div>
	);
}

export default Costing;
